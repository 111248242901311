import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import MyCampaignCard from './MyCampaignCard';
import {
	getMyCampaigns,
	setContentCreator,
	updateUserProfileImage
} from '../Api';

import { Link } from 'react-router-dom';
import CustomLoader from '../CustomLoader';
import ProfileStatsBar from '../Profile/ProfileStatsBar';
import SidebarTrending from '../SidebarComponent/SidebarTrending';
import { BsFillChatSquareQuoteFill } from 'react-icons/bs';
import ProfileBar from '../SidebarComponent/profileStatus';
import { uploadToCloudinary } from '../ImageCropper/canvasUtils';
import { Modal, Button } from 'react-bootstrap';
import Cropper from 'react-easy-crop';
import './winner.scss';

import LoadingModal from '../LoadingModal/LoadingModal';

export default function MyCampaigns() {
	const [campaignData, setcampaignData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [userImageUrl, setuserImageUrl] = useState(null);
	const [userImage, setuserImage] = useState(null);
	const handleCloseProfile = () => setShowModal(false);
	const handleShowProfile = () => setShowModal(true);
	const [showCropper, setShowCropper] = useState(false);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState('');
	const [profileImgExist, setProfileImgExist] = useState(false);
	const [regularCreator, setRegularCreator] = useState(false);
	const [userDetail, setuserDetail] = useState(null);
	const [isDownloadingImg, setIsDownloadingImg] = useState(false);

	var data = JSON.parse(localStorage.getItem('InspirerWorld'));

	useEffect(() => {
		getCampaigns();
		if (data) {
			setuserDetail(data);
			console.log('user is', data);
		}
	}, []);

	const imageUploader = async () => {
		const res = await uploadToCloudinary(
			userImageUrl,
			croppedAreaPixels,
			userImageUrl
		);

		let data = { profileImg: res.url };
		console.log(res);
		let resBackend = await updateUserProfileImage(data);
		let resContent = await setContentCreator({
			isContentCreator: regularCreator
		});
		if (!resBackend.error) {
			const done = JSON.parse(localStorage.getItem('InspirerWorld'));
			console.log(res);
			let newdata = {
				token: done.token,
				isLeader: done.isLeader,
				userId: done.userId,
				teamId: done.teamId,
				type: done.type,
				profileImg: res.url,
				name: done.name,
				expiry: done?.expiry
			};
			console.log(newdata.profileImg, 'succes', resContent);

			//   localStorage.removeItem('InspirerWorld')
			await localStorage.setItem('InspirerWorld', JSON.stringify(newdata));
			// console.log("success")
			setProfileImgExist(true);
			// handleCloseProfile()
			//window.location.reload()
		} else {
			console.log(resBackend);
		}
	};
	const imageChange = async image => {
		setuserImage(image);
		console.log('File is', image);
		//  const url = URL.createObjectURL(image);
		const url = URL.createObjectURL(image);
		setuserImageUrl(url);
		setShowCropper(true);
		//setShowCropper(true);
	};

	const onCropComplete = (croppedArea, croppedAreaPixels) => {
		console.log(croppedArea, croppedAreaPixels);
		setCroppedAreaPixels(croppedAreaPixels);
	};
	const getCampaigns = async () => {
		setLoading(true);
		const res = await getMyCampaigns();
		console.log('res is ', res.res.data.campaigns);
		setcampaignData(res.res.data.campaigns);
		console.log(true);
		if (!res.res.data.campaigns[0]) {
			handleShowProfile();
		}
		setLoading(false);
		return;
	};

	return (
		<>
			<Helmet>
				<title>Feed</title>
			</Helmet>
			<div className="myCompainContainer">
				{campaignData && (
					<ProfileStatsBar
						setIsDownloadingImg={setIsDownloadingImg}
						topCampaign={campaignData[0]}
					/>
				)}
				<Modal show={showCropper}>
					<Modal.Header closeButton={() => setShowCropper(false)}>
						<Modal.Title>Upload Image</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div
							id="crop-section"
							style={{
								width: '100vw',
								top: '0',
								bottom: '0',
								left: '0',
								right: '0',
								height: '50vh'
							}}
						>
							<Cropper
								style={{ zIndex: 100, backgroundColor: 'black' }}
								image={userImageUrl}
								crop={crop}
								zoom={zoom}
								aspect={780 / 780}
								onCropChange={crop => {
									setCrop(crop);
								}}
								onCropComplete={onCropComplete}
							/>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<input
							type="range"
							value={zoom}
							min="1"
							max="3"
							step="0.1"
							onChange={e => {
								setZoom(e.target.value);
							}}
							className="custom-range"
							id="customRange1"
						/>
						<br />
						<Button onClick={() => setShowCropper(false)}>Crop</Button>
					</Modal.Footer>
				</Modal>

				<Modal show={showModal} onHide={handleCloseProfile}>
					<Modal.Body>
						<div className="container row m-auto">
							<div className="row">
								<div className="col-12 p-2 text-center mb-5 m-auto">
									<h4>Thank for showing interest in Inspire World </h4>
									<span>Please let just know about you </span>
								</div>

								<div className="col-8 text-center ml-auto mr-auto mt-10 ">
									<h5 className="mt-4">Update your Profile Picture</h5>
									<input
										type="file"
										onChange={e => {
											imageChange(e.target.files[0]);
										}}
										id="chooseImage"
										className="d-none"
									/>
									<button
										className="upload-half-buttons mt-3  m-auto"
										onClick={() =>
											document.getElementById('chooseImage').click()
										}
									>
										Click here to upload your picture
									</button>
								</div>
								<div className="col-8  ml-auto mr-auto mt-10 text-center ">
									<h5 className="mt-4">Are You a regular content Creator?</h5>

									<button
										id="CreatorTrue"
										onClick={() => {
											document.getElementById('CreatorTrue').style.background =
												'green';
											document.getElementById('CreatorFalse').style.background =
												'#146d9c';
											setRegularCreator(true);
										}}
										className="upload-half-buttons rounded   m-auto"
									>
										yes
									</button>
									{'                     '}

									<button
										id="CreatorFalse"
										onClick={e => {
											document.getElementById('CreatorTrue').style.background =
												'#146d9c';
											document.getElementById('CreatorFalse').style.background =
												'green';

											setRegularCreator(false);
										}}
										className="upload-half-buttons rounded   m-auto"
									>
										No
									</button>
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary " onClick={handleCloseProfile}>
							Close
						</Button>
						<Button
							variant="primary"
							id="uploadbtn"
							onClick={() => {
								document.getElementById('uploadbtn').style.background = 'green';
								imageUploader();
							}}
						>
							Update
						</Button>
					</Modal.Footer>
				</Modal>

				<LoadingModal
					loading={isDownloadingImg}
					message="Downloading Artwork"
				/>

				<br className="lineBreaker" />
				<CustomLoader loading={loading} />

				<div className="container containerWrapper mt-2">
					<div className="row ">
						<ProfileBar />

						<div className="col-lg-7  col-sm-9 " style={{ marginLeft: '0' }}>
							<div className="d-flex justify-content-between mb-2">
								<Link
									to="/suggesttopic"
									className="mr-2"
									style={{ width: '49%' }}
								>
									<button
										className="suggest-quote-button learn-more mb-1 radius-5 radius-5 "
										style={{ fontSize: '14px', textAlign: 'center' }}
									>
										Suggest A Topic
									</button>
								</Link>
								<Link to="/suggestquote" style={{ width: '49%' }}>
									<button
										className="suggest-quote-button bg-danger radius-5 "
										style={{ fontSize: '14px' }}
									>
										<BsFillChatSquareQuoteFill
											color="white"
											style={{ width: '8%' }}
										/>{' '}
										Suggest A Quote
									</button>
								</Link>
							</div>
							{campaignData &&
								campaignData.map((item, index) => {
									return (
										<MyCampaignCard
											getMyCampaigns={getCampaigns}
											key={index}
											c_number={campaignData.length - index}
											item={item}
											index={index}
										/>
									);
								})}
						</div>

						<SidebarTrending isHome={true} />
					</div>
				</div>
			</div>
		</>
	);
}
