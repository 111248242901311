import React from "react";
import Slider from "react-slick";
import { data } from "../Users/UserProfile";

export default function Testimonial() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    navs: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <section className="section section-testimonials  gradient-light--lean-left pt-2">
        {/* Highlight SVG Background */}

        {/* <figure className="figure highlight-background highlight-background--lean-left">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="1439px"
            height="480px"
          >
            <defs>
              <linearGradient
                id="PSgrad_5"
                x1="42.262%"
                x2="0%"
                y1="90.631%"
                y2="0%"
              >
                <stop
                  offset="28%"
                  stopColor="rgb(245,246,252)"
                  stopOpacity={1}
                />
                <stop
                  offset="100%"
                  stopColor="rgb(255,255,255)"
                  stopOpacity={1}
                />
              </linearGradient>
            </defs>
            <path
              fillRule="evenodd"
              fill="rgb(255, 255, 255)"
              d="M863.247,-271.203 L-345.788,-427.818 L760.770,642.200 L1969.805,798.815 L863.247,-271.203 Z"
            />
            <path
              fill="url(#PSgrad_5)"
              d="M863.247,-271.203 L-345.788,-427.818 L760.770,642.200 L1969.805,798.815 L863.247,-271.203 Z"
            />
          </svg>
        </figure> */}

        <div className="container responsive-pad-remove">
          <div className="row">
            <div className="col-md-12 text-center ">
              <h2
                className="mt-2 mb-5 primary-text-color responseive-head-test mb-md-0"
                style={{ fontWeight: "bold", color: "#146d9c" }}
              >
                Our Happy Members
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className=" responsive-pad-remove">
                <div className="">
                  <Slider {...settings}>
                    {data.map((row) => {
                      return (
                        <div className=" testimony__card p-3">
                          <blockquote className="blockquote shadow">
                            <span className="rating text-warning d-block mb-4">
                              <i className="fas fa-star" />
                              <i className="fas fa-star" />
                              <i className="fas fa-star" />
                              <i className="fas fa-star" />
                              <i className="fas fa-star" />
                            </span>
                            <p
                              className="mb-4 min-height-400"
                              style={{ fontSize: "16px" }}
                            >
                              {row.message}
                            </p>
                            <footer className="blockquote-footer d-flex align-items-center">
                              <div className="testimony__avatar d-inline-block mr-3">
                                <img
                                  className="rounded-circle"
                                  src={row.image}
                                  alt="Avatar"
                                />
                              </div>
                              <div className="testimony__info d-inline-block">
                                <span
                                  className="info-name d-block"
                                  style={{ color: "#146d9c" }}
                                >
                                  {row.name}
                                </span>
                                <span className="info-company d-block">
                                  {row.role}
                                </span>
                              </div>
                            </footer>
                            <a
                              href={"./meet/" + row.slug}
                              className="readMoreBtn"
                            >
                              Read More <i className="fas fa-arrow-right"></i>
                            </a>
                          </blockquote>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </div>
              {/* End Swiper Container */}
            </div>
          </div>
        </div>
      </section>
      {/* END Section Testimonials */}
    </div>
  );
}
