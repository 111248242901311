import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import { Image, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import tropy_img from '../../images/trophy.png';
import { Helmet } from 'react-helmet-async';

import axios from 'axios';
import { Button } from 'react-bootstrap';
import { updateCampaignStats } from '../Api';
import fileDownload from 'js-file-download';
import { BsThreeDots } from 'react-icons/bs';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import StatsComponent from '../TeamLeader/StatsComponent';

import tropy_img2 from '../../images/Winner-Fill.png';
import Comments_img from '../../images/Comments.png';
import View_img from '../../images/VIew.png';
import Like_img from '../../images/Like.png';
import tropy_img3 from '../../images/trophy-3.png';

export default function MyCampaignCard({
	index,
	item,
	userId,
	getMyCampaigns
}) {
	// console.log('Item is',item)
	useEffect(() => {
		var data = JSON.parse(localStorage.getItem('InspirerWorld'));

		if (data) {
			setPic2(data.profileImg);
		}
		return () => {};
	}, []);
	const [disabledClaim, setDisabledClaim] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [Pic2, setPic2] = useState('');
	const [imgUrl, setimgUrl] = useState(null);
	const [ModifieProfilePic, setModifieProfilePic] = useState(null);
	const [show, setShow] = useState(false);
	const [likes, setlikes] = useState(0);
	const [views, setviews] = useState(0);
	const [linkedinUrl, setlinkedinUrl] = useState('');
	const [comments, setcomments] = useState(0);
	const [winner, setWinner] = useState(0);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [Winnertitles, setWinnerTitles] = useState([]);
	const [user, setUser] = useState('');

	useEffect(() => {
		let winnertitles = [];
		if (item.winner.contentCreator.find(element => element === userId)) {
			winnertitles.push('BEST CONTENT CREATOR');
		}
		if (item.winner.viewership.find(element => element === userId)) {
			winnertitles.push('BEST VIEWERSHIP');
		}
		if (item.winner.engager.find(element => element === userId)) {
			winnertitles.push('BEST ENGAGER');
		}
		setWinnerTitles(winnertitles);
		var inspirerData = JSON.parse(localStorage.getItem('InspirerWorld'));
		var Pic2 = inspirerData.profileImg;
		setUser(inspirerData);
		setPic2(Pic2);
	}, [0]);

	const finalFuncToDownloadProfilePic = async () => {
		let url = item.participants[0].imageUrl;
		let filename = `${item.title}.jpeg`;
		axios
			.get(url, {
				responseType: 'blob'
			})
			.then(res => {
				fileDownload(res.data, filename);
			});
	};

	const updateStats = async () => {
		let postUrl = linkedinUrl;
		let data = {
			likes,
			comments,
			views,
			postUrl
		};

		const res = await updateCampaignStats(item._id, data);
		if (res.error) {
			alert('Some Error occurred');
		} else {
			getMyCampaigns();
			return handleClose();
		}
	};

	return (
		<>
			<Helmet>
				<title>My Achievments</title>
			</Helmet>
			<div className="col-sm-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
				{index === 0 ? (
					<div className="campaign-card card">
						<div className="campaign-header-div myAchievmentsCard">
							<div>
								<h5 style={{ display: 'flex', alignItems: 'center' }}>
									<Image
										className="winner-dp-image trending-quotes-dp"
										style={{
											height: '35px',
											width: '35px',
											marginRight: '10px'
										}}
										src={Pic2}
										rounded
										roundedCircle
									/>
									<span
										style={{
											fontSize: '14px',
											fontWeight: 'bold',
											display: 'flex',
											flexDirection: 'column'
										}}
									>
										{user && user.name.toUpperCase()}
										<span style={{ color: '#898989', fontSize: '10px' }}>
											<Moment format="DD/MM/YYYY">{item.startsOn}</Moment>
										</span>
									</span>
								</h5>
							</div>
							<div className="d-flex profile-topbar-winner-button">
								<strong
									className="profile-topbar-winner-title"
									style={{ fontWeight: 'bold', fontSize: '12px' }}
								>
									{Winnertitles[0]}
								</strong>
								<img
									src={tropy_img3}
									alt="tropy"
									style={{
										marginLeft: '10px',
										width: '25px',
										height: '36px'
									}}
								/>
							</div>
							{/* {Winnertitles.length === 1 ? (
								<div className="d-flex profile-topbar-winner-button">
									<strong
										className="profile-topbar-winner-title"
										style={{ fontWeight: 'bold' }}
									>
										{Winnertitles[0]}
									</strong>
									<img
										src={tropy_img3}
										alt="tropy"
										style={{
											marginLeft: '10px',
											width: '25px',
											height: '36px'
										}}
									/>
								</div>
							) : (
								<div className="d-flex profile-topbar-winner-button">
									<strong
										className="profile-topbar-winner-title"
										style={{
											fontWeight: 'bold',
											fontSize: '12px',
											marginRight: '5px'
										}}
									>
										{Winnertitles[0]}
									</strong>
									<div class="dropdown dropleft">
										<div
											style={{ cursor: 'pointer', color: 'white' }}
											type="button"
											id="dropdownMenuButton"
											data-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false"
										>
											<MoreHorizIcon
												style={{ color: '#146d9c' }}
												size={24}
												color="#146d9c"
											/>
										</div>
										<div
											class="dropdown-menu"
											aria-labelledby="dropdownMenuButton"
										>
											<div className="d-flex profile-topbar-winner-button">
												<strong
													style={{ fontSize: '12px' }}
													className="profile-topbar-winner-title"
												>
													{Winnertitles[1]}
												</strong>
												<img
													style={{ width: '32px' }}
													alt="tropy"
													className="profile-topbar-winner-icon"
													src={tropy_img}
												/>
											</div>
											{Winnertitles.length === 3 ? (
												<div className="d-flex profile-topbar-winner-button">
													<strong
														style={{ fontSize: '12px' }}
														className="profile-topbar-winner-title"
													>
														{Winnertitles[2]}
													</strong>
													<img
														style={{ width: '32px' }}
														className="profile-topbar-winner-icon"
														src={tropy_img}
														alt="tropy"
													/>
												</div>
											) : null}
										</div>
									</div>
								</div>
							)} */}
						</div>
						<div
							style={{
								padding: '10px',
								paddingBottom: '0',
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center'
							}}
						>
							<h4
								style={{
									fontSize: '14px',
									color: '#146d9c',
									display: 'flex',
									flexDirection: 'column',
									fontWeight: 'bold'
								}}
							>
								<span style={{ color: '#898989', fontSize: '10px' }}>
									Compain {index + 1}
								</span>
								{item.title}
							</h4>
						</div>

						<div
							className="trendingcard-image-div"
							style={{ padding: '15px 10px' }}
						>
							{/* <img  src={imgUrl} /> */}
							{item.artWork && (
								<a href={item.artWork.postUrl} target="_blank" rel="noreferrer">
									<img
										src={item.artWork.imgUrl}
										className="card-img-top"
										alt="..."
									/>
								</a>
							)}
						</div>

						<div className="card-body" style={{ padding: 0 }}>
							<div
								className="trendingcard-header trendingcard-footer row"
								style={{ justifyContent: 'space-between' }}
							>
								<div className="col-3 text-center p-0 m-0">
									<StatsComponent link={Like_img} value={item.likes} w={33} />
								</div>
								<div className="col-3 text-center p-0 m-0">
									<StatsComponent
										link={Comments_img}
										value={item.comments}
										w={33}
									/>
								</div>
								<div className="col-3 text-center p-0 m-0">
									<StatsComponent link={View_img} value={item.views} w={33} />
								</div>
								<div className="col-3 text-center p-0 m-0 bg-primary trending-card-trophy">
									<div className="d-flex margin-auto">
										<Link
											className="margin-auto w-100"
											style={{ color: 'white' }}
											to="/winners"
										>
											<StatsComponent
												link={tropy_img2}
												value={winner}
												w={33}
												lastItem={true}
											/>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				) : (
					<div className="campaign-card card mt-4">
						<div className="campaign-header-div myAchievmentsCard">
							<div>
								<h5 style={{ display: 'flex', alignItems: 'center' }}>
									<Image
										className="winner-dp-image trending-quotes-dp"
										style={{
											height: '35px',
											width: '35px',
											marginRight: '10px'
										}}
										src={Pic2}
										rounded
										roundedCircle
									/>
									<span
										style={{
											fontSize: '14px',
											fontWeight: 'bold',
											display: 'flex',
											flexDirection: 'column'
										}}
									>
										{user && user.name.toUpperCase()}
										<span style={{ color: '#898989', fontSize: '10px' }}>
											<Moment format="DD/MM/YYYY">{item.startsOn}</Moment>
										</span>
									</span>
								</h5>
							</div>

							<div className="d-flex profile-topbar-winner-button">
								<strong
									className="profile-topbar-winner-title"
									style={{ fontWeight: 'bold', fontSize: '12px' }}
								>
									{Winnertitles[0]}
								</strong>
								<img
									src={tropy_img3}
									alt="tropy"
									style={{
										marginLeft: '10px',
										width: '25px',
										height: '36px'
									}}
								/>
							</div>
						</div>

						<div
							style={{
								padding: '10px',
								paddingBottom: '0',
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center'
							}}
						>
							<h4
								style={{
									fontSize: '14px',
									color: '#146d9c',
									display: 'flex',
									flexDirection: 'column',
									fontWeight: 'bold'
								}}
							>
								<span style={{ color: '#898989', fontSize: '10px' }}>
									Compain {index + 1}
								</span>
								{item.title}
							</h4>
						</div>
						<div
							className="trendingcard-image-div"
							style={{ padding: '15px 10px' }}
						>
							{/* <img  src={imgUrl} /> */}
							{item.artWork && (
								<a href={item.artWork.postUrl} target="_blank" rel="noreferrer">
									<img
										src={item.artWork.imgUrl}
										className="card-img-top"
										alt="..."
									/>
								</a>
							)}
						</div>

						<div className="card-body" style={{ padding: 0 }}>
							<div
								className="trendingcard-header trendingcard-footer row"
								style={{ justifyContent: 'space-between' }}
							>
								<div className="col-3 text-center p-0 m-0">
									<StatsComponent link={Like_img} value={item.likes} w={33} />
								</div>
								<div className="col-3 text-center p-0 m-0">
									<StatsComponent
										link={Comments_img}
										value={item.comments}
										w={33}
									/>
								</div>
								<div className="col-3 text-center p-0 m-0">
									<StatsComponent link={View_img} value={item.views} w={33} />
								</div>
								<div className="col-3 text-center p-0 m-0 bg-primary trending-card-trophy">
									<div className="d-flex margin-auto">
										<Link
											className="margin-auto w-100"
											style={{ color: 'white' }}
											to="/winners"
										>
											<StatsComponent
												link={tropy_img2}
												value={winner}
												w={33}
												lastItem={true}
											/>
										</Link>
									</div>
								</div>
							</div>

							{/* <span style={{ display: "flex", flexDirection: "row" }}>
      <a
        style={{
          marginTop: "5px",
          fontSize: "12px",
          marginLeft: 0,
          color: "white",
        }}
        className="btn btn-info"
        onClick={() => handleShow()}
      >
        Update Stats
      </a>
      
    </span>
  */}
						</div>
					</div>
				)}
			</div>
		</>
	);
}
