import React, { useState, useEffect } from 'react'
import {  authorDetail, editAuthor } from '../Api'
import { Modal, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import ImageCropper from '../ImageCropper/ImageCropperEdit'
import { uploadToCloudinary } from '../ImageCropper/canvasUtils'
import Cropper from "react-easy-crop";

export default function EditQuote({match}) {
  //const [name, setname] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [loading, setLoading] = useState(false)

 // const [userImage, setChanged] = useState(null);
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels);
    setCroppedAreaPixels(croppedAreaPixels);
  };

 
  const [authorImage, setauthorImage] = useState('');
  const [defaultImage, setdefaultImage] = useState('');
  const [name, setname] = useState("");
 
 // const [campDesc, setcampDesc] = useState("");
 let location=useHistory();
 const [croppedAreaPixels, setCroppedAreaPixels] = useState("");
 
 const [showCropper, setShowCropper] = useState(false);
  
  const handleClose = () => setShowCropper(false);
  const handleShow = () => setShowCropper(true);

    useEffect(() => {
        fetchCampaignDetail(match.params.id)
    }, [])
    const fetchCampaignDetail = async (campaignId) => {
        const res = await authorDetail(campaignId)
 console.log('author details',res)
 setname(res.res.data.author.name)
 setauthorImage(res.res.data.author.imageUrl)
 setdefaultImage(res.res.data.author.imageUrl)
    }
    const imageChange = async (image) => {
      setauthorImage(image);
      console.log("File is", image);
      //  const url = URL.createObjectURL(image);
      const url = URL.createObjectURL(image);
      setauthorImage(url);
      setShowCropper(true);
      //setShowCropper(true);
    };
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    let url=defaultImage
    if(defaultImage!==authorImage){

      const res = await uploadToCloudinary(
        authorImage,
        croppedAreaPixels,
        authorImage
        );
        url=res.url
      }

    let data = { imageUrl: url,name:name };
    const res2=await editAuthor(match.params.id,data);
    console.log(res2)
    if(!res2.error){
      setLoading(false)
      location.push({
        pathname: '/create-author',
        state: { detail: 'Success' }
      })

    }
    else{
    setLoading(false)
    
    alert("Something went Wrong");
      
    }
  }


 

 
  return (
    <div className="container">
           <div className="row">
        <div className="col-lg-1"></div>
        <br />
        <br />
        <br />
        <div className="col-lg-10 mb-2 contact-form__wrapper p-1 order-lg-1">
          <center>
            <h2>Edit Author</h2>
          </center>

          <br />

         
            <div className="row">
            <Modal show={showCropper} onHide={() => setShowCropper(false)}>
        <Modal.Header closeButton={() => setShowCropper(false)}>
          <Modal.Title>Upload Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            id="crop-section"
            style={{
              width: "100vw",
              top: "0",
              bottom: "0",
              left: "0",
              right: "0",
              height: "50vh",
            }}
          >
            <Cropper
              style={{ zIndex: 100, backgroundColor: "black" }}
              image={authorImage}
              crop={crop}
              zoom={zoom}
              aspect={780 / 780}
              onCropChange={(crop) => {
                setCrop(crop);
              }}
              onCropComplete={onCropComplete}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <input
            type="range"
            value={zoom}
            min="1"
            max="3"
            step="0.1"
            onChange={(e) => {
              setZoom(e.target.value);
            }}
            className="custom-range"
            id="customRange1"
          />
          <br />
          <Button onClick={()=>setShowCropper(false)}>Crop</Button>
        </Modal.Footer>
      </Modal>
      <form
            action="#"
            className="contact-form form-validate"
            onSubmit={handleSubmit}
          >
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label>Enter Author</label>
                  <input
                    type="text"
                    placeholder="Enter text"
                    required
                    value={name}
                    onChange={(e) => {
                      setname(e.target.value);
                    }}
                  />
                
                </div>
                <div className='col-8 m-auto'>
<div className="trendingcard-image-div p-2">

          {/* <img  src={imgUrl} /> */}
          {authorImage && (
            <img
            src={authorImage}
            className="card-img-top"
            alt="..."
            />
            )}
            </div>
          
</div>
                <div className="form-group">
                  <input
                    type="file"
                    id="myfileUpload"
                    name="myfile"
                    onChange={(e) => {
                      imageChange(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
              
            
              {/* <div className="col-3"></div> */}
              <div className="col-sm-12 mb-3">
              {!loading?
                <input
                type="submit"
                value="Launch Quote"
                name="submit"
                className="btn btn-primary"
                />
              : <input
              type="submit"
              value="Loading..."
              name="submit"
              className="btn btn-primary"
              />
              }
              </div>
          
            </div>
          </form>
       
     </div>
   
        </div>{" "}
        {/* End Contact Form Wrapper */}
      </div>
  


      </div>
    
  )
}
