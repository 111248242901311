import React, { useEffect, useState } from 'react'
import { getQuoteDetail } from '../Api'

function QuoteDetail({ match }) {
    const [detail, setdetail] = useState(null)
    useEffect(async () => {
        const res = await getQuoteDetail(match.params.id)
        console.log(res)
        setdetail(res.data.quote)
    }, [])
    return (
        <div style={{ marginTop: '10px' }}>
            {detail && <div className="container-fluid">
                <div className="content">


                    <div className="row">
                        <div className="col-md-6 text-capitalize">
                            Quote Title&nbsp;:&nbsp;{detail.title}
                        </div>
                    </div>
                    <br />
                    <div className="row text-capitalize">
                        <div className="col-md-6">
                            Started On&nbsp;:&nbsp;{new Date(detail.startsOn).toDateString()}
                        </div>
                    </div>
                    <br />
                    <div className="row text-capitalize">
                        <div className="col-md-6">
                            Quote description&nbsp;:&nbsp;"{detail.desc}"
                        </div>
                    </div>
                    <br />
                    <div className="row text-capitalize">
                        <div className="col-md-6">
                            Quote status&nbsp;:&nbsp;{detail.active?"Open":"Close"}
                        </div>
                    </div>
                    <br />
                    {/* <div className="row text-capitalize">
                        <div className="col-md-6">
                            winner of Quote&nbsp;:
                            TBA
                            </div>
                    </div> */}
                    <br />

                    <div className="row">
                        {/* <div className="col-md-3">
                            <div className="card mini-stats-wid">
                                <div className="card-body">
                                    <div className="media">
                                        <div className="media-body">
                                            <p className="text-muted font-weight-medium">Participants</p>
                                            <h4 className="mb-0">84</h4>
                                        </div>

                                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                            <span className="avatar-title">
                                                <i className="bx bx-user font-size-24"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="col-md-3">
                            <div className="card mini-stats-wid">
                                <div className="card-body">
                                    <div className="media">
                                        <div className="media-body">
                                            <p className="text-muted font-weight-medium">Views</p>
                                            <h4 className="mb-0">{detail.views}</h4>
                                        </div>
                                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                            <span className="avatar-title">
                                                <i className="mdi mdi-eye font-size-24"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="card mini-stats-wid">
                                <div className="card-body">
                                    <div className="media">
                                        <div className="media-body">
                                            <p className="text-muted font-weight-medium">Comments</p>
                                            <h4 className="mb-0">{detail.comments}</h4>
                                        </div>
                                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle bg-primary">
                                                <i className="mdi mdi-chat font-size-24"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="card mini-stats-wid">
                                <div className="card-body">
                                    <div className="media">
                                        <div className="media-body">
                                            <p className="text-muted font-weight-medium">Likes</p>
                                            <h4 className="mb-0">{detail.likes}</h4>
                                        </div>
                                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle bg-primary">
                                                <i className="mdi mdi-thumb-up font-size-24"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>}
        </div>
    )
}
export default QuoteDetail;