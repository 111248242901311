import React, { useState, useEffect } from 'react'
import SignupImage from '../../images/signup.jpg'
import { editCampaignDetail, getCampaignDetail, SignupUser, UploadCampaign } from '../Api'
import { Modal, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import ImageCropperEdit from '../ImageCropper/ImageCropperEdit'
import ImageCropper from '../ImageCropper/ImageCropper'
import uploadImageCampaign from './uploadImageCampaign'

export default function EditCampaign({match}) {

  let location=useHistory();

  const [dataLoaded, setDataLoaded] = useState(false)
  const [name, setname] = useState(null)
  const [targetlikes, settargetlikes] = useState('')
  const [targetcomment, settargetcomment] = useState('')
  const [targetviews, settargetviews] = useState('')
  const [startdate, setstartdate] = useState('')
  const [campDesc, setcampDesc] = useState('')
  const [dimensions, setDimensions] = useState(null)
  const [scaleX, setscaleX] = useState(null)
  const [scaleY, setscaleY] = useState(null)
  const [loading, setLoading] = useState(false)

  const [selectedImage, setSelectedImage] = useState("");
  const [show, setShow] = useState(false);
  const [Error, setError] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
const [image,setImage]=useState("")
    useEffect(() => {
        fetchCampaignDetail(match.params.id)
    }, [])
    
    const fetchCampaignDetail = async (campaignId) => {
        const res = await getCampaignDetail(campaignId)
        setname(res.data.campaign.title)
        settargetviews(res.data.campaign.views)
        ////
        setImage(res.data.campaign?.artWork?.imgUrl)
        console.log(res.data.campaign?.artWork?.imgUrl)
        let date = new Date(res.data.campaign.startsOn);
        let year = date.getFullYear();
        let month = date.getMonth()+1;
        let dt = date.getDate();

        if (dt < 10) {
        dt = '0' + dt;
        }
        if (month < 10) {
        month = '0' + month;
        }
        console.log(year+'-' + month + '-'+dt)
        setstartdate(year+'-' + month + '-'+dt)
        settargetlikes(res.data.campaign.likes)
        settargetcomment(res.data.campaign.comments)
        setcampDesc(res.data.campaign.desc)
        setSelectedImage(res.data.campaign?.artWork?.imgUrl)
        setDimensions({
            x: res.data.campaign?.artWork?.x,
            y: res.data.campaign?.artWork?.y,
            height: res.data.campaign?.artWork?.h,
            width: res.data.campaign?.artWork?.w,
        })
        setDataLoaded(true)
    }
  const update_dimensions = (data,scaleX,scaleY) => {
    setDimensions(data)
    setscaleX(scaleX)
    setscaleY(scaleY)
    console.log("Parent =>", data)
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const myUploadedImage = await uploadImageCampaign(selectedImage)
    console.log(myUploadedImage)
   const data = {
     "title":name,
    "desc":campDesc,
    "startsOn":startdate,
    "artWork":{
        "imgUrl":myUploadedImage,
        "x":dimensions.x,
        "y":dimensions.y,
        "h":dimensions.height,
        "w":dimensions.width
    },
    "likes": targetlikes,
    "views": targetviews,
    "comments": targetcomment
  }
  const res=await editCampaignDetail(match.params.id,data)
  if(res.status===201){
    setLoading(false)
    location.push({
      pathname: '/admin-campaign',
      state: { detail: 'Success' }
    })
  }
  else{
    setLoading(false)
    alert(" Something went Wrong")
    
  }

  }


  const updateImage = (data) => {
    setSelectedImage(data)
    console.log(data)
    setImage(data)
  }

  return (
    <div className="container">
      {dataLoaded && <div className="row">

        <div className="col-lg-1"></div>
        <br />
        <br />
        <br />

        <div className="col-lg-10 mb-2 contact-form__wrapper p-1 order-lg-1">

          <center>
            <h2>Edit Camapign</h2>
          </center>

          <br />



          <form action="#" className="contact-form form-validate" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-12 mb-3">
                <div className="form-group">
                  <label className="required-field" htmlFor="firstName">Camapaign Name</label>
                  <input onChange={(e) => setname(e.target.value)} value={name} type="text" className="form-control" id="firstName" name="firstName" placeholder="title" />
                </div>
              </div>

              <div className="col-sm-6 mb-3">
                <div className="form-group">
                  <label className="required-field" htmlFor="email">Target Likes</label>
                  <input onChange={(e) => settargetlikes(e.target.value)} value={targetlikes} type="text" className="form-control" id="email" name="email" placeholder="target likes" />
                </div>
              </div>



              <div className="col-sm-6 mb-3">
                <div className="form-group">
                  <label htmlFor="phone">Target Comments</label>
                  <input onChange={(e) => settargetcomment(e.target.value)} value={targetcomment} type="text" className="form-control" id="phone" name="phone" placeholder="Target Comments" />
                </div>
              </div>


              <div className="col-sm-6 mb-3">
                <div className="form-group">
                  <label htmlFor="phone">Target Views</label>
                  <input onChange={(e) => settargetviews(e.target.value)} value={targetviews} type="text" className="form-control" id="phone" name="phone" placeholder="Target Views" />
                </div>
              </div>



              <div className="col-sm-6 mb-3">
                <div className="form-group">
                  <label htmlFor="phone">Started On</label>
                  <input type="date" onChange={(e) => {setstartdate(e.target.value)}} value={startdate} className="form-control" id="phone" name="phone" placeholder="Date of campaign" />
                </div>
              </div>



              <div className="col-sm-12 mb-3">
                <div className="form-group">
                  <label htmlFor="phone">Campaign Description</label>
                  <textarea onChange={(e) => setcampDesc(e.target.value)} type="text" value={campDesc} className="form-control" id="phone" name="phone" placeholder="description" />
                </div>
              </div>


              {/* <div className="col-sm-12 mb-3">
                <div className="form-group">
                  <label htmlFor="phone">ArtWork : </label> &nbsp;
                        <ImageCropperEdit update_dimensions={(item,scaleX,scaleY) => update_dimensions(item,scaleX,scaleY)} updateImage={(item)=>updateImage(item)} dimensions={dimensions} uploaded_image={selectedImage}/>
                </div>
              </div> */}
<div className='col-8 m-auto'>
<div className="trendingcard-image-div p-2">
          {/* <img  src={imgUrl} /> */}
          {image && (
            <img
              src={image}
              className="card-img-top"
              alt="..."
            />
          )}
           <div className="form-group">
                  <label htmlFor="phone">ArtWork : </label> &nbsp;
                        <ImageCropper update_dimensions={(item,scaleX,scaleY) => 
                          update_dimensions(item,scaleX,scaleY)} 
                          updateImage={(item)=>updateImage(item)}/>
                </div>
        </div>
</div>

<div className="col-sm-12 mb-3">
              {!loading?
                <input
                type="submit"
                value="Update Campaign"
                name="submit"
                className="btn btn-primary"
                />
              : <input
              type="submit"
              value="Loading..."
              name="submit"
              className="btn btn-primary"
              />
              }
              </div>
            </div>
          </form>
        </div> {/* End Contact Form Wrapper */}




      </div>}
    </div>
  )
}
