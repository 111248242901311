import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { createQuoteCategory, deleteQuoteCategory, getQuoteCategoryAdmin } from '../Api';
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import {Modal,Button} from 'react-bootstrap'

function QuoteCategory() {
    const [newcategory, setnewcategory] = useState(null);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
    const [data, setdata] = useState(null)
    
useEffect(() => {
  getCategory()
  return () => {
  
  }
}, [])

    const getCategory = async () => {
        const res = await getQuoteCategoryAdmin()
        setdata(res.data.quoteCategories)
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        const res = await createQuoteCategory({
            "title":newcategory
        })
        alert("Category Created!")
        handleClose()
        getCategory()
    }
    return (
        <div className='dashboard-container admin-campaign' style={{marginTop:'20px'}}>
        <div className='container'>
          <div className='row text-align-center'>
            <div className='col-lg-6'>
              <h3 className='last-campaign admin-last-campaign text-left'>
                Quote Category
              </h3>
            </div>

            <div className='col-lg-3'>
              <h3 className='last-campaign admin-last-campaign text-right'>
                  <button className='btn btn-info' onClick={handleShow}>Create Category</button>
              </h3>
            </div>
            <div className='col-lg-3'>
              <h3 className='last-campaign admin-last-campaign text-right'>
                <Link to='/admin-quote'>
                  <button className='btn btn-outline-info'>Go back to Quotes</button>
                </Link>
              </h3>
            </div>
          </div>
        </div>

        <br />
        <table className='table table-bordered'>
          <thead>
            <tr>
              <th scope='col' className='font-weight-bold' style={{width:'12%'}}>
                Sr.
              </th>
              <th scope='col' className='font-weight-bold'>
                Name
              </th>

              <th scope='col' className='font-weight-bold' style={{width:'12%'}}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>



         {data && data.map((item,index)=>{
           return (
            <tr key={index}>
            <th scope='row'>{index+1}</th>
            <td>{item.title}</td>



            <td>
            {/* <Link to={`/admin-quote/edit/${item._id}`}><EditIcon/></Link> &emsp;  */}
            <DeleteIcon onClick={async ()=>{await deleteQuoteCategory(item._id);getCategory()}} /> &emsp;


            </td>
          </tr>
           )
         })}

          </tbody>
        </table>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Quote Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form container-fluid" onSubmit={(e)=>handleSubmit(e)}>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label>Enter Category Name</label>
                  <input type="text" placeholder="Enter text" required onChange={(e)=>{setnewcategory(e.target.value)}}/>
                </div>
              </div>
              <div className="col-3">
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              
              </div>
              <div className="col-3"></div>
              {/* <div className="col-3"></div> */}
              <div className="col-6">
                <input type="submit" className="btn btn-primary" style={{float:'right'}} value="Create"/>
              </div>
            </div>
          </form>
          
          </Modal.Body>
       
         
      </Modal>
        </div>
    )
}

export default QuoteCategory
