import React, { useState, useEffect } from 'react';
import ProfileInput from './ProfileInput';
import { Modal, Alert, Button } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { Edit } from '@material-ui/icons';
import { uploadToCloudinary } from '../ImageCropper/canvasUtils';
import Cropper from 'react-easy-crop';
import { updateUserProfile, changePwd } from '../Api';
import Resizer from 'react-image-file-resizer';
import { Avatar, Checkbox, FormControlLabel } from '@material-ui/core';
import { FaEyeSlash, FaEye } from 'react-icons/fa';

import './index.scss';

const PassInputIcon = ({ status, onClick }) => {
	if (status) {
		return (
			<FaEyeSlash color="#146d9c" className="passInputIcon" onClick={onClick} />
		);
	} else {
		return (
			<FaEye color="#146d9c" className="passInputIcon" onClick={onClick} />
		);
	}
};

export default function ProfileDisplay({ profile }) {
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState('');
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [croppedAreaPixelsArray, setCroppedAreaPixelsArray] = useState([]);

	const [showModal, setshowModal] = useState(false);
	const [isError, setisError] = useState(null);
	const [isSuccess, setisSuccess] = useState(null);
	const [showOldPass, setShowOldPass] = useState(false);
	const [showNewPass, setShowNewPass] = useState(false);
	const [modalTitle, setmodalTitle] = useState('');
	const [modalMsg, setmodalMsg] = useState('');
	const [Error, setError] = useState(false);
	const [modalHeading, setmodalHeading] = useState('');
	const [modalText, setmodalText] = useState('');
	const [modalButton, setmodalButton] = useState('');
	const [loading, setloading] = useState(false);
	const [show, setShow] = useState(false);
	const handleClose = () => {
		setShow(false);
		setshowModal(false);
	};
	const handleShow = () => setShow(true);
	const [userImage, setuserImage] = useState(null);
	const [userImageUrl, setuserImageUrl] = useState(profile.profileImg);
	const [oldPassword, setOldPassword] = useState();
	const [newPassword, setNewPassword] = useState();
	const [email, setemail] = useState(profile.email);
	const [name, setName] = useState(profile.name);
	const [whatsapp, setwhatsapp] = useState(profile.phoneNumber);
	const [linkedinUrl, setlinkedinUrl] = useState(profile.linkedinUrl);
	let newDate = new Date(profile.dob);
	const [date, setDate] = useState(newDate);
	const [defaultDate, setDefaultDate] = useState(newDate);
	const [contentCreator, setContentCreator] = useState(
		profile.isContentCreator
	);
	console.log(newDate, date);
	const [instagramUrl, setinstagramUrl] = useState(
		profile.socialAccounts.instagramUrl
	);
	const [showCropper, setShowCropper] = useState(false);
	const onCropComplete = (croppedArea, croppedAreaPixels) => {
		console.log(croppedArea, croppedAreaPixels);
		setCroppedAreaPixels(croppedAreaPixels);
	};
	Date.prototype.yyyymmdd = function () {
		var mm = this.getMonth() + 1; // getMonth() is zero-based
		var dd = this.getDate();

		return [
			this.getFullYear(),
			(mm > 9 ? '' : '0') + mm,
			(dd > 9 ? '' : '0') + dd
		].join('-');
	};

	const imageChange = image => {
		setuserImage(image);
		console.log('File is', image);
		const url = URL.createObjectURL(image);
		setuserImageUrl(url);
		setShowCropper(true);
	};

	// console.log(profile.phoneNumber);

	const submitHandler = async () => {
		let croppedImage;
		if (croppedAreaPixels) {
			croppedImage = await uploadToCloudinary(
				userImageUrl,
				croppedAreaPixels,
				userImageUrl
			);
		} else {
			croppedImage = { url: userImageUrl };
		}
	//	console.log(date);
		let data = {
			name: name,
			profileImg: croppedImage.url,
			phoneNumber: whatsapp,
			linkedinUrl: linkedinUrl,
			instagramUrl: instagramUrl,
			email: email,
			dob: date,
			isContentCreator: contentCreator
		};

		let res = await updateUserProfile(data);

		//To change image in navbar
		var profileDataLocal = await JSON.parse(
			localStorage.getItem('InspirerWorld')
		);
		// console.log("profileDataLocal",profileDataLocal)
		profileDataLocal['profileImg'] = croppedImage.url;
		await localStorage.setItem(
			'InspirerWorld',
			JSON.stringify(profileDataLocal)
		);
		if (res.error) {
			console.log(res);
		} else {
			console.log(res);
		}
		alert('Profile Updated Successfully');
		window.location.reload();
	};

	const ChangePassword = async () => {
		console.log('check change');
		let data = {
			oldPassword: oldPassword,
			newPassword: newPassword
		};
		console.log('check1', newPassword, oldPassword, data);
		let res = await changePwd(data);
		console.log('res', res);
		if (res.error) {
			setisError(true);
			setisSuccess(false);
		} else {
			setisSuccess(true);
			setisError(false);
		}
	};
	return (
		<div className="profile-display profileDisplay editProfileDisplay">
			<Modal show={showCropper} onHide={handleClose}>
				<Modal.Header closeButton={handleClose}>
					<Modal.Title>Crop Your Profile</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div
						id="crop-section"
						style={{
							width: '100vw',
							top: '0',
							bottom: '0',
							left: '0',
							right: '0',
							height: '50vh'
						}}
					>
						<Cropper
							style={{ zIndex: 100, backgroundColor: 'black' }}
							image={userImageUrl}
							crop={crop}
							zoom={zoom}
							aspect={780 / 780}
							onCropChange={crop => {
								setCrop(crop);
							}}
							onCropComplete={onCropComplete}
						/>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<input
						type="range"
						value={zoom}
						min="1"
						max="3"
						step="0.1"
						onChange={e => {
							setZoom(e.target.value);
						}}
						className="custom-range"
						id="customRange1"
					/>
					<br />
					<Button
						onClick={() => {
							// setImgSrc(imgSrcArrayVar)
							setShowCropper(false);
							handleClose(false);
							setCroppedAreaPixelsArray(croppedAreaPixels);
							console.log(croppedAreaPixelsArray);
						}}
					>
						Crop
					</Button>
				</Modal.Footer>
			</Modal>

			<div className="tabView">
				<span
					onClick={() => {
						setSelectedIndex(0);
					}}
				>
					Edit Profile
				</span>
				<span
					onClick={() => {
						setSelectedIndex(1);
					}}
				>
					Change Password
				</span>
			</div>
			<div className="contentView">
				<div display="d-flex">
					<div className="detailWrapper">
						<div className="user-img userImageDiv">
							<div className="dp ">
								<img src={userImageUrl} alt="user-img" className="userImage" />
							</div>
						</div>
						<div className="userDetail">
							<input
								type="file"
								style={{ display: 'none' }}
								id="myfileUpload2"
								name="myfile"
								onChange={e => {
									console.log('e', e.target.files[0]);
									imageChange(e.target.files[0]);
								}}
							/>
							<div className="userName mb-1">{name}</div>
							<button
								onClick={() => {
									document.getElementById('myfileUpload2').click();
								}}
								className="editButton"
							>
								Change Profile Pic
							</button>
						</div>
					</div>
				</div>
				{selectedIndex === 0 && (
					<div>
						<div className="form-input">
							<label>Name</label>
							<input value={name} onChange={e => setName(e.target.value)} />
						</div>
						<div className="form-input">
							<label>Linkedin profile link</label>
							<input
								value={linkedinUrl}
								onChange={e => setlinkedinUrl(e.target.value)}
							/>
						</div>
						<div className="form-input">
							<label>Email</label>
							<input value={email} onChange={e => setemail(e.target.value)} />
						</div>
						<div className="form-input">
							<label>Phone Number</label>
							<input
								defaultValue={profile.phoneNumber}
								onChange={e => setwhatsapp(e.target.value)}
							/>
						</div>
						{/* <div className="form-input">
						<label>Gender</label>
						<input value={name} onChange={e => setName(e.target.value)} />
					</div> */}
						<div className="form-input">
							<label>Date of Birth</label>
							<input
								type="date"
								defaultValue={`${defaultDate.yyyymmdd()}`}
								onChange={e => setDate(e.target.value)}
							/>
						</div>
						<div className="form-input">
							<label>Regular content creator</label>
							<div className="checkBoxInput">
								<input
									type="checkbox"
									className="checkBoxInput"
									name="checkedA"
									checked={contentCreator}
									onChange={() => setContentCreator(!contentCreator)}
								/>
								Regular content creator
							</div>
						</div>
						<div className="form-input">
							<label></label>
							<button className="submitBtn" onClick={submitHandler}>
								Submit
							</button>
						</div>
					</div>
				)}
				{selectedIndex === 1 && (
					<div>
						<div className="form-input">
							<label>Old Password</label>
							<div className="inputDiv">
								<input
									value={oldPassword}
									type={showOldPass ? 'text' : 'password'}
									onChange={e => setOldPassword(e.target.value)}
								/>
								<PassInputIcon
									status={showOldPass}
									onClick={() => {
										setShowOldPass(!showOldPass);
									}}
								/>
							</div>
						</div>
						<div className="form-input">
							<label>New Password</label>
							<div className="inputDiv">
								<input
									value={newPassword}
									type={showNewPass ? 'text' : 'password'}
									onChange={e => setNewPassword(e.target.value)}
								/>
								<PassInputIcon
									status={showNewPass}
									onClick={() => {
										setShowNewPass(!showNewPass);
									}}
								/>
							</div>
						</div>
						{isError && (
							<Alert variant="danger">Oops Some Error Occurred !</Alert>
						)}

						{isSuccess && (
							<Alert variant="success">Password Changed Successfully !</Alert>
						)}
						<div className="form-input">
							<label></label>
							<button className="submitBtn" onClick={ChangePassword}>
								Change
							</button>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
