import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import Home from './components/Home/Home';
import Login from './components/Auth/Login';
import Signup from './components/Auth/Signup';
import Navbar from './components/Navbar/Navbar';
import Contact from './components/Contact/Contact';
import Profile from './components/Profile/Profile';
import memberProfile from './components/Members/memberProfile';
import memberProfileView from './components/Members/memberProfileView';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import CampaignScreen from './components/Campaign/CampaignScreen';
import LearnMore from './components/Campaign/LearnMoreScreen';
import AddLearnMore from './components/LearnMore/CreateLearnMore';

import CampaignParticipantsScreen from './components/Campaign/CampaignParticipants';
import QuotesScreen from './components/Quotes/QuotesScreen';
import QuotesScreenAuthor from './components/Quotes/QuotesScreenAuthor';
import NotFound from './components/NotFound';
import Dashboard from './components/Dashboard/Dashboard';
import BottomNavigationContainer from './components/Navbar/BottomNavigationContainer';
import AdminCampaign from './components/AdminCampaign/AdminCampaign';
import AdminLearnMore from './components/LearnMore/AdminLearnMore';
import CreateCampaign from './components/AdminCampaign/CreateCampaign';
import Author from './components/AdminQuote/Author';
import Teams from './components/Teams/Teams';
import TestingMerge from './components/Testing/TestingMerge';
import MyCampaigns from './components/Campaign/MyCampaigns';
import CampaignsContent from './components/Campaign/campaignsContent';
import MyQuotes from './components/Quotes/MyQuotes';
import Quote from './components/Quotes/QuoteById';
import UserQuote from './components/Home/QuoteById';
import MySavedQuotes from './components/Quotes/MySavedQuotes';
import CreateTeam from './components/Teams/CreateTeam';
import TeamDetail from './components/Teams/TeamDetail';
import UpcomingCampaignDetail from './components/AdminQuote/upcomingCampaignQuotes';
import AllMember from './components/Members/AllMember';
import AddMember from './components/Members/AddMember';
import { FaUserAlt, FaUsers, FaBroadcastTower, FaBook } from 'react-icons/fa';
import { MdDashboard } from 'react-icons/md';
import AdminQuote from './components/AdminQuote/AdminQuote';
import CreateQuote from './components/AdminQuote/CreateQuote';
import MyTeam from './components/TeamLeader/MyTeam';
import MyTeamList from './components/TeamLeader/MyTeamList';
import TeamProfile from './components/Teams/TeamProfile';
import UserProfile from './components/Users/UserProfile';
import Services from './components/Services/index.js';
import ServicesDetail from './components/Services/details.js';
import EditCampaign from './components/AdminCampaign/EditCampaign';
import EditAuthor from './components/AdminQuote/EditAuthor';
import EditQuote from './components/AdminQuote/EditQuote';
import MySuggestedQuotes from './components/Quotes/MySuggestedQuotes';
import SuggestedQuotes from './components/AdminQuote/SuggestedQuotes';
import SuggestedTopic from './components/AdminQuote/SuggestedTopic';
import EditSuggestedQuotes from './components/Quotes/EditSuggestedQuote';
import QuoteCategory from './components/AdminQuote/QuoteCategory';
import QuoteDetail from './components/AdminQuote/QuoteDetail';
import CampaignDetail from './components/AdminCampaign/CampaignDetail';
import About from './components/Home/About';
import AllQuotes from './components/Home/QuotesScreen';

import Winners from './components/Campaign/Winners';
import Nominations from './components/AdminCampaign/Nominations';
import SuggestQuote from './components/Quotes/SuggestQuote';
import SuggestTopic from './components/Quotes/SuggestTopic';
import AddContent from './components/Quotes/AddContent';
import EditContent from './components/Quotes/EditContent';
import Notification from './components/Notification/Notification';
import { BiBell } from 'react-icons/bi';
import QuoteByCategory from './components/AdminQuote/QuotesByCategory';
import UpcomingCampaign from './components/AdminQuote/UpcomingCampaign';
import QuotesByauthorName from './components/AdminQuote/QuotesByauthorName';
import {
	getAllQuotesAdmin,
	getBirthdays
} from './components/Api';
import TeamLeaderAndAdmins from './components/Members/teamLeaderndAdmins';
import ForgotPassword from './components/Auth/ForgotPass';
import NewPassword from './components/Auth/NewPassword';
import EditSuggestedQuote from './components/AdminQuote/EditSuggestedQuote';
import EditSuggestedTopic from './components/AdminQuote/EditSuggestedTopic';
import Upcoming from './components/AdminQuote/EditUpcoming';
import QuoteByAuthor from './components/Home/QuotesByAuthor';
import MyAchievments from './components/Campaign/MyAchievements';
import AllDeactivatedMember from './components/Members/AllDeactivatedMember';
import AllNonActiveMember from './components/Members/AllNonActiveMember';

import Logo1 from './images/Logo-A.png';

import Image from 'react-bootstrap/Image';
import { BiLogOutCircle } from 'react-icons/bi';
import campaignsWiseContent from './components/Campaign/campaignsWiseContent';
import CampaignsUserWise from './components/Campaign/CampaignUserWise';
import CampaignById from './components/Campaign/CampaignById';
import skilsAndDegree from './components/Home/skilsAndDegree';
import SuggestQuoteCategory from './components/Quotes/SuggestQuoteCategory';

function App() {
	const [isAuth, setisAuth] = useState(false);
	const [user, setUser] = useState('');
	const [isUser, setisUser] = useState(true);
	const [month, setMonth] = useState('');
	const [quotes, setquotes] = useState('');
	const [showNavbar,setShowNavbar]=useState(true);
	let history=useHistory()

//	console.log('check: ', isAuth, isUser, user);
useEffect(() => {
	console.log(window.location.pathname =='/skillVsDegree',window.location.pathname);
		if(window.location.pathname=='/skillVsDegree'){
			setShowNavbar(false)
		}
	}, []);
		
	useEffect(() => {
		if (isAuth && !isUser) {
			console.log(isAuth);
			getNot();
			getCamapigns();
		}
		
	}, [isAuth]);

	const getCamapigns = async () => {
		const res = await getAllQuotesAdmin();
		if (res.error) {
			console.log(res.error);
		} else {
			setquotes(res.data.quotes.length);
		}
	};

	const getNot = async () => {
		let res = await getBirthdays();
		if (res.error) {
			console.log(res);
		} else {
			console.log(res);
			setMonth(res.res.data.birthdayToday.length);
		}
	};

	useEffect(() => {
		var data = JSON.parse(localStorage.getItem('InspirerWorld'));
		const now = new Date();
		if (data && data.expiry > now.getTime()) {
			if (data.type === 'user') {
				setisAuth(true);
				setisUser(true);
				setUser(data);

				console.log('I am running in App js user type is user');
			} else {
				setisAuth(true);
				setisUser(false);
				setUser(data);

				console.log('I am running in App js user type is Admin');
			}
		}
	}, []);

	const handleLogout = () => {
		window.location.href = '/';
		localStorage.removeItem('InspirerWorld');
		setisAuth(false);
		setUser('');
	};

	return (
		<div className="App">
			{isUser && isAuth && (
				<div
					className="mobileLogoutBtn"
					style={{
						display: 'none',
						textAlign: 'center',
						background: '#fb5757',
						color: 'white',
						fontSize: '18px',
						fontWeight: 'bold',
						padding: '10px'
					}}
					onClick={handleLogout}
				>
					Logout
				</div>
			)}
			<HelmetProvider>
				<Helmet
					defaultTitle="Inspirer World"
					titleTemplate="%s | Inspirer World"
				/>
				<Router>
			
					{((isUser && isAuth) || !isAuth && showNavbar) && <Navbar />}
					<Route exact path="/skillVsDegree" 
				component={skilsAndDegree} /> 
				
					<Switch>
						<Route exact path="/" component={Home} />
						<Route exact path="/signup" component={Signup} />
						<Route exact path="/login" component={Login} />
						<Route exact path="/forgot-pwd" component={ForgotPassword} />
						<Route exact path="/new-password/:id" component={NewPassword} />
						<Route exact path="/contact" component={Contact} />
						<Route exact path="/about" component={About} />
						<Route exact path="/allquotes" component={AllQuotes} />
						<Route exact path="/suggesttopic" component={SuggestTopic} />
						<Route exact path="/suggestquote" component={SuggestQuote} />
						<Route exact path="/suggestquote/:campaign/:id" component={SuggestQuote} />
					{
						!isAuth && (
							<Route exact path="/campaigns" component={CampaignScreen} />
						)
 					}
						<Route exact path="/allquotes/:author" component={QuoteByAuthor} />
						<Route exact path="/allquote/quote/:id" component={UserQuote} />
						<Route exact path="/meet/:userId" component={UserProfile} />
						{/* <Route exact path="/services" component={Services} /> */}
				
						<Route exact path="/all-campaigns" component={CampaignsContent} />
						{/* <Route exact path="/all-campaigns/author/:authorname" component={CampaignsContent} /> */}
						<Route exact path="/all-campaigns/campaign/:campaignname/:id" component={campaignsWiseContent} />
						<Route exact path="/all-campaigns/:campaignname/:username/:id/:userid" component={CampaignById} />
						<Route exact path="/all-campaigns/user/:username/:id" component={CampaignsUserWise} />
						<Route exact path="/services/:id" component={ServicesDetail} />
						{/* this is for normal user login  */}
						{isAuth && isUser && (
							<div>
						<Route exact path="/campaigns" component={CampaignScreen} />

								<Route exact path="/profile" component={Profile} />
								<Route exact path="/editprofile" component={Profile} />
								<Route exact path="/teamlist/:teamId" component={TeamDetail} />
								{/* <Route exact path="/campaigns" component={CampaignScreen} /> */}
								<Route exact path="/learn-more" component={LearnMore} />
								<Route
									exact
									path="/campaign/:id/participants"
									component={CampaignParticipantsScreen}
								/>
								<Route exact path="/quotes" component={QuotesScreen} />
								<Route exact path="/myquotes" component={QuotesScreen} />
								<Route
									exact
									path="/quotes/:author"
									component={QuotesScreenAuthor}
								/>
								<Route exact path="/quote/:id" component={Quote} />

								<Route exact path="/winners" component={Winners} />
								<Route exact path="/mycampaigns" component={MyCampaigns} />
								<Route exact path="/myAchievments" component={MyAchievments} />
								<Route exact path="/mydownloadedquotes" component={MyQuotes} />
								<Route exact path="/mysavedquotes" component={MySavedQuotes} />
								<Route
									exact
									path="/mysuggestedquotes"
									component={MySuggestedQuotes}
								/>
								<Route exact path="/teams" component={Teams} />
								<Route
									exact
									path="/myteamlist/:teamId"
									component={MyTeamList}
								/>
								<Route exact path="/myteam" component={MyTeam} />
								<Route exact path="/test" component={TestingMerge} />
								<Route exact path="/create-team" component={CreateTeam} />
								{/* <Route exact path="/suggestquote" component={SuggestQuote} /> */}
								<Route
									exact
									path="/campaign/:id/addcontent"
									component={AddContent}
								/>
								<Route
									exact
									path="/campaign/:id/editcontent"
									component={EditContent}
								/>
								<Route
									exact
									path="/editSuggestedQuote/:id"
									component={EditSuggestedQuotes}
								/>
								<Route exact path="/allmembers/:type" component={AllMember} />
								<Route
									exact
									path="/user/:name/profile/:id"
									component={Profile}
								/>
								<Route exact path="/meet/:userId" component={UserProfile} />

								<br />
								<br />
								<br />

								<BottomNavigationContainer />
							</div>
						)}
					</Switch>

					{/* this is for admin login  */}
					{!isUser && isAuth && (
						<>
							<div
								className="container-fluid"
								style={{ minHeight: '100vh', background: '#f8f8fb' }}
							>
								<div
									className="row adminSidebar"
									style={{ minHeight: '100vh' }}
								>
									<div
										className="col-2"
										style={{
											background: '#2a3042',
											color: 'white',
											display: 'flex',
											flexDirection: 'column',
											cursor: 'pointer',
											padding: '0'
										}}
									>
										<a className="navbar-brand" href="/dashboard">
											<img
												src={Logo1}
												style={{
													maxWidth: '100px',
													marginLeft: '20px',
													marginTop: '20px'
												}}
												alt="LinkediN"
											/>
										</a>

										<br />
										<span
											style={{
												marginLeft: 'auto',
												padding: 0,
												background: 'none',
												border: 'none'
											}}
											className="btn btn-secondary"
										>
											{/* <img src="https://img.icons8.com/windows/32/ffffff/macos-close.png"/> */}
										</span>

										<br />

										<Link to="/dashboard">
											<button
												style={{
													background: 'none',
													color: 'white',
													border: 'none',
													width: '100%',
													fontSize: '14px',
													paddingLeft: '20px',
													paddingRight: '20px',
													display: 'flex',
													alignItems: 'center'
												}}
												className="btn btn-secondary"
												type="button"
											>
												<MdDashboard
													color="#fff"
													style={{ marginRight: '10px' }}
												/>{' '}
												Dashboard
											</button>
										</Link>

										<div className="dropdown">
											<button
												style={{
													background: 'none',
													color: 'white',
													width: '100%',
													border: 'none',
													fontSize: '14px',
													paddingLeft: '20px',
													paddingRight: '20px',
													display: 'flex',
													alignItems: 'center'
												}}
												className="btn btn-secondary dropdown-toggle"
												type="button"
												id="dropdownMenuButton"
												data-toggle="dropdown"
												aria-haspopup="true"
												aria-expanded="false"
											>
												<FaUserAlt
													color="#fff"
													style={{ marginRight: '10px' }}
												/>{' '}
												Members
											</button>
											<div
												className="dropdown-menu"
												style={{ padding: '10px' }}
												aria-labelledby="dropdownMenuButton"
											>
												<Link to="/allmembers/allmembers">
													<a
														style={{
															display: 'flex',
															alignItems: 'center',
															color: '#ca1fce'
														}}
													>
														All Members
													</a>
												</Link>
												<br />
												<Link to="/teams-leader-And-Admins">
													<a
														style={{
															display: 'flex',
															alignItems: 'center',
															color: 'green'
														}}
													>
														Team Leader nd Admins
													</a>
												</Link>

												<br />
												<Link to="/non-active-member">
													<a
														style={{
															display: 'flex',
															alignItems: 'center',
															color: '#127212'
														}}
													>
														Non Active
													</a>
												</Link>

												<br />

												<Link to="/deactivated-member">
													<a
														style={{
															display: 'flex',
															alignItems: 'center',
															color: 'tomato'
														}}
													>
														Inactive
													</a>
												</Link>
											</div>
										</div>

										<Link to="/teams">
											<button
												style={{
													background: 'none',
													color: 'white',
													width: '100%',
													border: 'none',
													fontSize: '14px',
													paddingLeft: '20px',
													paddingRight: '0',
													display: 'flex',
													alignItems: 'center'
												}}
												className="btn btn-secondary"
												type="button"
											>
												<FaUsers color="#fff" style={{ marginRight: '10px' }} />{' '}
												All Teams
											</button>
										</Link>

										<Link to="/admin-campaign">
											<button
												style={{
													background: 'none',
													color: 'white',
													border: 'none',
													width: '100%',
													fontSize: '14px',
													paddingLeft: '20px',
													paddingRight: '20px',
													display: 'flex',
													alignItems: 'center'
												}}
												className="btn btn-secondary"
												type="button"
											>
												<FaBroadcastTower
													color="#fff"
													style={{ marginRight: '10px' }}
												/>{' '}
												Campaign
											</button>
										</Link>
										<Link to="/suggested-topic">
											<button
												style={{
													background: 'none',
													color: 'white',
													border: 'none',
													width: '100%',
													fontSize: '14px',
													paddingLeft: '20px',
													paddingRight: '20px',
													display: 'flex',
													alignItems: 'center'
												}}
												className="btn btn-secondary"
												type="button"
											>
												<FaBroadcastTower
													color="#fff"
													style={{ marginRight: '10px' }}
												/>{' '}
												Suggest Topic
											</button>
										</Link>

										<Link to="/admin-learn-more">
											<button
												style={{
													background: 'none',
													color: 'white',
													border: 'none',
													width: '100%',
													fontSize: '14px',
													paddingLeft: '20px',
													paddingRight: '20px',
													display: 'flex',
													alignItems: 'center'
												}}
												className="btn btn-secondary"
												type="button"
											>
												<div clasdName="d-flex">
													<EmojiObjectsIcon style={{ marginRight: '10px' }} />
													Learn-More
												</div>
											</button>
										</Link>

										<Link to="/admin-quote">
											<button
												style={{
													background: 'none',
													fontSize: '14px',
													color: 'white',
													width: '100%',
													border: 'none',
													textAlign: 'left',
													paddingLeft: '20px',
													paddingRight: '0',
													display: 'flex',
													alignItems: 'center'
												}}
												className="btn btn-secondary"
												type="button"
											>
												<FaBook color="#fff" style={{ marginRight: '10px' }} />{' '}
												Quote Library
												<span
													className="bg-danger text-light not"
													style={{ marginTop: '-10px' }}
												>
													{quotes}
												</span>
											</button>
										</Link>

										<Link to="/notification">
											<button
												style={{
													background: 'none',
													fontSize: '14px',
													color: 'white',
													width: '100%',
													border: 'none',
													textAlign: 'left',
													paddingLeft: '20px',
													paddingRight: '0',
													display: 'flex',
													alignItems: 'center'
												}}
												className="btn btn-secondary"
												type="button"
											>
												<BiBell color="#fff" style={{ marginRight: '10px' }} />{' '}
												Notification {''}
												<span
													className="bg-danger text-light not"
													style={{ marginTop: '-10px' }}
												>
													{month}
												</span>
											</button>
										</Link>

										<br />
										<div
											className="dropdown"
											style={{
												position: 'fixed',
												bottom: '20px',
												left: '20px'
											}}
										>
											<button
												style={{
													background: 'none',
													color: 'white',
													border: 'none',
													padding: '0'
													// width: '200px'
												}}
												className="btn btn-secondary dropdown-toggle"
												type="button"
												id="dropdownMenuButton"
												data-toggle="dropdown"
												aria-haspopup="true"
												aria-expanded="false"
											>
												<Image
													style={{
														height: '30px',
														width: '30px',
														objectFit: 'cover'
													}}
													src={user.profileImg}
													rounded
													roundedCircle
													thumbnail
													fluid
												/>
												&nbsp; {user.name}
											</button>

											<div
												className="dropdown-menu"
												style={{ padding: '10px' }}
												aria-labelledby="dropdownMenuButton"
											>
												<Link to="/profile">
													<a style={{ display: 'flex', alignItems: 'center' }}>
														<FaUserAlt color="#146d9c" /> Profile
													</a>
												</Link>

												<br />
												<Link onClick={handleLogout}>
													<a
														style={{
															display: 'flex',
															alignItems: 'center',
															color: '#f46a6a'
														}}
													>
														{' '}
														<BiLogOutCircle /> Logout
													</a>
												</Link>
											</div>
										</div>
									</div>

									<div className="col-10">
										<Switch>
											<Route exact path="/" component={Home} />
											<Route exact path="/signup" component={Signup} />
											<Route exact path="/login" component={Login} />
											<Route exact path="/contact" component={Contact} />

											{isAuth && (
												<div>
													<Route
														exact
														path="/deactivated-member"
														component={AllDeactivatedMember}
													/>
													<Route
														exact
														path="/non-active-member"
														component={AllNonActiveMember}
													/>
													<Route exact path="/profile" component={Profile} />
													<Route
														exact
														path="/editprofile"
														component={Profile}
													/>
													<Route
														exact
														path="/teamlist/:teamId"
														component={TeamDetail}
													/>
													<Route
														exact
														path="/upcomingCamapign-details/:id"
														component={UpcomingCampaignDetail}
													/>
													<Route
														exact
														path="/campaigns"
														component={CampaignScreen}
													/>
													<Route
														exact
														path="/dashboard"
														component={Dashboard}
													/>
													<Route
														exact
														path="/admin-campaign"
														component={AdminCampaign}
													/>
													<Route
														exact
														path="/admin-learn-more"
														component={AdminLearnMore}
													/>
													<Route
														exact
														path="/add-learn-more"
														component={AddLearnMore}
													/>
													<Route
														exact
														path="/create-upcoming-campaign"
														component={UpcomingCampaign}
													/>
													<Route
														exact
														path="/admin-campaign/edit/:id"
														component={EditCampaign}
													/>
													<Route
														exact
														path="/admin-quote/edit/:id"
														component={EditQuote}
													/>
													<Route
														exact
														path="/author/edit/:id"
														component={EditAuthor}
													/>
													<Route
														exact
														path="/createcampaign"
														component={CreateCampaign}
													/>
													<Route
														exact
														path="/create-author"
														component={Author}
													/>
													<Route
														exact
														path="/nominees/campaignId/:id"
														component={Nominations}
													/>
													<Route
														exact
														path="/campaign-detail/:id"
														component={CampaignDetail}
													/>
													<Route
														exact
														path="/admin-quote"
														component={AdminQuote}
													/>
													<Route
														exact
														path="/quote-category"
														component={QuoteCategory}
													/>
													<Route
														exact
														path="/suggestedquote"
														component={SuggestedQuotes}
													/>
													<Route
														exact
														path="/suggested-topic"
														component={SuggestedTopic}
													/>
													<Route
														exact
														path="/quote-by-category/:id"
														component={QuoteByCategory}
													/>
													<Route
														exact
														path="/quote-by-author/:id"
														component={QuotesByauthorName}
													/>
													<Route
														exact
														path="/createquote"
														component={CreateQuote}
													/>
													<Route
														exact
														path="/edit-suggested-quote-admin/:id"
														component={EditSuggestedQuote}
													/>
													<Route
														exact
														path="/edit-suggested-topic-admin/:id"
														component={EditSuggestedTopic}
													/>
													<Route
														exact
														path="/edit-upcoming-quote-admin/:cid/:uid"
														component={Upcoming}
													/>

													<Route
														exact
														path="/quote-detail/:id"
														component={QuoteDetail}
													/>
													<Route
														exact
														path="/mycampaigns"
														component={MyCampaigns}
													/>
													<Route exact path="/teams" component={Teams} />
													{/* <Route exact path='/test' component={TestingMerge} /> */}

													<Route
														exact
														path="/add-member"
														component={AddMember}
													/>
													<Route
														exact
														path="/notification"
														component={Notification}
													/>
													<Route
														exact
														path="/create-team"
														component={CreateTeam}
													/>
													<Route
														exact
														path="/allmembers/:type"
														component={AllMember}
													/>
													<Route
														exact
														path="/teams-leader-And-Admins"
														component={TeamLeaderAndAdmins}
													/>
													<Route
														exact
														path="/allmembers/:name/profile/:id"
														component={memberProfile}
													/>
													<Route
														exact
														path="/allmembers/:name/profileview/:id"
														component={memberProfileView}
													/>
												</div>
											)}

											<Route component={NotFound} />
										</Switch>
									</div>
								</div>
							</div>{' '}
						</>
					)}
				</Router>
			</HelmetProvider>
		</div>
	);
}

export default App;
