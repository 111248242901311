import React, { useState, useEffect } from "react";
import { FaLinkedin, FaWhatsapp } from "react-icons/fa";
import EditIcon from "@material-ui/icons/Edit";

import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { ActivatedUser, DeactivatedUser, getAllUsers } from "../Api";
export default function AllNonActiveMember(props) {
  useEffect(() => {
    getUsers();
  }, []);
 
  const [page, setPage] = useState(0);
  const [totalpage, setTotalPage] = useState(0);
  const [memberList, setmemberList] = useState([]);
  const [entryToShow, setentryToShow] = useState(null);
  const [entry, setEntry] = useState(10);
  const [memberListFilter, setmemberListFilter] = useState([]);
  const location = useLocation();

  const [searchTerm, setsearchTerm] = useState("");
  
  const getUsers = async () => {
    let res = await getAllUsers();
    if (res.error) {
      console.log(res.error);
    } else { 
      let temp4 = [];
      res.res.data.users.forEach((member) => {
        if (member.activity <= 1) {
          temp4.push(member);
        }
      });
      setmemberListFilter(temp4);
      setmemberList(temp4);
      setmemberListFilter(temp4);


    }
  };
  useEffect(() => {
    setTotalPage(Math.ceil(memberList.length/entry))
    console.log(page, (page+1)*entry,totalpage,Math.ceil(memberList.length/entry))
    let array = memberList.slice(page*entry, (page+1)*entry)
    console.log(array)
    setmemberListFilter(array)
  }, [entry,page])
 
  return (
    <div>
      <div className="dashboard-main-container">
        <div className="teams-title">
          <h3
            className="last-campaign admin-last-campaign text-left"
            style={{ textTransform: "capitalize" }}
          >
            {props.match.params.type} List
          </h3>
        
        </div>
       
        <div className="dashboard-container admin-campaign">
          <div className="teams-search">
            <label className="teams-select">
              Show
              <select
                name="datatable-buttons_length"
                aria-controls="datatable-buttons"
                className="custom-select teams-custom-select"
                onChange={(e) => {setEntry(e.target.value)
                setPage(0)}}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              entries
            </label>

         
            <label className="teams-searchbar">
              Search:
              <input
                type="search"
                className="form-control form-control-sm"
                placeholder=""
                aria-controls="datatable-buttons"
                onChange={(e) => setsearchTerm(e.target.value)}
              />
            </label>
          </div>

          <div className="container">
            <div className="row text-align-center">
              <div className="col-lg-6"></div>

              <div className="col-lg-6">
                <h3 className="last-campaign admin-last-campaign text-right"></h3>
              </div>
            </div>
          </div>

          <br />

          <table className="table table-bordered">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="font-weight-bold"
                  style={{ width: "10%" }}
                >
                  <div className="up-down-container">
                    <strong> Sr.</strong>
                    <div className="up-down-arrows"></div>
                  </div>
                </th>
                <th
                  scope="col"
                  className="font-weight-bold"
                  style={{ width: "80%" }}
                >
                  <div className="up-down-container">
                    <strong> Member Name</strong>
                    <div className="up-down-arrows"></div>
                  </div>
                </th>

                <th
                  scope="col"
                  className="font-weight-bold"
                  style={{ width: "80%" }}
                >
                  <div className="up-down-container">
                    <strong> Email</strong>
                    <div className="up-down-arrows"></div>
                  </div>
                </th>
                <th
                  scope="col"
                  className="font-weight-bold"
                  style={{ width: "80%" }}
                >
                  <div className="up-down-container">
                    <strong> Regular Content Creator </strong>
                    <div className="up-down-arrows"></div>
                  </div>
                </th>
                <th
                  scope="col"
                  className="font-weight-bold"
                  style={{ width: "80%" }}
                >
                  <div className="up-down-container">
                    <strong> Campaigns Participated</strong>
                    <div className="up-down-arrows"></div>
                  </div>
                </th>
                <th
                  scope="col"
                  className="font-weight-bold"
                  style={{ width: "80%" }}
                >
                  <div className="up-down-container">
                    <strong> Activeness</strong>
                    <div className="up-down-arrows"></div>
                  </div>
                </th>
                <th
                  scope="col"
                  className="font-weight-bold"
                  style={{ width: "80%" }}
                >
                  <div className="up-down-container">
                    <strong> Edit </strong>
                    <div className="up-down-arrows"></div>
                  </div>
                </th>

                <th
                  scope="col"
                  className="font-weight-bold"
                  style={{ width: "80%" }}
                >
                  <div className="up-down-container">
                    <strong> Social</strong>
                    <div className="up-down-arrows"></div>
                  </div>
                </th>
                <th
                  scope="col"
                  className="font-weight-bold"
                  style={{ width: "80%" }}
                >
                  <div className="up-down-container">
                    <strong> Active </strong>
                    <div className="up-down-arrows"></div>
                  </div>
                </th>

                {/* <th scope="col" className="font-weight-bold">
                  <div className="up-down-container">
                    <strong>Action</strong>
                    <div className="up-down-arrows"></div>
                  </div>
                </th> */}
              </tr>
            </thead>
            <tbody>
              {memberListFilter &&
                memberListFilter
                  .filter((item, index) => {
                    if (
                      item.name
                        .toLocaleLowerCase()
                        .includes(searchTerm.toLocaleLowerCase()) &&
                      item.isContentCreator === true
                    ) {
                      return item;
                    }
                  })
                  .map((item, index) => {
                    if (item.isContentCreator) {
                      return (
                        <tr key={index + 1}>
                          <th scope="row">{index + 1}</th>
                          <td>
                            <Link
                              to={
                                "/allmembers/" +
                                item.name +
                                "/profile/" +
                                item._id
                              }
                            >
                              {item.name}
                            </Link>
                          </td>
                          <td>{item.email}</td>
                          <td>{item.isContentCreator ? "Yes" : "No"}</td>
                          <td>{item.campaigns[0]}</td>
                          <td>{item.activity}%</td>
                          <td>
                            <Link
                              to={`/allmembers/${item.name}/profile/${item._id}`}
                            >
                              <EditIcon />
                            </Link>
                          </td>

                          <td>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <a
                                href={`https://wa.me/${item.phoneNumber}?text=`}
                                target="_blank"
                              >
                                <img
                                  width="30"
                                  src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                                />
                              </a>
                              <a href={item.linkedinUrl} target="_blank">
                                <img
                                  width="25"
                                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c9/Linkedin.svg/1200px-Linkedin.svg.png"
                                />
                              </a>
                            </div>
                          </td>
                          <td>
                            {item.type === "user" ? (
                              item.deactivated ? (
                                <button
                                  className="btn btn-active btn-info create-team-btn"
                                  onClick={async () => {
                                    await ActivatedUser(item._id);
                                    getUsers();
                                  }}
                                >
                                  Activate
                                </button>
                              ) : (
                                <button
                                  className="btn btn-active btn-info create-team-btn"
                                  onClick={async () => {
                                    await DeactivatedUser(item._id);
                                    getUsers();
                                  }}
                                >
                                  Deactivated
                                </button>
                              )
                            ) : (
                              "Owner Can't be Deactivated"
                            )}
                          </td>
                        </tr>
                      );
                    }
                  })}
              {memberListFilter &&
                memberListFilter
                  .filter((item, index) => {
                    if (
                      item.name
                        .toLocaleLowerCase()
                        .includes(searchTerm.toLocaleLowerCase())
                    ) {
                      return item;
                    }
                  })
                  .map((item, index) => {
                    if (!item.isContentCreator) {
                      return (
                        <tr key={index + 1}>
                          <th scope="row">{index + 1}</th>
                          <td>
                            <Link
                              to={
                                "/allmembers/" +
                                item.name +
                                "/profile/" +
                                item._id
                              }
                            >
                              {item.name}
                            </Link>
                          </td>
                          <td>{item.email}</td>
                          <td>{item.isContentCreator ? "Yes" : "No"}</td>
                          <td>{item.campaigns[0]}</td>
                          <td>{item.activity}%</td>
                          <td>
                            <Link
                              to={`/allmembers/${item.name}/profile/${item._id}`}
                            >
                              <EditIcon />
                            </Link>
                          </td>

                          <td>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <a
                                href={`https://wa.me/${item.phoneNumber}?text=`}
                                target="_blank"
                              >
                                <img
                                  width="30"
                                  src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                                />
                              </a>
                              <a href={item.linkedinUrl} target="_blank">
                                <img
                                  width="25"
                                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c9/Linkedin.svg/1200px-Linkedin.svg.png"
                                />
                              </a>
                            </div>
                          </td>
                          <td>
                            {item.type === "user" ? (
                              item.deactivated ? (
                                <button
                                  className="btn btn-active btn-info create-team-btn"
                                  onClick={async () => {
                                    await ActivatedUser(item._id);
                                    getUsers();
                                  }}
                                >
                                  Activate
                                </button>
                              ) : (
                                <button
                                  className="btn btn-active btn-info create-team-btn"
                                  onClick={async () => {
                                    await DeactivatedUser(item._id);
                                    getUsers();
                                  }}
                                >
                                  Deactivated
                                </button>
                              )
                            ) : (
                              "Owner Can't be Deactivated"
                            )}
                          </td>
                        </tr>
                      );
                    }
                  })}
            </tbody>
          </table>

          <div className="prev-next-row">
            <div className="entries-text">
              Showing{" "}
              {entryToShow > memberListFilter.length
                ? memberListFilter.length
                : entryToShow}{" "}
              of {memberListFilter.length} entries
            </div>
            {/* <div className='prev-next'>
            <div>Previous</div> <div className='prev-count'>1</div>{' '}
            <div>Next</div>
          </div> */}
            <div className='float-right mr-4'>
              <button disabled={page===0?true:false} className='btn  previous p-2 m-2' onClick={()=>setPage(page-1)}>
              Previous
              </button>
              <button disabled={totalpage!==page+1?false:true}  className='btn next p-2 m-2' onClick={()=>setPage(page+1)}>
          Next 
              </button>
            </div>
          </div>
        </div>
        <div>
        </div>
      </div>
    </div>
  );
}
