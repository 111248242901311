import React, { useState, useEffect } from "react";
import {
  getAllAuthor,
  getQuoteCategoryAdmin,
  getUpcomingSuggestedQuotesDetails,
  lunchSugestedQuotesApi,
} from "../Api";
import { useHistory, useParams } from "react-router-dom";
import ImageCropper from "../ImageCropper/ImageCropper";
import uploadImageCampaign from "../AdminCampaign/uploadImageCampaign";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

export default function EditSuggestedQuote({ match }) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  const [campDesc, setcampDesc] = useState("");
  const [Category, setCategory] = useState(null);
  const [categoryData, setcategoryData] = useState([]);
  const [name, setname] = useState("");
  const [authorID, setAuthorID] = useState("");
  const [selectedOption, setselectedOption] = useState();

  const [tags, setTags] = useState("");
  // const [campDesc, setcampDesc] = useState("");
  let location = useHistory();
  const params = useParams();
  const [CategoryId, setCategoryId] = useState(false);
  const [userImageUrl, setuserImageUrl] = useState("");
  const [croppedAreaPixels, setCroppedAreaPixels] = useState("");

  const [authors, setauthors] = useState([]);
  const [selectedAuthor, setSelectedAuthor] = useState("");
  const [quoteData, setquoteData] = useState({});
  const [defaultAuthor, setDefaultAuthor] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [show, setShow] = useState(false);
  const [Error, setError] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showCropper, setShowCropper] = useState(false);
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels);
    setCroppedAreaPixels(croppedAreaPixels);
  };
  const [dimensions, setDimensions] = useState(null);
  const [scaleX, setscaleX] = useState(null);
  const [scaleY, setscaleY] = useState(null);
  const [userImage, setuserImage] = useState(null);
  const [croppedAreaPixelsArray, setCroppedAreaPixelsArray] = useState([]);
  const [quoteId, setQuoteId] = useState("");
  useEffect(() => {
    getCategory();
    fetchSuggestedQuoteDetail(params.cid, params.uid);
  }, []);
  const fetchSuggestedQuoteDetail = async (cid, uid) => {
    const res = await getUpcomingSuggestedQuotesDetails(cid, uid);
    console.log(res);

    setSelectedAuthor(res.res.data.quote.quoteAuthor);

    setcampDesc(res.res.data.quote.quoteMsg);

    setuserImage(res.res.data.quote.imageUrl);
    setQuoteId(res.res.data.quote._id);
  };
  const update_dimensions = (data, scaleX, scaleY) => {
    setDimensions(data);
    setscaleX(scaleX);
    setscaleY(scaleY);
    console.log("Parent =>", data);
  };
  console.log("params");
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedImage) {
      let croppedImage = await uploadImageCampaign(selectedImage);

      setLoading(true);
      const data = {
        desc: campDesc,
        authorId: authorID,
        artWork: {
          imgUrl: croppedImage,
          x: dimensions.x * scaleX,
          y: dimensions.y * scaleY,
          h: dimensions.height * scaleY,
          w: dimensions.width * scaleX,
        },
      };
      const res = await lunchSugestedQuotesApi(params.cid, quoteId, data);
      // const res = await editUpcomingSuggestedQuotes(
      // 	params.cid,
      // 	params.uid,
      // 	data
      // );
      if (!res.error) {
        setLoading(false);
        location.push({
          pathname: `/upcomingCamapign-details/${params.cid}`,
          state: { detail: "Success" },
        });
      } else {
        setLoading(false);
        alert(" Something went Wrong");
      }
    }
  };

  const imageChange = (image) => {
    setuserImage(image);
    console.log("File is", image);
    const url = URL.createObjectURL(image);
    setuserImageUrl(url);

    setShowCropper(true);
  };
  const updateImage = (data) => {
    setSelectedImage(data);
  };

  const getCategory = async (category) => {
    const res = await getQuoteCategoryAdmin();
    const res2 = await getAllAuthor();
    console.log("res2.res.data.authors", res2.res.data.authors);
    setauthors(res2.res.data.authors);

    const curr_author = res2.res.data.authors.find(
      (item) => item.userId === params.uid
    );
    setAuthorID(curr_author._id);
    setselectedOption(curr_author.name);

    console.log(res2.res.data);
    setcategoryData(res.data.quoteCategories);
    res.data.quoteCategories.map((item) => {
      if (category === item.title) {
        setCategory(item._id);
      }
    });
  };

  console.log("selectedOption", selectedOption);
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-1"></div>
        <br />
        <br />
        <br />
        <div className="col-lg-10 mb-2 contact-form__wrapper p-1 order-lg-1">
          <center>
            <h2>Edit Quote</h2>
          </center>

          <br />

          <form
            action="#"
            className="contact-form form-validate"
            onSubmit={handleSubmit}
          >
            <div className="row">
              <div className="col-sm-12 mb-3">
                <div className="searchInput form-group">
                  <label className="required-field" htmlFor="firstName">
                    Author
                  </label>
                  <Autocomplete
                    style={{ width: "100%" }}
                    multiple={false}
                    options={authors}
                    defaultValue={{
                      name: selectedOption,
                    }}
                    onChange={(e, item) => {
                      if (item && item.name) {
                        setAuthorID(item._id);
                        setname(item.name);
                        setselectedOption(item.name);
                      } else {
                        setAuthorID();
                        setselectedOption();
                      }
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className=" choosing-option-autocomplete"
                        variant="standard"
                        label=""
                        placeholder={selectedOption}
                      />
                    )}
                  />
                </div>

                {/* <input
									onChange={e => setname(e.target.value)}
									type="text"
									className="form-control"
									id="firstName"
									name="firstName"
									defaultValue={selectedAuthor}
									placeholder="Author Name"
								/> */}
              </div>

              <div className="col-sm-12 mb-3">
                <div className="form-group">
                  <label htmlFor="phone">Quote Description</label>
                  <textarea
                    onChange={(e) => setcampDesc(e.target.value)}
                    defaultValue={campDesc}
                    type="text"
                    className="form-control"
                    id="phone"
                    name="phone"
                    placeholder="description"
                  />
                </div>
              </div>
              <div className="col-sm-12 mb-3">
                <div className="form-group">
                  <label htmlFor="phone">ArtWork : </label> &nbsp;
                  <ImageCropper
                    update_dimensions={(item, scaleX, scaleY) =>
                      update_dimensions(item, scaleX, scaleY)
                    }
                    updateImage={(item) => updateImage(item)}
                  />
                </div>{" "}
              </div>

              <div className="col-sm-12 mb-3">
                {!loading ? (
                  <input
                    type="submit"
                    value="Launch Quote"
                    name="submit"
                    className="btn btn-primary"
                  />
                ) : (
                  <input
                    type="submit"
                    value="Loading..."
                    name="submit"
                    className="btn btn-primary"
                  />
                )}
              </div>
            </div>
          </form>
        </div>{" "}
        {/* End Contact Form Wrapper */}
      </div>
    </div>
  );
}
