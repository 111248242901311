import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { getUpcomingCampaignDetail } from '../Api';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy, FaCheckCircle } from 'react-icons/fa';
import Edit from '@material-ui/icons/Edit';

function UPcomingCampaignDetails() {
	const [data, setdata] = useState({ quoteSuggestions: [] });

	const [copyId, setcopyId] = useState(null);
	const params = useParams();

	useEffect(async () => {
		const res = await getUpcomingCampaignDetail(params.id);
		setdata(res.res.data.upcomingCampaign);
		console.log(res.res.data);
	}, []);

	return (
		<div className="dashboard-container admin-campaign">
			<div className="container">
				<div className="row text-align-center">
					<div className="col-lg-6">
						<h3 className="last-campaign admin-last-campaign text-left">
							~~ Quote Suggestion for {data.title} ~~
						</h3>
						created On {data.createdOn}
					</div>

					<div className="col-lg-6">
						<h3 className="last-campaign admin-last-campaign text-right">
							<Link to="/admin-quote">
								<button className="btn btn-outline-info">
									Go back to Quotes
								</button>
							</Link>
						</h3>
					</div>
				</div>
			</div>

			<br />

			<table className="table table-bordered">
				<thead>
					<tr>
						<th scope="col" className="font-weight-bold">
							Sr.
						</th>
						<th scope="col" className="font-weight-bold">
							Quote
						</th>
						<th scope="col" className="font-weight-bold">
							Author
						</th>
						<th scope="col" className="font-weight-bold">
							Status
						</th>
						<th scope="col" className="font-weight-bold">
							Action
						</th>
					</tr>
				</thead>
				<tbody>
					{data &&
						data.quoteSuggestions.map((item, index) => {
							return (
								<tr key={index}>
									<th scope="row">{index + 1}</th>
									<td>
										<CopyToClipboard
											text={item.quoteMsg}
											onCopy={() => setcopyId(index)}
										>
											{copyId == index ? (
												<button
													className="btn btn-primary"
													style={{
														padding: '10px',
														background: '#0ba50b',
														border: 'none'
													}}
												>
													<FaCheckCircle
														size={20}
														style={{ width: '20px', height: '20px' }}
													/>
												</button>
											) : (
												<button
													className="btn btn-primary"
													style={{ padding: '10px' }}
												>
													<FaCopy
														size={20}
														style={{ width: '20px', height: '20px' }}
													/>
												</button>
											)}
										</CopyToClipboard>
										&nbsp; &nbsp;
										<span>
											<strong>{item.quoteMsg}</strong>
										</span>
									</td>
									<td>
										{item.author && (
											<Link to={'/quote-by-author/' + item.author._id}>
												{item.author.name}
											</Link>
										)}
									</td>
									{item.published ? (
										<td style={{ color: 'green', fontWeight: 'bold' }}>
											Published
										</td>
									) : (
										<td style={{ color: 'red', fontWeight: 'bold' }}>
											Unpublished
										</td>
									)}

									<td>
										{' '}
										<Link
											to={`/edit-upcoming-quote-admin/${params.id}/${item.user._id}`}
										>
											<Edit />
										</Link>
									</td>
								</tr>
							);
						})}
				</tbody>
			</table>
		</div>
	);
}

export default UPcomingCampaignDetails;
