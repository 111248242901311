import React, { useState, useEffect } from "react";
import { FaLinkedin, FaWhatsapp } from "react-icons/fa";
import EditIcon from "@material-ui/icons/Edit";

import { Link } from "react-router-dom";
import { ActivatedUser, DeactivatedUser, getAllLeaders, getAllAdmins } from "../Api";
export default function AllMember(props) {
  useEffect(() => {
    getUsers();
  }, []);

  //console.log(props.match.params.type);

  const [searchTerm, setsearchTerm] = useState("");

  const [memberList, setmemberList] = useState([]);
  const [entryToShow, setentryToShow] = useState(null);
  const [memberListFilter, setmemberListFilter] = useState([]);

  const getUsers = async () => {
    let res = await getAllLeaders();
    let res1 = await getAllAdmins();
    if (res.error) {
      console.log(res.error);
    } else {
      let array=[...res.res.data.leaders,...res1.res.data.users]
    //  console.log(res,res1);
    
      setmemberList(array);
      setmemberListFilter(array);
console.log(array)
    }
  };
  useEffect(() => {
    switch (entryToShow) {
      case "all":
        setmemberListFilter(memberList);
        break;
      case "active":
        let temp1 = [];
        memberList.forEach((member) => {
          if (member.activity > 75) {
            temp1.push(member);
          }
        });
        setmemberListFilter(temp1);
        break;
      case "partial":
        let temp2 = [];
        memberList.forEach((member) => {
          if (member.activity > 25 && member.activity <= 75) {
            temp2.push(member);
          }
        });
        setmemberListFilter(temp2);
        break;
      case "random":
        let temp3 = [];
        memberList.forEach((member) => {
          if (member.activity > 1 && member.activity <= 25) {
            temp3.push(member);
          }
        });
        setmemberListFilter(temp3);
        break;
      case "na":
        let temp4 = [];
        memberList.forEach((member) => {
          if (member.activity <= 1) {
            temp4.push(member);
          }
        });
        setmemberListFilter(temp4);
        break;
      case "nr":
        let temp5 = [];
        memberList.forEach((member) => {
          if (
            new Date().getTime() <
            new Date(member.createdAt).getTime() + 604800000
          ) {
            temp5.push(member);
          }
        });
        setmemberListFilter(temp5);
        break;
      default:
        setmemberListFilter(memberList);
    }
  }, [entryToShow]);
  return (
    <div>
      <div className="dashboard-main-container">
        <div className="teams-title">
          <h3
            className="last-campaign admin-last-campaign text-left"
            style={{ textTransform: "capitalize" }}
          >
            Teams Leaders and Admins List
          </h3>
        </div>

        <div className="dashboard-container admin-campaign">
          <div className="teams-search">
            <label className="teams-select">
              Show
              <select
                name="datatable-buttons_length"
                aria-controls="datatable-buttons"
                className="custom-select teams-custom-select"
                onChange={(e) => setentryToShow(e.target.value)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              entries
            </label>

            <label className="teams-select">
              Activity Status :
              <select
                name="datatable-buttons_length"
                aria-controls="datatable-buttons"
                className="custom-select teams-custom-select form-control"
                onChange={(e) => setentryToShow(e.target.value)}
              >
                <option value="all">Choose Activity Status</option>
                <option value="active">Active</option>
                <option value="partial">Partially Active</option>
                <option value="random">Random Active</option>
                <option value="na">Non Active</option>
                <option value="nr">New Registration</option>
              </select>
            </label>

            <label className="teams-searchbar">
              Search:
              <input
                type="search"
                className="form-control form-control-sm"
                placeholder=""
                aria-controls="datatable-buttons"
                onChange={(e) => setsearchTerm(e.target.value)}
              />
            </label>
          </div>

          <div className="container">
            <div className="row text-align-center">
              <div className="col-lg-6"></div>

              <div className="col-lg-6">
                <h3 className="last-campaign admin-last-campaign text-right"></h3>
              </div>
            </div>
          </div>

          <br />

          <table className="table table-bordered">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="font-weight-bold"
                  style={{ width: "10%" }}
                >
                  <div className="up-down-container">
                    <strong> Sr.</strong>
                    <div className="up-down-arrows"></div>
                  </div>
                </th>
                <th
                  scope="col"
                  className="font-weight-bold"
                  style={{ width: "80%" }}
                >
                  <div className="up-down-container">
                    <strong> Member Name</strong>
                    <div className="up-down-arrows"></div>
                  </div>
                </th>

                <th
                  scope="col"
                  className="font-weight-bold"
                  style={{ width: "80%" }}
                >
                  <div className="up-down-container">
                    <strong> Email</strong>
                    <div className="up-down-arrows"></div>
                  </div>
                </th>
                <th
                  scope="col"
                  className="font-weight-bold"
                  style={{ width: "80%" }}
                >
                  <div className="up-down-container">
                    <strong> Activeness</strong>
                    <div className="up-down-arrows"></div>
                  </div>
                </th>
                <th
                  scope="col"
                  className="font-weight-bold"
                  style={{ width: "80%" }}
                >
                  <div className="up-down-container">
                    <strong> Edit </strong>
                    <div className="up-down-arrows"></div>
                  </div>
                </th>
                <th
                  scope="col"
                  className="font-weight-bold"
                  style={{ width: "80%" }}
                >
                  <div className="up-down-container">
                    <strong> Type </strong>
                    <div className="up-down-arrows"></div>
                  </div>
                </th>
                <th
                  scope="col"
                  className="font-weight-bold"
                  style={{ width: "80%" }}
                >
                  <div className="up-down-container">
                    <strong> Social</strong>
                    <div className="up-down-arrows"></div>
                  </div>
                </th>
                

                {/* <th scope="col" className="font-weight-bold">
                  <div className="up-down-container">
                    <strong>Action</strong>
                    <div className="up-down-arrows"></div>
                  </div>
                </th> */}
              </tr>
            </thead>
            <tbody>
              {memberListFilter &&
                memberListFilter
                  .filter((item, index) => {
                    if (
                      item.name
                        .toLocaleLowerCase()
                        .includes(searchTerm.toLocaleLowerCase())
                    )
                      return item;
                  })
                  .map((item, index) => {
                    return (
                      <tr key={index + 1}>
                        <th scope="row">{index + 1}</th>
                        <td>
                          <Link
                            to={
                              "/allmembers/" +
                              item.name +
                              "/profile/" +
                              item._id
                            }
                          >
                            {item.name}
                          </Link>
                        </td>
                        <td>{item.email}</td>
                        {/* <td>{item.campaigns[0]}</td> */}
                        <td>{item.activity}%</td>
                        <td>
                          <Link
                            to={`/allmembers/${item.name}/profile/${item._id}`}
                          >
                            <EditIcon />
                          </Link>
                        </td>
                        <td>
                          {item.type==='user'?"Leader":"Admin"}
                          
                        </td>

                        <td>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <a
                              href={`https://wa.me/${item.phoneNumber}?text=`}
                              target="_blank"
                            >
                              <img
                                width="30"
                                src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                              />
                            </a>
                            <a href={item.linkedinUrl} target="_blank">
                              <img
                                width="25"
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c9/Linkedin.svg/1200px-Linkedin.svg.png"
                              />
                            </a>
                          </div>
                        </td>
                       
                      </tr>
                    );
                  })}
            </tbody>
          </table>

          <div className="prev-next-row">
            <div className="entries-text">
              Showing{" "}
              {entryToShow > memberListFilter.length
                ? memberListFilter.length
                : entryToShow}{" "}
              of {memberListFilter.length} entries
            </div>
            {/* <div className='prev-next'>
            <div>Previous</div> <div className='prev-count'>1</div>{' '}
            <div>Next</div>
          </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
