import React, { useEffect, useState } from "react";
import { getCampaignDetail, getNominations, RemoveWinner, setWinner } from "../Api";
import Team from "../Home/Team";

function Nominations({ match }) {
  const [data, setdata] = useState(null);
  const [detail, setdetail] = useState(null);
  const [getContentCreator, setGetContentCreator] = useState([]);
  const [getViewership, setGetViewership] = useState([]);
  const [getEngager, setGetEngager] = useState([]);
  useEffect(() => {
    getAllNominations();
  }, []);
  const Remove = async (winningType, value) => {
    let data = {};
    data[winningType] = value;
    let sendData = {
      winner: {
        type: winningType,
        userId: value,
      },
    };
    const res = await RemoveWinner(match.params.id, sendData);

    console.log(res);
    if (res.error) {
      alert("Some Error occurred in selecting winner");
    } else {
      console.log("Res in winner", res.data.msg);
      alert(res.data.msg);
      getAllNominations();
      //setwinner({contentCreator:"",viewership:"",engager:""})
    }
  };
  const getAllNominations = async () => {
    const res = await getNominations(match.params.id);
    const res1 = await getCampaignDetail(match.params.id);
    console.log(res, res1);
    setdata(res.data.nomination);
    setdetail(res1.data.campaign);
  };
  const appointWinnerContent = async (id) => {
    const res = await setWinner(match.params.id, {
      winner: {
        contentCreator: id,
      },
    });
    getAllNominations();
  };
  const appointWinnerEngagger = async (id) => {
    const res = await setWinner(match.params.id, {
      winner: {
        engager: id,
      },
    });
    getAllNominations();
  };
  const appointWinnerViewer = async (id) => {
    const res = await setWinner(match.params.id, {
      winner: {
        viewership: id,
      },
    });
    getAllNominations();
  };
  const chooseWinner = async (winningType, value) => {
    let data = {};
    data[winningType] = value;
    let sendData = {
      winner: {
        type: winningType,
        userId: value,
      },
    };

    const res = await setWinner(match.params.id, sendData);

    console.log(res);
    if (res.error) {
      alert("Some Error occurred in selecting winner");
    } else {
      console.log("Res in winner", res.data.msg);
      alert(res.data.msg);
      getAllNominations()
      //setwinner({contentCreator:"",viewership:"",engager:""})
    }
  };
  return (
    <div>
      <h4>
        Campaign Name: {detail && detail.title}
      </h4>
      <br />
      <table className="table table-bordered" style={{ background: "white" }}>
        <thead>
          <tr>
            <th>
              <strong>S.No</strong>
            </th>
            <th>
              <strong>Team Name</strong>
            </th>
            <th>
              <strong>Nominated Content Creator</strong>
            </th>
            <th>
              <strong>Nominated Engager</strong>
            </th>
            <th>
              <strong>Nominated Viewership</strong>
            </th>
          </tr>
        </thead>
        {data && detail && (
          <tbody>
            {data &&
              data.length > 0 &&
              data.map((item, idx) => {
                console.log(item);
                return (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>{item.team.title}</td>
                    <td>
                      {item.nominees.contentCreator &&
                        item.nominees.contentCreator.name}
                      {item.nominees.contentCreator ? (
                        <span className=" float-right">
                            {detail.winner.contentCreator.find(
                            (ele) => ele._id === item.nominees.contentCreator._id
                          ) ?
                          <span
                            onClick={() =>
                              Remove(
                                "contentCreator",
                                item.nominees.contentCreator._id
                              )
                            }
                          >
                            <i class="fas fa-check p-1 bg-success"></i>
                          </span>:
                          <span
                          onClick={() =>
                            chooseWinner(
                              "contentCreator",
                              item.nominees.contentCreator._id
                            )
                          }
                        >
                          <i class="fas fa-check p-1 bg-primary"></i>
                        </span>}
                        </span>
                      ) : null}
                      {(detail["winner"] === undefined ||
                        detail.winner["contentCreator"] === undefined) &&
                        item.nominees.contentCreator && (
                          <button
                            onClick={() =>
                              appointWinnerContent(
                                item.nominees.contentCreator._id
                              )
                            }
                            className="btn btn-info"
                            style={{
                              padding: "5px",
                              borderRadius: "28px",
                              fontSize: "11px",
                            }}
                          >
                            Appoint Winner
                          </button>
                        )}
                    </td>
                    <td>
                      {item.nominees.engager && item.nominees.engager.name}
                      {item.nominees.engager ? (
                        <span className=" float-right">
                          {detail.winner.engager.find(
                            (ele) => ele._id === item.nominees.engager._id
                          ) ? (
                            <span
                              onClick={() =>
                                Remove(
                                  "engager",
                                  item.nominees.engager._id
                                )
                              }
                            >
                              <i class="fas fa-check p-1 bg-success"></i>
                            </span>
                          ) : (
                            <span
                              onClick={() =>
                                chooseWinner(
                                  "engager",
                                  item.nominees.engager._id
                                )
                              }
                            >
                              <i class="fas fa-check p-1 bg-success"></i>
                            </span>
                          )}
                        </span>
                      ) : null}
                      {(detail["winner"] === undefined ||
                        detail.winner["engager"] === undefined) &&
                        item.nominees.engager && (
                          <button
                            onClick={() =>
                              appointWinnerEngagger(item.nominees.engager._id)
                            }
                            className="btn btn-info"
                            style={{
                              padding: "5px",
                              borderRadius: "28px",
                              fontSize: "11px",
                            }}
                          >
                            Appoint Winner
                          </button>
                        )}
                    </td>
                    <td>
                      {item.nominees.viewership &&
                        item.nominees.viewership.name}
                      {item.nominees.viewership ? (
                        <span className=" float-right">
                          {detail.winner.viewership.find(
                            (ele) => ele._id === item.nominees.viewership._id
                          ) ? (
                            <span
                              onClick={() =>
                                Remove(
                                  "viewership",
                                  item.nominees.viewership._id
                                )
                              }
                            >
                              <i class="fas fa-check p-1 bg-success"></i>
                            </span>
                          ) : (
                            <span
                              onClick={() =>
                                chooseWinner(
                                  "viewership",
                                  item.nominees.viewership._id
                                )
                              }
                            >
                              <i class="fas fa-check p-1 bg-primary "></i>
                            </span>
                          )}
                        </span>
                      ) : null}

                      {(detail["winner"] === undefined ||
                        detail.winner["viewership"] === undefined) &&
                        item.nominees.viewership && (
                          <button
                            onClick={() =>
                              appointWinnerViewer(item.nominees.viewership._id)
                            }
                            className="btn btn-info"
                            style={{
                              padding: "5px",
                              borderRadius: "28px",
                              fontSize: "11px",
                            }}
                          >
                            Appoint Winner
                          </button>
                        )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        )}
      </table>
    </div>
  );
}

export default Nominations;
