import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import TagList from '../TagList/campaignList';

import CampaignContentCard from './CampaignContentCard';
import {
	getCampaignsContentById,
	getCampaignSingleParticipate,
	getCampaignsList,
	getUserCampaigns,
	getUserDetails
} from '../Api';

import CustomLoader from '../CustomLoader';
import './winner.scss';
import { useParams } from 'react-router-dom';
import CampaignContantUserBYId from './CampaignContentCardById';
import Footer from '../Navbar/Footer';

export default function CampaignById() {
	const [campaignData, setcampaignData] = useState([]);
	const [campaignDataList, setcampaignDataList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [userData,setUserData]=useState({});
  let location=useParams()
	useEffect(() => {
		console.log(location)
		setLoading(true);
		campaignContentByID();
	
	}, [location]);


	const campaignContentByID = async id => {
	//	setLoading(true);
		const res = await getCampaignSingleParticipate(location.id,location.userid);
		const userDetails= await getUserDetails(location.userid)
 
		setUserData(userDetails.res?.data?.user)
		//console.log('userData', userData)
		if (!res.error && res.res) {

	console.log('res.res.data.campaign', res)
	setcampaignData(res.res?.data?.participation)
		}
		setLoading(false);
	};

	return (
		<>
			<Helmet>
				<title>Campaign</title>
			</Helmet>
			<div className="myCompainContainer">
				<br className="lineBreaker" />
				{loading&& <div style={{minHeight:'100vh'}}>	<CustomLoader loading={loading} />
			</div>}
{!loading &&
				<div className="container containerWrapper mt-2">
					<div className="row mb-3">
						<div className="col-lg-10 m-auto  col-sm-9 " style={{ marginLeft: '0' }}>
							
										<CampaignContantUserBYId
										//	getMyCampaigns={getCampaigns}
										
											item={campaignData}
											//index={index}
											userData={userData}
										/>
								
							
						</div>
{/* 
						<TagList
							campainList={true}
							tagData={campaignDataList}
							setTags={campaignContentByID}
							
						/> */}
					</div>
				</div>
}</div>
					<Footer/>
		</>
	);
}
