import React from 'react';
import TrendingCampaignClaimDiv from './TrendingCampaignClaimDiv';
import { Link } from 'react-router-dom';

export default function TrendingQuoteDiv({ quotesData }) {
	return (
		<div>
			<button className="suggest-quote-button sidebar-heading-button radius-5 ">
				<strong style={{ fontSize: '14px', fontWeight: 'bold' }}>
					Trending Quotes
				</strong>
				<Link to="/quotes" style={{ color: 'white', fontWeight: '700' }}>
					See all
				</Link>
			</button>

			<div className="campaign-list-sidebar">
				{quotesData &&
					quotesData.map((item, index) => {
						return <TrendingCampaignClaimDiv item={item} key={index} />;
					})}
			</div>
		</div>
	);
}
