import React, { useState, useEffect } from 'react';
import ProfileDisplay from './ProfileDisplay';
import { getUserProfile } from '../Api';
import { Modal, Button } from 'react-bootstrap';

export default function Profile(props) {
	const [profileData, setprofileData] = useState(null);
	const [Error, setError] = useState(false);
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [modalHeading, setmodalHeading] = useState('');
	const [modalText, setmodalText] = useState('');
	const [modalButton, setmodalButton] = useState('');

	useEffect(() => {
		console.log('her');
		getUser();

		return () => {};
	}, []);

	const getUser = async () => {
		const res = await getUserProfile(props.match.params.id);
		console.log(res);
		if (res.error) {
			setmodalHeading('Oops');
			setmodalText(res.data);
			setmodalButton('Okay !');
			setShow(true);
			return setError(true);
		} else {
			setprofileData(res.res.data.user);
			console.log('Profile data is', res.res.data);
		}
	};

	console.log('profileData', profileData);
	return (
		<div className="profile-container">
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>{modalHeading}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{modalText}</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
					<Button variant="primary" onClick={handleClose}>
						{modalButton}
					</Button>
				</Modal.Footer>
			</Modal>

			<br />
			{profileData && (
				<ProfileDisplay getUserProfile={getUserProfile} profile={profileData} />
			)}
		</div>
	);
}
