import React, { useState, useEffect } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import { Link } from "react-router-dom";
import Moment from "react-moment";
import {
  deleteCampaign,
  deleteLearnMore,
  getAllCampaignsAdmin,
  getAllLearnMore,
  toggleCampaignAdmin,
  editLearnMore
} from "../Api";
import { Modal, Button } from "react-bootstrap";

export default function AdminLearnMore() {
  const [searchTerm, setsearchTerm] = useState("");
  const [allCampaigns, setallCampaigns] = useState(null);
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [Error, setError] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);
  const [modalHeading, setmodalHeading] = useState("");
  const [modalText, setmodalText] = useState("");
  const [modalButton, setmodalButton] = useState("");
  const [loading, setloading] = useState(false);
  const [entryToShow, setentryToShow] = useState(null);
  const [allCampaignsFilter, setallCampaignsFilter] = useState([]);
  const [allLearnMore, setallLearnMore] = useState([]);
  const [url, setUrl] = useState("");
  const [title, setTitle] = useState("");
  const [activeId, setActiveId] = useState("");
  useEffect(() => {
    getLearnMore();

    return () => {};
  }, [1]);
const EditLearn=async()=>{
  let data={
    title:title,url:url
  }
  let res = await editLearnMore(data,activeId);
  console.log(res)
  if (res) {
    getLearnMore()
    handleCloseEdit()
  } else {
    alert("Something went Wrong");
  }
}
  const getLearnMore = async () => {
    const res = await getAllLearnMore();
    if (res.error) {
      setmodalHeading("Oops !");
      setmodalText(`${res}`);
      setmodalButton("Try Again");
      setShow(true);
      return setError(true);
    } else {
      console.log("Res is", res.data);
      setallLearnMore(res.data.learnMore);
    }
  };

  return (
    <div className="dashboard-main-container">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalHeading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalText}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

          {!Error && (
            <a href="/campaigns">
              <Button variant="primary">{modalButton}</Button>
            </a>
          )}

          {Error && (
            <a>
              <Button variant="primary" onClick={handleClose}>
                {modalButton}
              </Button>
            </a>
          )}
        </Modal.Footer>
      </Modal>
      <Modal show={showEdit} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title> Edit Learn More</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form
            action="#"
            className="contact-form form-validate"
           
          >
            <div className="row">
              <div className="col-sm-12 mb-3">
                <div className="form-group">
                  <label className="required-field" htmlFor="firstName">
                    Title
                  </label>
                  <input
                    onChange={(e) => setTitle(e.target.value)}
                    type="text"
                    className="form-control"
                    id="firstName"
                    name="Title"
                    defaultValue={title}
                    placeholder="title"
                  />
                </div>
              </div>

              <div className="col-sm-12 mb-3">
                <div className="form-group">
                  <label className="required-field" htmlFor="email">
                    Url
                  </label>
                  <input
                    onChange={(e) => setUrl(e.target.value)}
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="url"
                    defaultValue={url}
                  />
                </div>
              </div>
              <div className="col-sm-12 mb-3">
              <Button onClick={EditLearn} variant="primary">Edit Button</Button>
              </div>
            </div>
          </form>
     

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

         
        </Modal.Footer>
      </Modal>

      <div className="dashboard-container admin-campaign">
        <div className="container">
          <div className="row text-align-center">
            <div className="col-lg-6">
              <h3 className="last-campaign admin-last-campaign text-left">
                Learn More List
              </h3>
            </div>

            <div className="col-lg-6">
              <h3 className="last-campaign admin-last-campaign text-right">
                <Link to="/add-learn-more">
                  <button className="btn btn-info">Create Learn More</button>
                </Link>
              </h3>
            </div>
          </div>
        </div>

        <br />
        <div className="row">
          <div className="col-5">
            <label className="teams-searchbar">
              Search:
              <input
                type="search"
                className="form-control form-control-sm"
                placeholder=""
                aria-controls="datatable-buttons"
                onChange={(e) => setsearchTerm(e.target.value)}
              />
            </label>
          </div>
        </div>
        <table className="table ">
          <thead>
            <tr>
              <th scope="col" className="font-weight-bold">
                Sr.
              </th>
              <th scope="col" className="font-weight-bold">
                Campaign Title
              </th>
              <th scope="col" className="font-weight-bold">
                Started On
              </th>
              <th scope="col" className="font-weight-bold">
                Video
              </th>

              <th scope="col" className="font-weight-bold">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {allLearnMore &&
              allLearnMore
                .filter((item, index) => {
                  if (
                    item.title
                      .toLocaleLowerCase()
                      .includes(searchTerm.toLocaleLowerCase())
                  )
                    return item;
                })
                .map((item, index) => {
                  return (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>
                      
                          {item.title}
                     
                      </td>
                      <td>
                        <Moment format="DD/MM/YYYY">{item.updatedOn}</Moment>
                      </td>

                      <td>
                        <iframe width="100" height="100" src={item.url}></iframe>
                      </td>

                      <td>
                        <div className="d-flex">
                          <Link onClick={()=>{
                            setUrl(item.url)
                            setTitle(item.title)
                            setActiveId(item._id)
                            handleShowEdit()
                          }}>
                            <EditIcon />
                          </Link>
                          &emsp;{" "}
                          <DeleteIcon
                            onClick={async () => {
                              await deleteLearnMore(item._id);
                              getLearnMore()
                            }}
                          />
                       
                        </div>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
