import { useEffect, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import PDF from "./skill-vs-degree-inspirer-world.pdf";
import { Modal, Button } from "react-bootstrap";
import CustomLoader from "../CustomLoader";
import { checkEmail, confirmEmail, verifyProductToken } from "../Api";
import { useLocation, useParams } from "react-router-dom";
const App = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isAuth, setIsAuth] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show, setShow] = useState(true);
  const [showOtp, setShowOTP] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [otp, setOTP] = useState("");
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const history = useLocation();
  console.log(history);
  useEffect(() => {
    if (history.search) {
      let search = history.search.split("=");
      handleClose();
      verify(search[1]);
    }
  }, []);
  const verify = async (id) => {
    let data = {
      productToken: id,
    };
    let verifyToken = await verifyProductToken(data);
    console.log(verifyToken);
    if (!verifyToken.error) {
      setIsAuth(true);
    } else {
      alert("Invalid Token");
      window.location.href = "/skillVsDegree/";
    }
  };
  const goToPrevPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = () =>
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

  const handleSubmit = async () => {
    const data = {
      email,
    };

    let response = await checkEmail(data);
    console.log(response);
    if (response.res.error) {
      alert("Something went wrong");
    } else {
      if (!response?.res?.data?.customerFound) {
        // setIsAuth(true);
        setShowOTP(true);
      } else {
        window.location.href =
          "/skillVsDegree?token=" + response?.res?.data?.productToken;
      }
    }
  };
  const OTPValidation = async () => {
    let data = {
      email,
      name,
      otp,
    };
    let res = await confirmEmail(data);

    if (res?.res?.data?.otpCorrect) {
      window.location.href =
        "/skillVsDegree?token=" + res?.res?.data?.productToken;
    }
  };
  return (
    <div style={{ backgroundColor: "#146d9c" }}>
      <div
        style={{ textAlign: "center", minHeight: "100vh" }}
        className="container"
      >
        <div style={{ width: "80%", margin: "auto" }}>
          <Modal show={show}>
            <Modal.Header>
              <Modal.Title>Here to Read Our Book?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row m-auto">
                <div className="col-sm-12 mb-2">
                  <div className="form-group">
                    <label htmlFor="email">Email Address</label>
                    <input
                      type="text"
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control"
                      id="email"
                      placeholder="wendy.apple@seed.com"
                    />
                  </div>
                </div>
                {showOtp && (
                  <>
                    <div className="col-sm-12 mb-2">
                      <div className="form-group">
                        <label htmlFor="email">Name</label>
                        <input
                          type="text"
                          onChange={(e) => setName(e.target.value)}
                          className="form-control"
                          id="email"
                          placeholder="wendy.apple@seed.com"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 mb-2">
                      <div className="form-group">
                        <label htmlFor="email">OTP</label>
                        <input
                          type="text"
                          onChange={(e) => setOTP(e.target.value)}
                          className="form-control"
                          id="email"
                          placeholder="wendy.apple@seed.com"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 mb-2">
                      <div className="form-group text-warning">
                      <label style={{color:"gray"}}  htmlFor="email ">
                        Please check <span style={{color:"red",fontWeight:'700'}}>SPAM</span> or
						<span style={{color:"red",fontWeight:'700'}}>
            JUNK
						 </span> mail folder if you didnt receive the{" "}  
						<span style={{color:"red",fontWeight:'700'}}>
						 OTP{" "}
						 </span>
						  in the inbox.
                      </label>  
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              {!showOtp ? (
                <Button variant="secondary" onClick={handleSubmit}>
                  Submit
                </Button>
              ) : (
                <Button variant="secondary" onClick={OTPValidation}>
                  Submit
                </Button>
              )}
            </Modal.Footer>
          </Modal>
        </div>
        <div className="row">
          <div className="col-md-7 m-auto responsive-margin ">
            <Document
              file={PDF}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={<CustomLoader color="#fff" loading={true} size={100} />}
              //	onClick={goToNextPage}

              //	onItemClick={({ pageNumber }) => alert('Clicked an item from page ' + pageNumber + '!')}
            >
              <Page pageNumber={pageNumber} />
              <div
                style={{ justifyContent: "center", margin: "center" }}
                className=" col-12 text-center d-flex"
              >
                {isAuth ? (
                  <>
                    {" "}
                    <button
                      style={{ backgroundColor: "transparent", border: "none" }}
                      onClick={goToPrevPage}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        class="bi bi-arrow-left-circle icon-arrow"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                        />
                      </svg>{" "}
                    </button>
                    <p
                      style={{
                        color: "#fff",
                        marginTop: "10px",
                        fontSize: ".8rem",
                      }}
                    >
                      Page {pageNumber} of {numPages}
                    </p>
                    <button
                      className=""
                      style={{ backgroundColor: "transparent", border: "none" }}
                      onClick={goToNextPage}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        class="bi bi-arrow-right-circle  icon-arrow"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                        />
                      </svg>{" "}
                    </button>
                  </>
                ) : null}
              </div>
            </Document>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
