import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

function generateDownload(canvas, crop) {
  if (!crop || !canvas) {
    return;
  }
  canvas.toBlob(
    (blob) => {
      const previewUrl = window.URL.createObjectURL(blob);

      const anchor = document.createElement('a');
      anchor.download = 'cropPreview.png';
      anchor.href = URL.createObjectURL(blob);
      anchor.click();

      window.URL.revokeObjectURL(previewUrl);
    },
    'image/png',
    1
  );
}

export default function ImageCropper({update_dimensions, updateImage}) {
  const [upImg, setUpImg] = useState();
  const [cropSize,setCropSize]=useState(30)
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: 'px', x:0,y:0 });
  const [completedCrop, setCompletedCrop] = useState(null);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {setUpImg(reader.result); updateImage(reader.result)});
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    canvas.height = image.naturalHeight
    canvas.width = image.naturalWidth
    console.log("ADMIN CROP =>",image.naturalHeight)
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    console.log("completed crop =>", completedCrop)
    update_dimensions(completedCrop,scaleX,scaleY)
  }, [completedCrop]);

  return (
    <div className="App imageCropper">
      <div>
        <input type="file" accept="image/*" onChange={onSelectFile} />
      </div>
      <Modal show={upImg} className='imageCropper'  >
        <div style={{width:'700px'}}>

       
        <Modal.Header closeButton>
            <Modal.Title>
<div>
              Mark the part to mask
<div className='float-right ml-5'>

            <input type="number" placeholder='Size'
             style={{width:'150px'}}  
             value={crop.width}
             onChange={(e)=>{
               console.log(crop,'n',e.target.value)
               setCrop({...crop,width:parseInt(e.target.value),height:parseInt(e.target.value)})}} />
</div>
</div>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{overflowX:'scroll'}}>
        
            <ReactCrop
                src={upImg}
                imageStyle={{maxWidth:'2000px'}}
                style={{maxWidth:'2000px'}}
               
                aspect={4 / 3}
                onImageLoaded={onLoad}
                crop={crop}
                onChange={(c) => {
                  
                  setCrop(c)}}
                onComplete={(c) => setCompletedCrop(c)}
                cropSize={{ width: cropSize, height: cropSize }}
            />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={()=>{setUpImg()}}>
                Done
            </Button>
        </Modal.Footer>
     </div>  </Modal>
      
      <div style={{display:'none'}}>
        <canvas
          ref={previewCanvasRef}
          // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
          style={{
            width: Math.round(completedCrop?.width ?? 0),
            height: Math.round(completedCrop?.height ?? 0)
          }}
        />
      </div>
      {/* <p>
        Note that the download below won't work in this sandbox due to the
        iframe missing 'allow-downloads'. It's just for your reference.
      </p> */}
      {/* <button
        type="button"
        disabled={!completedCrop?.width || !completedCrop?.height}
        onClick={() =>
          generateDownload(previewCanvasRef.current, completedCrop)
        }
      >
        Download cropped image
      </button> */}
    </div>
  );
}
