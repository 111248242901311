import React, { useState, useEffect } from 'react';
import { deleteMySuggestedQuotes, getMySuggestedQuotes } from '../Api';
import Moment from 'react-moment';

import edit_img from '../../images/Edit.png';
import { Modal, Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Delete } from '@material-ui/icons';

export default function MySuggestedQuotes() {
	const [myQuoteList, setmyQuoteList] = useState(null);

	useEffect(() => {
		getSuggestedQuotes();
		return () => {};
	}, []);

	const dateFormat = date => {
		let formatDate = date;
	};

	const getSuggestedQuotes = async () => {
		let res = await getMySuggestedQuotes();
		if (res.error) {
			alert('Some Error Occurred !');
		} else {
			setmyQuoteList(res.data.quotes);
			console.log(res.data.quotes);
		}
	};

	const user = JSON.parse(localStorage.getItem('InspirerWorld'));

	const [show, setShow] = useState(false);
	const [selectedQuote, SetSelectedQuote] = useState({});
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return (
		<div>
			{!myQuoteList && <h6>Loading...</h6>}

			{myQuoteList &&
				myQuoteList.map((item, index) => {
					return (
						<>
							<div
								key={index}
								className="profile-short-div "
								style={{ background: '#F4F4F4' }}
							>
								<div className="profile-topbar-container">
									<div
										className=""
										style={{
											flexBasis: '50%',
											display: 'flex',
											alignItems: 'center'
										}}
									>
										<Image
											className="winner-dp-image trending-quotes-dp"
											src={user.profileImg}
											rounded
											roundedCircle
											thumbnail
											fluid
										/>

										<strong className="profile-topbar-name">{user.name}</strong>
									</div>

									<div
										className="text-right d-flex"
										style={{
											flexBasis: '50%',
											justifyContent: 'flex-end'
										}}
									>
										<Link to={'editSuggestedQuote/' + item._id}>
											<img
												src={edit_img}
												style={{ width: '24px' }}
												onClick={() => 'hello'}
											/>
										</Link>
										<Link
											onClick={async () => {
												await deleteMySuggestedQuotes(item._id);
												getSuggestedQuotes();
											}}
										>
											<Delete
												className="text-danger"
												style={{ fontSize: '30px' }}
											/>
										</Link>
									</div>
								</div>
							</div>

							<div
								className="new-quote-cards-container"
								style={{ marginBottom: '22px' }}
							>
								<div className="new-quote-cards-left">
									<div className="quote-container-div">
										{/* <h5>{item.title}</h5> */}
										<p className="card-text quote-card-quote-text">
											"{item.quoteMsg}"
										</p>
									</div>

									<br />

									<div className="save-div">
										<div className="additional-info">
											<div className="individual-info">
												<div className="info-heading">
													<span>Tags</span>
												</div>
												<div className="info-value">
													{item.hashTags &&
														item.hashTags.map(i => {
															return <span>#{i.toUpperCase()}</span>;
														})}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					);
				})}

			{/* if user has not suggesting any quotes show them empty div  */}

			{myQuoteList && myQuoteList.length < 1 && (
				<div>
					<center>
						<img
							style={{ width: '50%' }}
							src="https://cdn.dribbble.com/users/2071065/screenshots/6559618/dribble_4-19_4x.png?compress=1&resize=800x600"
						/>
						<h4>Oops ! You Haven't suggested any quote yet...</h4>
					</center>
				</div>
			)}
		</div>
	);
}
