import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import fileDownload from 'js-file-download';
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Alert } from 'react-bootstrap';
import Pagination from '../Pagination/index';
import {
	getAllUsers,
	getAllActivatedUsers,
	getCampaignDetail,
	getCampaignParticipants,
	getNominations,
	ParticipantInCampaignByAdmin,
	RemoveWinner,
	setWinner,
	updateCampaignStatsUser,
	viewArtwork,
	viewArtworkAdmin,
	AdminEditContentToCampain,
	toggleContentPublish
} from '../Api';

function CampaignDetail({ match }) {
	const history = useHistory();
	const [detail, setdetail] = useState(null);
	const [participants, setparticipants] = useState(null);
	const [participantsFilter, setparticipantsFilter] = useState([]);
	const [searchTerm, setsearchTerm] = useState('');
	const [getContentCreator, setGetContentCreator] = useState([]);
	const [getViewership, setGetViewership] = useState([]);
	const [getEngager, setGetEngager] = useState([]);
	const [userId, setuserId] = useState(null);
	const [likes, setLikes] = useState(0);
	const [views, setViews] = useState(0);
	const [comments, setComment] = useState(0);
	const [url, setUrl] = useState('');
	const [disabled, setDisabled] = useState(false);

	const [entry, setEntry] = useState(10);
	const [entryToShow, setentryToShow] = useState(null);
	const [page, setPage] = useState(0);
	const [totalpage, setTotalPage] = useState(0);

	const [loading, setLoading] = useState(false);
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [totalUser, setTotalUser] = useState(0);
	const [NewArtwork, setNewArtwork] = useState(null);
	const [disabledClaim, setDisabledClaim] = useState(false);
	const [memberList, setmemberList] = useState([]);
	const [memberListFilter, setmemberListFilter] = useState([]);
	const [claimIndex, setClaimIndex] = useState(null);
	const [campWinner, setCampWinner] = useState({ contentCreator: [] });
	const [showContent, setShowContent] = useState(false);
	const [contentDetail, setContentDetail] = useState();

	const [isError, setisError] = useState(null);
	const [isSuccess, setisSuccess] = useState(null);

	useEffect(async () => {
		CampaignDetail();
		const res1 = await getNominations(match.params.id);
		getUsers();
	}, []);

	const CampaignDetail = async () => {
		const res = await getCampaignDetail(match.params.id);
		let res1 = await getCampaignParticipants(match.params.id);
		setdetail(res.data.campaign);
		console.log(res.data.campaign.winner, 'Cmapign details here', res1.data);
		setCampWinner(res.data.campaign.winner);
		let arr = [];
		let arr1 = [];
		let arr2 = [];
		let user = res1.data.participants;
		console.log(res1.data.participants);
		res.data.campaign.winner.contentCreator.map(i => {
			res1.data.participants.map(ele => {
				if (i._id === ele.userId._id) {
					arr.push(ele.userId._id);
				}
			});
		});
		res.data.campaign.winner.viewership.map(i => {
			res1.data.participants.map(ele => {
				if (i._id === ele.userId._id) {
					arr1.push(ele.userId._id);
				}
			});
		});
		res.data.campaign.winner.engager.map(i => {
			res1.data.participants.map(ele => {
				if (i._id === ele.userId._id) {
					arr2.push(ele.userId._id);
				}
			});
		});
		setGetContentCreator(arr);
		setGetEngager(arr2);
		setGetViewership(arr1);
		setparticipants(user);
	};

	const getUsers = async () => {
		const res1 = await getCampaignParticipants(match.params.id);
		// let res = await getAllUsers();
		const res = await getAllActivatedUsers();
		// console.log('activated', res3);
		if (res.error) {
			// console.log(res.error);
		} else {
			let userInCampaign = res.res.data.users;
			setTotalUser(res.res.data.users.length);
			let user = [];
			// console.log(userInCampaign.length)
			res1.data.participants.map(element => {
				userInCampaign.map((item, index) => {
					if (element.userId) {
						if (item._id === element.userId._id) {
							// console.log(item._id, element.userId._id);
							userInCampaign.splice(index, 1);
						}
					}
				});
			});
			setmemberList(userInCampaign);

			//  console.log(res1.data, res.res.data.users);
		}
	};

	const [winners, setwinner] = useState({
		contentCreator: '',
		viewership: '',
		engager: ''
	});

	const finalFuncToResizeProfilePic = async id => {
		setLoading(true);
		setDisabled(true);
		const res1 = await viewArtworkAdmin(id, match.params.id);
		console.log('viewArtwork =>', res1);
		let filename = `${detail.title}.jpeg`;
		console.log('viewArtwork =>', res1);
		axios
			.get(res1.data, {
				responseType: 'blob'
			})
			.then(res => {
				fileDownload(res.data, filename);
			});
		setNewArtwork(res1.data);
		setDisabled(false);
		finalFuncToDownloadProfilePic(id);
	};

	const finalFuncToDownloadProfilePic = async id => {
		setDisabledClaim(true);
		const res2 = await ParticipantInCampaignByAdmin(id, match.params.id);
		// let url = res2.data.userArtWork;
		// let filename = `${detail.title}.jpeg`;
		// axios
		//   .get(url, {
		//     responseType: "blob",
		//   })
		//   .then((res) => {
		//     fileDownload(res.data, filename);
		//     setLoading(false);
		//   });
		setDisabledClaim(false);
		CampaignDetail();
	};

	const selectThisUser = item => {
		// console.log(item);
		if (item.userId) {
			setuserId(item.userId._id);
			setLikes(item.likes);
			setViews(item.views);
			setComment(item.comments);
			setUrl(item.postUrl);
			handleShow();
		} else {
			alert('User not found');
		}
	};

	const handleSubmit = async e => {
		e.preventDefault();
		const res = await updateCampaignStatsUser(
			{
				likes: likes,
				comments: comments,
				views: views,
				postUrl: url
			},
			match.params.id,
			userId
		);
		handleClose();
		const res1 = await getCampaignParticipants(match.params.id);
		setparticipants(res1.data.participants);
	};
	const refreshStats = async () => {
		const res = await getCampaignDetail(match.params.id);
		setdetail(res.data.campaign);
	};

	const chooseWinner = async (winningType, value) => {
		let data = {};
		data[winningType] = value;
		let sendData = {
			winner: {
				type: winningType,
				userId: value
			}
		};

		setwinner({ ...winners, ...{ [winningType]: value } });
		console.log(winners);
		const res = await setWinner(match.params.id, sendData);

		console.log(res);
		if (res.error) {
			alert('Some Error occurred in selecting winner');
		} else {
			console.log('Res in winner', res.data.msg);
			alert(res.data.msg);
			CampaignDetail();
			//setwinner({contentCreator:"",viewership:"",engager:""})
		}
	};
	const Remove = async (winningType, value) => {
		let data = {};
		data[winningType] = value;
		let sendData = {
			winner: {
				type: winningType,
				userId: value
			}
		};
		const res = await RemoveWinner(match.params.id, sendData);

		console.log(res);
		if (res.error) {
			alert('Some Error occurred in selecting winner');
		} else {
			console.log('Res in winner', res.data.msg);
			alert(res.data.msg);
			CampaignDetail();
			//setwinner({contentCreator:"",viewership:"",engager:""})
		}
	};

	useEffect(() => {
		const participantAllData =
			(participants &&
				participants.map(item => ({
					...item,
					arrayType: 'participant'
				}))) ||
			[];

		const memberAllData =
			(memberList &&
				memberList.map(item => ({
					...item,
					arrayType: 'memberList'
				}))) ||
			[];

		if (participantAllData && memberAllData) {
			const allData = participantAllData.concat(memberAllData);
			if (allData && allData.length > 0) {
				const filteredData = allData.filter((item, index) => {
					if (item.arrayType === 'memberList') {
						if (
							item.name
								.toLocaleLowerCase()
								.includes(searchTerm.toLocaleLowerCase())
						)
							return item;
					} else {
						if (
							item.userId &&
							item.userId.name
								.toLocaleLowerCase()
								.includes(searchTerm.toLocaleLowerCase())
						)
							return item;
					}
				});
				setTotalPage(Math.ceil(filteredData.length / entry));
				let array = filteredData.slice(page * entry, (page + 1) * entry);
				setmemberListFilter(array);
			}
		}
	}, [entry, page, participants, memberList, searchTerm]);

	const showContentBox = content => {
		const curr_body = content.body;
		setContentDetail({ ...content, body: curr_body.replaceAll('<br>', '\n') });
		setShowContent(true);
	};

	const submitEdit = async () => {
		const cur_body = contentDetail.body;
		let data = {
			body: cur_body.replaceAll('\n', '<br>')
		};

		let res = await AdminEditContentToCampain(contentDetail._id, data);

		if (res.error) {
			setisError(true);
			setisSuccess(false);
		} else {
			setisSuccess(true);
			setisError(false);
			CampaignDetail();
		}
	};

	const togglePublish = async () => {
		let res = await toggleContentPublish(contentDetail._id);

		if (res.error) {
		} else {
			CampaignDetail();
			if (res.res && res.res.data) {
				setContentDetail({
					...contentDetail,
					published: res.res.data.published
				});
			}
		}
	};

	return (
		<div style={{ marginTop: '10px' }}>
			{detail && (
				<div className="container-fluid">
					<div className="content">
						<div className="row">
							<div className="col-md-6 text-capitalize">
								Campaign Title&nbsp;:&nbsp;{detail.title}
							</div>
						</div>
						<br />
						<div className="row text-capitalize">
							<div className="col-md-6">
								Started On&nbsp;:&nbsp;
								{new Date(detail.startsOn).toDateString()}
							</div>
						</div>
						<br />
						<div className="row text-capitalize">
							<div className="col-md-6">
								Campaign description&nbsp;:&nbsp;"{detail.desc}"
							</div>
						</div>
						<br />
						<div className="row text-capitalize">
							<div className="col-md-6">
								Campaign status&nbsp;:&nbsp;{detail.active ? 'Open' : 'Close'}
							</div>
						</div>
						<br />
						{/* <div className="row text-capitalize">
                        <div className="col-md-6">
                            winner of Campaign&nbsp;:
                            TBA
                            </div>
                    </div> */}
						<br />
						<Button
							onClick={refreshStats}
							variant="outline-info"
							style={{ float: 'right', marginBottom: '10px' }}
						>
							Refresh Stats
						</Button>
						<br />
						<div className="row" style={{ width: '100%' }}>
							<div className="col-md-3">
								<div className="card mini-stats-wid">
									<div className="card-body">
										<div className="media">
											<div className="media-body">
												<p className="text-muted font-weight-medium">
													Participants
												</p>
												<h4 className="mb-0">
													{participants && participants.length}/{totalUser}
												</h4>
											</div>

											<div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
												<span className="avatar-title">
													<i className="bx bx-user font-size-24"></i>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-md-3">
								<div className="card mini-stats-wid">
									<div className="card-body">
										<div className="media">
											<div className="media-body">
												<p className="text-muted font-weight-medium">Views</p>
												<h4 className="mb-0">{detail.views}</h4>
											</div>
											<div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
												<span className="avatar-title">
													<i className="mdi mdi-eye font-size-24"></i>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-md-3">
								<div className="card mini-stats-wid">
									<div className="card-body">
										<div className="media">
											<div className="media-body">
												<p className="text-muted font-weight-medium">
													Comments
												</p>
												<h4 className="mb-0">{detail.comments}</h4>
											</div>
											<div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
												<span className="avatar-title rounded-circle bg-primary">
													<i className="mdi mdi-chat font-size-24"></i>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-md-3">
								<div className="card mini-stats-wid">
									<div className="card-body">
										<div className="media">
											<div className="media-body">
												<p className="text-muted font-weight-medium">Likes</p>
												<h4 className="mb-0">{detail.likes}</h4>
											</div>
											<div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
												<span className="avatar-title rounded-circle bg-primary">
													<i className="mdi mdi-thumb-up font-size-24"></i>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			<br />
			<br />
			<div className="row">
				<div className="col-5">
					<label className="teams-searchbar">
						Search:
						<input
							type="search"
							className="form-control form-control-sm"
							placeholder=""
							aria-controls="datatable-buttons"
							onChange={e => setsearchTerm(e.target.value)}
						/>
					</label>
				</div>
			</div>
			{participants && (
				<table className="table table-bordered container-fluid">
					<thead>
						<tr>
							<th>
								<strong>S.no</strong>
							</th>
							<th>
								<strong>Name</strong>
							</th>

							<th>
								<strong>Likes</strong>
							</th>
							<th>
								<strong>Comments</strong>
							</th>
							<th>
								<strong>Views</strong>
							</th>
							<th>
								<strong>Social</strong>
							</th>
							<th>
								<strong>Nominate</strong>
							</th>
							<th>
								<strong>Rated/avgRating</strong>
							</th>
							<th>
								<strong>Artwork</strong>
							</th>
							<th>
								<strong>Action</strong>
							</th>
						</tr>
					</thead>
					<tbody>
						{memberListFilter &&
							memberListFilter.map((item, idx) => {
								if (item.arrayType === 'memberList') {
									return (
										<tr key={idx}>
											<td>{entry * page + idx + 1}</td>
											<td>
												<Link
													to={
														'/allmembers/' + item.name + '/profile/' + item._id
													}
												>
													{item.name}
												</Link>
											</td>
											{/* <td>{item.email}</td> */}
											<td>0</td>
											<td>0</td>
											<td>0</td>
											{/* <td scope='row' className="m-auto d-flex text-center ">
                    <div className="nominater-option ml-2">
                      <span > C</span>
                    </div>
                    <div className="nominater-option ml-2">
                      <span > V</span>
                    </div>
                    <div className="nominater-option ml-2">
                      <span > E</span>
                    </div>
                  </td>
                */}
											<td>
												<div
													style={{
														display: 'flex',
														justifyContent: 'space-between',
														alignItems: 'center'
													}}
												>
													<a
														href={`https://wa.me/${item.phoneNumber}?text=`}
														target="_blank"
													>
														<img
															width="30"
															src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
														/>
													</a>
													<a href={item.linkedinUrl} target="_blank">
														<img
															width="25"
															src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c9/Linkedin.svg/1200px-Linkedin.svg.png"
														/>
													</a>
												</div>
											</td>
											<td>not participanted</td>
											<td>not participanted</td>
											<td>not participanted</td>
											<td>
												<button
													className="btn btn-info"
													onClick={() => {
														setClaimIndex(idx);
														finalFuncToResizeProfilePic(item._id);
													}}
												>
													{!loading ? (
														<a classname="claim-hover">Claim</a>
													) : idx === claimIndex ? (
														<a classname="claim-hover">Claiming...</a>
													) : (
														<a classname="claim-hover">Claim</a>
													)}
												</button>
											</td>
										</tr>
									);
								} else {
									return (
										<tr key={idx}>
											<td>{entry * page + idx + 1}</td>

											<td>
												{item.userId ? (
													<Link
														to={
															'/allmembers/' +
															item.userId.name +
															'/profile/' +
															item.userId._id
														}
													>
														{item.userId.name}
													</Link>
												) : null}
											</td>
											<td>{item.likes}</td>
											<td>{item.comments}</td>
											<td>{item.views}</td>
											<td>
												<div
													style={{
														display: 'flex',
														justifyContent: 'space-between',
														alignItems: 'center'
													}}
												>
													<a
														href={`https://wa.me/${item.phoneNumber}?text=`}
														target="_blank"
													>
														<img
															width="30"
															src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
														/>
													</a>
													<a href={item.linkedinUrl} target="_blank">
														<img
															width="25"
															src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c9/Linkedin.svg/1200px-Linkedin.svg.png"
														/>
													</a>
												</div>
											</td>
											<td
												scope="row"
												style={{
													flexWrap: item && item.contentId ? 'wrap' : 'nowrap'
												}}
												className="auto d-flex text-center winner-nomination-div "
											>
												{getContentCreator.find(
													element => item.userId._id == element
												) ? (
													<div className="nominater-option bg-success ml-2">
														<span
															onClick={() =>
																Remove('contentCreator', item.userId._id)
															}
														>
															{' '}
															C
														</span>
													</div>
												) : (
													<div className="nominater-option ml-2">
														<span
															onClick={() =>
																chooseWinner('contentCreator', item.userId._id)
															}
														>
															{' '}
															C
														</span>
													</div>
												)}

												{getViewership.find(
													element => item.userId._id == element
												) ? (
													<div className="nominater-option bg-success ml-2">
														<span
															onClick={() =>
																Remove('viewership', item.userId._id)
															}
														>
															{' '}
															V
														</span>
													</div>
												) : (
													<div className="nominater-option ml-2">
														<span
															onClick={() =>
																chooseWinner('viewership', item.userId._id)
															}
														>
															{' '}
															V
														</span>
													</div>
												)}
												{getEngager.find(
													element => item.userId._id == element
												) ? (
													<div className="nominater-option bg-success  ml-2">
														<span
															onClick={() => Remove('engager', item.userId._id)}
														>
															{' '}
															E
														</span>
													</div>
												) : (
													<div className="nominater-option  ml-2">
														<span
															onClick={() =>
																chooseWinner('engager', item.userId._id)
															}
														>
															{' '}
															E
														</span>
													</div>
												)}
												{item && item.contentId && (
													<button
														style={{
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															border: 'none',
															borderRadius: '3px',
															color: 'white',
															background: item.contentId?.published
																? '#66df7c '
																: '#166d9c',
															width: '100%',
															marginTop: '10px',
															padding: '8px 0'
														}}
														onClick={() => {
															showContentBox(item.contentId);
														}}
													>
														Show Content
													</button>
												)}
											</td>
											<td>
												{item.ratingsCount} / {item.avgRating}
											</td>
											<td>
												<a href={item.postUrl} target="_blank" rel="noreferrer">
													<img
														src={item.imageUrl}
														style={{ width: '50px' }}
														alt="postImage"
													/>
												</a>
											</td>
											<td>
												<button
													className="btn btn-info"
													onClick={() => selectThisUser(item)}
												>
													Edit Stats
												</button>
											</td>
										</tr>
									);
								}
							})}
					</tbody>
				</table>
			)}
			{participants && participants.length > 0 && (
				<Pagination
					page={page}
					entryToShow={10}
					setPage={setPage}
					totalpage={totalpage}
					totalData={participants.length}
				/>
			)}
			{showContent}
			<Modal
				show={showContent}
				onHide={() => {
					setShowContent(false);
				}}
			>
				<Modal.Header closeButton>
					<Modal.Title>Content</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{contentDetail?.body && (
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<div className="font-bold">Description</div>
							<textarea
								style={{ minHeight: '330px' }}
								className="mt-2 mb-2 p-3"
								defaultValue={contentDetail?.body}
								onChange={e => {
									setContentDetail({ ...contentDetail, body: e.target.value });
								}}
							></textarea>
							{isError && (
								<Alert variant="danger">Oops Some Error Occurred !</Alert>
							)}

							{isSuccess && (
								<Alert variant="success">Content Edited Successfully !</Alert>
							)}
							<div style={{ display: 'flex' }} className="mt-2">
								<button
									onClick={togglePublish}
									className="mr-2"
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										border: 'none',
										borderRadius: '3px',
										color: 'white',
										background: '#166d9c',
										width: '100%',
										marginTop: '10px',
										padding: '8px 0'
									}}
								>
									{contentDetail?.published ? 'Un-Publish' : 'Publish'}
								</button>

								<button
									onClick={submitEdit}
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										border: 'none',
										borderRadius: '3px',
										color: 'white',
										background: '#166d9c',
										width: '100%',
										marginTop: '10px',
										padding: '8px 0'
									}}
								>
									Submit Edit
								</button>
							</div>
						</div>
					)}
				</Modal.Body>
			</Modal>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Modify Campaign Stats Wizard</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form
						onSubmit={e => {
							handleSubmit(e);
						}}
					>
						<Form.Group controlId="formBasicEmail">
							<Form.Label>Likes</Form.Label>
							<Form.Control
								type="number"
								placeholder="Enter number of likes"
								onChange={e => {
									setLikes(e.target.value);
								}}
								value={likes}
							/>
						</Form.Group>
						<Form.Group controlId="formBasicEmail">
							<Form.Label>Views</Form.Label>
							<Form.Control
								type="number"
								placeholder="Enter number of likes"
								onChange={e => {
									setViews(e.target.value);
								}}
								value={views}
							/>
						</Form.Group>
						<Form.Group controlId="formBasicEmail">
							<Form.Label>Comments</Form.Label>
							<Form.Control
								type="number"
								placeholder="Enter number of likes"
								onChange={e => {
									setComment(e.target.value);
								}}
								value={comments}
							/>
						</Form.Group>
						<Form.Group controlId="formBasicEmail">
							<Form.Label>LinkedinUrl</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter number of likes"
								onChange={e => {
									setUrl(e.target.value);
								}}
								value={url}
							/>
						</Form.Group>
						<hr />
						<Button
							variant="secondary"
							onClick={e => {
								e.preventDefault();
								handleClose();
							}}
						>
							Close
						</Button>
						<Button variant="primary" type="submit" style={{ float: 'right' }}>
							Update
						</Button>
					</Form>
				</Modal.Body>
			</Modal>
		</div>
	);
}
export default CampaignDetail;
