import React from 'react';
import SamiaUsmani from '../../images/Samia-Usmani.jpg';
import Shobana from '../../images/Shobana.jpg';
import Megha from '../../images/Megha.jpeg';
import Safaa from '../../images/Safaa.jpg';
import Ahmed from '../../images/Ahmed.jpeg';
import Ioanna from '../../images/Ioanna.jpeg';
import Nermeen from '../../images/Nermeen.jpeg';
import AsmaMukadam from '../../images/Test.jpeg';
import NayyabFarooq from '../../images/Nayyab-Farooq-Satti.png';
import altafAhmed from '../../images/Altaf Ahmed.jpeg';
import shakeelMulla from '../../images/Shakeel Mulla.jpeg';
import VaishaliJog from '../../images/VaishaliJog.jpeg';
import MariaDeLosAngeles from '../../images/MariaDeLosAngeles.jpeg';

import './index.css';

export const data = [
	{
		name: 'Samia Usmani',
		role: 'Content Creator',
		message:
			'Inspirer world is an excellent platform for generating ideas and creativity. It provides the members a freedom of thought and expression. The best thing about it is that it creates an excitement in members to engage in the posts and collaborate with each other in a very forum are extremely encouraging and motivating. The vibrant posts and amazing poster designs show the true spirit of compassion and devotion of the admin team. I am glad to be the part of Inspirer world community.',
		image: SamiaUsmani,
		slug: 'samia-usmani'
	},
	{
		name: 'Safaa Sahul Hameed',
		role: 'Content Creator',
		message:
			"Inspired World - A pandemic Blessing. Who would have thought a campaign on Linkedin would unlock new opportunities and open horizons? Create content creators from amateur writers? Though, it sounds a dream unreal but the efforts behind each campaign has measured the success rate of what is today.True to it's name , Inspirer World has inspired like minded writers to share ideas that change the world. Great leaders like Shakespeare and Newton have accomplished miraculous things confined to their houses.Likewise, Inspirer World has helped me bring out my innate writing and also be productive under peculiar circumstances. I wanted to produce something, but I couldn't do it. Inspirer World has been a stepping stone for me to what I am today! It has inspired me and I am sure it will leave it's legacy behind inspiring millions!",
		image: Safaa,
		slug: 'safaa-sahul-hameed'
	},
	{
		name: 'Ahmed Soliman',
		role: 'Content Creator',
		message:
			"Positive Energy in this Life Happens when you meet people having your same vision, Goals and Work as your ignition to move on and inspire others and spread positivity Everywhere, and be the reason to influence a person and make a difference and transform another person's life. I was drastically blessed from GOD by meeting this Platform's founders,  where we worked as one team every day and every week. Every week we inspired people together and every day with this magnificent team i really found a difference. I would love to point out that inspirerworld.com foundation was a part of my success on Linkedin and the social media in general. All Gratitude and Gratefulness to God who showered me and all of us with his blessings and For enabling the founders to launch this magnificent and strong Platform to inspire the world.",
		image: Ahmed,
		slug: 'ahmed-soliman'
	},
	{
		name: 'Megha Chhabra',
		role: 'Content Creator',
		message:
			'Inspirer world- A community where my imaginations get wings. The thought-provoking campaigns always help me to discover something new and skyrocket my creativity to the next level. Inspirer world helps me sharpen my writing skills and also brings a new paradigm shift that changes my outlook towards life. For me, the Inspirer World is a platform where we all learn and grow together. It is for all. We are for all. Inspirer World can be represented as- I [nspire] R = from Innovation to Realization. It has unlocked new opportunities for everyone. We create, innovate, realize our strengths and weaknesses. W [orl] d = from Winning to Discovery We win. We win. And only We win. The ecstasy of this community is that no one fails. In case your content is not being selected, you will not lose. In reality, you will learn something great from others. Therefore, it is always a win-win situation, and each day you discover yourSELF with new learnings and imaginations.',
		image: Megha,
		slug: 'megha-chhabra'
	},
	{
		name: 'Ioanna Petrochilou',
		role: 'Content Creator',
		message:
			'One year ago, it was just participation in a campaign. Ιt was the curiosity of the different great topics, the wonderful posts, the amazing posters and the people who were members of it. Today is more than that, it is something big, it is a school where you teach people, and you learn from them at the same time. Every campaign during this year was a lesson for me, was the trigger to learn more, to develop myself to do more things for others. People are not anymore, just companion in the journey, today these people are friends, are family, are fellow travellers to the best journey ever. Inspire World made me realize my passion for the people, made me realize that dreams can become a reality when we possess a vision, a willingness to work and do more, a desire for the excellence, and the belief to in ourselves. Inspire World made me realize that I can do things I did not even imagine a year ago. Today I am a Content Writer Expert, I am writing for what I love the most, “People” and I am doing what I have always loved and dreamed of and which I never found the courage to do so. Inspire World is a school, a teacher, a student, and a friend at the same time. It is the place which made me realize that I still have more dreaming to do! “Inspire World” is my place where I can do what I meant to do, to inspire people and make them realize their potential. Make them realize that they must always hunt their dreams. Thank you, “Inspire World”',
		image: Ioanna,
		slug: 'ioanna-petrochilou'
	},
	{
		name: 'Nermeen Deyab',
		role: 'Content Creator',
		message:
			'I had been already off work when I was introduced to the Inspirer World community. I had hardly posted anything on my LinkedIn profile by that time. Inspirer World has encouraged me to start sharing quality content. Every campaign provided me with a new perspective about new topics which was perfect for me being forever curious and forever learning. Inspirer World is led by passion and devotion, and that is manifested in every little detail of weekly campaigns, episodes by experts and even posts designs. It is a community that elevates others and gives them the opportunity to be their own unique selves. In the Inspirer world community you are heard, elevated and nourished.',
		image: Nermeen,
		slug: 'nermeen-deyab'
	},
	{
		name: 'Shobana Karthik',
		role: 'Content Creator',
		message:
			'Inspirer world is a treasure I found on LinkedIn platform. Each campaign topic makes me think on a different outlook. The support, engagement and encouragement of the LinkedIn family is amazing. It gives better visibility and creates new leads as well. The poster design for each campaign is awesome which is an add-on to the post. Inspirer world has helped me set a new benchmark and empowered me with more confidence. Deep gratitude and best wishes to the whole Inspirer world team.',
		image: Shobana,
		slug: 'shobana-karthik'
	},
	{
		name: 'Asma Mukadam',
		role: 'Content Creator',
		message:
			'Inspirer world founders and admins, all of your volunteer work is greatly appreciated. While others work their way towards being different, you strive towards making a difference for LinkedIn members giving them a unique outlook to engage, shine and grow. Your passion and energy are unparalleled. I am happy to join you in my professional journey, passionately creating content every week not to compete or win but to inspire others through my experiences. I respect your spirit of volunteerism immensely, therefore ready to exchange ideas and strategies for the consequent project. Thank you for being so selfless with so many in whose lives you make a tangible difference.',
		image: AsmaMukadam,
		slug: 'asma-mukadam'
	},
	{
		name: 'Nayab Farooq Satti',
		role: 'Content Creator',
		message:
			"Inspirer world is the best discovery of pandemic, which grows me in diversified ways. In every campaign, I love the process of analyzation towards a topic. It's not only a content creating platform, infect leads a benchmark for building your identity and a self awareness guide.",
		image: NayyabFarooq,
		slug: 'nayab-farooq-satti'
	},
	{
		name: 'Altaf Ahmed, MBA',
		role: 'Content Creator',
		message:
			'Inspirer World is the Launchpad for many content writers like me, who during the pandemic has rested shoulders on. With continuous support, encouragement, motivation and great collaboration with like-minded professionals across the globe, I too have polished my skills to become a Content Writer in the weekly campaigns. This is an excellent platform for creativity & expression of thoughts which comes directly from the heart that led my confidence to grow to other dimension. I am very glad to be associated with Inspirer World and my best wishes to the whole team and every one participating in it',
		image: altafAhmed,
		slug: 'altaf-ahmed'
	},
	{
		name: 'Shakeel Mulla',
		role: 'Content Creator',
		message:
			'There is a famous dialogue that says -  "when you desire something from the heart and soul, all the universe conspires you to achieve it." This I have experienced in reality with "Inspirer World". I have been known for my innovative thoughts and my writing skills. I use to write articles, quotes, just for pleasure. And in this Pandemic when I was pushed in hard circumstances of life both professionally & personally,  I made my writing skill my best colleague to keep myself and my people, my team motivated. During this effort, I came across Mr.Nasir Ali on the LinkedIn platform, who introduced me to his amazing community of "Inspirer world". Here each member shares his experience, ideas, thoughts on certain topics in the form of content. All topics were on the development of personal & professional life, which were aligned with my thought process. This association helped me to improve more & explore the magic  in my writing skills. Along with other great writers, my content received a warm acceptance and appreciation more than my expectations. At Inspirer world I discovered my writing as my passion. Now I am inspired to inspire others with my writings. This is giving me real happiness and satisfaction. From bottom of my heart, I extend my gratitude to the entire team, panel, and members in this wonderful world of Inspirers.',
		image: shakeelMulla,
		slug: 'shakeel-mulla'
	},
	{
		name: 'Vaishali Jog',
		role: 'Content Creator',
		message:
			'It has been around 3 months since l have been writing on LinkedIn and around a month since l joined the Inspirer World. I have to say that joining this community has been one of the best decisions that l made.The community is amazing in every sense l can think of.Its a group of individuals who have the intention to grow themselves and to help everyone grow in the community 🙂🙂🙂. The community has an campaign every week with the selection of best quote and best content creators.This kind of competition instills the drive to get better and better with every content that l publish. The engagement that the content gets on this platform also motivates every member of the community to do better and better. Lastly, l loved the ideology of the group which is Engage , Enlighten and Encourage. Thanks Nasir Ali for inviting me in the group 👥 , l am loving every bit of it🙂🙂🙂.',
		image: VaishaliJog,
		slug: 'vaishali-jog'
	},
	{
		name: 'María de los Ángeles',
		role: 'Content Creator',
		message:
			'Inspirer world has given meaning to my life . It has the greatest recognition of, in an epic pandemic humanity period, turning a  multicultural professional community  into a meaningful and valuable changing agent network. How did it make it ? Using a unique and powerful methodology wich main priority was to become a single action into a conscious colective behaviour, creating a new contextualized concept of being successful  in life based on present times. Each campaign has resembled a drop of knowledge from which unstoppable ripples of coherent and well articulated contents have been generated. Inspirer world has turned an individual inpiration into a world one. It also has the merit of educating its members  through selfdiscipline and commintment going beyond a weekly campaign and transforming their lifestyles. Inspirer World has no place for failure or defeats it is the place where you know your worth,where you come to  realize how the impact you have been unconsciously doing as an individual in society can be shaped into a greater one for humanity.I am forever grateful to join this community, absolutely sure, that the best things are  yet to come.',
		image: MariaDeLosAngeles,
		slug: 'maria-de-los-angeles'
	}
];

export default function UserProfile({ match }) {
	const activeSlug = match.params.userId;
	const activeMember = data.find(item => item.slug === activeSlug);

	return (
		<div>
			<section className="section section-testimonials memberDetailWrapper  gradient-light--lean-left pt-5">
				<div className="container responsive-pad-remove">
					<div className="row">
						<div className="col-md-12 text-center ">
							<h2 className="mt-2 mb-5 primary-text-color responseive-head-test mb-md-0 font-bold">
								Testimonial
							</h2>
						</div>
					</div>
					<div className="row mt-3">
						<div className="col">
							<div className=" responsive-pad-remove">
								<div className="">
									{activeMember && (
										<div className=" testimony__card p-3 mb-2">
											<blockquote className="blockquote shadow">
												<footer className="blockquote-footer d-flex align-items-center mb-2">
													<div className="testimony__avatar d-inline-block mb-3">
														<img
															className="rounded-circle"
															src={activeMember.image}
															alt="Avatar"
														/>
													</div>
													<div className="testimony__info">
														<span className="info-name d-block">
															{activeMember.name}
														</span>
														<span className="info-company d-block">
															{activeMember.role}
														</span>
													</div>
												</footer>
												<span className="rating text-warning mb-4">
													<i className="fas fa-star" />
													<i className="fas fa-star" />
													<i className="fas fa-star" />
													<i className="fas fa-star" />
													<i className="fas fa-star" />
												</span>
												<p className="memberMsg mb-4">{activeMember.message}</p>
											</blockquote>
										</div>
									)}
									{data.map(
										(item, index) =>
											item.slug !== activeSlug && (
												<div
													className=" testimony__card p-3 mb-2"
													key={`member-card-${index}`}
												>
													<blockquote className="blockquote shadow">
														<footer className="blockquote-footer d-flex align-items-center mb-2">
															<div className="testimony__avatar d-inline-block mb-3">
																<img
																	className="rounded-circle"
																	src={item.image}
																	alt="Avatar"
																/>
															</div>
															<div className="testimony__info">
																<span className="info-name d-block">
																	{item.name}
																</span>
																<span className="info-company d-block">
																	{item.role}
																</span>
															</div>
														</footer>
														<span className="rating text-warning mb-4">
															<i className="fas fa-star" />
															<i className="fas fa-star" />
															<i className="fas fa-star" />
															<i className="fas fa-star" />
															<i className="fas fa-star" />
														</span>
														<p className="memberMsg mb-4">{item.message}</p>
													</blockquote>
												</div>
											)
									)}
								</div>
							</div>
							{/* End Swiper Container */}
						</div>
					</div>
				</div>
			</section>
			{/* END Section Testimonials */}
		</div>
	);
}
