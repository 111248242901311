import React from "react";
import { Image } from "react-bootstrap";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

export default function MyCampaignCard({ index, item, campaignDetails }) {
  console.log("item", campaignDetails);
  return (
    <div className="col-sm-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
      {index === 0 ? (
        <div className="campaign-card card">
          <div className="campaign-header-div mycampainCard">
            <div>
              <h5 style={{ display: "flex", alignItems: "center" }}>
                <Image
                  className="winner-dp-image trending-quotes-dp img-thumbnail"
                  style={{
                    height: "40px",
                    width: "40px",
                    marginRight: "10px",
                  }}
                  src={item.userId && item?.userId?.profileImg}
                  rounded
                  roundedCircle
                />

                <Link
                  to={
                    "/all-campaigns/user/" +
                    item.userId?.name?.replaceAll(" ", "-")?.replaceAll('?'," ")  +
                    "/" +
                    item.userId?._id
                  }
                >
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {item.userId && item.userId?.name?.toUpperCase()}
                  </span>
                </Link>
              </h5>
            </div>
          </div>
          <div
            className="trendingcard-image-div"
            style={{ padding: "15px 10px" }}
          >
            {item.imageUrl && (
              <Link
                to={
                  "/all-campaigns/" +
                  campaignDetails?.title?.replaceAll(" ", "-")?.replaceAll('?',"")  +"/" +
                  item.userId?.name?.replaceAll(" ", "-")?.replaceAll('?',"")  +
                  
                  "/" +
                  campaignDetails._id +
                  "/" +
                  item.userId?._id
                }
              >
                <img
                  src={item.imageUrl}
                  className="card-img-top"
                  alt="Campaign poster"
                />
              </Link>
            )}
            {item.contentId && (
              <div className="mt-2">{parse(item?.contentId?.body)}</div>
            )}
          </div>
        </div>
      ) : (
        <div className="campaign-card card mt-4">
          <div className="campaign-header-div mycampainCard">
            <div>
              <h5 style={{ display: "flex", alignItems: "center" }}>
                <Image
                  className="winner-dp-image trending-quotes-dp img-thumbnail"
                  style={{
                    height: "35px",
                    width: "35px",
                    marginRight: "10px",
                  }}
                  src={item.userId && item.userId?.profileImg}
                  rounded
                  roundedCircle
                />
                <Link
                  to={
                    "/all-campaigns/user/" +
                    item.userId?.name?.replaceAll(" ", "-")?.replaceAll('?'," ")  +
                    "/" +
                    item.userId?._id
                  }
                >
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {item.userId && item.userId?.name?.toUpperCase()}
                  </span>
                </Link>
              </h5>
            </div>
          </div>
          <div
            className="trendingcard-image-div"
            style={{ padding: "15px 10px" }}
          >
            {item.imageUrl && (
              <Link
                to={
                  "/all-campaigns/" +
                  campaignDetails.title?.replaceAll(" ", "-")?.replaceAll('?'," ")  +
                  "/" +
                  item.userId?.name?.replaceAll(" ", "-")?.replaceAll('?'," ")  +
                  "/" +
                  campaignDetails._id +
                  +"/" +
                  item.userId?._id
                }
              >
                <img
                  src={item.imageUrl}
                  className="card-img-top"
                  alt="Campaign poster"
                />
              </Link>
            )}

            {item.contentId && (
              <div className="mt-2">{parse(item?.contentId?.body)}</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
