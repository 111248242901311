import React, { useState, useEffect } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import { Link, useHistory, useLocation } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Cropper from "react-easy-crop";
import { Modal } from "react-bootstrap";
import { uploadToCloudinary } from "../ImageCropper/canvasUtils";
import { addMember } from "../Api";
export default function CreateTeam() {
  const [croppedAreaPixels, setCroppedAreaPixels] = useState("");
  const [TeamMembers, setTeamMembers] = useState([]);
  const [name, setName] = useState("");
  const [show, setShow] = useState(false);
  let location=useHistory();
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [type, setType] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false)

  const [showCropper, setShowCropper] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [modalHeading, setModalHeading] = useState("");
  const [modalText, setmodalText] = useState("");
  const imageChange = (e) => {
    const url = URL.createObjectURL(e.target.files[0]);
    setImage(url);
    console.log("File is", e.target.files[0], url, image);
    setShowCropper(true);
  };
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels);
    setCroppedAreaPixels(croppedAreaPixels);
  };
  const validation = () => {
    let isValid = true;
    if (!name || !email) {
      isValid = false;
      setError("Fill the Fields");
    } else if (!password) {
      isValid = false;
      setError("Password Field cant be empty");
    } else if (cpassword !== password) {
      isValid = false;
      setError("Password Doesn't match");
    }
    return isValid;
  };
  const createMember = async (e) => {
    e.preventDefault();
    setLoading(true)
    let croppedImage;
    if (validation()) {
      croppedImage = await uploadToCloudinary(image, croppedAreaPixels, image);
      let data = {
        name: name,
        email: email,
        phoneNumber: phoneNumber,
        linkedinUrl: linkedin,
        type: type,
        pwd: password,
        profileImg: croppedImage.url,
     
      };
      console.log(data)
      const res = await addMember(data);

      if (res.error) {
    setLoading(false)

        setModalHeading("Oops");
        setmodalText(res.data);
        // setmodalButton('Okay !')
        setErrorModal(true);
        //setError(res.data)
      } else {
    setLoading(false)
    location.push({
      pathname: '/allmembers/allmembers',
      state: { detail: 'Member Create' }
    })

        console.log("success", res.res.data);
      }
      // console.log(croppedImage);
    } else {
      setModalHeading("Oops");
      setmodalText(error);
      // setmodalButton('Okay !')
      setErrorModal(true);
    }
  };
  return (
    <div className="dashboard-main-container">
      <Modal show={errorModal} onHide={()=>{setErrorModal(false)}}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h1>{modalHeading}</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalText}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{setErrorModal(false)}}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
<Link to='/admin-quote' id='member-page-link' className='d-none'></Link>
      <h3 className="last-campaign assign-text text-left">Add Member</h3>

      <div className="dashboard-container admin-campaign">
        <input
          className="add-member-input"
          type="text"
          placeholder="Name"
          name="name"
          onChange={(e) => {
            setError("");
            setName(e.target.value);
          }}
        />
        <input
          className="add-member-input"
          type="text"
          placeholder="Email"
          name="Email"
          onChange={(e) => {
            setError("");
            setEmail(e.target.value);
          }}
          // onBlur={() => console.log(teamTitle)}
        />{" "}
        <input
          className="add-member-input"
          type="text"
          placeholder="Password"
          name="password"
          onChange={(e) => {
            setError("");
            setPassword(e.target.value);
          }}
          // onBlur={() => console.log(teamTitle)}
        />{" "}
        <input
          className="add-member-input"
          type="text"
          placeholder="Confirm Password"
          name="CPassword"
          onChange={(e) => {
            setError("");
            setCpassword(e.target.value);
          }}
          //  onBlur={() => console.log(teamTitle)}
        />{" "}
        <input
          className="add-member-input"
          type="text"
          placeholder="Linkedin Url"
          name="Linkedin"
          onChange={(e) => {
            setError("");
            setLinkedin(e.target.value);
          }}
          //  onBlur={() => console.log(teamTitle)}
        />{" "}
        <select
          onChange={(e) => {
            setType(e.target.value);
            console.log(e.target.value)
            setError("");
          }}
          name="datatable-buttons_length"
          aria-controls="datatable-buttons"
          className="custom-select choosing-option bg-black"
          placeholder="Type"
        >
          <option value=""> Choose Type</option>

          <option value="user"> User</option>
          <option value="admin">Admin</option>
        </select>
        <input
          className="add-member-input"
          type="text"
          placeholder="Whatsaap"
          name="whatsapp"
          onChange={(e) => setPhoneNumber(e.target.value)}
          //  onBlur={() => console.log(teamTitle)}
        />{" "}
        <input
          className="d-none add-member-input"
          type="file"
          multiple={false}
          name="Profile"
          id="profileImage"
          onChange={imageChange}
          // onBlur={() => console.log(teamTitle)}
        />
        <Button
          variant="contained "
          className="create-team-btn ml-4 br-20"
          onClick={() => document.getElementById("profileImage").click()}
        >
          Choose Profile{" "}
        </Button>
        <br />
        <div className="create-team-btns">
          <Link to="/member">
            {loading? <Button
            variant="contained"
            className="create-team-btn"
         
            >
            Loading....
            </Button>:
            <Button
            variant="contained"
            className="create-team-btn"
            onClick={createMember}
            >
              Create Member
            </Button>
            }
          </Link>

          <Link to="/teams">
            <Button
              variant="contained"
              className="create-team-btn create-team-back-btn"
            >
              Back
            </Button>
          </Link>
        </div>
      </div>

      <Modal show={showCropper} onHide={handleClose}>
        <Modal.Body>
          <div
            id="crop-section"
            style={{
              width: "100vw",
              top: "0",
              bottom: "0",
              left: "0",
              right: "0",
              height: "50vh",
            }}
          >
            <Cropper
              style={{ zIndex: 100, backgroundColor: "black" }}
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={780 / 780}
              onCropChange={(crop) => {
                setCrop(crop);
              }}
              onCropComplete={onCropComplete}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <input
            type="range"
            value={zoom}
            min="1"
            max="3"
            step="0.1"
            onChange={(e) => {
              setZoom(e.target.value);
            }}
            className="custom-range"
            id="customRange1"
          />
          <br />
          <Button
            className="bg-primary"
            onClick={() => {
              // setImgSrc(imgSrcArrayVar)
              setShowCropper(false);
              handleClose(false);
            }}
          >
            Crop
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
