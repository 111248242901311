import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import TagList from '../TagList/campaignList';

import CampaignContentCard from './CampaignContentCard';
import {
	getCampaignsContentById,
	getCampaignsList,
	getUserCampaigns,
	getUserCampaignsWithoutAuth,
	getUserDetails
} from '../Api';

import CustomLoader from '../CustomLoader';
import './winner.scss';
import { useParams } from 'react-router-dom';
import CampaignContantUser from './CampaignContentCardUser';
import Footer from '../Navbar/Footer';

export default function CampaignsUserWise() {
	const [campaignData, setcampaignData] = useState([]);
	const [campaignDetails, setCampaignDetails] = useState({});
	const [campaignDataList, setcampaignDataList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [userData,setUserData]=useState({});
  let location=useParams()
	useEffect(() => {
		console.log(location.id)
		setLoading(true);
		campaignContentByID(location.id);
	
	}, [location]);
useEffect(() => {
	getCampaigns();
}, [location]);
	const getCampaigns = async () => {
	//	setLoading(true);
	//	campaignContentByID(location._id);
		const res = await getCampaignsList();
		if (!res.error && res.res) {
			if (res.res.data && res.res.data.campaigns) {
				if (res.res.data.campaigns.length > 0) {
					const campaign_name_list = res.res.data.campaigns.reverse();
					setcampaignDataList(campaign_name_list);
					console.log('campaign_name_list', location);
				
				}
			}
		}
	//	setLoading(false);
		return;
	};

	const campaignContentByID = async id => {
	//	setLoading(true);
		const res = await getUserCampaignsWithoutAuth(id);
		const userDetails= await getUserDetails(id)
	//	console.log('userDetails', userDetails)
		setUserData(userDetails.res?.data?.user)
		console.log('userData', userData)
		if (!res.error && res.res) {

			if (res.res.data && res.res.data?.campaigns) {
	//			console.log('res.res.data', res.res.data);
			
					let campData=[]
					
					res.res.data.campaigns.map(item=> {
						if(item.participants[0]?.contentId?.published){
						var newData={...item,contentId:item.participants[0]?.contentId,imageUrl:item.participants[0]?.imageUrl}
						campData.push(newData)
						}
					})
					setcampaignData(
						campData
					);
	//				console.log('campaign_name_list', campData);
			}
		}
		setLoading(false);
	};

	return (
		<>
			<Helmet>
				<title>Campaign</title>
			</Helmet>
			<div className="myCompainContainer">
				<br className="lineBreaker" />
			{loading&& <div style={{minHeight:'100vh'}}>	<CustomLoader loading={loading} />
			</div>}
{!loading &&
				<div className="container containerWrapper mt-2">
					<div className="row mb-3">
						<div className="col-lg-9  col-sm-9 " style={{ marginLeft: '0' }}>
							{campaignData &&
								campaignData.map((item, index) => {
									return (
										<CampaignContantUser
											getMyCampaigns={getCampaigns}
											key={index}
											item={item}
											index={index}
											userData={userData}
										//	campaignDetails={campaignDetails}
										/>
									);
								})}
						</div>

						<TagList
							campainList={true}
							tagData={campaignDataList}
							setTags={campaignContentByID}
							
						/>
					</div>
				</div>
}</div>
<Footer/>
		</>
	);
}
