import React, { useState, useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Link, useLocation } from 'react-router-dom';
import Moment from 'react-moment';
import { deleteQuote, getAllQuotesAdmin, toggleQuoteAdmin } from '../Api';
import { Modal, Button } from 'react-bootstrap';
import Pagination from '../Pagination/index';

export default function AdminQuote() {
	const [allCampaigns, setallCampaigns] = useState(null);
	const [show, setShow] = useState(false);
	const [Error, setError] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [showSuccess, setshowSuccess] = useState(false);
	const location = useLocation();

	const [quotesFilterData, setquotesFilterData] = useState(null);
	const [entry, setEntry] = useState(10);
	const [entryToShow, setentryToShow] = useState(null);
	const [page, setPage] = useState(0);
	const [totalpage, setTotalPage] = useState(0);

	const [modalHeading, setmodalHeading] = useState('');
	const [modalText, setmodalText] = useState('');
	const [modalButton, setmodalButton] = useState('');
	const [loading, setloading] = useState(false);
	const [searchTerm, setsearchTerm] = useState('');

	useEffect(() => {
		console.log(location, '89');
		if (location.state) {
			setshowSuccess(true);
		}
		setInterval(() => {
			setshowSuccess(false);
		}, 3000);
	}, [location.pathname]);
	useEffect(() => {
		getCamapigns();
		return () => {};
	}, [1]);

	const getCamapigns = async () => {
		const res = await getAllQuotesAdmin();
		console.log('hello');
		console.log(res);
		if (res.error) {
			setmodalHeading('Oops !');
			setmodalText(`${res.data}`);
			setmodalButton('Try Again');
			setShow(true);
			return setError(true);
		} else {
			console.log('Res is', res.data.quotes);
			setallCampaigns(res.data.quotes);
		}
	};

	const toggleCampaign = async id => {
		const res = await toggleQuoteAdmin(id);
		if (res.error) {
			setmodalHeading('Oops !');
			setmodalText(`${res}`);
			setmodalButton('Try Again');
			setShow(true);
			return setError(true);
		} else {
			console.log('Res is', res.data.quotes);
			getCamapigns();
			setallCampaigns(res.data.quotes);
		}
	};

	useEffect(() => {
		if (allCampaigns && allCampaigns.length > 0) {
			setTotalPage(Math.ceil(allCampaigns.length / entry));
			let array = allCampaigns.slice(page * entry, (page + 1) * entry);
			setquotesFilterData(array);
		}
	}, [entry, page, allCampaigns]);

	return (
		<div className="dashboard-main-container">
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>{modalHeading}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{modalText}</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>

					{!Error && (
						<a href="/campaigns">
							<Button variant="primary">{modalButton}</Button>
						</a>
					)}

					{Error && (
						<a>
							<Button variant="primary" onClick={handleClose}>
								{modalButton}
							</Button>
						</a>
					)}
				</Modal.Footer>
			</Modal>

			<div className="dashboard-container admin-campaign">
				<div className="container">
					<div className="row text-align-center">
						<div className="col-lg-6">
							<h3 className="last-campaign admin-last-campaign text-left">
								~~ Quote List ~~
							</h3>
						</div>
					</div>
				</div>

				<br />
				{showSuccess ? (
					<div className="text-center">
						<h3
							className=" text-light p-1 m-5 bg-success"
							style={{ textTransform: 'capitalize' }}
						>
							Quote Added Succesfuly
						</h3>
					</div>
				) : null}
				<div className="row">
					<div className="col-5" style={{ alignItems: 'center' }}>
						<label className="teams-searchbar">
							Search:
							<input
								type="search"
								className="form-control form-control-sm"
								placeholder=""
								aria-controls="datatable-buttons"
								onChange={e => setsearchTerm(e.target.value)}
							/>
						</label>
					</div>
					<div className="col-lg-4"></div>
					<div className="col-lg-3">
						<h3 className="last-campaign admin-last-campaign text-right">
							<Link to="/createquote">
								<button className="btn btn-info">Create Quote</button>
							</Link>
						</h3>
					</div>
				</div>
				<div className="row text-align-center">
					<div className="col-lg-4" style={{ marginTop: '10px' }}>
						<h3 className="last-campaign admin-last-campaign text-right">
							<Link to="/create-upcoming-campaign">
								<button
									className="btn btn-small btn-outline-info pad-10"
									style={{ width: '100%' }}
								>
									Upcoming Campaign
								</button>
							</Link>
						</h3>
					</div>{' '}
					<div className="col-lg-4" style={{ marginTop: '10px' }}>
						<h3 className="last-campaign admin-last-campaign text-right">
							<Link to="/create-author">
								<button
									className="btn btn-outline-info"
									style={{ width: '100%' }}
								>
									Manage Author
								</button>
							</Link>
						</h3>
					</div>
					<div className="col-lg-4" style={{ marginTop: '10px' }}>
						<h3 className="last-campaign admin-last-campaign text-right">
							<Link to="/suggestedquote">
								<button
									className="btn btn-small pad-10 btn-outline-info"
									style={{ width: '100%' }}
								>
									Suggested Quotes
								</button>
							</Link>
						</h3>
					</div>
				</div>

				<table className="table table-bordered">
					<thead>
						<tr>
							<th scope="col" className="font-weight-bold">
								Sr.
							</th>
							<th scope="col" className="font-weight-bold">
								Quote
							</th>
							<th scope="col" className="font-weight-bold">
								Started On
							</th>
							<th scope="col" className="font-weight-bold">
								Initiated By
							</th>

							<th scope="col" className="font-weight-bold">
								Artwork
							</th>
							<th scope="" className="font-weight-bold">
								Participants
							</th>
							<th scope="col" className="font-weight-bold">
								Actions
							</th>
						</tr>
					</thead>
					<tbody>
						{quotesFilterData &&
							quotesFilterData
								.filter((item, index) => {
									if (
										item.desc
											.toLocaleLowerCase()
											.includes(searchTerm.toLocaleLowerCase())
									)
										return item;
								})
								.map((item, index) => {
									return (
										<tr key={index}>
											<th scope="row">{index + 1}</th>
											<td>
												<Link
													style={{ fontSize: '12px' }}
													//  to={`/quote-detail/${item._id}`}
												>
													{item.desc}
												</Link>
											</td>
											<td>
												<Moment format="DD/MM/YYYY">{item.startsOn}</Moment>
											</td>
											{/* {console.log(item)} */}
											<td>
												{item.author ? (
													<Link to={'/quote-by-author/' + item.author.name}>
														{item.author.name}
													</Link>
												) : item.madeBy ? (
													<Link to={'/quote-by-author/' + item.madeBy.name}>
														{item.madeBy.name}
													</Link>
												) : (
													'Not Found'
												)}
											</td>
											<td>
												<img
													style={{ maxWidth: '70px' }}
													src={item.artWork.imgUrl}
												/>
											</td>
											<td>{item.noOfParticipants} </td>

											<td style={{ alignItems: 'center' }}>
												<div className="d-flex">
													<Link to={`/admin-quote/edit/${item._id}`}>
														<EditIcon />
													</Link>
													&emsp;
													<DeleteIcon
														onClick={async () => {
															await deleteQuote(item._id);
															getCamapigns();
														}}
													/>
													&emsp;
													{item.active ? (
														<Button
															variant="contained"
															className="text-light w-50 p-0 bg-success"
															onClick={() => toggleCampaign(item._id)}
														>
															Open{' '}
														</Button>
													) : (
														<Button
															variant="contained"
															className="text-light w-50 p-0 bg-danger"
															onClick={() => toggleCampaign(item._id)}
														>
															{' '}
															Close
														</Button>
													)}
												</div>
											</td>
										</tr>
									);
								})}
					</tbody>
				</table>
				{allCampaigns && allCampaigns.length > 0 && (
					<Pagination
						page={page}
						entryToShow={10}
						setPage={setPage}
						totalpage={totalpage}
						totalData={allCampaigns.length}
					/>
				)}
			</div>
		</div>
	);
}
