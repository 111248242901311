import React, { useState, useEffect } from 'react';

import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { Modal, Button, Image } from 'react-bootstrap';
import {
	participateInQuotes,
	setContentCreator,
	updateUserProfileImage
} from '../Api';
import fileDownload from 'js-file-download';
import { FaBookmark, FaUser } from 'react-icons/fa';
import { uploadToCloudinary } from '../ImageCropper/canvasUtils';
import Cropper from 'react-easy-crop';

import user_pic from '../../images/user.png';

export default function CampaignCard({
	item,
	setTags,
	changeActiveTab,
	setCategory,
	setFilterByName
}) {
	const history = useHistory();

	const [ModifieProfilePic, setModifieProfilePic] = useState(null);
	const [disabled, setDisabled] = useState(false);
	const [disabledClaim, setDisabledClaim] = useState(false);
	const handleCloseProfile = () => setShowModal(false);
	const handleShowProfile = () => setShowModal(true);
	const [profileImgExist, setProfileImgExist] = useState(false);
	const [regularCreator, setRegularCreator] = useState(false);
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [showModal, setShowModal] = useState(false);
	const [userImageUrl, setuserImageUrl] = useState(null);
	const [userImage, setuserImage] = useState(null);
	const [showCropper, setShowCropper] = useState(false);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState('');
	const user = JSON.parse(localStorage.getItem('InspirerWorld'));
	const imageChange = async image => {
		setuserImage(image);
		// console.log('File is', image);
		//  const url = URL.createObjectURL(image);
		const url = URL.createObjectURL(image);
		setuserImageUrl(url);
		setShowCropper(true);
		//setShowCropper(true);
	};

	const onCropComplete = (croppedArea, croppedAreaPixels) => {
		// console.log(croppedArea, croppedAreaPixels);
		setCroppedAreaPixels(croppedAreaPixels);
	};
	const imageUploader = async () => {
		const res = await uploadToCloudinary(
			userImageUrl,
			croppedAreaPixels,
			userImageUrl
		);

		let data = { profileImg: res.url };
		// console.log(res);
		let resBackend = await updateUserProfileImage(data);
		let resContent = await setContentCreator({
			isContentCreator: regularCreator
		});
		if (!resBackend.error) {
			const done = JSON.parse(localStorage.getItem('InspirerWorld'));
			// console.log(res);
			let newdata = {
				token: done.token,
				isLeader: done.isLeader,
				userId: done.userId,
				teamId: done.teamId,
				type: done.type,
				profileImg: res.url,
				name: done.name,
				expiry: done?.expiry
			};
			console.log(newdata.profileImg, 'succes', resContent);

			//   localStorage.removeItem('InspirerWorld')
			await localStorage.setItem('InspirerWorld', JSON.stringify(newdata));
			// console.log("success")
			setProfileImgExist(true);
			handleCloseProfile();
			window.location.reload();
		} else {
			console.log(resBackend);
		}
	};

	const finalFuncToDownloadProfilePic = async id => {
		// console.log('hello i am running');
		if (profileImgExist) {
			setDisabledClaim(true);
			const res2 = await participateInQuotes(id);
			let url = res2.data.userArtWork;
			let filename = `${item.title}.jpeg`;
			axios
				.get(url, {
					responseType: 'blob'
				})
				.then(res => {
					fileDownload(res.data, filename);
				});
			setDisabledClaim(false);
		} else {
			handleShowProfile();
		}
	};

	return (
		<div
			className="col-lg-12"
			style={{
				marginBottom: '0px',
				marginLeft: 'auto',
				marginRight: 'auto',
				padding: 0
			}}
		>
			<Modal show={showCropper}>
				<Modal.Header closeButton={() => setShowCropper(false)}>
					<Modal.Title>Upload Image</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div
						id="crop-section"
						style={{
							width: '100vw',
							top: '0',
							bottom: '0',
							left: '0',
							right: '0',
							height: '50vh'
						}}
					>
						<Cropper
							style={{ zIndex: 100, backgroundColor: 'black' }}
							image={userImageUrl}
							crop={crop}
							zoom={zoom}
							aspect={780 / 780}
							onCropChange={crop => {
								setCrop(crop);
							}}
							onCropComplete={onCropComplete}
						/>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<input
						type="range"
						value={zoom}
						min="1"
						max="3"
						step="0.1"
						onChange={e => {
							setZoom(e.target.value);
						}}
						className="custom-range"
						id="customRange1"
					/>
					<br />
					<Button onClick={() => setShowCropper(false)}>Crop</Button>
				</Modal.Footer>
			</Modal>

			<Modal show={showModal} onHide={handleCloseProfile}>
				<Modal.Body>
					<div className="container row m-auto">
						<div className="row">
							<div className="col-12 p-2 text-center mb-5 m-auto">
								<h4>Thank for showing interest in Inspire World </h4>
								<span>Please let just know about you </span>
							</div>

							<div className="col-8 text-center ml-auto mr-auto mt-10 ">
								<h5 className="mt-4">Update your Profile Picture</h5>
								<input
									type="file"
									onChange={e => {
										imageChange(e.target.files[0]);
									}}
									id="chooseImage"
									className="d-none"
								/>
								<button
									className="upload-half-buttons mt-3  m-auto"
									onClick={() => document.getElementById('chooseImage').click()}
								>
									Click here to upload your picture
								</button>
							</div>
							<div className="col-8  ml-auto mr-auto mt-10 text-center ">
								<h5 className="mt-4">Are You a regular content Creator?</h5>

								<button
									id="CreatorTrue"
									onClick={() => {
										document.getElementById('CreatorTrue').style.background =
											'green';
										document.getElementById('CreatorFalse').style.background =
											'#146d9c';
										setRegularCreator(true);
									}}
									className="upload-half-buttons rounded   m-auto"
								>
									yes
								</button>
								{'                     '}

								<button
									id="CreatorFalse"
									onClick={e => {
										document.getElementById('CreatorTrue').style.background =
											'#146d9c';
										document.getElementById('CreatorFalse').style.background =
											'green';

										setRegularCreator(false);
									}}
									className="upload-half-buttons rounded   m-auto"
								>
									No
								</button>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseProfile}>
						Close
					</Button>
					<Button
						variant="primary"
						id="uploadbtn"
						onClick={() => {
							document.getElementById('uploadbtn').style.background = 'green';
							imageUploader();
						}}
					></Button>
				</Modal.Footer>
			</Modal>

			<div
				className="profile-short-div radius-t-5"
				style={{ background: '#F4F4F4' }}
			>
				<div className="profile-topbar-container">
					<div
						className=""
						style={{
							flexBasis: '50%',
							display: 'flex',
							alignItems: 'center'
						}}
					>
						{item.author ? (
							<Image
								className="winner-dp-image trending-quotes-dp"
								src={item.author.imageUrl}
								rounded
								roundedCircle
								thumbnail
								fluid
							/>
						) : (
							<Image
								className="winner-dp-image trending-quotes-dp"
								src={item.madeBy.profileImg}
								rounded
								roundedCircle
								thumbnail
								fluid
							/>
						)}
						{item.author ? (
							<Link to={'/allquotes/' + item.author.name.replace(' ', '_')}>
								{' '}
								<strong
									style={{ cursor: 'pointer', fontWeight: 'bold' }}
									className="profile-topbar-name"
								>
									{item.author.name}
								</strong>
							</Link>
						) : (
							<Link to={'/allquotes/' + item.madeBy.name}>
								{' '}
								<strong
									style={{ cursor: 'pointer' }}
									className="profile-topbar-name"
								>
									{item.madeBy.name.replace(' ', '_')}
								</strong>
							</Link>
						)}{' '}
					</div>
					{/* <div className="col-lg-1 col-md-1"></div> */}
					<div
						className="text-right d-flex"
						style={{
							flexBasis: '50%',
							justifyContent: 'flex-end'
						}}
					></div>
				</div>
			</div>

			<div className="new-quote-cards-container radius-b-5">
				<div className="new-quote-cards-left">
					<div className="quote-container-div">
						<Link to={'/allquote/quote/' + item._id}>
							<p className="card-text quote-card-quote-text">{item.desc}</p>
						</Link>
					</div>

					<br />

					<div className="save-div">
						<div className="additional-info">
							<div className="individual-info">
								<span className="tagMobile">Tags: </span>
								<div className="info-heading tag-btn radius-5">
									<span className="tag">Tags</span>
								</div>
								<div className="info-value">
									{item.tags.map(i => {
										return (
											<span
												style={{ cursor: 'pointer' }}
												onClick={() => setTags(i)}
											>
												#{i}
											</span>
										);
									})}
								</div>
							</div>
						</div>
						<div
							className="individual-info pr-0 viewImageDisktop"
							style={{ width: '100%' }}
						>
							<div
								style={{ marginLeft: 'auto' }}
								className="info-heading mr-0 radius-5 cursor-pointer"
								onClick={() => {
									history.push('/allquote/quote/' + item._id);
								}}
							>
								<span>View Image</span>
							</div>
						</div>
					</div>
				</div>

				<div className="trending-quotes-card-button-container">
					<div
						className="individual-info pr-0 viewImageMobile"
						style={{ width: '100%' }}
					>
						<div
							style={{ marginLeft: 'auto' }}
							className="info-heading mr-0 radius-5 cursor-pointer"
							onClick={() => {
								history.push('/allquote/quote/' + item._id);
							}}
						>
							<span>View Image</span>
						</div>
					</div>
					{disabledClaim ? (
						<div className="new-quote-cards-right">
							<span className="cmapign-download">Claiming...</span>
						</div>
					) : (
						<button
							className="trending-quotes-claim-button"
							// onClick={() => finalFuncToDownloadProfilePic(item._id)}
						>
							{' '}
							<Link to={{ pathname: '/login', state: 'quotes' }}>
								<p className="block-claim-text">
									<img src={user_pic} className="user-pic-icon" />
									Sign-in to Claim
								</p>
							</Link>
						</button>
					)}
				</div>
			</div>

			<br />
		</div>
	);
}
