import React, { useState, useEffect } from 'react';
import Trendup from '../../images/trendup.svg';
import { Helmet } from 'react-helmet-async';

import MyQuotesCard from './MyQuotesCard';
import { getMyQuotes } from '../Api';
import CustomLoader from '../CustomLoader';
import ProfileStatsBar from '../Profile/ProfileStatsBar';
import { Link } from 'react-router-dom';
import SidebarTrending from '../SidebarComponent/SidebarTrending';
export default function Myquotes() {
	const [quoteData, setquoteData] = useState(null);
	const [loading, setLoading] = useState(false);

	const [selectedCategory, setselectedCategory] = useState('All Category');

	useEffect(() => {
		getQuotes();
		return () => {};
	}, [1]);

	const getQuotes = async () => {
		setLoading(true);
		const res = await getMyQuotes();
		if (res.res) {
			console.log('res is ', res.res.data.quotes);
			setquoteData(res.res.data.quotes);
		}
		return setLoading(false);
	};

	return (
		<>
			<Helmet>
				<title>My Downloaded Quotes</title>
			</Helmet>
			<div>
				<div>
					{quoteData && (
						<ProfileStatsBar topCampaign={quoteData[0]} type="quote" />
					)}

					<br />

					<div className="container mt-2">
						<div className="row ">
							<div className="col-lg-9 row col-md-12">
								{quoteData &&
									quoteData.map((item, index) => {
										return (
											<MyQuotesCard
												key={index}
												item={item}
												getQuotes={getQuotes}
											/>
										);
									})}

								{!quoteData && (
									<center>
										<h5>Loading.......</h5>
									</center>
								)}

								{quoteData && quoteData.length < 1 && (
									<div>
										<center>
											<img
												style={{ width: '50%' }}
												src="https://cdn.dribbble.com/users/2071065/screenshots/6559618/dribble_4-19_4x.png?compress=1&resize=800x600"
											/>
											<h4>Oops ! Nothing Found here...</h4>
										</center>
									</div>
								)}
							</div>

							<SidebarTrending />
						</div>
					</div>
				</div>

				<br />

				{/* <center><h2 className="section__title mb-2">My quotes
        <img style={{marginLeft : '10px'}} src={Trendup}/></h2></center> 

 */}

				<CustomLoader loading={loading} />
			</div>
		</>
	);
}
