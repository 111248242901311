import React, { useState, useEffect } from 'react';
import ProfileInput from './ProfileInput';
import { Modal, Button } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { Edit } from '@material-ui/icons';
import { uploadToCloudinary } from '../ImageCropper/canvasUtils';
import Cropper from 'react-easy-crop';
import { updateUserProfileAdminSide } from '../Api';
import Resizer from 'react-image-file-resizer';
import { Avatar, Checkbox, FormControlLabel } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
export default function ProfileDisplay({ profile }) {
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [loading, setLoading] = useState(false);

	const [croppedAreaPixels, setCroppedAreaPixels] = useState('');
	const [croppedImage, setCroppedImage] = useState('');
	const [croppedAreaPixelsArray, setCroppedAreaPixelsArray] = useState([]);
	const [contentCreator, setContentCreator] = useState(
		profile.isContentCreator
	);
	const [showModal, setshowModal] = useState(false);
	const [modalTitle, setmodalTitle] = useState('');
	const [modalMsg, setmodalMsg] = useState('');
	const [Error, setError] = useState(false);
	const [modalHeading, setmodalHeading] = useState('');
	const [modalText, setmodalText] = useState('');
	const [modalButton, setmodalButton] = useState('');
	// const [loading, setloading] = useState(false);
	const [show, setShow] = useState(false);
	const handleClose = () => {
		setShow(false);
		setshowModal(false);
	};
	const handleShow = () => setShow(true);
	const [userImage, setuserImage] = useState(null);
	const [userImageUrl, setuserImageUrl] = useState(profile.profileImg);
	const [imgSrcArray, setImgSrcArray] = useState([]);
	const [email, setemail] = useState(profile.email);
	const [pwd2, setpwd] = useState(null);
	const [name, setName] = useState(profile.name);
	const [pwd, setPWD] = useState('');
	const [whatsapp, setwhatsapp] = useState(profile.phoneNumber);
	const [linkedinUrl, setlinkedinUrl] = useState(profile.linkedinUrl);
	let newDate = new Date(profile.dob);
	let location = useHistory();
	const [date, setDate] = useState(newDate);
	const [defaultDate, setDefaultDate] = useState(newDate);
	console.log(newDate, date);
	const [instagramUrl, setinstagramUrl] = useState(
		profile.socialAccounts.instagramUrl
	);
	const [showCropper, setShowCropper] = useState(false);
	const onCropComplete = (croppedArea, croppedAreaPixels) => {
		console.log(croppedArea, croppedAreaPixels);
		setCroppedAreaPixels(croppedAreaPixels);
	};
	Date.prototype.yyyymmdd = function () {
		var mm = this.getMonth() + 1; // getMonth() is zero-based
		var dd = this.getDate();

		return [
			this.getFullYear(),
			(mm > 9 ? '' : '0') + mm,
			(dd > 9 ? '' : '0') + dd
		].join('-');
	};
	console.log(profile);
	const imageChange = image => {
		setuserImage(image);
		console.log('File is', image);
		const url = URL.createObjectURL(image);
		setuserImageUrl(url);
		setShowCropper(true);
	};

	// console.log(profile.phoneNumber);

	const submitHandler = async () => {
		let croppedImage;
		setLoading(true);
		if (croppedAreaPixels) {
			croppedImage = await uploadToCloudinary(
				userImageUrl,
				croppedAreaPixels,
				userImageUrl
			);
		} else {
			croppedImage = { url: userImageUrl };
		}
		console.log(date);
		let data;
		if (pwd.trim().length < 1) {
			data = {
				name: name,
				profileImg: croppedImage.url,
				phoneNumber: whatsapp,
				linkedinUrl: linkedinUrl,
				instagramUrl: instagramUrl,
				email: email,
				dob: date,
				isContentCreator: contentCreator
			};
		} else {
			data = {
				name: name,
				profileImg: croppedImage.url,
				phoneNumber: whatsapp,
				linkedinUrl: linkedinUrl,
				instagramUrl: instagramUrl,
				email: email,
				dob: date,
				isContentCreator: contentCreator,
				pwd: pwd
			};
		}

		console.log(data);

		let res = await updateUserProfileAdminSide(data, profile._id);
		if (res.error) {
			console.log(res);
			alert('Something went wrong');
			setLoading(false);
		} else {
			console.log(res);
			setLoading(false);
			location.push({
				pathname: '/allmembers/allmembers',
				state: { detail: 'Updated Profile' }
			});
		}
		// window.location.reload();
	};
	// alert(JSON.stringify(profile._id))
	return (
		<div className="profile-display">
			<Modal show={showCropper} onHide={handleClose}>
				<Modal.Header closeButton={() => setShowCropper(false)}>
					<Modal.Title>{modalTitle}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div
						id="crop-section"
						style={{
							width: '100vw',
							top: '0',
							bottom: '0',
							left: '0',
							right: '0',
							height: '50vh'
						}}
					>
						<Cropper
							style={{ zIndex: 100, backgroundColor: 'black' }}
							image={userImageUrl}
							crop={crop}
							zoom={zoom}
							aspect={780 / 780}
							onCropChange={crop => {
								setCrop(crop);
							}}
							onCropComplete={onCropComplete}
						/>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<input
						type="range"
						value={zoom}
						min="1"
						max="3"
						step="0.1"
						onChange={e => {
							setZoom(e.target.value);
						}}
						className="custom-range"
						id="customRange1"
					/>
					<br />
					<Button
						onClick={() => {
							// setImgSrc(imgSrcArrayVar)
							setShowCropper(false);
							handleClose(false);
							setCroppedAreaPixelsArray(croppedAreaPixels);
							console.log(croppedAreaPixelsArray);
						}}
					>
						Crop
					</Button>
				</Modal.Footer>
			</Modal>

			<div className="row m-auto ">
				<div className="col-md-2"></div>
				<div className="user-img col-md-4  ml-5 col-12">
					<div className="dp ">
						<img src={userImageUrl} alt="user-img" />
					</div>
					<Edit
						style={{
							marginLeft: '35%',
							marginTop: '-10%',
							fontSize: '33px',
							borderRadius: '31px',
							zIndex: 1,
							backgroundColor: 'azure'
						}}
						onClick={() => {
							document.getElementById('myfileUpload').click();
						}}
					/>
					<input
						type="file"
						style={{ display: 'none' }}
						id="myfileUpload"
						name="myfile"
						onChange={e => {
							imageChange(e.target.files[0]);
						}}
					/>
				</div>
				<div className="stats col-md-3 col-12">
					<div className="stat  m-4">
						<img src="images/views.svg" alt="views" />
						<h6>{profile.views}</h6>
					</div>
					<div className="stat  m-4">
						<img src="images/comments.svg" alt="views" />
						<h6>{profile.comments}</h6>
					</div>
					<div className="stat  m-4">
						<img src="images/likes.svg" alt="views" />
						<h6>{profile.likes}</h6>
					</div>
				</div>
			</div>

			<div className="profile m-auto profile-edit p-2">
				<div className="col-2">
					<Avatar alt="Remy Sharp" src={userImageUrl} />
				</div>
				<div className="col-10 ml-2">
					<input
						className="profile-data-input"
						style={{ border: 'none', backgroundColor: 'transparent' }}
						value={name}
						onChange={e => setName(e.target.value)}
					/>
				</div>
			</div>
			<div className="profile m-auto profile-edit p-2">
				<div className="col-2">
					<Avatar alt="Remy Sharp" src={userImageUrl} />
				</div>
				<div className="col-10 ml-2">
					<input
						className="profile-data-input"
						style={{ border: 'none', backgroundColor: 'transparent' }}
						value={pwd}
						onChange={e => setpwd(e.target.value)}
						placeHolder="pwd"
					/>
				</div>
			</div>

			<div className="profile m-auto profile-edit p-2">
				<div className="col-2">
					<Avatar alt="Remy Sharp" src={userImageUrl} />
				</div>
				<div className="col-10 ml-2">
					<input
						className="profile-data-input"
						style={{ border: 'none', backgroundColor: 'transparent' }}
						value={email}
						onChange={e => setemail(e.target.value)}
					/>
				</div>
			</div>
			<div className="profile m-auto profile-edit p-2">
				<div className="col-2">
					<Avatar alt="Remy Sharp" src={userImageUrl} />
				</div>
				<div className="col-10 ml-2">
					<input
						className="profile-data-input"
						style={{ border: 'none', backgroundColor: 'transparent' }}
						defaultValue={profile.phoneNumber}
						onChange={e => setwhatsapp(e.target.value)}
					/>
				</div>
			</div>
			<div className="profile m-auto profile-edit p-2">
				<div className="col-2">
					<Avatar alt="Remy Sharp" src={userImageUrl} />
				</div>
				<div className="col-10 ml-2">
					<input
						className="profile-data-input"
						placeholder="Linkedin"
						style={{ border: 'none', backgroundColor: 'transparent' }}
						value={linkedinUrl}
						onChange={e => setlinkedinUrl(e.target.value)}
					/>
				</div>
			</div>

			<div className="profile m-auto profile-edit p-2 mt-5">
				<div className="col-2">
					<Avatar alt="Remy Sharp" src={userImageUrl} />
				</div>
				<div className="col-10 ml-2">
					<input
						placeholder="instagram Url"
						className="profile-data-input"
						style={{ border: 'none', backgroundColor: 'transparent' }}
						value={instagramUrl}
						onChange={e => setinstagramUrl(e.target.value)}
					/>
				</div>
			</div>

			<div className="profile m-auto profile-edit p-2 mt-5">
				<div className="col-2">
					<Avatar alt="Remy Sharp" src={userImageUrl} />
				</div>
				<div className="col-10 ml-2 d-flex">
					<input
						placeholder="day"
						className="profile-data-input"
						style={{ border: 'none', backgroundColor: 'transparent' }}
						type="date"
						defaultValue={`${defaultDate.yyyymmdd()}`}
						onChange={e => setDate(e.target.value)}
					/>
				</div>
			</div>
			<div className="profile m-auto profile-edit p-2 mt-5">
				<span style={{ color: 'red', fontSize: 10, fontWeight: 'bold' }}>
					NOTE: Please leave password blank, If you don't wish to modify it.
				</span>
				<div className="col-10 ml-2 d-flex">
					<input
						placeholder="Password"
						className="profile-data-input"
						style={{ border: 'none', backgroundColor: 'transparent' }}
						value={pwd}
						onChange={e => setPWD(e.target.value)}
					/>
				</div>
			</div>
			<div className="profile m-auto profile-edit p-2 mt-5">
				<div className="col-2">
					<Avatar alt="Remy Sharp" src={userImageUrl} />
				</div>
				<div className="col-10 ml-2 d-flex">
					<FormControlLabel
						control={
							<Checkbox
								checked={contentCreator}
								onChange={() => setContentCreator(!contentCreator)}
								name="checkedA"
							/>
						}
						label="Regular Content Creator"
					/>
				</div>
			</div>

			<div className="mt-3 text-center ">
				{loading ? (
					<button className="any-button">Loading...</button>
				) : (
					<button className="any-button" onClick={submitHandler}>
						Save Changes
					</button>
				)}
			</div>
		</div>
	);
}
