import React from 'react';
import { Helmet } from 'react-helmet-async';
import parse from 'html-react-parser';
import './index.scss';

import servicesImage1 from '../../images/servicesImage1.png';
import servicesImage2 from '../../images/servicesImage2.png';
import servicesImage3 from '../../images/servicesImage3.png';
import servicesImage4 from '../../images/servicesImage4.png';
import servicesImage5 from '../../images/servicesImage5.png';
import servicesImage6 from '../../images/servicesImage6.png';

const data = [
	{
		title: 'Content Writing',
		imagesrc: servicesImage1,
		description:
			"<h4>Does your business leverage the power of the right words? Is your content relevant and relatable to your audience? Does your content help your readers to connect emotionally with your brand?</h4> \
	  <p>If No, then you are missing out on something that you may regret later. We have a profound team of exclusive professionals that help your brand stand out by taking advantage of the word power.</p> \
	  <h4>Team of world's best and creative content writers.</h4> \
	  <p>Inspirer world has prominent expertise in content writing and copywriting services. We aim to offer the best and outstanding quality to our customers. Therefore to provide optimal and premium quality, we have created a team of the world's best content writers. We will help you in creating an unconventional success with the help of our VOICE framework.</p> \
	  <ol> \
		<li>V= Value addition</li> \
		<li>O= Original & understandable</li> \
		<li>I= Intention</li> \
		<li>C= Conversational & relevant</li> \
		<li>E= Engaging & emotionally connected</li> \
	  </ol> \
	  <h4>Exclusive content writing services - Diversity of creativity</h4> \
	  <p>We understand that not every content writer is best for your brand. Hence, we have an in-house team of brilliantly expert content writers who specialize in their specific areas. First, we understand your brand and requirements. After that, we assign the best and dedicated content writer that handles all your content writing work. In this way, you will get the right expert that your brand deserves.</p> \
	  <h4>Our exclusive Content Writing services include.</h4> \
	  <ul> \
		<li>Content creation for websites & social media posts</li> \
		<li>Copywriting services </li> \
		<li>Editing & proofreading services </li> \
		<li>Blog writing</li> \
		<li>Scriptwriting</li> \
		<li>E-book content creation</li> \
		<li>Content for flyers, brochures & PowerPoint presentations</li> \
	  </ul> \
	  <h4>Are you looking for content writing services that help you stand out from the competition and bring sales? </h4> \
	  <p>Let's make your content conversational and relatable that trigger your audience subconsciously for taking immediate action.</p>",
		slug: '/services/content-writing'
	},
	{
		title: 'Graphic Design',
		imagesrc: servicesImage2,
		description:
			'<h4>Creating ideas. Designing dreams. Building brands.</h4> \
	  	<p>Inspirer world is the pioneer graphic design agency that believes in innovation, out of box thinking, and a brand new future. If your brand wants to reach the right eyeballs, your brand has to take strategic advantage of graphic designs. Stunning and eye-catching graphics are the pivotal element that will get noticed by your customers and help them to associate with your brand.</p> \
	  	<h4>Our graphic designing services.</h4> \
	  	<ul> \
	  	<li>Attention-grabbing logos.</li> \
	  	<li>Noticeable flyers.</li> \
	  	<li>Attractive flexes.</li> \
	  	<li>Attractive social media infographic</li> \
	  	<li>Creative packaging desig</li> \
	  	<li>Impeccable 3d design</li> \
	  	<li>Eye-catching advertisement desig</li> \
	  	<li>Appealing menu design</li> \
	  	<li>Stunning brochure desig</li> \
	  	<li>Vibrant magazine cover desig</li> \
	  	<li>Fresh catalogue desig</li> \
	  	<li>Remarkable business card design</li> \
	  	<li>Professional trade show booth desig</li> \
	  	</ul> \
	  	<h4>Why is Inspirer World the premium choice for your business?</h4> \
		  <ul> \
		  <li>No compromise with quality.</li> \
		  <li>A team of in-house creative and ingenious professionals.</li> \
		  <li>We create graphics that speak to your brand.</li> \
		  <li>Design is our language, and hence, We create magic through our designs.</li> \
		  <li>Graphic designing in your budget.</li> \
		  <li>We believe in innovation and have an adaptive approach towards the latest technology. </li> \
		  </ul> \
		<h4>Get a perfect and creative design at your price.</h4> \
	  	<p>Connect with our team now and take advantage of our highly creative graphic designing services.</p>',
		slug: '/services/graphic-design'
	},
	{
		title: 'Web Design & Development',
		imagesrc: servicesImage3,
		description:
			"<h4>Let's create your digital home. </h4> \
	<p>Design. Develop. Transform.</p> \
	<p> Inspirer world offers pivotal web design and development solutions that strongly integrate user experience and interface. A professional team will help you turn your website into a statistical and marketing tool that engages your customer and brings sales and conversions.</p> \
	<h4>A website is more than a design. It's an experience that matters.</h4> \
	<p> Yes, it's 100% true that the first impression is your last impression to bring your customer to your brand. But what will happen when your customer visits your website, and after that, they do not get the experience that they deserve. The result would be they immediately leave your website, and it will never come again.</p> \
	<p> The design is equally important, but we cannot ignore the User experience. Along with beautiful aesthetics, your brand needs strong functionality and easy navigation so that your customer reaches the perfect destination without effort.</p> \
	<h4>Advantages of the responsive website.</h4> \
	<ul> \
	<li>Improved SEO</li> \
	<li>Follow search engine guidelines</li> \
	<li>Better performance</li> \
	<li>Incredible User experience</li> \
	<li>Save money and time</li> \
	</ul> \
	<h4>Our web design and development services.</h4> \
	<p>Inspirer world offers a magnitude of digital services that help your brand to reach the next level.</p> \
	<h4>Our web design services.</h4> \
	<ul> \
	<li>Corporate website design</li> \
	<li>E-commerce website design</li> \
	<li>Portfolio website design</li> \
	<li>Landing page design</li> \
	<li>Blogs/News website</li> \
	<li>Video Content website</li> \
	</ul> \
	<h4>Our web development services.</h4> \
	<ul> \
	<li>Open-Source Software Development</li> \
	<li>Custom Website Development</li> \
	<li>JavaScript Website Development</li> \
	<li>WordPress Web Development</li> \
	<li>CMS Development</li> \
	<li>Ecommerce Development</li> \
	<li>Mobile Development</li> \
	<li>Website Maintenance</li> \
	<li>Online Website Development</li> \
	<li>Joomla Website Development</li> \
	<li>Dynamic Website Development</li> \
	<li>Corporate Website Design</li> \
	<li>eCommerce Website Design</li> \
	<li>Portfolio Website Design</li> \
	<li>Landing Page Design</li> \
	<li>Blog/News Website</li> \
	<li>Video Content website</li> \
	</ul> \
  <h4>We build user-friendly websites at affordable prices.</h4> \
	<p>Connect with our team Now. We will help you create your digital home with incredible design and user experience.</p>",
		slug: '/services/web-design-development'
	},
	{
		title: 'Mobile App Design & Development',
		imagesrc: servicesImage4,
		description:
			'<h4>Let’s create your stunning application.</h4> \
	<p>Build smart. Build a winning app.</p> \
	<p>Inspirer world helps you create the aesthetic and functional mobile application design that offers the best user experience. An appealing mobile app design rightly builds the first impression on your customers and attracts them to your brand. We take care of all the minute design details, and hence we can offer you the best and intuitive application. We help you to create custom-tailor apps for android and IoS devices.</p> \
	<h4>Why does your business need Inspirer World services?</h4> \
	<p>We help your business to stand out from the competition and create an impact on your customers.</p> \
	<h4>Brand identity.</h4> \
	<p>We help in making a strong brand identity and convert visitors into prospective users.</p> \
	<h4>Expertise.</h4> \
	<p>Our well-versed team has decades of expertise in their domain.</p> \
	<h4>Value for money.</h4> \
	<p>We aim to deliver exclusive services at affordable pricing.</p> \
	<h4>Dynamic solutions.</h4> \
	<p>We offer dynamic mobile app solutions that fulfill all your customer needs and expectations.</p> \
	<h4>Intelligent user experience.</h4> \
	<p>With our mobile app services, we offer an intuitive user experience that helps in building your brand credibility.</p> \
	<h4>Our mobile app services.</h4> \
	<p>Inspirer World offers a plethora of mobile app services for your business, and hence we are a one-stop solution for all your mobile app design needs.</p> \
	<ul> \
	<li>Mobile App strategy consulting.</li> \
	<li>App UI design</li> \
	<li>IOS app design</li> \
	<li>Android app design</li> \
	<li>Social media app design</li> \
	<li>Hybrid app design</li> \
	<li>Application redesign</li> \
	<li>PWA development</li> \
	</ul> \
	<h4>Does your business need an intuitive and responsive app design?</h4> \
	<p>Sounds great! Let’s create your mobile app right away.</p>',
		slug: '/services/mobile-app-design-development'
	},
	{
		title: 'Digital Marketing',
		imagesrc: servicesImage5,
		description:
			'<h4>Does your business impeccably build a strong online presence and generate a steady stream of sales?</h4> \
	  <p>Leverage the expertise of our digital marketing services and elevate your business growth.</p> \
	  <h4>We drive your great business forward Reach, Engage. Convert.</h4> \
	  <p>If you are looking for complete digital marketing solutions, look no further than Inspirer World. We are the industry-leading expert who helps businesses build their robust digital presence and attract new customers to their brand. Our pivotal services help your brand increase your brand awareness, increase engagement, grow organic followers, increase your ranking in the SERPs and bring qualified leads to your website.</p> \
	  <h4>Why is digital marketing a sure-proof solution for your brand?</h4> \
	  <ul> \
	  <li>Reach your target audience quickly without spending millions.</li> \
	  <li>Bring qualified traffic to your website, generate leads and conversions.</li> \
	  <li>You can easily track and monitor your digital activities. Hence save a lot of time, money, and effort with the help of digital marketing.</li> \
	  <li>It incredibly connects your customers with your brand. It is a great tool to increase awareness and brand reputation.</li> \
	  <li>Many customers buy products online, and hence digital marketing is a laser-focus strategy to expand your business reach and maximize ROI.</li> \
	  </ul> \
	  <h4>Our digital marketing services</h4> \
	  <p>Inspirer World digital marketing services has created a conversion-boosting blueprint that helps your brand achieve a big win in your concerned market and eclipse your brand competition.</p> \
	  <ul> \
	  <li>Search engine optimization</li> \
	  <li>Search media management</li> \
	  <li>PPC services </li> \
	  <li>Social media advertising services </li> \
	  <li>Landing page creation and promotion</li> \
	  <li>Striking content for poster ads</li> \
	  <li>Creative ad posters to explainer videos</li> \
	  <li>PR marketing</li> \
	  <li>Email marketing</li> \
	  <li>E-Commerce solution</li> \
	  <li>Mobile application design</li> \
	  <li>Website design and development</li> \
	  <li>YouTube marketing</li> \
	  <li>Bulk WhatsApp promotion</li> \
	  <li>Content writing and copywriting services </li> \
	  <li>social media creative design</li> \
	  </ul> \
	  <h4>Are you ready for digital growth and want to boost your business to the next level? </h4> \
	  <p>Our professional team will help you create a robust brand identity, enhance engagement, boost your sales and bring qualified conversions.</p>',
		slug: '/services/digital-marketing'
	},
	{
		title: 'Campaign Advertising',
		imagesrc: servicesImage6,
		description:
			'<h4>Exclusive advertising of our services.</h4> \
	  <p>We have a sure-shot and proven strategy to market your services and expand your reach without spending millions.</p> \
	  <h4>How do we provide exclusive advertising?</h4> \
	  <p>We have a large community on Linkedin, and we run weekly campaigns. We will offer 20% of our weekly campaign posters space for advertising your services. In this way, your business can reach the masses, enhance your sales and bring profit to your doors.</p> \
	  <h4>Why is it important to leverage the power of Linkedin?</h4> \
	  <p>Linkedin has become the goldmine for various businesses. Let’s explore the statistics.</p> \
		<ul><li>Linkedin has more than 722 million members.</li><li>80% of the B2B leads drive from Linkedin.</li><li>92% of B2B marketers use the Linkedin platform for promoting their brand.</li><li>More than 50% of the social media traffic drives to B2B websites are through Linkedin.</li><li>79% of marketers see that Linkedin is responsible for getting good leads.</li><li>Linkedin generates revenue 19X more than Instagram, 3X more than Twitter, and 4X more than Facebook.</li></ul>\
	  <p>Is your business looking to increase its sales and revenues by leveraging the power of advertising services?</p> \
	  <p>Let’s connect with our Inspirer World team and unlock the limitless opportunities for your business.</p>',
		slug: '/services/campaign-advertising'
	}
];

export default function ServicesDetails({ match }) {
	const activeSlug = match.url;
	const activeService = data.find(item => item.slug === activeSlug);

	return (
		<>
			{activeService && (
				<Helmet>
					<title>{activeService.title}</title>
				</Helmet>
			)}
			<div>
				<section className="section servicesDetailContainer gradient-light--lean-left pt-5">
					<div className="container responsive-pad-remove">
						<div className="row">
							<div className="col-md-12 text-center ">
								{/* <h2 className=" mainTitle mt-2 mb-5 primary-text-color responseive-head-test mb-md-0">
								Services
							</h2> */}
							</div>
						</div>
						<div className="row mt-3">
							{activeService && (
								<div className="col">
									<div className="services__card p-3">
										<img
											className="servicesImage"
											src={activeService.imagesrc}
											alt="Avatar"
										/>
										<h4 className="serviceTitle">{activeService.title}</h4>

										<div className="servicesMessageWrapper">
											<p className="info-name d-block">
												{parse(activeService.description)}
											</p>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</section>
				{/* END Section Testimonials */}
			</div>
		</>
	);
}
