import React,{useState,useEffect} from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import TextField from '@material-ui/core/TextField';

import {getAllTeams,getAllUsers,getTeamDetails,createTeam as createTeamApi} from '../Api'
export default function CreateTeam() {
  const [allMembers, setAllMembers] = useState([])
  const [TeamMembers, setTeamMembers] = useState([])
const [teamTitle, setTeamTitle] = useState('');
const [teamleader, setTeamLeader] = useState('');
useEffect(()=>{

  getUsers()
},[])


const getUsers= async ()=>{

  let res= await getAllUsers()
  if(res.error){
    console.log(res.error)
  }
  else{
 //  let Teams=res.res.data
setAllMembers(res.res.data.users)
    console.log(res.res.data)
 }
}


const createTeam= async(e)=>{
 
  if(!teamleader  && !teamTitle){
    console.log('teamLeader not found')
  }
else{

  let res=await createTeamApi(teamTitle,TeamMembers,teamleader)
  if(res.error){
    console.log(res.error)
  }
  else{
    console.log('success')
    window.location.reload()
  }
}
  //console.log('hello',teamleader,TeamMembers,teamTitle)
 // let res=await
}
const addMembersToTeam=(e,v)=>{
let newMembers=[...v]
setTeamMembers(newMembers)
console.log(TeamMembers)
}
  return (
    <div className='dashboard-main-container'>
      <h3 className='last-campaign assign-text text-left'>
       Create New Team
      </h3>

      <div className='dashboard-container admin-campaign'>
        <input
          className='team-title-input'
          type='text'
          placeholder='Team Title'
          name='teamTitle'
          onChange={(e)=>setTeamTitle(e.target.value)}
          onBlur={()=>console.log(teamTitle)}
        />
        <div className='choosing-options '>
          
        <Autocomplete
        style={{width:'100%'}}
        multiple
        id="fixed-tags-demo"
        options={allMembers}
        onChange={addMembersToTeam}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            className=' choosing-option-autocomplete'
            variant="standard"
            label="Multiple values"
            placeholder="Select Members"
          
          />
        )}
      />
          <select
            onChange={(e)=>setTeamLeader(e.target.value)}
            name='datatable-buttons_length'
            aria-controls='datatable-buttons'
            className='custom-select choosing-option'
            placeholder='Choose Team Leader'
          >
{allMembers.map(members=>{
  return <option value={members._id}>{members.name}</option>
})}
            
          </select>
        </div>
        <div className='create-team-btns'>
          <Link to="/teams">
          <Button variant='contained' className='create-team-btn' onClick={createTeam}>
            Create Team
          </Button></Link>

          <Link to="/teams">
          <Button
            variant='contained'
            className='create-team-btn create-team-back-btn'
          >
            Back
          </Button></Link>
        </div>
      </div>
    </div>
  )
}
