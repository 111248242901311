import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import { Image, Modal, Button, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Resizer from 'react-image-file-resizer';
import parse from 'html-react-parser';
import axios from 'axios';
import {
	participateInCampaign,
	updateCampaignStats,
	getUserStats
} from '../Api';
import fileDownload from 'js-file-download';
import { BsThreeDots } from 'react-icons/bs';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import StatsComponent from '../TeamLeader/StatsComponent';

import tropy_img2 from '../../images/Winner-Fill.png';
import Comments_img from '../../images/Comments.png';
import View_img from '../../images/VIew.png';
import Like_img from '../../images/Like.png';
import { useHistory } from 'react-router-dom';

export default function MyCampaignCard({
	c_number,
	index,
	item,
	getMyCampaigns
}) {
	// console.log('Item is',item)
	const history = useHistory();
	useEffect(() => {
		var data = JSON.parse(localStorage.getItem('InspirerWorld'));

		if (data) {
			setPic2(data.profileImg);
		}
		return () => {};
	}, []);
	const [disabledClaim, setDisabledClaim] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [Pic2, setPic2] = useState('');
	const [imgUrl, setimgUrl] = useState(null);
	const [ModifieProfilePic, setModifieProfilePic] = useState(null);
	const [show, setShow] = useState(false);
	const [p_data, setP_data] = useState();
	const [likes, setlikes] = useState(0);
	const [views, setviews] = useState(0);
	const [linkedinUrl, setlinkedinUrl] = useState('');
	const [comments, setcomments] = useState(0);
	const [winner, setWinner] = useState(0);
	const [isError, setisError] = useState(null);
	const [isSuccess, setisSuccess] = useState(null);
	const handleClose = () => {
		setShow(false);
		setisError(false);
		setisSuccess(false);
		setP_data();
	};
	const handleShow = data => {
		setP_data(data);
		setcomments(data.comments);
		setviews(data.views);
		setlikes(data.likes);
		setlinkedinUrl(data.postUrl);
		setShow(true);
	};
	const [user, setUser] = useState('');

	useEffect(() => {
		var inspirerData = JSON.parse(localStorage.getItem('InspirerWorld'));
		var Pic2 = inspirerData.profileImg;
		setUser(inspirerData);
		setPic2(Pic2);
		let a = 0;
		if (item.winner) {
			if (item.winner.contentCreator) {
				a = winner + 1;
			}
			if (item.winner.engager) {
				setWinner(winner + 1);
				a = winner + 1;
			}
			if (item.winner.viewership) {
				setWinner(winner + 1);
				a = winner + 1;
			}
			setWinner(a);
		}
	}, []);

	const finalFuncToDownloadProfilePic = async () => {
		let url = item.participants[0].imageUrl;
		let filename = `${item.title}.jpeg`;
		axios
			.get(url, {
				responseType: 'blob'
			})
			.then(res => {
				fileDownload(res.data, filename);
			});
	};

	const updateStats = async () => {
		let postUrl = linkedinUrl;
		let data = {
			likes,
			comments,
			views,
			postUrl
		};

		const res = await updateCampaignStats(item._id, data);
		if (res.error) {
			setisError(true);
			setisSuccess(false);
		} else {
			setisError(false);
			setisSuccess(true);
			setTimeout(() => {
				getMyCampaigns();
				return handleClose();
			}, 1500);
		}
	};

	return (
		<div className="col-sm-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Update Stats</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{p_data && (
						<div>
							<div className="form-group">
								<label htmlFor="password">
									Likes{' '}
									<img
										alt="just"
										style={{ marginTop: '-4px' }}
										src="https://img.icons8.com/material-sharp/20/101f41/facebook-like.png"
									/>{' '}
								</label>
								<input
									type="number"
									onChange={e => setlikes(e.target.value)}
									className="form-control"
									id="password"
									defaultValue={p_data.likes}
									placeholder="Number of likes"
								/>
							</div>

							<div className="form-group">
								<label htmlFor="password">
									{' '}
									Comments{' '}
									<img
										style={{ marginTop: '-4px' }}
										src="https://img.icons8.com/material-rounded/20/101f41/topic.png"
									/>{' '}
								</label>
								<input
									type="number"
									onChange={e => setcomments(e.target.value)}
									className="form-control"
									id="password"
									defaultValue={p_data.comments}
									placeholder="Number of Comments"
								/>
							</div>

							<div className="form-group">
								<label htmlFor="password">
									Views{' '}
									<img
										style={{ marginTop: '-4px' }}
										src="https://img.icons8.com/material-sharp/20/101f41/visible.png"
									/>
								</label>
								<input
									type="number"
									onChange={e => setviews(e.target.value)}
									className="form-control"
									id="password"
									defaultValue={p_data.views}
									placeholder="Number of Views"
								/>
							</div>

							<div className="form-group mb-3">
								<label htmlFor="password">Linkedin Url :</label>
								<input
									type="text"
									onChange={e => setlinkedinUrl(e.target.value)}
									className="form-control"
									id="password"
									defaultValue={p_data.postUrl}
									placeholder="Link of the Post"
								/>
							</div>
							{isError && (
								<Alert variant="danger">Oops Some Error Occurred !</Alert>
							)}

							{isSuccess && (
								<Alert variant="success">Status Updated Successfully !</Alert>
							)}
						</div>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
					<Button variant="primary" onClick={() => updateStats()}>
						Update
					</Button>
				</Modal.Footer>
			</Modal>
			{/* {console.log(item)} */}
			{index === 0 ? (
				<div className="campaign-card card">
					<div className="campaign-header-div mycampainCard">
						<div
							onClick={() => {
								history.push(`/profile`);
							}}
							style={{ cursor: 'pointer' }}
						>
							<h5 style={{ display: 'flex', alignItems: 'center' }}>
								<Image
									className="winner-dp-image trending-quotes-dp"
									style={{
										height: '40px',
										width: '40px',
										marginRight: '10px'
									}}
									className="img-thumbnail"
									src={Pic2}
									rounded
									roundedCircle
								/>{' '}
								{'  '}
								<span
									style={{
										fontSize: '14px',
										fontWeight: 'bold',
										display: 'flex',
										flexDirection: 'column'
									}}
								>
									{user && user.name.toUpperCase()}
									<span style={{ color: '#898989', fontSize: '10px' }}>
										<Moment format="DD/MM/YYYY">{item.startsOn}</Moment>
									</span>
								</span>
							</h5>
						</div>
						<div
							style={{ cursor: 'pointer', color: '#146d9c' }}
							onClick={() => handleShow(item.participants[0])}
						>
							<MoreHorizIcon
								style={{ color: '#146d9c' }}
								size={24}
								color="#fff"
							/>
						</div>
					</div>
					<div
						style={{
							padding: '10px',
							paddingBottom: '0',
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center'
						}}
					>
						<h4
							style={{
								fontSize: '14px',
								color: '#146d9c',
								display: 'flex',
								flexDirection: 'column',
								fontWeight: 'bold'
							}}
						>
							<span style={{ color: '#898989', fontSize: '10px' }}>
								Campaign participated {c_number}
							</span>
							{item.title}
						</h4>
						{item.participants[0] && item.participants[0]?.contentId ? (
							<button
								onClick={() =>
									history.push(`/campaign/${item._id}/editcontent`)
								}
								style={{
									background: '#166d9c',
									color: '#fff',
									fontSize: '14px',
									border: 'none',
									padding: '10px',
									fontWeight: 'bold',
									borderRadius: '5px',
									whiteSpace: 'nowrap'
								}}
							>
								Edit Content
							</button>
						) : (
							<button
								onClick={() => history.push(`/campaign/${item._id}/addcontent`)}
								style={{
									background: '#166d9c',
									color: '#fff',
									fontSize: '14px',
									border: 'none',
									padding: '10px',
									fontWeight: 'bold',
									borderRadius: '5px'
								}}
							>
								Add Content
							</button>
						)}
					</div>
					<div
						className="trendingcard-image-div"
						style={{ padding: '15px 10px' }}
					>
						{/* <img  src={imgUrl} /> */}
						{item.artWork && (
							<a href={item.participants[0].postUrl} target="_blank">
								<img
									src={item.participants[0].imageUrl}
									className="card-img-top"
									alt="..."
								/>
							</a>
						)}
						{item.participants[0] && item.participants[0]?.contentId && (
							<div className="mt-2">
								{/* {item.participants[0]?.contentId.body} */}
								{parse(item.participants[0]?.contentId.body)}
							</div>
						)}
					</div>

					<div className="card-body" style={{ padding: 0 }}>
						<div
							className="trendingcard-header trendingcard-footer row"
							style={{ justifyContent: 'space-between' }}
						>
							<div className="col-3 text-center p-0 m-0">
								<StatsComponent
									link={Like_img}
									value={item.participants[0].likes}
									w={33}
								/>
							</div>
							<div className="col-3 text-center p-0 m-0">
								<StatsComponent
									link={Comments_img}
									value={item.participants[0].comments}
									w={33}
								/>
							</div>
							<div className="col-3 text-center p-0 m-0">
								<StatsComponent
									link={View_img}
									value={item.participants[0].views}
									w={33}
								/>
							</div>
							<div className="col-3 text-center p-0 m-0 bg-primary trending-card-trophy">
								<div className="d-flex margin-auto">
									<Link
										className="margin-auto w-100"
										style={{ color: 'white' }}
										to="/winners"
									>
										<StatsComponent
											link={tropy_img2}
											value={winner}
											w={33}
											lastItem={true}
										/>
									</Link>
								</div>
							</div>
						</div>

						{/* <span style={{ display: "flex", flexDirection: "row" }}>
            <a
              style={{
                marginTop: "5px",
                fontSize: "12px",
                marginLeft: 0,
                color: "white",
              }}
              className="btn btn-info"
              onClick={() => handleShow()}
            >
              Update Stats
            </a>
            
          </span>
        */}
					</div>
				</div>
			) : (
				<div className="campaign-card card mt-4">
					<div className="campaign-header-div mycampainCard">
						<div
							onClick={() => {
								history.push(`/profile`);
							}}
							style={{ cursor: 'pointer' }}
						>
							<h5 style={{ display: 'flex', alignItems: 'center' }}>
								<Image
									className="winner-dp-image trending-quotes-dp img-thumbnail"
									style={{
										height: '35px',
										width: '35px',
										marginRight: '10px'
									}}
									src={Pic2}
									rounded
									roundedCircle
								/>
								<span
									style={{
										fontSize: '14px',
										fontWeight: 'bold',
										display: 'flex',
										flexDirection: 'column'
									}}
								>
									{user && user.name.toUpperCase()}
									<span style={{ color: '#898989', fontSize: '10px' }}>
										<Moment format="DD/MM/YYYY">{item.startsOn}</Moment>
									</span>
								</span>
							</h5>
						</div>

						<div
							style={{ cursor: 'pointer', color: 'white' }}
							onClick={() => handleShow(item.participants[0])}
						>
							<MoreHorizIcon
								style={{ color: '#146d9c' }}
								size={24}
								color="#fff"
							/>
						</div>
					</div>
					<div
						style={{
							padding: '10px',
							paddingBottom: '0',
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center'
						}}
					>
						<h4
							style={{
								fontSize: '14px',
								color: '#146d9c',
								display: 'flex',
								flexDirection: 'column',
								fontWeight: 'bold'
							}}
						>
							<span style={{ color: '#898989', fontSize: '10px' }}>
								Campaign participated {c_number}
							</span>
							{item.title}
						</h4>
						{item.participants[0] && item.participants[0]?.contentId ? (
							<button
								onClick={() =>
									history.push(`/campaign/${item._id}/editcontent`)
								}
								style={{
									background: '#166d9c',
									color: '#fff',
									fontSize: '14px',
									border: 'none',
									padding: '10px',
									fontWeight: 'bold',
									borderRadius: '5px',
									whiteSpace: 'nowrap'
								}}
							>
								Edit Content
							</button>
						) : (
							<button
								onClick={() => history.push(`/campaign/${item._id}/addcontent`)}
								style={{
									background: '#166d9c',
									color: '#fff',
									fontSize: '14px',
									border: 'none',
									padding: '10px',
									fontWeight: 'bold',
									borderRadius: '5px'
								}}
							>
								Add Content
							</button>
						)}
					</div>

					<div
						className="trendingcard-image-div"
						style={{ padding: '15px 10px' }}
					>
						{/* <img  src={imgUrl} /> */}

						{item.artWork && (
							<a href={item.participants[0].postUrl} target="_blank">
								<img
									src={item.participants[0].imageUrl}
									className="card-img-top"
									alt="..."
								/>
							</a>
						)}

						{item.participants[0] && item.participants[0]?.contentId && (
							<div className="mt-2">
								{parse(item.participants[0]?.contentId.body)}
								{/* {item.participants[0]?.contentId.body} */}
							</div>
						)}
					</div>

					<div className="card-body" style={{ padding: 0 }}>
						<div
							className="trendingcard-header trendingcard-footer row"
							style={{ justifyContent: 'space-between' }}
						>
							<div className="col-3 text-center p-0 m-0">
								<StatsComponent
									link={Like_img}
									value={item.participants[0].likes}
									w={33}
								/>
							</div>
							<div className="col-3 text-center p-0 m-0">
								<StatsComponent
									link={Comments_img}
									value={item.participants[0].comments}
									w={33}
								/>
							</div>
							<div className="col-3 text-center p-0 m-0">
								<StatsComponent
									link={View_img}
									value={item.participants[0].views}
									w={33}
								/>
							</div>
							<div className="col-3 text-center p-0 m-0 bg-primary trending-card-trophy">
								<div className="d-flex margin-auto">
									<Link
										className="margin-auto w-100"
										style={{ color: 'white' }}
										to="/winners"
									>
										<StatsComponent
											link={tropy_img2}
											value={winner}
											w={33}
											lastItem={true}
										/>
									</Link>
								</div>
							</div>
						</div>

						{/* <span style={{ display: "flex", flexDirection: "row" }}>
      <a
        style={{
          marginTop: "5px",
          fontSize: "12px",
          marginLeft: 0,
          color: "white",
        }}
        className="btn btn-info"
        onClick={() => handleShow()}
      >
        Update Stats
      </a>
      
    </span>
  */}
					</div>
				</div>
			)}
		</div>
	);
}
