import React from 'react';
import './index.scss';

export default function Pagination({
	entryToShow = 0,
	page = 0,
	setPage,
	totalpage = 0,
	totalData = 0
}) {
	const rangeStart = page + 1 - totalpage + 3;
	const showEntry = entryToShow * (page + 1);
	return (
		<div className="prev-next-row paginationContainer">
			<div className="entries-text">
				Showing {entryToShow > totalData ? 1 : showEntry - 9} to{' '}
				{entryToShow > totalData
					? totalData
					: showEntry > totalData
					? totalData
					: showEntry}{' '}
				of {totalData} entries
			</div>
			<div className="float-right btnBox" style={{ display: 'flex' }}>
				<button
					disabled={page === 0 ? true : false}
					className="btn  previous p-2"
					onClick={() => setPage(page - 1)}
				>
					Previous
				</button>
				<div style={{ display: 'flex', margin: '0' }} className="numberWrapper">
					{page > 0 && (
						<button
							onClick={() => setPage(0)}
							style={{
								color: page === 0 ? '#146d9c' : 'black'
							}}
						>
							1
						</button>
					)}
					{page > 1 && (
						<button
							disabled
							style={{
								color: 'black'
							}}
						>
							...
						</button>
					)}
					{[...Array(3)].map((item, p_index) => (
						<button
							onClick={() =>
								setPage(
									rangeStart > 0
										? p_index - rangeStart + page + 1
										: page + p_index
								)
							}
							style={{
								color:
									rangeStart > 0
										? p_index - rangeStart + page + 2 === page + 1
											? '#146d9c'
											: 'black'
										: page + 1 + p_index === page + 1
										? '#146d9c'
										: 'black'
							}}
						>
							{rangeStart > 0
								? p_index - rangeStart + page + 2
								: page + 1 + p_index}
						</button>
					))}

					{rangeStart < 0 && (
						<button
							disabled
							style={{
								color: 'black'
							}}
						>
							...
						</button>
					)}

					{rangeStart <= 0 && (
						<button
							onClick={() => setPage(totalpage - 1)}
							style={{
								color: totalpage === page + 1 ? '#146d9c' : 'black'
							}}
						>
							{totalpage}
						</button>
					)}
				</div>
				<button
					disabled={totalpage !== page + 1 ? false : true}
					className="btn next p-2"
					onClick={() => setPage(page + 1)}
				>
					Next
				</button>
			</div>
		</div>
	);
}
