import React, { useState, useEffect } from 'react';
import user_pic from '../../images/user.png';
import CustomLoader from '../CustomLoader';

// import SidebarTrending from './Sidebar';
import { Helmet } from 'react-helmet-async';

import { Image } from 'react-bootstrap';
import { getUserQuoteDetails } from '../Api';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

export default function Myquotes({ match }) {
	const history = useHistory();

	const [quoteData, setquoteData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const handleCloseProfile = () => setShowModal(false);
	const handleShowProfile = () => setShowModal(true);
	const [profileImgExist, setProfileImgExist] = useState(false);
	const [regularCreator, setRegularCreator] = useState(false);

	const [showModal, setShowModal] = useState(false);
	const [userImageUrl, setuserImageUrl] = useState(null);
	const [userImage, setuserImage] = useState(null);
	const [showCropper, setShowCropper] = useState(false);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState('');
	const [disabledClaim, setDisabledClaim] = useState(false);

	useEffect(() => {
		// checkImage();
		getQuote();
	}, [0]);
	const getQuote = async () => {
		const id = match.params.id;
		if (id) {
			let res = await getUserQuoteDetails(id);
			console.log(res);
			setquoteData(res.res.data.quote);
		}
	};

	return (
		<>
			{quoteData && (
				<Helmet>
					<title>{quoteData.desc}</title>
				</Helmet>
			)}
			<div>
				<div>
					<br />

					<div className="container mt-2">
						<div className="row ">
							<div className="col-lg-12 col-md-12">
								{!quoteData && (
									<center>
										<h5>Loading.......</h5>
									</center>
								)}
								{quoteData && (
									<>
										<p className="card-text quote-card-quote-text">
											{quoteData.desc}{' '}
										</p>
										<div
											className="profile-short-div "
											style={{ background: '#F4F4F4' }}
										>
											<div className="profile-topbar-container">
												<div
													className=""
													style={{
														flexBasis: '50%',
														display: 'flex',
														alignquotesDatas: 'center'
													}}
												>
													{quoteData.author ? (
														<Image
															className="winner-dp-image trending-quotes-dp"
															src={quoteData.author.imageUrl}
															rounded
															roundedCircle
															thumbnail
															fluid
														/>
													) : (
														<Image
															className="winner-dp-image trending-quotes-dp"
															// src={quoteData.madeBy.profileImg}
															rounded
															roundedCircle
															thumbnail
															fluid
														/>
													)}
													{quoteData.author ? (
														<Link
															to={
																'/allquotes/' +
																quoteData.author.name.replace(' ', '_')
															}
															style={{
																display: 'flex',
																alignItems: 'center'
															}}
														>
															{' '}
															<strong
																style={{
																	cursor: 'pointer',
																	fontWeight: 'bold'
																}}
																className="profile-topbar-name"
															>
																{quoteData.author.name}
															</strong>
														</Link>
													) : (
														<Link
															to={'/quotes/' + quoteData.madeBy.name}
															style={{
																display: 'flex',
																alignItems: 'center'
															}}
														>
															{' '}
															<strong
																style={{
																	cursor: 'pointer',
																	fontWeight: 'bold'
																}}
																className="profile-topbar-name"
															>
																{quoteData.madeBy.name.replace(' ', '_')}
															</strong>
														</Link>
													)}
												</div>
												{/* <div className="col-lg-1 col-md-1"></div> */}
												<div
													className="text-right d-flex"
													style={{
														flexBasis: '50%',
														justifyContent: 'flex-end'
													}}
												></div>
											</div>
										</div>

										<div className="new-quote-cards-container">
											<div className="new-quote-cards-left">
												<div className="trendingcard-image-div">
													{/* <img  src={imgUrl} /> */}

													<img
														src={quoteData.artWork.imgUrl}
														className="card-img-top"
														alt={quoteData.desc}
													/>
												</div>
												<div className="quote-container-div">
													{/* <h5>{quoteData.title}</h5> */}
													<p className="card-text quote-card-quote-text">
														{quoteData.desc}{' '}
													</p>
												</div>

												<br />

												<div className="save-div">
													<div className="additional-info">
														<div className="individual-info">
															<div className="info-heading">
																<span>Tags</span>
															</div>
															<div className="info-value">
																{quoteData.tags.map(i => {
																	return (
																		<span
																			onClick={() => {
																				history.push({
																					pathname: '/allquotes',
																					state: { tag: i }
																				});
																			}}
																			style={{ cursor: 'pointer' }}
																		>
																			#{i}
																		</span>
																	);
																})}
															</div>
														</div>
													</div>
												</div>
											</div>

											<div className="trending-quotes-card-button-container">
												<button
													className="trending-quotes-claim-button"
													// onClick={() => finalFuncToDownloadProfilePic(item._id)}
												>
													{' '}
													<Link to={{ pathname: '/login', state: 'quotes' }}>
														<p className="block-claim-text">
															<img src={user_pic} className="user-pic-icon" />
															Sign-in to Claim
														</p>
													</Link>
												</button>
											</div>
										</div>
									</>
								)}
							</div>

							{/* <SidebarTrending /> */}
						</div>
					</div>
				</div>

				<br />

				{/* <center><h2 className="section__title mb-2">My quotes
        <img style={{marginLeft : '10px'}} src={Trendup}/></h2></center> 

 */}

				<CustomLoader loading={loading} />
			</div>
		</>
	);
}
