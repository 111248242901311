import { style } from 'dom-helpers';
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './index.scss';

export default function TagList({
	onCallback,
	setTags,
	tagData = [],
	islogout = false
}) {
	const tagList = [...new Set(tagData)];
	return (
		<div className="col-lg-3 tagList">
			<ul className="tagWrapper">
				{tagList.map((item, index) => (
					<Link to={'/all-campaigns/campaign/'+item?.title?.replaceAll(" ","-")?.replaceAll('?',"") +"/"+ item._id} >
					<li key={`tag-no-${index}`} >
						{item.title}
					</li>
					</Link>
				))}
			</ul>
		</div>
	);
}
