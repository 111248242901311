import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { deleteSuggestedQuotes, getSuggestedQuotes } from '../Api';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy, FaCheckCircle } from 'react-icons/fa';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Moment from 'react-moment';

function SuggestedQuotes() {
	const [data, setdata] = useState(null);

	const [copyId, setcopyId] = useState(null);

	useEffect(async () => {
		suggestedQuotes();
	}, []);
	const suggestedQuotes = async () => {
		const res = await getSuggestedQuotes();
		setdata(res.data.quotes);
	};
	return (
		<div className="dashboard-container admin-campaign">
			<div className="container">
				<div className="row text-align-center">
					<div className="col-lg-6">
						<h3 className="last-campaign admin-last-campaign text-left">
							~~ Suggested Quote List ~~
						</h3>
					</div>

					<div className="col-lg-6">
						<h3 className="last-campaign admin-last-campaign text-right">
							<Link to="/admin-quote">
								<button className="btn btn-outline-info">
									Go back to Quotes
								</button>
							</Link>
						</h3>
					</div>
				</div>
			</div>

			<br />

			<table className="table table-bordered">
				<thead>
					<tr>
						<th scope="col" className="font-weight-bold">
							Sr.
						</th>
						<th scope="col" className="font-weight-bold">
							Quote
						</th>
						<th scope="col" className="font-weight-bold">
							Author
						</th>
						<th scope="col" className="font-weight-bold">
							Suggested By
						</th>
						<th scope="col" className="font-weight-bold">
							Started On
						</th>

						<th scope="col" className="font-weight-bold">
							Artwork
						</th>
						<th scope="col" className="font-weight-bold">
							Actions
						</th>
					</tr>
				</thead>
				<tbody>
					{data &&
						data.map((item, index) => {
							return (
								<tr key={index}>
									<th scope="row">{index + 1}</th>
									<td>
										<CopyToClipboard
											text={item.quoteMsg}
											onCopy={() => setcopyId(index)}
										>
											{copyId == index ? (
												<button
													className="btn btn-primary"
													style={{
														padding: '10px',
														background: '#0ba50b',
														border: 'none'
													}}
												>
													<FaCheckCircle
														size={20}
														style={{ width: '20px', height: '20px' }}
													/>
												</button>
											) : (
												<button
													className="btn btn-primary"
													style={{ padding: '10px' }}
												>
													<FaCopy
														size={20}
														style={{ width: '20px', height: '20px' }}
													/>
												</button>
											)}
										</CopyToClipboard>
										&nbsp; &nbsp;
										<span>
											<strong>{item.quoteMsg}</strong>
										</span>
									</td>
									<td>
										<Link to={'/quote-by-author/' + item.quoteAuthor}>
											{item.quoteAuthor}
										</Link>
									</td>

									<td>
										<Link to={'/quote-by-author/' + item.quoteAuthor}>
											{item.user.name}{' '}
										</Link>
									</td>
									<td>
										<Moment format="DD/MM/YYYY">{item.startsOn}</Moment>
									</td>
									<td>
										<img
											style={{ maxWidth: '70px' }}
											src={item.artWork?.imgUrl}
											alt="art work"
										/>
									</td>
									<td style={{ alignItems: 'center' }}>
										<div className="d-flex">
											<Link to={`/edit-suggested-quote-admin/${item._id}`}>
												<EditIcon />
											</Link>
											&emsp;
											<DeleteIcon
												onClick={async () => {
													await deleteSuggestedQuotes(item._id);
													alert('Deleted');
													suggestedQuotes();
												}}
											/>
											&emsp;
										</div>
									</td>
								</tr>
							);
						})}
				</tbody>
			</table>
		</div>
	);
}

export default SuggestedQuotes;
