import React, { useState, useEffect } from 'react';
import Trendup from '../../images/trendup.svg';
import CampaignCard from './CampaignParticipantsCard';
import {
	getCampaignParticipantsForUser,
	getCampaignParticipantsRating,
	rateCampaign
} from '../Api';
import CustomLoader from '../CustomLoader';
import ProfileStatsBar from '../Profile/ProfileStatsBar';
import SidebarTrending from '../SidebarComponent/SidebarTrending';
import './campaigncard.css';

export default function CampaignScreen({ match }) {
	const [ratingDetails, setratingDetails] = useState([]);
	const [campaignData, setcampaignData] = useState(null);
	const [userId, setUserId] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		var Pic2 = JSON.parse(localStorage.getItem('InspirerWorld'));

		setUserId(Pic2.userId);
		getCampaigns();
		return () => {};
	}, []);

	const RateCampaign = async (id, rating) => {
		console.log(id, rating);
		let Reqid = { cid: match.params.id, uid: id };
		let data = { rating: rating };
		let res = await rateCampaign(Reqid, data);
		console.log(res);
	};
	const getCampaigns = async () => {
		setLoading(true);
		const res = await getCampaignParticipantsForUser(match.params.id);
		const res2 = await getCampaignParticipantsRating(match.params.id);

		setcampaignData(res.data.participants);
		setratingDetails(res2.data.ratings);
		console.log(res.data);
		setLoading(false);
		return;
	};

	return (
		<div>
			{campaignData && <ProfileStatsBar topCampaign={campaignData} />}

			<br />

			<div className="container">
				<div className="row ">
					<div className="col-lg-9 col-md-12">
						{campaignData &&
							campaignData.map((item, index) => {
								return (
									<CampaignCard
										key={index}
										RateCampaign={RateCampaign}
										item={item}
										index={index}
										ratingDetails={ratingDetails}
										loggedInUserId={userId}
										getCampaigns={getCampaigns}
									/>
								);
							})}
					</div>

					<SidebarTrending />
				</div>
			</div>
		</div>
	);
}
