import React, { useState, useEffect } from 'react';
import SignupImage from '../../images/signup.jpg';
import {
	getAllAuthor,
	getQuoteCategoryAdmin,
	SignupUser,
	UploadQuote
} from '../Api';
import { Modal, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ImageCropper from '../ImageCropper/ImageCropper';
import uploadImageCampaign from '../AdminCampaign/uploadImageCampaign';

export default function CreateQuote() {
	const history = useHistory();
	const [Category, setCategory] = useState(null);
	const [categoryData, setcategoryData] = useState(null);
	const [name, setname] = useState('');
	const [targetlikes, settargetlikes] = useState('');
	const [targetcomment, settargetcomment] = useState('');
	const [targetviews, settargetviews] = useState('');
	const [startdate, setstartdate] = useState('');
	const [campDesc, setcampDesc] = useState('');
	const [dimensions, setDimensions] = useState(null);
	const [scaleX, setscaleX] = useState(null);
	const [scaleY, setscaleY] = useState(null);
	const [authors, setauthors] = useState([]);
	const [selectedAuthor, setSelectedAuthor] = useState('');

	const [selectedImage, setSelectedImage] = useState('');
	const [show, setShow] = useState(false);
	const [Error, setError] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [loading, setLoading] = useState(false);
	let location = useHistory();

	useEffect(async () => {
		getCategory();
	}, []);

	const getCategory = async () => {
		const res = await getQuoteCategoryAdmin();
		const res2 = await getAllAuthor();
		setauthors(res2.res.data.authors);
		console.log(res2.res.data);
		setcategoryData(res.data.quoteCategories);
		setCategory(res.data.quoteCategories[0]._id);
	};

	const update_dimensions = (data, scaleX, scaleY) => {
		setDimensions(data);
		setscaleX(scaleX);
		setscaleY(scaleY);
		console.log('Parent =>', data);
	};
	const handleSubmit = async e => {
		e.preventDefault();
		setLoading(true);
		const myUploadedImage = await uploadImageCampaign(selectedImage);
		const data = {
			tags: name,
			desc: campDesc,
			authorId: selectedAuthor,
			startsOn: startdate,
			artWork: {
				imgUrl: myUploadedImage,
				x: dimensions.x * scaleX,
				y: dimensions.y * scaleY,
				h: dimensions.height * scaleY,
				w: dimensions.width * scaleX
			},
			quoteCategoryId: Category
		};
		console.log(data);
		const res = await UploadQuote(data);
		if (res.status === 201) {
			setLoading(false);
			location.push({
				pathname: '/admin-quote',
				state: { detail: 'Success' }
			});
		} else {
			setLoading(false);
			alert(' Something went Wrong');
		}
	};

	const updateImage = data => {
		setSelectedImage(data);
	};

	return (
		<div className="container">
			<div className="row">
				<div className="col-lg-1"></div>
				<br />
				<br />
				<br />
				<div className="col-lg-10 mb-2 contact-form__wrapper p-1 order-lg-1">
					<center>
						<h2>Create Quote</h2>
					</center>

					<br />

					<form
						action="#"
						className="contact-form form-validate"
						onSubmit={handleSubmit}
					>
						<div className="row">
							<div className="col-sm-6 mb-3">
								<div className="form-group">
									<label className="required-field" htmlFor="firstName">
										Author
									</label>
									<br />
									<select
										onChange={e => setSelectedAuthor(e.target.value)}
										name="datatable-buttons_length"
										aria-controls="datatable-buttons"
										className="custom-select"
										placeholder="Choose Team Leader"
									>
										<option>Select</option>
										{authors.map(members => {
											return (
												<option value={members._id}>{members.name}</option>
											);
										})}
									</select>
								</div>
							</div>
							<div className="col-sm-6 mb-3">
								<div className="form-group">
									<label htmlFor="phone">Started On</label>
									<input
										type="date"
										onChange={e => setstartdate(e.target.value)}
										className="form-control"
										id="phone"
										name="phone"
										placeholder="Date of campaign"
									/>
								</div>
							</div>
							<div className="col-sm-12 mb-3">
								<div className="form-group">
									<label className="required-field" htmlFor="firstName">
										Keywords
									</label>
									<input
										onChange={e => setname(e.target.value)}
										type="text"
										className="form-control"
										id="firstName"
										name="firstName"
										placeholder="Motivation( Write your keywords without #)"
									/>
								</div>
							</div>

							<div className="col-sm-12 mb-3">
								<div className="form-group">
									<label htmlFor="phone">Quote Description</label>
									<textarea
										onChange={e => setcampDesc(e.target.value)}
										type="text"
										className="form-control"
										id="phone"
										name="phone"
										placeholder="description"
									/>
								</div>
							</div>

							<div className="col-sm-12 mb-3">
								<div className="form-group">
									<label htmlFor="phone">ArtWork : </label> &nbsp;
									<ImageCropper
										update_dimensions={(item, scaleX, scaleY) =>
											update_dimensions(item, scaleX, scaleY)
										}
										updateImage={item => updateImage(item)}
									/>
								</div>
							</div>

							<div className="col-sm-12 mb-3">
								{!loading ? (
									<input
										type="submit"
										value="Launch Quote"
										name="submit"
										className="btn btn-primary"
									/>
								) : (
									<input
										type="submit"
										value="Loading..."
										name="submit"
										className="btn btn-primary"
									/>
								)}
							</div>
						</div>
					</form>
				</div>{' '}
				{/* End Contact Form Wrapper */}
			</div>
		</div>
	);
}
