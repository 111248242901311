import React, { useState, useEffect } from 'react';
import Trendup from '../../images/trendup.svg';
import QuotesCard from './QuotesCard';
import user_pic from '../../images/user.png';
import fileDownload from 'js-file-download';
import { Helmet } from 'react-helmet-async';

import {
	suggestQuote,
	getAllQuotes,
	getQuoteCategory,
	getMyQuotes,
	getUpcomingAndSuggestedQuotes,
	participateInUpcomingQuotes,
	checkImageExistence,
	participateInCategoryQuotes
} from '../Api';
import Pagination from '../Pagination/index';
import CustomLoader from '../CustomLoader';
import ProfileStatsBar from '../Profile/ProfileStatsBar';
import bookmark_img from '../../images/bookmark.png';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import {
	Modal,
	Button,
	Alert,
	Tab,
	Nav,
	Col,
	Row,
	Image
} from 'react-bootstrap';
import MySuggestedQuotes from './MySuggestedQuotes';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SuggestQuote from './SuggestQuote';
// import SidebarTrending from '../SidebarComponent/SidebarTrending';
import TagList from '../TagList';
import { BsFillBookmarkFill } from 'react-icons/bs';
import Myquotes from './MyQuotes';
import { ContactSupportOutlined } from '@material-ui/icons';
import axios from 'axios';
import './quotes.css';

export default function CampaignScreen({ match }) {
	const [search, setsearch] = useState('');
	const [showModal, setShowModal] = useState(false);

	const [disabledClaim, setDisabledClaim] = useState(false);
	const [profileImgExist, setProfileImgExist] = useState(false);

	const [quotesData, setquotesData] = useState(null);
	const [quotesFilterData, setquotesFilterData] = useState(null);
	const [entry, setEntry] = useState(10);
	const [entryToShow, setentryToShow] = useState(null);
	const [page, setPage] = useState(0);
	const [totalpage, setTotalPage] = useState(0);

	const [tagData, setTagData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [originalData, setoriginalData] = useState(null);
	const [selectedCategory, setselectedCategory] = useState('All Category');
	const [categoryAll, setcategoryAll] = useState(null);
	const [show, setShow] = useState(false);
	const [userSuggestedQuote, setuserSuggestedQuote] = useState(null);
	const [quoteAuthorName, setquoteAuthorName] = useState(null);
	const [userDetail, setuserDetail] = useState(null);
	// code for suggest quote
	const history = useHistory();
	const [checked, setChecked] = useState(false);
	const [isError, setisError] = useState(null);
	const [suggestedData, setsuggestedData] = useState([]);
	const [upcomingsuggestedData, setupcomingsuggestedData] = useState([]);
	const [isSuccess, setisSuccess] = useState(null);
	const [categoryWise, showCategoryWise] = useState(true);
	const [quoteDownloads, setquoteDownloads] = useState('');

	const params = useLocation();
	const handleClose = () => {
		setShow(false);
		setisSuccess(null);
		setisError(null);
		setChecked(false);
	};

	const handleShow = () => setShow(true);
	useEffect(() => {
		getQuotes();
	}, [1]);
	useEffect(() => {
		getCategory();
		getUserQuotes();

		checkImage();

		var data = JSON.parse(localStorage.getItem('InspirerWorld'));
		if (data) {
			setuserDetail(data);
		}
		return () => {};
	}, [1]);

	const setCategory = category => {
		setselectedCategory(category);
	};
	const setFilterByName = name => {
		let data = originalData.filter(item => {
			if (item.author.name.toLowerCase() == name.toLowerCase()) {
				return item;
			} else if (item.madeBy.name.toLowerCase() == name.toLowerCase()) {
				return item;
			}
		});

		setquotesData(data);
	};
	const setFilterByUrl = (name, Qdata) => {
		let search = name.replace('?', '');
		// console.log(search, 'shy');
		let data = Qdata.filter(item => {
			if (item.author.name.toLowerCase() == search.toLowerCase()) {
				return item;
			} else if (item.madeBy.name.toLowerCase() == search.toLowerCase()) {
				return item;
			}
		});

		setquotesData(data);
	};
	const setTags = tag => {
		// console.log(tag);
		let res = [];
		let data = originalData.filter(item => {
			item.tags.filter(tagitem => {
				if (tag === tagitem) {
					// console.log(item)
					res.push(item);
					return item;
				}
			});
			//   console.log(res)
			return res;
		});

		setquotesData(res);
	};
	const getQuotes = async () => {
		setLoading(true);
		const res = await getUpcomingAndSuggestedQuotes();
		// console.log(res.res.data, 'upcoming');
		setsuggestedData(res.res.data.quoteSuggestions);
		setupcomingsuggestedData(res.res.data.upcomingCampaigns);
		//const res = await getAllCampaigns();
		const response = await getAllQuotes();

		setquotesData(response.data.quotes);
		let tagArray = [];
		for (const item of response.data.quotes) {
			if (item.tags.length > 0) {
				tagArray = tagArray.concat(item.tags);
			}
		}
		setTagData(tagArray);
		setoriginalData(response.data.quotes);

		return setLoading(false);
	};

	const getCategory = async () => {
		let res = await getQuoteCategory();
		if (res.error) {
		} else {
			// console.log('Res category is', res);
			setcategoryAll(res.data.quoteCategories);
		}
	};

	const getUserQuotes = async () => {
		let res = await getMyQuotes();
		// console.log("my quotes", res.res.data.quotes.length);
		//  setquoteDownloads(res.res.data.quotes.length);
	};

	const [currentActiveTab, setcurrentActiveTab] = useState(
		match?.path === '/myquotes' ? 'mySuggestedQuotes' : 'trendingQuotes'
	);

	//this is for category filter
	useEffect(() => {
		// console.log(selectedCategory)

		if (quotesData && selectedCategory !== 'All Category') {
			let data = originalData.filter(item => {
				if (item.category.toLowerCase() == selectedCategory.toLowerCase())
					return item;
			});

			setquotesData(data);
			// console.log(`Quotes for ${selectedCategory} are `, data);
		} else {
			setquotesData(originalData);
		}

		return () => {};
	}, [selectedCategory]);
	const checkImage = async () => {
		let res = await checkImageExistence();

		setProfileImgExist(res.data.isSet);
	};

	const finalFuncToDownloadProfilePic = async (id, name) => {
		if (profileImgExist) {
			setDisabledClaim(true);
			const res2 = await participateInCategoryQuotes(id);
			let url = res2.data;
			// console.log(res2);
			let filename = `Suggested.jpeg`;
			axios
				.get(url, {
					responseType: 'blob'
				})
				.then(res => {
					// console.log(res, 'blob');
					fileDownload(res.data, filename);
				});
			setDisabledClaim(false);
		}
	};
	const finalFuncToDownloadProfilePicUpcoming = async (id, userId) => {
		if (profileImgExist) {
			setDisabledClaim(true);
			const res2 = await participateInUpcomingQuotes(id, userId);
			// console.log(res2);
			let url = res2.data;
			// console.log(res2);
			let filename = `Suggested.jpeg`;
			axios
				.get(url, {
					responseType: 'blob'
				})
				.then(res => {
					// console.log(res, 'blob');
					fileDownload(res.data, filename);
				});
			setDisabledClaim(false);
		}
	};
	const handleChange = event => {
		if (event.target.checked) {
			setquoteAuthorName(userDetail.name);
		}
		setChecked(event.target.checked);
	};

	const sendSuggestedQuote = async () => {
		let data = {
			quoteMsg: userSuggestedQuote,
			quoteAuthor: quoteAuthorName
		};

		let res = await suggestQuote(data);

		if (res.error) {
			setisError(true);
			setisSuccess(false);
		} else {
			setisSuccess(true);
			setisError(false);
		}
	};

	const changeActiveTab = screenName => {
		setcurrentActiveTab(screenName);
	};

	const filterData = () => {
		if (quotesData && quotesData.length > 0) {
			const cur_Data = quotesData.filter((item, index) => {
				if (item.desc.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
					return item;
			});
			if (cur_Data && cur_Data.length > 0) {
				setTotalPage(Math.ceil(cur_Data.length / entry));
				let array = cur_Data.slice(page * entry, (page + 1) * entry);
				setquotesFilterData(array);
			}
		}
	};

	useEffect(() => {
		filterData();
	}, [entry, page, quotesData, search]);

	return (
		<>
			<Helmet>
				<title>
					{match?.path === '/myquotes' ? 'My Quotes' : 'Quotes Library'}
				</title>
			</Helmet>
			<div className="quotesScreen">
				{quotesData && (
					<ProfileStatsBar topCampaign={quotesData[0]} type="quote" />
				)}

				<br />

				<div className="container ">
					<div className="row  ">
						<div className="col-lg-9 ">
							<Tab.Container
								defaultActiveKey="third"
								unmountOnExit={true}
								activeKey={currentActiveTab}
							>
								<Nav variant="pills" className="tab-navbar">
									<div
										style={{ display: 'none' }}
										className="button-container quote-button-container"
									>
										<div className="winner-button">
											<Nav.Item
												style={{
													backgroundColor:
														currentActiveTab !== 'trendingQuotes'
															? '#146d9c'
															: '#00AC07'
												}}
											>
												<Nav.Link
													style={{ fontSize: '12px', padding: '12px' }}
													className="quotes-nav-link"
													onClick={() => {
														setquotesData(originalData);
														history.push({ pathname: '/quotes', search: null });
														changeActiveTab('trendingQuotes');
													}}
												>
													Trending Quotes
												</Nav.Link>
											</Nav.Item>
										</div>{' '}
										<div className="winner-button">
											<Nav.Item
												style={{
													backgroundColor:
														currentActiveTab !== 'mySuggestedQuotes'
															? '#146d9c'
															: '#00AC07'
												}}
											>
												<Nav.Link
													style={{ fontSize: '12px', padding: '12px' }}
													className="quotes-nav-link"
													onClick={() => changeActiveTab('mySuggestedQuotes')}
												>
													My Suggested Quotes
												</Nav.Link>
											</Nav.Item>
										</div>{' '}
										<div className="winner-button">
											<Nav.Item
												style={{
													backgroundColor:
														currentActiveTab !== 'campaignQuotes'
															? '#146d9c'
															: '#00AC07'
												}}
											>
												<Nav.Link
													style={{ fontSize: '12px', padding: '12px' }}
													className="quotes-nav-link"
													onClick={() => changeActiveTab('campaignQuotes')}
												>
													Campaign Quotes
												</Nav.Link>
											</Nav.Item>
										</div>
									</div>
								</Nav>

								<h2 className="quotesTitle">
									{currentActiveTab === 'mySuggestedQuotes'
										? 'My Quotes'
										: 'Popular Quotes'}
								</h2>
								<Tab.Content style={{ width: '100%' }}>
									<Tab.Pane lg={3} eventKey="campaignQuotes">
										<div>
											<div className="row">
												<div
													className="button-half-div col-12"
													style={{ marginTop: '-25px' }}
												>
													<button
														variant="info"
														id="cwise"
														className="btn1 btn3 rounded"
														onClick={() => {
															document.getElementById(
																'authorbtn'
															).style.background = '#146d9c';
															document.getElementById(
																'cwise'
															).style.background = 'green';
															showCategoryWise(true);
														}}
													>
														{' '}
														Campaign Wise
													</button>

													<button
														variant="info"
														id="authorbtn"
														className="btn2 btn3 rounded"
														onClick={() => {
															showCategoryWise(false);
															document.getElementById(
																'authorbtn'
															).style.background = 'green';
															document.getElementById(
																'cwise'
															).style.background = '#146d9c';
														}}
														style={{ fontWeight: 'bold' }}
													>
														Category Wise
													</button>
												</div>
											</div>
											{!categoryWise
												? suggestedData.map(item => {
														return (
															<div className="new-quote-cards-container">
																<div className="new-quote-cards-left">
																	<div className="trendingcard-image-div">
																		{/* <img  src={imgUrl} /> */}

																		<img
																			src={item.artWork.imgUrl}
																			className="card-img-top"
																			alt="..."
																		/>
																	</div>
																	<div className="quote-container-div">
																		{/* <h5>{item.title}</h5> */}
																		<p className="card-text quote-card-quote-text">
																			{item.quoteMsg}
																		</p>
																	</div>

																	<br />

																	<div className="save-div">
																		<div className="additional-info">
																			<div className="individual-info">
																				<div className="info-heading">
																					<span>Category</span>
																				</div>
																				<div
																					className="info-value"
																					style={{ cursor: 'pointer' }}
																					onClick={() =>
																						setCategory(item.category)
																					}
																				>
																					<span>{item.category}</span>
																				</div>
																			</div>
																			<div className="individual-info">
																				<div className="info-heading">
																					<span>Tags</span>
																				</div>
																				<div className="info-value">
																					{item.hashTags.map(i => {
																						return (
																							<span
																								style={{ cursor: 'pointer' }}
																							>
																								#{i}
																							</span>
																						);
																					})}
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="trending-quotes-card-button-container">
																		{disabledClaim ? (
																			<div className="new-quote-cards-right">
																				<span className="cmapign-download">
																					Claiming...
																				</span>
																			</div>
																		) : (
																			<button
																				className="trending-quotes-claim-button"
																				onClick={() =>
																					finalFuncToDownloadProfilePic(
																						item._id,
																						item.quoteMsg
																					)
																				}
																			>
																				{' '}
																				<p className="block-claim-text">
																					<img
																						src={user_pic}
																						className="user-pic-icon"
																					/>
																					Click here to claim your quote poster
																				</p>
																			</button>
																		)}
																	</div>
																</div>
															</div>
														);
												  })
												: upcomingsuggestedData.map((item, index) => {
														// console.log(item);
														return item.quoteSuggestions.map(element => {
															return (
																<div
																	className={'new-quote-cards-container mt-2 '}
																>
																	<div className="new-quote-cards-left">
																		<div className="trendingcard-image-div">
																			{/* <img  src={imgUrl} /> */}

																			<img
																				src={element.artWork.imgUrl}
																				className="card-img-top"
																				alt="..."
																			/>
																		</div>
																		<div className="quote-container-div">
																			{/* <h5>{item.title}</h5> */}
																			<p className="card-text quote-card-quote-text">
																				{element.quoteMsg}
																			</p>
																		</div>

																		<br />
																		<div className="save-div">
																			<div className="additional-info">
																				<div className="individual-info">
																					<div className="info-heading">
																						<span>Campaign</span>
																					</div>
																					<div
																						className="info-value"
																						style={{ cursor: 'pointer' }}
																						onClick={() =>
																							setCategory(item.category)
																						}
																					>
																						<span>{item.title}</span>
																					</div>
																				</div>
																			</div>
																			<div className="individual-info">
																				<div className="info-heading">
																					<span>Author</span>
																				</div>
																				<div className="info-value">
																					{element.quoteAuthor}
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="trending-quotes-card-button-container">
																		{disabledClaim ? (
																			<div className="new-quote-cards-right">
																				<span className="cmapign-download">
																					Claiming...
																				</span>
																			</div>
																		) : (
																			<button
																				className="trending-quotes-claim-button"
																				onClick={() =>
																					finalFuncToDownloadProfilePicUpcoming(
																						item._id,
																						element.user
																					)
																				}
																			>
																				{' '}
																				<p className="block-claim-text">
																					<img
																						src={user_pic}
																						className="user-pic-icon"
																					/>
																					Click here to claim your quote poster
																				</p>
																			</button>
																		)}
																	</div>
																</div>
															);
														});
												  })}
										</div>
									</Tab.Pane>

									<Tab.Pane lg={3} eventKey="mySuggestedQuotes">
										<div>
											<MySuggestedQuotes />
										</div>
									</Tab.Pane>

									<Tab.Pane lg={3} eventKey="trendingQuotes">
										<div className="row">
											<div className="col-sm-12  float-right mb-3">
												<div className="form-group">
													<input
														onChange={e => setsearch(e.target.value)}
														type="text"
														className="form-control "
														id="firstName"
														name="firstName"
														placeholder="Find quotes by keyword, author"
													/>
												</div>
											</div>
										</div>

										<div style={{ display: 'flex', flexDirection: 'column' }}>
											{quotesFilterData &&
												quotesFilterData.map((item, index) => {
													return (
														<QuotesCard
															getQuotes={getQuotes}
															setCategory={setCategory}
															setTags={setTags}
															setFilterByName={setFilterByName}
															changeActiveTab={changeActiveTab}
															key={index}
															item={item}
														/>
													);
												})}

											{quotesData && quotesData.length < 1 && (
												<div>
													<center>
														<img
															style={{ width: '50%' }}
															src="https://cdn.dribbble.com/users/2071065/screenshots/6559618/dribble_4-19_4x.png?compress=1&resize=800x600"
														/>
														<h4>Oops ! No Quotes in this Category...</h4>
													</center>
												</div>
											)}

											{quotesData && quotesData.length > 0 && (
												<Pagination
													page={page}
													entryToShow={10}
													setPage={setPage}
													totalpage={totalpage}
													totalData={quotesData.length}
												/>
											)}
										</div>
									</Tab.Pane>

									<Tab.Pane lg={3} eventKey="myQuotes">
										<div>{/* <Myquotes /> */}</div>
									</Tab.Pane>
								</Tab.Content>
							</Tab.Container>
						</div>
						<TagList tagData={tagData} setTags={setTags} />
					</div>
				</div>
			</div>
		</>
	);
}
