import React from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import ProfileIcon from '@material-ui/icons/Person';
import HomeIcon from '@material-ui/icons/Home';
import { FaTrophy, FaBullhorn } from 'react-icons/fa';
import { BsChatSquareQuoteFill } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import Home_Fill from '../../images/Home-Fill.png';
import Campaign_Fill from '../../images/Campaign-Fill.png';
import Winner_Fill from '../../images/Winner-Fill.png';
import Quotes_Fill from '../../images/Quotes-Fill.png';

export default function BottomNavigationContainer() {
	const history = useHistory();
	const [value, setValue] = React.useState('recents');

	const handleChange = (event, newValue) => {
		setValue(newValue);
		history.push(`/${newValue}`);
	};

	return (
		<BottomNavigation value={value} onChange={handleChange}>
			<BottomNavigationAction
				label="Home"
				value="mycampaigns"
				// icon={<HomeIcon size={28} style={{ width: '80%', height: '2.5rem' }} />}
				icon={<img style={{ width: '24px' }} src={Home_Fill} alt="home icn" />}
			/>
			<BottomNavigationAction
				label="Camapigns"
				value="campaigns"
				// icon={
				// 	<FaBullhorn size={28} style={{ width: '80%', height: '1.8rem' }} />
				// }
				icon={
					<img
						style={{ width: '28px' }}
						src={Campaign_Fill}
						alt="campaigns icn"
					/>
				}
			/>
			<BottomNavigationAction
				label="Winners"
				value="winners"
				// icon={<FaTrophy size={28} style={{ width: '80%', height: '1.8rem' }} />}
				icon={
					<img style={{ width: '20px' }} src={Winner_Fill} alt="winners icn" />
				}
			/>
			<BottomNavigationAction
				label="Quotes"
				value="quotes"
				// icon={
				// 	<BsChatSquareQuoteFill
				// 		size={28}
				// 		style={{ width: '80%', height: '2.0rem' }}
				// 	/>
				// }
				icon={
					<img
						style={{ height: '2.0rem' }}
						src={Quotes_Fill}
						alt="quotes icn"
					/>
				}
			/>
		</BottomNavigation>
	);
}
