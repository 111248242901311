import React, { useState, useEffect } from 'react';
import './sidebarTrending.css';
import { getAllCampaigns, getAllQuotes } from '../Api';
import TrendingQuoteDiv from './TrendingQuoteDiv';
import PreviousCampaignDiv from './PreviousCampaignDiv';
import { BsFillChatSquareQuoteFill } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';

export default function SidebarTrending({ isHome = false }) {
	const [campaignData, setcampaignData] = useState(null);
	const [quotesData, setquotesData] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		getCampaigns();
		getQuotes();
		return () => {};
	}, [1]);

	const getCampaigns = async () => {
		setLoading(true);
		const res = await getAllCampaigns();
		// console.log('res is ',res.data)
		setcampaignData(res.data.campaigns.slice(0, 3));
		setLoading(false);
		return;
	};

	const getQuotes = async () => {
		const response = await getAllQuotes();
		console.log('trending side is quottes ', response.data.quotes);

		setquotesData(response.data.quotes.slice(0, 3));
	};

	return (
		<div className="col-lg-3 sidebar-campaign">
			<Link to="/learn-more">
				<button
					className="suggest-quote-button learn-more mb-1 radius-5 radius-5 "
					style={{ fontSize: '14px' }}
				>
					{' '}
					<img
						style={{ marginTop: '-4px' }}
						className="learn-more-icon "
						src="./images/img_4525691.png"
					/>{' '}
					Learn More
				</button>
			</Link>
			{!isHome && (
				<Link to="/suggestquote">
					<button
						className="suggest-quote-button bg-danger radius-5 "
						style={{ fontSize: '14px' }}
					>
						<BsFillChatSquareQuoteFill color="white" style={{ width: '8%' }} />{' '}
						Suggest A Quote
					</button>
				</Link>
			)}
			<TrendingQuoteDiv quotesData={quotesData} />

			<PreviousCampaignDiv campaignData={campaignData} />
		</div>
	);
}
