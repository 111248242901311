import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Edit } from '@material-ui/icons';
import Moment from 'react-moment';
import Cropper from 'react-easy-crop';
import { getUserCampaigns } from '../Api';
import { useHistory } from 'react-router-dom';
export default function ProfileDisplay({ profile }) {
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [loading, setLoading] = useState(false);

	const [croppedAreaPixels, setCroppedAreaPixels] = useState('');
	const [croppedImage, setCroppedImage] = useState('');
	const [croppedAreaPixelsArray, setCroppedAreaPixelsArray] = useState([]);
	const [contentCreator, setContentCreator] = useState(
		profile.isContentCreator
	);
	const [showModal, setshowModal] = useState(false);
	const [modalTitle, setmodalTitle] = useState('');
	const [Error, setError] = useState(false);
	// const [loading, setloading] = useState(false);
	const [show, setShow] = useState(false);
	const handleClose = () => {
		setShow(false);
		setshowModal(false);
	};
	const handleShow = () => setShow(true);
	const [userImage, setuserImage] = useState(null);
	const [userImageUrl, setuserImageUrl] = useState(profile.profileImg);
	const [imgSrcArray, setImgSrcArray] = useState([]);
	const [email, setemail] = useState(profile.email);
	const [pwd2, setpwd] = useState(null);
	const [name, setName] = useState(profile.name);
	const [pwd, setPWD] = useState('');
	const [whatsapp, setwhatsapp] = useState(profile.phoneNumber);
	const [linkedinUrl, setlinkedinUrl] = useState(profile.linkedinUrl);
	let newDate = new Date(profile.dob);
	let location = useHistory();
	const [date, setDate] = useState(newDate);
	const [userCampainList, setUserCampainList] = useState([]);
	console.log(newDate, date);
	const [instagramUrl, setinstagramUrl] = useState(
		profile.socialAccounts.instagramUrl
	);
	const [showCropper, setShowCropper] = useState(false);
	const onCropComplete = (croppedArea, croppedAreaPixels) => {
		console.log(croppedArea, croppedAreaPixels);
		setCroppedAreaPixels(croppedAreaPixels);
	};
	Date.prototype.yyyymmdd = function () {
		var mm = this.getMonth() + 1; // getMonth() is zero-based
		var dd = this.getDate();

		return [
			this.getFullYear(),
			(mm > 9 ? '' : '0') + mm,
			(dd > 9 ? '' : '0') + dd
		].join('-');
	};
	console.log(profile);
	const imageChange = image => {
		setuserImage(image);
		console.log('File is', image);
		const url = URL.createObjectURL(image);
		setuserImageUrl(url);
		setShowCropper(true);
	};

	const userCampainData = async () => {
		const res = await getUserCampaigns(profile._id);
		if (res && res.error) {
		} else {
			if (
				res &&
				res.res &&
				res.res.data &&
				res.res.data.campaigns &&
				res.res.data.campaigns.length > 0
			) {
				setUserCampainList(res.res.data.campaigns.reverse());
			}
		}
	};

	useEffect(() => {
		userCampainData();
	}, []);

	// alert(JSON.stringify(profile._id))
	return (
		<div className="profile-display profileDiplayView">
			<Modal show={showCropper} onHide={handleClose}>
				<Modal.Header closeButton={() => setShowCropper(false)}>
					<Modal.Title>{modalTitle}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div
						id="crop-section"
						style={{
							width: '100vw',
							top: '0',
							bottom: '0',
							left: '0',
							right: '0',
							height: '50vh'
						}}
					>
						<Cropper
							style={{ zIndex: 100, backgroundColor: 'black' }}
							image={userImageUrl}
							crop={crop}
							zoom={zoom}
							aspect={780 / 780}
							onCropChange={crop => {
								setCrop(crop);
							}}
							onCropComplete={onCropComplete}
						/>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<input
						type="range"
						value={zoom}
						min="1"
						max="3"
						step="0.1"
						onChange={e => {
							setZoom(e.target.value);
						}}
						className="custom-range"
						id="customRange1"
					/>
					<br />
					<Button
						onClick={() => {
							// setImgSrc(imgSrcArrayVar)
							setShowCropper(false);
							handleClose(false);
							setCroppedAreaPixelsArray(croppedAreaPixels);
							console.log(croppedAreaPixelsArray);
						}}
					>
						Crop
					</Button>
				</Modal.Footer>
			</Modal>

			<div className="row m-auto ">
				<div className="col-6">
					<div className="nameTitle">{name}</div>
				</div>
				<div className="col-6 userImg">
					<div className="dp ">
						<img src={userImageUrl} alt="user-img" />
					</div>
					<Edit
						style={{
							fontSize: '33px',
							borderRadius: '31px',
							zIndex: 1,
							backgroundColor: 'azure'
						}}
						onClick={() => {
							document.getElementById('myfileUpload').click();
						}}
					/>
					<input
						type="file"
						style={{ display: 'none' }}
						id="myfileUpload"
						name="myfile"
						onChange={e => {
							imageChange(e.target.files[0]);
						}}
					/>
				</div>
			</div>

			<div className="mainInfo">
				<div className="row">
					<div>Email</div>
					<div>{email}</div>
				</div>
				<div className="row">
					<div>Phone No</div>
					<div>{profile.phoneNumber}</div>
				</div>
				<div className="row">
					<div>Linked In</div>
					<div>{linkedinUrl}</div>
				</div>
				<div className="row">
					<div>instagram Url</div>
					<div>{instagramUrl}</div>
				</div>
				<div className="row">
					<div>Regular Content Creator</div>
					<div>{contentCreator ? 'Yes' : 'No'}</div>
				</div>
			</div>

			<table className="table table-bordered mt-4">
				<thead>
					<tr>
						<th scope="col" className="font-weight-bold">
							Sr.
						</th>
						<th scope="col" className="font-weight-bold">
							Campaign Title
						</th>
						<th scope="col" className="font-weight-bold">
							Participation Date
						</th>
						<th scope="col" className="font-weight-bold">
							Likes
						</th>
						<th scope="col" className="font-weight-bold">
							Comments
						</th>
						<th scope="col" className="font-weight-bold">
							Views
						</th>
						<th scope="col" className="font-weight-bold">
							Artwork
						</th>
					</tr>
				</thead>
				<tbody>
					{userCampainList &&
						userCampainList.map((item, index) => {
							return (
								<tr key={index}>
									<th scope="row">{userCampainList.length - index}</th>
									<td>{item.title}</td>
									<td>
										<Moment format="DD/MM/YYYY">
											{item.participants &&
												item.participants.length > 0 &&
												item.participants[0].createdAt}
										</Moment>
									</td>
									<td>
										{item.participants &&
											item.participants.length > 0 &&
											item.participants[0].likes}
									</td>
									<td>
										{item.participants &&
											item.participants.length > 0 &&
											item.participants[0].comments}
									</td>
									<td>
										{item.participants &&
											item.participants.length > 0 &&
											item.participants[0].views}
									</td>
									<td>
										<div className="artworkImage">
											<img
												alt="dp"
												src={
													item.participants &&
													item.participants.length > 0 &&
													item.participants[0]?.imageUrl
												}
											/>
										</div>
									</td>
								</tr>
							);
						})}
				</tbody>
			</table>
		</div>
	);
}
