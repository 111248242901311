import React, { useState, useEffect } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import { Link } from "react-router-dom";
import Moment from "react-moment";
import {
  deleteCampaign,
  getAllCampaignsAdmin,
  getBirthdays,
  sendBirthday,
  toggleCampaignAdmin,
} from "../Api";
import { Modal, Button } from "react-bootstrap";
import { scryRenderedDOMComponentsWithClass } from "react-dom/test-utils";

export default function AdminCampaign() {
  const [allCampaigns, setallCampaigns] = useState(null);
  const [show, setShow] = useState(false);
  const [Error, setError] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modalHeading, setmodalHeading] = useState("");
  const [modalText, setmodalText] = useState("");
  const [modalButton, setmodalButton] = useState("");
  const [loading, setloading] = useState(false);
  const [notifcation, setNotification] = useState([]);
  const [month, setMonth] = useState([]);

  useEffect(() => {
    getNot();
  }, []);
  const getNot = async () => {
    let res = await getBirthdays();
    if (res.error) {
      console.log(res);
    } else {
      console.log(res);
      setMonth(res.res.data.birthdayThisMonth);
      setNotification(res.res.data.birthdayToday);
    }
  };
  const sendWish = async (id) => {
    let res = await sendBirthday(id);
    if (res.error) {
      console.log(res);
    } else {
      console.log(res);
      setmodalHeading('Successfully sent')
      setmodalText('Wished User')
     
      handleShow()
    }
  };

  return (
    <div className="dashboard-main-container">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalHeading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalText}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

          {!Error && (
            <a href="/campaigns">
              <Button variant="primary">{modalButton}</Button>
            </a>
          )}

          {Error && (
            <a>
              <Button variant="primary" onClick={handleClose}>
                {modalButton}
              </Button>
            </a>
          )}
        </Modal.Footer>
      </Modal>

      <div className="dashboard-container admin-campaign">
        <div className="container">
          <div className="row text-align-center">
            <div className="col-lg-6">
              <h3 className="last-campaign admin-last-campaign text-left">
                Birthday Notification
              </h3>
            </div>
          </div>
        </div>

        <br />

        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col" className="font-weight-bold">
                Sr.
              </th>
              <th scope="col" className="font-weight-bold">
                Name
              </th>
              <th scope="col" className="font-weight-bold">
                Email
              </th>
              <th scope="col" className="font-weight-bold">
                Date
              </th>
              <th className="font-weight-bold">
                Social Accounts
              </th>
              <th className="font-weight-bold">
                Wish User
              </th>
            </tr>

          </thead>
          <tbody>
            {notifcation.map((item, index) => {
              return (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>
                    <Link>{item.name}</Link>
                  </td>
                  <td>
                    <a href={"mailto:" + item.email}>{item.email}</a>
                  </td>

                  <td className='bg-success'>
                    <Moment format="DD/MM">{item.dob}</Moment>
                  </td>

                  <td>
                    <div
                      style={{
                        display: "flex",
                        
                        alignItems: "center",
                      }}
                    >
                      <a
                        href={`https://wa.me/${item.phoneNumber}?text=`}
                        target="_blank"
                      >
                        <img
                          width="30"
                          src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                        />
                      </a>
                      <a href={item.linkedinUrl} target="_blank">
                        <img
                          width="25"
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c9/Linkedin.svg/1200px-Linkedin.svg.png"
                        />
                      </a>
                    </div>
                  </td>
                  <td>
                    <button className='btn btn-primary' onClick={()=>sendWish(item._id)}>
                      Send Email
                    </button>
                  </td>
                </tr>
              );
            })}
            {month.map((item, index) => {
              return (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>
                    <Link>{item.name}</Link>
                  </td>
                  <td>
                    <a href={"mailto:" + item.email}>{item.email}</a>
                  </td>

                  <td className='bg-info'>
                    <Moment format="DD/MM">{item.dob}</Moment>
                  </td>

                  <td>
                    <div
                      style={{
                        display: "flex",
                       
                        alignItems: "center",
                      }}
                    >
                      <a
                        href={`https://wa.me/${item.phoneNumber}?text=`}
                        target="_blank"
                      >
                        <img
                          width="30"
                          src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                        />
                      </a>
                      <a href={item.linkedinUrl} target="_blank">
                        <img
                          width="25"
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c9/Linkedin.svg/1200px-Linkedin.svg.png"
                        />
                      </a>
                    </div>
                  </td>
                  <td>
                    <button className='btn-primary btn' >
                      Available on birthDate
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
