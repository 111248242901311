import React, { useState, useEffect } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import { Link, useLocation } from "react-router-dom";
import Moment from "react-moment";
import {
  deleteCampaign,
  getAllCampaignsAdmin,
  toggleCampaignAdmin,
} from "../Api";
import { Modal, Button } from "react-bootstrap";

export default function AdminCampaign() {
  const [showSuccess, setshowSuccess] = useState(false);

  const [searchTerm, setsearchTerm] = useState("");
  const [allCampaigns, setallCampaigns] = useState(null);
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [Error, setError] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modalHeading, setmodalHeading] = useState("");
  const [modalText, setmodalText] = useState("");
  const [modalButton, setmodalButton] = useState("");
  const [loading, setloading] = useState(false);
  const [entryToShow, setentryToShow] = useState(null);
  const [allCampaignsFilter, setallCampaignsFilter] = useState([]);
  useEffect(() => {
    getCamapigns();
    switch (entryToShow) {
      case "all":
        setallCampaignsFilter(allCampaigns);
        break;
      case "active":
        let temp1 = [];
        allCampaigns.forEach((member) => {
          if (member.activity > 75) {
            temp1.push(member);
          }
        });
        setallCampaignsFilter(temp1);
        break;
      case "partial":
        let temp2 = [];
        allCampaigns.forEach((member) => {
          if (member.activity > 25 && member.activity <= 75) {
            temp2.push(member);
          }
        });
        setallCampaignsFilter(temp2);
        break;
      case "random":
        let temp3 = [];
        allCampaigns.forEach((member) => {
          if (member.activity > 1 && member.activity <= 25) {
            temp3.push(member);
          }
        });
        setallCampaignsFilter(temp3);
        break;
      case "na":
        let temp4 = [];
        allCampaigns.forEach((member) => {
          if (member.activity <= 1) {
            temp4.push(member);
          }
        });
        setallCampaignsFilter(temp4);
        break;
      case "nr":
        let temp5 = [];
        allCampaigns.forEach((member) => {
          if (
            new Date().getTime() <
            new Date(member.createdAt).getTime() + 604800000
          ) {
            temp5.push(member);
          }
        });
        setallCampaignsFilter(temp5);
        break;
      default:
        setallCampaignsFilter(allCampaigns);
    }
    return () => {};
  }, [1]);

  const getCamapigns = async () => {
    const res = await getAllCampaignsAdmin();
    if (res.error) {
      setmodalHeading("Oops !");
      setmodalText(`${res}`);
      setmodalButton("Try Again");
      setShow(true);
      return setError(true);
    } else {
      console.log("Res is", res.data.campaigns);
      setallCampaigns(res.data.campaigns);
    }
  };

  const toggleCampaign = async (id) => {
    const res = await toggleCampaignAdmin(id);
    if (res.error) {
      setmodalHeading("Oops !");
      setmodalText(`${res.data}`);
      console.log(res);
      setmodalButton("Try Again");
      setShow(true);
      return setError(true);
    } else {
      console.log("Res is", res.data);
      getCamapigns();
      // setallCampaigns(res.data)
    }
  };

  useEffect(() => {
    console.log(location, "89");
    if (location.state) {
      setshowSuccess(true);
    }
    setInterval(() => {
      setshowSuccess(false);
    }, 3000);
  }, [location.pathname]);
  return (
    <div className="dashboard-main-container">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalHeading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalText}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

          {!Error && (
            <a href="/campaigns">
              <Button variant="primary">{modalButton}</Button>
            </a>
          )}

          {Error && (
            <a>
              <Button variant="primary" onClick={handleClose}>
                {modalButton}
              </Button>
            </a>
          )}
        </Modal.Footer>
      </Modal>

      <div className="dashboard-container admin-campaign">
        <div className="container">
          <div className="row text-align-center">
            <div className="col-lg-6">
              <h3 className="last-campaign admin-last-campaign text-left">
                Campaigns List
              </h3>
            </div>

            <div className="col-lg-6">
              <h3 className="last-campaign admin-last-campaign text-right">
                <Link to="/createcampaign">
                  <button className="btn btn-info">Create Campaign</button>
                </Link>
              </h3>
            </div>
          </div>
        </div>
        {showSuccess ? (
          <div className="text-center">
            <h3
              className=" text-light p-1 m-5 bg-success"
              style={{ textTransform: "capitalize" }}
            >
              Campaign Added Succesfuly
            </h3>
          </div>
        ) : null}
        <br />
        <div className="row">
          <div className="col-5">
            <label className="teams-searchbar">
              Search:
              <input
                type="search"
                className="form-control form-control-sm"
                placeholder=""
                aria-controls="datatable-buttons"
                onChange={(e) => setsearchTerm(e.target.value)}
              />
            </label>
          </div>
        </div>
        <table className="table ">
          <thead>
            <tr>
              <th scope="col" className="font-weight-bold">
                Sr.
              </th>
              <th scope="col" className="font-weight-bold">
                Campaign Title
              </th>
              <th scope="col" className="font-weight-bold">
                Started On
              </th>
              <th scope="col" className="font-weight-bold">
                Initiated By
              </th>

              <th scope="col" className="font-weight-bold">
                Artwork
              </th>
              <th scope="" className="font-weight-bold">
                Participants
              </th>
              <th scope="" className="font-weight-bold">
                Nominated Winners
              </th>

              <th scope="col" className="font-weight-bold">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {allCampaigns &&
              allCampaigns
                .filter((item, index) => {
                  if (
                    item.title
                      .toLocaleLowerCase()
                      .includes(searchTerm.toLocaleLowerCase())
                  )
                    return item;
                })
                .map((item, index) => {
                  return (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>
                        <Link to={`/campaign-detail/${item._id}`}>
                          {item.title}
                        </Link>
                      </td>
                      <td>
                        <Moment format="DD/MM/YYYY">{item.startsOn}</Moment>
                      </td>
                      <td>{item.madeBy && item.madeBy.name}</td>

                      <td>
                        <img
                          style={{ maxWidth: "70px" }}
                          src={item?.artWork?.imgUrl}
                        />
                      </td>
                      <td>{item.noOfParticipants}</td>
                      {item["winner"] === undefined && (
                        <td>
                          <Link
                            className="btn btn-secondary"
                            to={`/nominees/campaignId/${item._id}`}
                          >
                            View
                          </Link>
                        </td>
                      )}
                      {item["winner"] !== undefined && (
                        <td>
                          <Link
                            className="btn btn-secondary"
                            to={`/nominees/campaignId/${item._id}`}
                          >
                            View
                          </Link>
                        </td>
                      )}

                      <td>
                        <div className="d-flex">
                          <Link to={`/admin-campaign/edit/${item._id}`}>
                            <EditIcon />
                          </Link>
                          &emsp;{" "}
                          <DeleteIcon
                            onClick={async () => {
                              await deleteCampaign(item._id);
                              getCamapigns();
                            }}
                          />
                          &emsp;
                          {item.active ? (
                            <Button
                              variant="contained"
                              className="text-light w-50 p-0 bg-success"
                              onClick={() => toggleCampaign(item._id)}
                            >
                              Open{" "}
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              className="text-light w-50 p-0 bg-danger"
                              onClick={() => toggleCampaign(item._id)}
                            >
                              {" "}
                              Close
                            </Button>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
