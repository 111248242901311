import React, { useState, useEffect } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { getAllTeams } from "../Api";
import { Edit } from "@material-ui/icons";
export default function Teams() {
  const getTeams = async () => {
    let res = await getAllTeams();
    if (res.error) {
      console.log(res.error);
    } else {
      setteamList(res.res.data.teams);

      console.log(res.res.data.teams);
    }
  };
  useEffect(() => {
    getTeams();
  }, []);

  const [searchTerm, setsearchTerm] = useState("");
  const [entryToShow, setentryToShow] = useState(25);

  const [teamList, setteamList] = useState(null);

  return (
    <div className="dashboard-main-container">
      <div className="teams-title">
        <h3 className="last-campaign admin-last-campaign text-left">
          Teams List
        </h3>
        <Link to="/create-team">
          <button className="btn btn-info create-team-btn">Create Team</button>
        </Link>
      </div>

      <div className="dashboard-container admin-campaign">
        <div className="teams-search">
          <label className="teams-select">
            Show
            <select
              name="datatable-buttons_length"
              aria-controls="datatable-buttons"
              className="custom-select teams-custom-select"
              onChange={(e) => setentryToShow(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            entries
          </label>

          <label className="teams-searchbar">
            Search:
            <input
              type="search"
              className="form-control form-control-sm"
              placeholder=""
              aria-controls="datatable-buttons"
              onChange={(e) => setsearchTerm(e.target.value)}
            />
          </label>
        </div>

        <div className="container">
          <div className="row text-align-center">
            <div className="col-lg-6"></div>

            <div className="col-lg-6">
              <h3 className="last-campaign admin-last-campaign text-right"></h3>
            </div>
          </div>
        </div>

        <br />

        <table className="table table-bordered">
          <thead>
            <tr>
              <th className="font-weight-bold" style={{ width: "10%" }}>
                <div className="up-down-container">
                  <strong> Sr.</strong>
                </div>
              </th>
              <th scope="row" className="font-weight-bold">
                <div className="up-down-container">
                  <strong> Team Name</strong>
                </div>
              </th>
              <th style={{ width: "30%" }}>
                <strong>Last Campaign Stats</strong>
              </th>
              <th className="font-weight-bold">
                <div className="up-down-container">
                  <strong>Action</strong>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {teamList &&
              teamList
                .filter((item, index) => {
                  if (
                    item.title
                      .toLocaleLowerCase()
                      .includes(searchTerm.toLocaleLowerCase()) &&
                    index < entryToShow
                  )
                    return item;
                })
                .map((item, index) => {
                  return (
                    <tr key={index + 1}>
                      <th>{index + 1}</th>
                      <td>
                        <Link to={`/teamlist/${item._id}`}>{item.title}</Link>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <span>
                              <img
                                className="teams-views"
                                src="images/icn_fluxo_users.svg"
                                style={{ width: "20px", marginRight: "10px" }}
                                alt=""
                              />
                            </span>
                            <span>{item.lastCampaignParticipants}</span>
                          </div>

                          <div>
                            <span>
                              <img
                                className="teams-views"
                                src="images/comments.svg"
                                style={{ width: "20px", marginRight: "10px" }}
                                alt=""
                              />
                            </span>
                            <span>{item.lastCampaignComments}</span>
                          </div>
                          <div>
                            <span>
                              <img
                                className="teams-views"
                                src="images/likes.svg"
                                style={{ width: "20px", marginRight: "10px" }}
                                alt=""
                              />
                            </span>
                            <span>{item.lastCampaignLikes}</span>
                          </div>
                          <div>
                            <span>
                              <img
                                className="teams-views"
                                src="images/views.svg"
                                style={{ width: "20px", marginRight: "10px" }}
                                alt=""
                              />
                            </span>
                            <span>{item.lastCampaignViews}</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <Link style={{ justifyContent: "space-around" }}>
                          <Edit />
                          <a
                            href={`https://wa.me/${item.phoneNumber}?text=`}
                            target="_blank"
                          >
                            <img
                              width="30"
                              className="m-2"
                              src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                            />
                          </a>
                          <a href={item.linkedinUrl} target="_blank">
                            <img
                              width="25"
                              src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c9/Linkedin.svg/1200px-Linkedin.svg.png"
                            />
                          </a>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>

        <div className="prev-next-row">
          {/* <div className='entries-text'>Showing {entryToShow} of {teamList && teamList.length} entries</div> */}
          {/* <div className='prev-next'>
            <div>Previous</div> <div className='prev-count'>1</div>{' '}
            <div>Next</div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
