import React, { useState, useEffect } from 'react';
import SignupImage from '../../images/signup.jpg';
import {
	editQuoteDetail,
	getAllAuthor,
	getQuoteCategoryAdmin,
	getQuoteDetail,
	SignupUser,
	UploadCampaign
} from '../Api';
import { Modal, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ImageCropper from '../ImageCropper/ImageCropper';
import uploadImageCampaign from '../AdminCampaign/uploadImageCampaign';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

export default function EditQuote({ match }) {
	const history = useHistory();
	const [dataLoaded, setDataLoaded] = useState(false);
	const [loading, setLoading] = useState(false);
	const [startdate, setstartdate] = useState('');
	const [campDesc, setcampDesc] = useState('');
	const [dimensions, setDimensions] = useState(null);
	const [scaleX, setscaleX] = useState(null);
	const [scaleY, setscaleY] = useState(null);
	const [Category, setCategory] = useState(null);
	const [categoryData, setcategoryData] = useState(null);
	const [name, setname] = useState('');
	const [targetlikes, settargetlikes] = useState('');
	const [targetcomment, settargetcomment] = useState('');
	const [targetviews, settargetviews] = useState('');
	// const [campDesc, setcampDesc] = useState("");
	let location = useHistory();

	const [authors, setauthors] = useState([]);
	const [selectedAuthor, setSelectedAuthor] = useState('');
	const [defaultAuthor, setDefaultAuthor] = useState('');
	const [selectedImage, setSelectedImage] = useState('');
	const [Tags, setTags] = useState('');
	const [show, setShow] = useState(false);
	const [Error, setError] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [image, setImage] = useState('');
	const [selectedOption, setselectedOption] = useState('Select');

	useEffect(() => {
		fetchCampaignDetail(match.params.id);
	}, []);

	const fetchCampaignDetail = async campaignId => {
		const res = await getQuoteDetail(campaignId);
		if (res.data && res.data.quote && res.data.quote.tags) {
			if (res.data.quote.tags.length > 0) {
				if (res.data.quote.tags.length === 1) {
					setTags(res.data.quote.tags[0]);
				} else {
					const curr_tags = res.data.quote.tags.join(', ');
					setTags(curr_tags);
				}
			}
		}
		setname(res.data.quote.title);
		settargetviews(res.data.quote.views);
		////
		let date = new Date(res.data.quote.startsOn);
		let year = date.getFullYear();
		let month = date.getMonth() + 1;
		let dt = date.getDate();

		if (dt < 10) {
			dt = '0' + dt;
		}
		if (month < 10) {
			month = '0' + month;
		}
		console.log(year + '-' + month + '-' + dt);
		setstartdate(year + '-' + month + '-' + dt);
		setcampDesc(res.data.quote.desc);
		if (res.data.quote.author) {
			setDefaultAuthor(res.data.quote.author._id);
			setSelectedAuthor(res.data.quote.author._id);
		}
		setImage(res.data.quote?.artWork?.imgUrl);

		//   console.log(res.data.quote.author._id,'id')
		//  setDefaultCategory(res.data.)
		if (res.author) {
			setname(res.author.name);
		}

		getCategory(res.data.quote.category);
		setSelectedImage(res.data.quote.artWork.imgUrl);
		setDimensions({
			x: res.data.quote.artWork.x,
			y: res.data.quote.artWork.y,
			height: res.data.quote.artWork.h,
			width: res.data.quote.artWork.w
		});
		setDataLoaded(true);
	};
	const update_dimensions = (data, scaleX, scaleY) => {
		setDimensions(data);
		setscaleX(scaleX);
		setscaleY(scaleY);
		console.log('Parent =>', data);
	};
	const handleSubmit = async e => {
		e.preventDefault();
		setLoading(true);
		const myUploadedImage = await uploadImageCampaign(selectedImage);
		const data = {
			title: name,
			tags: Tags,
			desc: campDesc,
			startsOn: startdate,
			authorId: selectedAuthor,
			artWork: {
				imgUrl: myUploadedImage,
				x: dimensions.x,
				y: dimensions.y,
				h: dimensions.height,
				w: dimensions.width
			},
			likes: targetlikes,
			views: targetviews,
			comments: targetcomment,
			quoteCategoryId: Category
		};
		const res = await editQuoteDetail(match.params.id, data);
		if (res.status === 201) {
			setLoading(false);
			location.push({
				pathname: '/admin-quote',
				state: { detail: 'Success' }
			});
		} else {
			setLoading(false);
			alert(' Something went Wrong');
		}
	};

	const updateImage = data => {
		setSelectedImage(data);
	};

	const getCategory = async category => {
		const res = await getQuoteCategoryAdmin();
		const res2 = await getAllAuthor();
		setauthors(res2.res.data.authors);
		console.log(res2.res.data);
		setcategoryData(res.data.quoteCategories);
		res.data.quoteCategories.map(item => {
			if (category === item.title) {
				setCategory(item._id);
			}
		});
	};

	return (
		<div className="container">
			<div className="row">
				<div className="col-lg-1"></div>
				<br />
				<br />
				<br />
				<div className="col-lg-10 mb-2 contact-form__wrapper p-1 order-lg-1">
					<center>
						<h2>Edit Quote</h2>
					</center>

					<br />

					<form
						action="#"
						className="contact-form form-validate"
						onSubmit={handleSubmit}
					>
						<div className="row">
							<div className="col-sm-6 mb-3">
								{/* <div style={{ margin: '10px' }}> */}
								<div className="searchInput form-group">
									<label className="required-field" htmlFor="firstName">
										Author
									</label>
									<Autocomplete
										style={{ width: '100%' }}
										multiple={false}
										options={authors}
										defaultValue={{
											title: selectedOption
										}}
										onChange={(e, item) => {
											if (item && item.name) {
												setSelectedAuthor(item._id);
												setselectedOption(item.title);
											} else {
												setSelectedAuthor();
												setselectedOption('Select');
											}
										}}
										getOptionLabel={option => option.name}
										renderInput={params => (
											<TextField
												{...params}
												className=" choosing-option-autocomplete"
												variant="standard"
												label=""
												placeholder={'Select...'}
											/>
										)}
									/>
								</div>
								<div className="form-group" style={{ display: 'none' }}>
									<label className="required-field" htmlFor="firstName">
										Author
									</label>
									<br />
									<select
										onChange={e => setSelectedAuthor(e.target.value)}
										name="datatable-buttons_length"
										aria-controls="datatable-buttons"
										className="custom-select"
										placeholder="Choose Team Leader"
										value={defaultAuthor}
									>
										<option>Select</option>
										{authors.map(members => {
											return (
												<option value={members._id}>{members.name}</option>
											);
										})}
									</select>
								</div>
							</div>
							<div className="col-sm-6 mb-3">
								<div className="form-group">
									<label htmlFor="phone">Started On</label>
									<input
										type="date"
										onChange={e => setstartdate(e.target.value)}
										className="form-control"
										id="phone"
										value={startdate}
										name="phone"
										placeholder="Date of campaign"
									/>
								</div>
							</div>
							<div className="col-sm-12 mb-3">
								<div className="form-group">
									<label className="required-field" htmlFor="firstName">
										Keywords
									</label>
									<input
										onChange={e => setTags(e.target.value)}
										type="text"
										className="form-control"
										id="firstName"
										name="firstName"
										value={Tags}
										placeholder="Motivation , Engineer"
									/>
								</div>
							</div>

							{/* <div className="col-sm-6 mb-3">
                <div className="form-group">
                  <label className="required-field" htmlFor="email">
                    Choose Category
                  </label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setCategory(e.target.value);
                    }}
                    value={Category}
                  >
                    {categoryData &&
                      categoryData.map((item) => {
                        return (
                          <option key={item._id} value={item._id}>
                            {item.title}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div> */}

							<div className="col-sm-12 mb-3">
								<div className="form-group">
									<label htmlFor="phone">Quote Description</label>
									<textarea
										onChange={e => setcampDesc(e.target.value)}
										value={campDesc}
										type="text"
										className="form-control"
										id="phone"
										name="phone"
										placeholder="description"
									/>
								</div>
							</div>
							<div className="col-8 m-auto">
								<div className="trendingcard-image-div p-2">
									{/* <img  src={imgUrl} /> */}
									{image && (
										<img src={image} className="card-img-top" alt="..." />
									)}
									<div className="form-group">
										<label htmlFor="phone">ArtWork : </label> &nbsp;
										<ImageCropper
											update_dimensions={(item, scaleX, scaleY) =>
												update_dimensions(item, scaleX, scaleY)
											}
											updateImage={item => updateImage(item)}
										/>
									</div>
								</div>
							</div>

							<div className="col-sm-12 mb-3">
								{!loading ? (
									<input
										type="submit"
										value="Launch Quote"
										name="submit"
										className="btn btn-primary"
									/>
								) : (
									<input
										type="submit"
										value="Loading..."
										name="submit"
										className="btn btn-primary"
									/>
								)}
							</div>
						</div>
					</form>
				</div>{' '}
				{/* End Contact Form Wrapper */}
			</div>
		</div>
	);
}
