import React, { useState, useEffect } from 'react';
import ProfileDisplay from './ProfileDisplay';
import EditCard from './EditCard';
import { getProfile, getUserDetails } from '../Api';
import { Modal, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import ProfileStatsBar from '../Profile/ProfileStatsBar';

export default function Profile({ match }) {
	const [profileData, setprofileData] = useState(null);
	const [Error, setError] = useState(false);
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [modalHeading, setmodalHeading] = useState('');
	const [modalText, setmodalText] = useState('');
	const [modalButton, setmodalButton] = useState('');

	useEffect(() => {
		if (match.url === '/editprofile' || match.url === '/profile') {
			getUserProfile();
		} else {
			getUser();
		}

		return () => {};
	}, []);

	const getUserProfile = async () => {
		const res = await getProfile();

		if (res.error) {
			setmodalHeading('Oops');
			setmodalText(res.data);
			setmodalButton('Okay !');
			setShow(true);
			return setError(true);
		} else {
			if (res && res.res && res.res.data && res.res.data.user) {
				setprofileData(res.res.data.user);
				console.log('Profile data is', res.res.data);
			}
		}
	};

	const getUser = async () => {
		const res = await getUserDetails(match.params.id);

		if (res && res.error) {
			setmodalHeading('Oops');
			setmodalText(res.data);
			setmodalButton('Okay !');
			setShow(true);
			return setError(true);
		} else {
			if (res && res.res && res.res.data && res.res.data.user) {
				setprofileData(res.res.data.user);
				console.log('Profile data is', res.res.data);
			}
		}
	};

	return (
		<>
			<Helmet>
				<title>Profile</title>
			</Helmet>
			<ProfileStatsBar />
			<div className="profile-container" style={{ width: '80%' }}>
				<Modal show={show} onHide={handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>{modalHeading}</Modal.Title>
					</Modal.Header>
					<Modal.Body>{modalText}</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							Close
						</Button>
						<Button variant="primary" onClick={handleClose}>
							{modalButton}
						</Button>
					</Modal.Footer>
				</Modal>
				<br />
				{profileData &&
					(match.url === '/editprofile' ? (
						<EditCard getUserProfile={getUserProfile} profile={profileData} />
					) : (
						<ProfileDisplay
							profile={profileData}
							getUserProfile={getUserProfile}
							showEdit={match.url === '/profile'}
						/>
					))}
			</div>
		</>
	);
}
