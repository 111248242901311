import axios from 'axios';

const baseUrl = 'https://inspirerworld.com/api';
 //const baseUrl = 'https://test.inspirerworld.com/api';
// const baseUrl = 'http://localhost:3001';

const userData = JSON.parse(localStorage.getItem('InspirerWorld'));

var token = '';

if (userData) {
	console.log('User data is', userData);
	token = userData?.token;
}

export const loginUser = async data => {
	try {
		const res = await axios.post(`${baseUrl}/auth/login`, data);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const VerifyOTP = async data => {
	try {
		const res = await axios.post(`${baseUrl}/auth/reset-pwd/verify-otp`, data);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const VerifyToken = async data => {
	try {
		const res = await axios.get(`${baseUrl}/auth/verify-token`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const setNewPassword = async data => {
	try {
		const res = await axios.post(`${baseUrl}/auth/reset-pwd/token`, data);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const forgotPwd = async data => {
	try {
		const res = await axios.post(`${baseUrl}/auth/forget-pwd`, data);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const changePwd = async data => {
	try {
		const res = await axios.post(`${baseUrl}/accounts/change-pwd`, data, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const lunchSugestedQuotesApi = async (c_id, u_id, data) => {
	try {
		const res = await axios.post(
			`${baseUrl}/admin/upcoming-campaign/${c_id}/quote/publish?suggestedQuoteId=${u_id}`,
			data,
			{
				headers: { Authorization: `Bearer ${token}` }
			}
		);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const getLocation = async () => {
	const res = await axios.get('https://ipinfo.io/json?token=21980c5e360fcb');
	return res;
};

export const SignupUser = async data => {
	try {
		const res = await axios.post(`${baseUrl}/auth/signup`, data);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const googleLogin = async data => {
	try {
		const res = await axios.post(`${baseUrl}/auth/google_login`, data);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const getProfile = async data => {
	if (userData?.type == 'admin') {
		try {
			const res = await axios.get(`${baseUrl}/admin/accounts/profile`, {
				headers: { Authorization: `Bearer ${token}` }
			});
			return { error: false, res: res };
		} catch (e) {
			const err = { error: true, data: [] };
			return err;
		}
	} else {
		try {
			const res = await axios.get(`${baseUrl}/accounts/profile`, {
				headers: { Authorization: `Bearer ${token}` }
			});
			return { error: false, res: res };
		} catch (e) {
			const err = { error: true, data: [] };
			return err;
		}
	}
};

export const getUserDetails = async data => {
	try {
		const res = await axios.get(`${baseUrl}/user/${data}`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const getCampaignSingleParticipate = async (id,data) => {
	try {
		const res = await axios.get(`${baseUrl}/campaign/${id}/single-participation?userId=${data}`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const getUpcomingAndSuggestedQuotes = async (data, userId) => {
	try {
		const res = await axios.get(
			`${baseUrl}/upcoming-campaigns-and-quote-suggestions/all`,
			{
				headers: { Authorization: `Bearer ${token}` }
			}
		);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const getUpcomingSuggestedQuotesDetails = async (data, userId) => {
	try {
		const res = await axios.get(
			`${baseUrl}/admin/upcoming-campaign/${data}/quote/details?userId=${userId}`,
			{
				headers: { Authorization: `Bearer ${token}` }
			}
		);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const getUserQuoteDetails = async data => {
	try {
		const res = await axios.get(`${baseUrl}/quote/${data}`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const getSuggestedQuotesDetails = async data => {
	try {
		const res = await axios.get(`${baseUrl}/admin/quote-suggestion/${data}`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const getSuggestedTopicDetails = async data => {
	try {
		const res = await axios.get(`${baseUrl}/admin/suggested-topic/${data}`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const getContentDetails = async id => {
	try {
		const res = await axios.get(`${baseUrl}/campaign/${id}/content`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const editSuggestedQuotes = async (id, data) => {
	try {
		const res = await axios.put(
			`${baseUrl}/admin/quote-suggestion/${id}`,
			data,
			{
				headers: { Authorization: `Bearer ${token}` }
			}
		);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const checkEmail = async data => {
	try {
		const res = await axios.post(`${baseUrl}/consumer/check-email`,data);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
}
export const confirmEmail = async data => {
	try {
		const res = await axios.post(`${baseUrl}/consumer/verify-email/confirm-email`,data);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
}
export const verifyProductToken = async data => {
	try {
		const res = await axios.post(`${baseUrl}/consumer/verify-product-token`,data);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
}
export const editSuggestedTopic = async (id, data) => {
	try {
		const res = await axios.put(
			`${baseUrl}/admin/suggested-topic/${id}`,
			data,
			{
				headers: { Authorization: `Bearer ${token}` }
			}
		);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const editUpcomingSuggestedQuotes = async (cid, uid, data) => {
	try {
		const res = await axios.put(
			`${baseUrl}/admin/upcoming-campaign/${cid}/quote/add-artwork?userId=${uid}`,
			data,
			{
				headers: { Authorization: `Bearer ${token}` }
			}
		);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const authorDetail = async data => {
	try {
		const res = await axios.get(`${baseUrl}/admin/author/${data}`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const editAuthor = async (id, data) => {
	try {
		const res = await axios.put(`${baseUrl}/admin/author/${id}`, data, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const getUpcomingCampaign = async () => {
	try {
		const res = await axios.get(`${baseUrl}/admin/upcoming-campaign/all`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const getAllAuthor = async () => {
	try {
		const res = await axios.get(`${baseUrl}/admin/author/all`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const getAllUserAuthor = async () => {
	try {
		const res = await axios.get(`${baseUrl}/author/all`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const getUpcomingCampaignForUser = async () => {
	try {
		const res = await axios.get(`${baseUrl}/upcoming-campaign/all`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const postQuoteForUpcomingCampaign = async (id, data) => {
	try {
		const res = await axios.put(
			`${baseUrl}/upcoming-campaign/${id}/quote`,
			data,
			{
				headers: { Authorization: `Bearer ${token}` }
			}
		);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const postTopic = async data => {
	try {
		const res = await axios.post(`${baseUrl}/suggested-topic/create`, data, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const allSuggestedTopic = async () => {
	try {
		const res = await axios.get(`${baseUrl}/admin/suggested-topic/all`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const getMySavedQuotes = async id => {
	try {
		const res = await axios.get(`${baseUrl}/user/save-quotes/all`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const getMyAchievments = async id => {
	try {
		const res = await axios.get(`${baseUrl}/user/my-winning-campaigns`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const saveQuote = async id => {
	try {
		const res = await axios.post(
			`${baseUrl}/user/save-quotes/add?quoteId=${id}`,
			null,
			{
				headers: { Authorization: `Bearer ${token}` }
			}
		);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const removeSavedQuote = async id => {
	try {
		const res = await axios.delete(
			`${baseUrl}/user/save-quotes/remove?quoteId=${id}`,
			{
				headers: { Authorization: `Bearer ${token}` }
			}
		);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const getUpcomingCampaignDetail = async id => {
	try {
		const res = await axios.get(`${baseUrl}/admin/upcoming-campaign/${id}`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const postUpcomingCampaign = async data => {
	try {
		const res = await axios.post(
			`${baseUrl}/admin/upcoming-campaign/create`,
			data,
			{
				headers: { Authorization: `Bearer ${token}` }
			}
		);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const editUpcomingCampaign = async (data, id) => {
	try {
		const res = await axios.put(
			`${baseUrl}/admin/upcoming-campaign/${id}`,
			data,
			{
				headers: { Authorization: `Bearer ${token}` }
			}
		);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const deleteQuoteUpcomingCampaign = async data => {
	try {
		const res = await axios.delete(
			`${baseUrl}/admin/upcoming-campaign/${data}`,
			{
				headers: { Authorization: `Bearer ${token}` }
			}
		);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const deleteAuthor = async data => {
	try {
		const res = await axios.delete(`${baseUrl}/admin/author/${data}`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const changeTeamLeader = async (teamId, userId) => {
	try {
		const res = await axios.put(
			`${baseUrl}/admin/team/${teamId}/change-leader?newLeader=${userId}`,
			null,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				}
			}
		);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const createTeam = async (teamTitle, TeamMembers, teamleader) => {
	let data = {
		title: teamTitle,
		leader: teamleader,
		members: TeamMembers
	};
	try {
		const res = await axios.post(`${baseUrl}/admin/team/create`, data, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			}
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const createNomination = async (teamId, campId, data) => {
	try {
		const res = await axios.post(
			`${baseUrl}/team/nomination/create?teamId=${teamId}&campaignId=${campId}`,
			data,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				}
			}
		);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const getMyCampaigns = async data => {
	try {
		const res = await axios.get(`${baseUrl}/campaign/my`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const getCampaignsContent = async data => {
	try {
		const res = await axios.get(`${baseUrl}/content/all-contents-by-campaign`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const getCampaignsContentById = async id => {
	try {
		const res = await axios.get(
			`${baseUrl}/content/all-contents-by-campaign?campaignId=${id}`,
			{
				headers: { Authorization: `Bearer ${token}` }
			}
		);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const getCampaignsList = async () => {
	try {
		const res = await axios.get(`${baseUrl}/campaign/list-all`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const getUserCampaigns = async data => {
	if (userData?.type !== 'user') {
	
		try {
			const res = await axios.get(`${baseUrl}/admin/user/${data}/campaigns`, {
				headers: { Authorization: `Bearer ${token}` }
			});
			return { error: false, res: res };
		} catch (e) {
			const err = { error: true, data: [] };
			return err;
		}
	} else {
		try {
			const res = await axios.get(`${baseUrl}/user/${data}/campaigns`, {
				headers: { Authorization: `Bearer ${token}` }
			});
			return { error: false, res: res };
		} catch (e) {
			const err = { error: true, data: [] };
			return err;
		}
	}
};

export const getUserCampaignsWithoutAuth = async data => {

		try {
			const res = await axios.get(`${baseUrl}/user/${data}/campaigns`, {
				headers: { Authorization: `Bearer ${token}` }
			});
			return { error: false, res: res };
		} catch (e) {
			const err = { error: true, data: [] };
			return err;
		}
	
};
export const getUserQuotes = async data => {
	if (userData?.type === 'user') {
		try {
			const res = await axios.get(`${baseUrl}/user/${data}/quotes`, {
				headers: { Authorization: `Bearer ${token}` }
			});
			return { error: false, res: res };
		} catch (e) {
			const err = { error: true, data: [] };
			return err;
		}
	} else {
		try {
			const res = await axios.get(`${baseUrl}/admin/user/${data}/quotes`, {
				headers: { Authorization: `Bearer ${token}` }
			});
			return { error: false, res: res };
		} catch (e) {
			const err = { error: true, data: [] };
			return err;
		}
	}
};

export const getMyQuotes = async data => {
	try {
		const res = await axios.get(`${baseUrl}/quote/my`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const rateCampaign = async (id, data) => {
	try {
		const res = await axios.post(
			`${baseUrl}/campaign/${id.cid}/participation/rate?participantUserId=${id.uid}`,
			data,
			{
				headers: { Authorization: `Bearer ${token}` }
			}
		);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

//quotes api

export const suggestQuote = async quoteData => {
	try {
		const res = await axios.post(
			`${baseUrl}/quote-suggestion/create`,
			quoteData,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				}
			}
		);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const addContentToCampain = async (campain, quoteData) => {
	try {
		const res = await axios.post(
			`${baseUrl}/campaign/${campain}/content`,
			quoteData,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				}
			}
		);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const EditContentToCampain = async (campain, quoteData) => {
	try {
		const res = await axios.put(
			`${baseUrl}/campaign/${campain}/content`,
			quoteData,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				}
			}
		);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const AdminEditContentToCampain = async (id, quoteData) => {
	try {
		const res = await axios.put(`${baseUrl}/admin/content/${id}`, quoteData, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			}
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const toggleContentPublish = async id => {
	try {
		const res = await axios.put(
			`${baseUrl}/admin/content/${id}/toggle-publish`,
			{ body: 'check' },
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				}
			}
		);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const getAllTeams = async data => {
	try {
		const res = await axios.get(`${baseUrl}/admin/team/all`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const getAllUsers = async data => {
	try {
		const res = await axios.get(`${baseUrl}/admin/user/all`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const getAllActivatedUsers = async () => {
	try {
		const res = await axios.get(
			`${baseUrl}/admin/user/by-activation?deactivated=false`,
			{
				headers: { Authorization: `Bearer ${token}` }
			}
		);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const getDeactivatedUsers = async data => {
	try {
		const res = await axios.get(
			`${baseUrl}/admin/user/by-activation?deactivated=true`,
			{
				headers: { Authorization: `Bearer ${token}` }
			}
		);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const getAllLeaders = async () => {
	try {
		const res = await axios.get(`${baseUrl}/admin/user/leaders`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const getAllAdmins = async () => {
	try {
		const res = await axios.get(`${baseUrl}/admin/user/admins`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const getUserProfile = async id => {
	try {
		const res = await axios.get(`${baseUrl}/admin/user/${id}`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const getTeamDetails = async id => {
	try {
		const res = await axios.get(`${baseUrl}/admin/team/` + id, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const getAdminStats = async () => {
	try {
		const res = await axios.get(`${baseUrl}/admin/campaign/statistics`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const getAdminQuoteStats = async () => {
	try {
		const res = await axios.get(`${baseUrl}/admin/quote/statistics`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const removeTeamMember = async (teamId, memberId) => {
	try {
		const res = await axios.delete(
			`${baseUrl}/admin/team/${teamId}/member?removeUser=${memberId}`,
			{
				headers: { Authorization: `Bearer ${token}` }
			}
		);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const addMember = async data => {
	try {
		const res = await axios.post(`${baseUrl}/admin/user/add`, data, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const updateUserProfile = async data => {
	if (userData?.type === 'user') {
		try {
			const res = await axios.post(`${baseUrl}/accounts/profile/edit`, data, {
				headers: { Authorization: `Bearer ${token}` }
			});
			return { error: false, res: res };
		} catch (e) {
			const err = { error: true, data: [] };
			return err;
		}
	} else {
		try {
			const res = await axios.post(
				`${baseUrl}/admin/accounts/profile/edit`,
				data,
				{
					headers: { Authorization: `Bearer ${token}` }
				}
			);
			return { error: false, res: res };
		} catch (e) {
			const err = { error: true, data: [] };
			return err;
		}
	}
};
export const updateUserProfileFromAdmin = async (data, id) => {
	if (userData?.type) {
		try {
			const res = await axios.put(`${baseUrl}/admin/user/${id}/edit`, data, {
				headers: { Authorization: `Bearer ${token}` }
			});
			return { error: false, res: res };
		} catch (e) {
			const err = { error: true, data: [] };
			return err;
		}
	}
};

export const DeactivatedUser = async id => {
	try {
		const res = await axios.put(
			`${baseUrl}/admin/user/${id}/deactivate`,
			null,
			{
				headers: { Authorization: `Bearer ${token}` }
			}
		);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const ActivatedUser = async id => {
	try {
		const res = await axios.put(`${baseUrl}/admin/user/${id}/activate`, null, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const updateUserProfileAdminSide = async (data, user_id) => {
	try {
		const res = await axios.put(`${baseUrl}/admin/user/${user_id}/edit`, data, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const updateCampaignStats = async (campaignId, data) => {
	try {
		const res = await axios.put(
			`${baseUrl}/campaign/${campaignId}/stats`,
			data,
			{
				headers: { Authorization: `Bearer ${token}` }
			}
		);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const updateQuoteStats = async (quoteId, data) => {
	try {
		const res = await axios.put(`${baseUrl}/quote/${quoteId}/stats`, data, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const addTeamMember = async (teamId, memberId) => {
	try {
		const res = await axios.put(
			`${baseUrl}/admin/team/${teamId}/member?addUser=${memberId}`,
			null,
			{
				headers: {
					Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWRtaW4iLCJ1c2VySWQiOiJVTUJzR3JfcWgiLCJpYXQiOjE2MTQzMjEwMTl9.oghkTovwbrVjg2UH6ZGV129SR_BmdnVW4HqXTS0AYkE`
				}
			}
		);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const getAllCampaigns = async () => {
	const res = await axios.get(`${baseUrl}/campaign/all`, {
		headers: { Authorization: `Bearer ${token}` }
	});
	return res;
};

export const getAllQuotes = async () => {
	const res = await axios.get(`${baseUrl}/quote/all`, {
		headers: { Authorization: `Bearer ${token}` }
	});
	return res;
};

export const getAllCampaignsAdmin = async () => {
	const res = await axios.get(`${baseUrl}/admin/campaign/all`, {
		headers: { Authorization: `Bearer ${token}` }
	});
	return res;
};
export const getCampaignDetail = async id => {
	const res = await axios.get(`${baseUrl}/admin/campaign/${id}`, {
		headers: { Authorization: `Bearer ${token}` }
	});
	return res;
};
export const getQuoteDetail = async id => {
	const res = await axios.get(`${baseUrl}/admin/quote/${id}`, {
		headers: { Authorization: `Bearer ${token}` }
	});
	return res;
};
export const editCampaignDetail = async (id, data) => {
	const res = await axios.post(`${baseUrl}/admin/campaign/${id}/edit`, data, {
		headers: { Authorization: `Bearer ${token}` }
	});
	return res;
};
export const ParticipantInCampaignByAdmin = async (userid, campaignId) => {
	const res = await axios.post(
		`${baseUrl}/admin/user/${userid}/campaigns/${campaignId}/participate`,
		null,
		{
			headers: { Authorization: `Bearer ${token}` }
		}
	);
	return res;
};

export const getQuotesByCategory = async id => {
	const res = await axios.get(`${baseUrl}/admin/quote/search?category=${id}`, {
		headers: { Authorization: `Bearer ${token}` }
	});
	return res;
};
export const getQuotesByauthorName = async id => {
	const res = await axios.get(
		`${baseUrl}/admin/quote-suggestion/search/by-author?quoteAuthor=${id}`,
		{
			headers: { Authorization: `Bearer ${token}` }
		}
	);
	return res;
};
export const editQuoteDetail = async (id, data) => {
	const res = await axios.post(`${baseUrl}/admin/quote/${id}/edit`, data, {
		headers: { Authorization: `Bearer ${token}` }
	});
	return res;
};
export const getAllQuotesAdmin = async () => {
	const res = await axios.get(`${baseUrl}/admin/quote/all`, {
		headers: { Authorization: `Bearer ${token}` }
	});
	return res;
};

export const toggleCampaignAdmin = async id => {
	try {
		const res = await axios.post(
			`${baseUrl}/admin/campaign/${id}/toggle-status`,
			null,
			{
				headers: { Authorization: `Bearer ${token}` }
			}
		);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: e.response.data.msg };
		return err;
	}
};
export const toggleQuoteAdmin = async id => {
	try {
		const res = await axios.post(`${baseUrl}/admin/quote/${id}/toggle-status`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: e.response.data.msg };
		return err;
	}
};

export const UploadCampaign = async data => {
	try {
		const res = await axios.post(`${baseUrl}/admin/campaign/create`, data, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			}
		});
		return res;
	} catch (e) {}
};
export const UploadQuote = async data => {
	try {
		const res = await axios.post(`${baseUrl}/admin/quote/create`, data, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			}
		});
		return res;
	} catch (e) {}
};

export const participateInCampaign = async campaign_id => {
	try {
		const res = await axios.post(
			`${baseUrl}/campaign/${campaign_id}/participate`,
			{},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				}
			}
		);
		return res;
	} catch (e) {}
};

export const updateUserProfileImage = async data => {
	try {
		const res = await axios.put(`${baseUrl}/accounts/profile-image`, data, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			}
		});
		return res;
	} catch (e) {}
};

export const participateInQuotes = async quotes_id => {
	try {
		const res = await axios.post(
			`${baseUrl}/quote/${quotes_id}/participate`,
			{},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				}
			}
		);
		return res;
	} catch (e) {}
};
export const participateInCategoryQuotes = async id => {
	try {
		const res = await axios.get(
			`${baseUrl}/upcoming-campaigns-and-quote-suggestions/artwork?type=quoteSuggestion&quoteSuggestionId=${id}`,

			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				}
			}
		);
		return res;
	} catch (e) {}
};
export const participateInUpcomingQuotes = async (id, user) => {
	try {
		const res = await axios.get(
			`${baseUrl}/upcoming-campaigns-and-quote-suggestions/artwork?type=upcomingCampaign&upcomingCampaignId=${id}&upcomingCampaignSuggestedQuoteUserId=${user}`,

			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				}
			}
		);
		return res;
	} catch (e) {}
};
export const getUserStats = async (id, user) => {
	try {
		const res = await axios.get(`${baseUrl}/accounts/analytics`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			}
		});
		return res;
	} catch (e) {}
};

export const getMyTeams = async () => {
	try {
		const res = await axios.get(`${baseUrl}/team/my`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const getMyTeamsMembers = async () => {
	try {
		const res = await axios.get(`${baseUrl}/team/members`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

//stats of my team
export const getMyTeamStats = async teamId => {
	console.log('Team id to get stats is', teamId);
	try {
		const res = await axios.get(`${baseUrl}/team/${teamId}/statistics`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: e.response };
		console.log(e);
		return err;
	}
};

export const getTeamCampaignStats = async () => {
	try {
		const res = await axios.get(`${baseUrl}/team/members-and-participation`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: e.response };
		console.log(e);
		return err;
	}
};

export const getUserParticipatedByCampaign = async (campId, userId) => {
	try {
		const res = await axios.get(
			`${baseUrl}/team/campaign/${campId}/participation?userId=${userId}`,
			{
				headers: { Authorization: `Bearer ${token}` }
			}
		);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: e.response };
		console.log(e);
		return err;
	}
};

export const editUserParticipatedByStats = async (campId, userId, data) => {
	try {
		const res = await axios.put(
			`${baseUrl}/team/campaign/${campId}/participation?userId=${userId}`,
			data,
			{
				headers: { Authorization: `Bearer ${token}` }
			}
		);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: e.response };
		console.log(e);
		return err;
	}
};

export const getSuggestedQuotes = async () => {
	try {
		const res = await axios.get(`${baseUrl}/admin/quote-suggestion/all`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return res;
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const getMySuggestedQuotes = async () => {
	try {
		const res = await axios.get(`${baseUrl}/quote-suggestion/my`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return res;
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const editMySuggestedQuotes = async (id, data) => {
	try {
		const res = await axios.put(`${baseUrl}/quote-suggestion/${id}`, data, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return res;
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const deleteMySuggestedQuotes = async id => {
	try {
		const res = await axios.delete(`${baseUrl}/quote-suggestion/${id}`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return res;
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const deleteSuggestedQuotes = async id => {
	try {
		const res = await axios.delete(`${baseUrl}/admin/quote-suggestion/${id}`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return res;
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const deleteSuggestedTopic = async id => {
	try {
		const res = await axios.delete(`${baseUrl}/admin/suggested-topic/${id}`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return res;
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const createQuoteSuggestion = async data => {
	try {
		const res = await axios.post(`${baseUrl}/quote-suggestion/create`, data, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			}
		});
		return res;
	} catch (e) {}
};

export const getMyTeamMembers = async teamId => {
	try {
		const res = await axios.get(`${baseUrl}/team/${teamId}/members`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const deleteCampaign = async id => {
	try {
		const res = await axios.delete(`${baseUrl}/admin/campaign/${id}`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const deleteQuote = async id => {
	try {
		const res = await axios.delete(`${baseUrl}/admin/quote/${id}`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const viewArtwork = async id => {
	try {
		const res = await axios.get(`${baseUrl}/campaign/${id}/view-artwork`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return res;
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const viewArtworkAdmin = async (userId, id) => {
	try {
		const res = await axios.get(
			`${baseUrl}/admin/user/${userId}/campaigns/${id}/view-artwork`,
			{
				headers: { Authorization: `Bearer ${token}` }
			}
		);
		return res;
	} catch (e) {
		const err = { error: true, data: e.response };
		return err;
	}
};

export const sendBirthday = async id => {
	try {
		const res = await axios.post(
			`${baseUrl}/admin/user/${id}/send-birthday-email`,
			null,
			{
				headers: { Authorization: `Bearer ${token}` }
			}
		);
		return res;
	} catch (e) {
		const err = { error: true, data: e.response };
		return err;
	}
};

export const viewQuote = async id => {
	try {
		const res = await axios.get(`${baseUrl}/quote/${id}/view-artwork`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return res;
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const getCampaignParticipants = async id => {
	try {
		const res = await axios.get(
			`${baseUrl}/admin/campaign/${id}/participants`,
			{
				headers: { Authorization: `Bearer ${token}` }
			}
		);
		return res;
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const getCampaignParticipantsForUser = async id => {
	try {
		const res = await axios.get(`${baseUrl}/campaign/${id}/participants`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return res;
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const getCampaignParticipantsRating = async id => {
	try {
		const res = await axios.get(
			`${baseUrl}/campaign/${id}/participation/rate`,
			{
				headers: { Authorization: `Bearer ${token}` }
			}
		);
		return res;
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const getQuoteCategory = async id => {
	try {
		const res = await axios.get(`${baseUrl}/quote/category/all`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return res;
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const getQuoteCategoryAdmin = async id => {
	try {
		const res = await axios.get(`${baseUrl}/admin/quote/category/all`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return res;
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const getNominations = async id => {
	try {
		const res = await axios.get(
			`${baseUrl}/admin/team/nomination/bycampaign?campaignId=${id}`,
			{
				headers: { Authorization: `Bearer ${token}` }
			}
		);
		return res;
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const setWinner = async (id, data) => {
	try {
		const res = await axios.post(
			`${baseUrl}/admin/campaign/${id}/winner`,
			data,
			{
				headers: { Authorization: `Bearer ${token}` }
			}
		);
		console.log(res);
		return res;
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const RemoveWinner = async (id, data) => {
	try {
		const res = await axios.delete(`${baseUrl}/admin/campaign/${id}/winner`, {
			headers: { Authorization: `Bearer ${token}` },
			data: data
		});
		console.log(res);
		return res;
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const createAuthor = async data => {
	try {
		const res = await axios.post(`${baseUrl}/admin/author/create`, data, {
			headers: { Authorization: `Bearer ${token}` }
		});
		console.log(res);
		return res;
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const createQuoteCategory = async data => {
	try {
		const res = await axios.post(
			`${baseUrl}/admin/quote/category/create`,
			data,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				}
			}
		);
		return res;
	} catch (e) {}
};

export const getAllLearnMoreUser = async () => {
	try {
		const res = await axios.get(
			`${baseUrl}/learn-more/all`,

			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				}
			}
		);
		return res;
	} catch (e) {}
};
export const checkImageExistence = async () => {
	try {
		const res = await axios.get(
			`${baseUrl}/accounts/profile-image/is-set`,

			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				}
			}
		);
		return res;
	} catch (e) {}
};
export const setContentCreator = async data => {
	try {
		const res = await axios.post(
			`${baseUrl}/accounts/profile-image/set-is-content-creator`,
			data,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				}
			}
		);
		return res;
	} catch (e) {}
};

export const addLearnMore = async data => {
	try {
		const res = await axios.post(`${baseUrl}/admin/learn-more/create`, data, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			}
		});
		return res;
	} catch (e) {}
};
export const getAllLearnMore = async () => {
	try {
		const res = await axios.get(
			`${baseUrl}/admin/learn-more/all`,

			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				}
			}
		);
		return res;
	} catch (e) {}
};
export const deleteLearnMore = async id => {
	try {
		const res = await axios.delete(
			`${baseUrl}/admin/learn-more/${id}`,

			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				}
			}
		);
		return res;
	} catch (e) {}
};
export const editLearnMore = async (data, id) => {
	try {
		const res = await axios.put(`${baseUrl}/admin/learn-more/${id}`, data, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`
			}
		});
		return res;
	} catch (e) {}
};

export const deleteMember = async (teamId, userId) => {
	try {
		const res = await axios.delete(
			`${baseUrl}/team/${teamId}/member?removeUser=${userId}`,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				}
			}
		);
		return res;
	} catch (e) {}
};

export const updateCampaignStatsUser = async (data, campaignId, userId) => {
	try {
		const res = await axios.post(
			`${baseUrl}/admin/campaign/${campaignId}/participants?userId=${userId}`,
			data,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`
				}
			}
		);
		return res;
	} catch (e) {}
};

export const deleteQuoteCategory = async id => {
	try {
		const res = await axios.delete(`${baseUrl}/admin/quote/category/${id}`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const getNomination = async (teamId, campaignId) => {
	try {
		const res = await axios.get(
			`${baseUrl}/team/nomination?teamId=${teamId}&campaignId=${campaignId}`,
			{
				headers: { Authorization: `Bearer ${token}` }
			}
		);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const getBirthdays = async () => {
	try {
		const res = await axios.get(`${baseUrl}/admin/user/birthdays`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const setNomination = async (teamId, campaignId, data) => {
	try {
		const res = await axios.post(
			`${baseUrl}/team/nomination/create?teamId=${teamId}&campaignId=${campaignId}`,
			data,
			{
				headers: { Authorization: `Bearer ${token}` }
			}
		);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};

export const sendEmail = async ( data) => {
	try {
		const res = await axios.post(
			`${baseUrl}/auth/email-verify-otp-send`,
			data,
			{
				headers: { Authorization: `Bearer ${token}` }
			}
		);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};
export const verify = async ( data) => {
	try {
		const res = await axios.post(
			`${baseUrl}/auth/email-verify-otp-verify`,
			data,
			
		);
		return { error: false, res: res };
	} catch (e) {
		const err = { error: true, data: [] };
		return err;
	}
};