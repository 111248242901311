import React,{useState,useEffect} from 'react'
import StatsCard from './StatsCard'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import AdminCampaign from '../AdminCampaign/AdminCampaign'
import {getAdminQuoteStats, getAdminStats} from '../Api'


export default function Dashboard() {

const [statsData, setstatsData] = useState(null)
const [lateststatsData, setlateststatsData] = useState(null)
const [lateststatsDataQuote, setlateststatsDataQuote] = useState(null)


useEffect(() => {
getStats()

  return () => {
    
  }
}, [1])


const getStats =async()=>{
  const res = await getAdminStats()
  const res1 = await getAdminQuoteStats()
  if(res.error){
    return
  }else{
    console.log('Data is',res.res.data)
  setlateststatsData(res.res.data.lastestCampaign)
  setstatsData(res.res.data.stats)
  }
  if(res1.error){}else{
    
  setlateststatsDataQuote(res1.res.data.stats)
  }
  


}



  return (
    <div className="dashboard-main-container">
      <div className='dashboard-container'>
{ statsData && <div>
      <h3 className='last-campaign'>All time campaign stats</h3>

        <div className='dashboard'>
          <StatsCard
            img='images/white-copy.svg'
            title='Total Campaigns'
            value={statsData.campaigns}
          />
           <StatsCard
            img='images/white-likes.svg'
            title='Total Likes'
            value={statsData.likes}
          />
             <StatsCard
            img='images/white-comments.svg'
            title='Total Comments'
            value={statsData.comments}
          />
          <StatsCard
            img='images/white-views.svg'
            title='Total Views'
            value={statsData.views}
          />
       
         
        </div> </div>}


        {lateststatsData && <div>
       <h3 className='last-campaign'>Last Campaign Stats</h3>
        <div className='dashboard'>
          <StatsCard
            img='images/white-user.svg'
            title='Participants'
            value={lateststatsData.participants}
          />
          <StatsCard img='images/white-views.svg' title='Views' value={lateststatsData.views} />
          <StatsCard
            img='images/white-comments.svg'
            title='Comments'
            value={lateststatsData.comments}
          />
          <StatsCard img='images/white-likes.svg' title='Likes' value={lateststatsData.likes} />
        </div>
        </div>}
        {lateststatsDataQuote && <div>
       <h3 className='last-campaign'>Quote Stats</h3>
        <div className='dashboard'>
          <StatsCard
            img='images/white-user.svg'
            title='Quotes'
            value={lateststatsDataQuote.quotes}
          />
          <StatsCard img='images/white-views.svg' title='Views' value={lateststatsDataQuote.views} />
          <StatsCard
            img='images/white-comments.svg'
            title='Comments'
            value={lateststatsDataQuote.comments}
          />
          <StatsCard img='images/white-likes.svg' title='Likes' value={lateststatsDataQuote.likes} />
        </div>
        </div>}






<br/>
<br/>
        <div >




      <AdminCampaign/>

      
      
      
      
        </div>
      </div>
    </div>
  )
}
