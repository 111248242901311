import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import SignupImage from '../../images/signup.jpg';
import 'react-phone-number-input/style.css';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import { SignupUser, getLocation, sendEmail, verify } from '../Api';
import { Modal, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import CustomLoader from '../CustomLoader';

import { useLocation } from 'react-router-dom';

export default function Signup() {
	const history = useHistory();
	const [name, setname] = useState('');
	const [email, setemail] = useState('');
	const [pwd, setpwd] = useState('');
	const [confirmpwd, setconfrimpwd] = useState('');
	const [phoneNumber, setphoneNumber] = useState('');
	const [linkedinUrl, setlinkedinUrl] = useState('');
	const [modalHeading, setmodalHeading] = useState('');
	const [modalText, setmodalText] = useState('');
	const [modalButton, setmodalButton] = useState('');
	const [myCountry, setmyCountry] = useState('');
	let location = useLocation();
const [otp,setotp]=useState('');
const [showforgotPwd, setshowforgotPwd] = useState(false);
const ForgothandleClose = () => setshowforgotPwd(false);
	const ForgothandleShow = () => setshowforgotPwd(true); 
	const [show, setShow] = useState(false);
	const [Error, setError] = useState(false);
const [token,setToken]=useState('');
	const [Loading, setLoading] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const handleSignup = async e => {
	//	e.preventDefault();
		const data = {
			name,
			email,
			pwd,
			phoneNumber,
			linkedinUrl,
			emailVerificationToken:e
		};

		setLoading(true);

		if (pwd === confirmpwd) {
			try {
				const res = await SignupUser(data);

				if (res.error) {
					setmodalHeading('Oops');
					console.log(res);
					setmodalText(res.data);
					setmodalButton('Okay !');
					setShow(true);
					setLoading(false);
					return setError(true);
				} else {
					console.log('User signed up successfully - ', res.res.data);
					setmodalHeading('Sign Up Sucessful');
					setmodalText(
						`${name} your account has been created successfully, Login to continue !`
					);
					setmodalButton('Okay');
					// const done = await localStorage.setItem('InspirerWorld',JSON.stringify(res.res.data))
					setShow(true);

					setTimeout(() => {
						history.push('/login');
					}, 4000);
				}
			} catch (e) {
				console.log(e);
			}
		} else {
			setmodalHeading('Oops');
			setmodalText(`Passwords do not match...`);
			setmodalButton('Try Again !');
			setShow(true);
			return setError(true);
		}
	};
	const sendOTP = async e => {
		let data = {
			email:email,
	
		}
			e.preventDefault();
  let res=await sendEmail(data);
  console.log(res);
  if(res.error){
	alert("Email Already Registered");
  }
  else{

	  ForgothandleShow()
	}
			
	}

	useEffect(() => {
		getUserLocation();
		return () => {};
	}, [1]);

	const getUserLocation = async () => {
		const res = await getLocation();
		setmyCountry(res.data.country);
		return console.log('Res of location is', res.data);
	};

	// const responseGoogle = response => {
	// 	console.log('google response', response);
	// 	handleSignup1(response.profileObj);
	// };
const VerifyOTP=async ()=>{
//	handleSignup()
	let data={
		email,otp
	}
	let res=await verify(data);
	console.log(res);
	if(res.error){
		alert("OTP is incorrect");
	}
	else{
		//history.push('/login');
		setToken(res.res.data.token);
		ForgothandleClose()
		handleSignup(res.res.data.token)


	}

}
	// const handleSignup1 = async profileObj => {
	// 	try {
	// 		const data = {
	// 			name: profileObj.name,
	// 			email: profileObj.email
	// 		};
	// 		setLoading(true);
	// 		const res = await googleLogin(data);
	// 		loginCheck(res);
	// 		return setLoading(false);
	// 	} catch (e) {
	// 		console.log(e);
	// 	}
	// };

	const loginCheck = async res => {
		if (res.error) {
			console.log(res);
			setmodalHeading('Oops !');
			if (res.data) {
				setmodalText(`${res.data}`);
			} else {
				setmodalText(
					`Your Id is Blocked By Administration ..Contact Us To Know More`
				);
			}
			setmodalButton('Try Again');
			setShow(true);
			setError(true);
		} else {
			console.log('Res is', res.res.data);
			const now = new Date();
			const log_data = { ...res.res.data, expiry: now.getTime() + 7200000 };
			const done = await localStorage.setItem(
				'InspirerWorld',
				JSON.stringify(log_data)
			);
			if (res.res.data.type === 'user') {
				if (location.state == 'Suggested') {
					window.location.replace('/suggestquote');
				} else if (location.state == 'quotes') {
					window.location.replace('/quotes');
				} else {
					window.location.replace('/campaigns');
				}
			} else {
				window.location.replace('/dashboard');
			}
		}
	};

	return (
		<>
			<Helmet>
				<title>Sign up</title>
			</Helmet>
			<div className="container">
				<div className="row">
					<Modal show={show} onHide={handleClose}>
						<Modal.Header closeButton>
							<Modal.Title>{modalHeading}</Modal.Title>
						</Modal.Header>
						<Modal.Body>{modalText}</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={handleClose}>
								Close
							</Button>
							{setError ? (
								<Button variant="primary" onClick={handleClose}>
									{modalButton}
								</Button>
							) : (
								<Button
									variant="primary"
									onClick={() => history.push('/campaigns')}
								>
									{modalButton}
								</Button>
							)}
						</Modal.Footer>
					</Modal>
					<Modal show={showforgotPwd} onHide={ForgothandleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Check Your Email</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="col-sm-12 mb-2">
						<div className="form-group">
							<label htmlFor="email">OTP</label>
							<input
								type="text"
								onChange={e => setotp(e.target.value)}
								className="form-control"
								id="email"
								placeholder="OTP"
							/>
						</div>
					</div>
					<div className="col-sm-12 mb-2">
                    <div className="form-group text-warning">
                    <label style={{color:"gray"}}  htmlFor="email ">
                        Please check <span style={{color:"red",fontWeight:'700'}}>SPAM</span> or
						<span style={{color:"red",fontWeight:'700'}}>
						JUNK
						 </span> mail folder if you didnt receive the{" "}  
						<span style={{color:"red",fontWeight:'700'}}>
						OTP{" "}
						 </span>
						  in the inbox.
                      </label>  
                    </div>
                    </div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={VerifyOTP}>
						Send
					</Button>
				</Modal.Footer>
			</Modal>
					<div className="col-lg-7 mb-2 contact-form__wrapper p-1 order-lg-1">
						<center>
							<h2>Create Account</h2>
						</center>

						<br />

						<form
							action="#"
							className="contact-form form-validate"
							onSubmit={sendOTP}
						>
							<div className="row">
								<div className="col-sm-12 mb-3">
									<div className="form-group">
										<label className="required-field" htmlFor="firstName">
											Full Name
										</label>
										<input
											onChange={e => setname(e.target.value)}
											type="text"
											className="form-control"
											id="firstName"
											name="firstName"
											placeholder="Wendy"
										/>
									</div>
								</div>

								<div className="col-sm-6 mb-3">
									<div className="form-group">
										<label className="required-field" htmlFor="email">
											Email
										</label>
										<input
											onChange={e => setemail(e.target.value)}
											type="text"
											className="form-control"
											id="email"
											name="email"
											placeholder="wendy.apple@seed.com"
										/>
									</div>
								</div>

								<div className="col-sm-6 mb-3">
									<div className="form-group">
										<label htmlFor="phone">Whatsapp Number</label>

										<PhoneInput
											placeholder="Enter whatsapp number"
											defaultCountry={myCountry}
											value={phoneNumber}
											maxLength="11"
											onChange={setphoneNumber}
										/>

										{/* <input onChange={(e)=>setphoneNumber(e.target.value)} type="tel" className="form-control" id="phone" name="phone" placeholder="(021)-454-545" /> */}
									</div>
								</div>

								<div className="col-sm-12 mb-3">
									<div className="form-group">
										<label htmlFor="phone">Password</label>
										<input
											onChange={e => setpwd(e.target.value)}
											type="password"
											className="form-control"
											id="password"
											name="password"
											placeholder="*******"
										/>
									</div>
								</div>

								<div className="col-sm-12 mb-3">
									<div className="form-group">
										<label htmlFor="phone">Confirm Password</label>
										<input
											onChange={e => setconfrimpwd(e.target.value)}
											type="password"
											className="form-control"
											id="confirmpassword"
											name="password"
											placeholder="*******"
										/>
									</div>
								</div>

								<div className="col-sm-12 mb-3">
									<div className="form-group">
										<label htmlFor="phone">Linkedin Profile URL</label>
										<input
											onChange={e => setlinkedinUrl(e.target.value)}
											type="text"
											className="form-control"
											id="phone"
											name="phone"
											placeholder="linkedin profile....."
										/>
									</div>
								</div>

								<div className="col-sm-12 mb-3">
									<input
										type="submit"
										value="Sign Up"
										name="submit"
										className="btn btn-primary"
										style={{ width: '100%' }}
									/>
								</div>
								
							</div>
						</form>
					</div>{' '}
					{/* End Contact Form Wrapper */}
					<div className="col-lg-5" style={{ overflow: 'hidden' }}>
						<img
							style={{ objectFit: 'contain', width: '80%' }}
							src={SignupImage}
							alt="signupimage"
						/>
					</div>
				</div>
			</div>
		</>
	);
}
