import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { deleteSuggestedTopic, allSuggestedTopic } from '../Api';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy, FaCheckCircle } from 'react-icons/fa';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Moment from 'react-moment';

function SuggestedQuotes() {
	const [topicData, setTopicDataata] = useState([]);

	const [copyId, setcopyId] = useState(null);

	useEffect(async () => {
		suggestedQuotes();
	}, []);

	const suggestedQuotes = async () => {
		const res = await allSuggestedTopic();
		if (res && res.res && res.res.data && res.res.data.suggestedTopics) {
			const data = res.res.data.suggestedTopics.map(item => ({
				_id: item._id,
				topic: item.topic,
				desc: item.desc,
				byAuthor: item.suggestedBy ? item.suggestedBy.name : '',
				createdAt: item.createdAt
			}));
			setTopicDataata(data);
		}
	};

	return (
		<div className="dashboard-container admin-campaign">
			<div className="container">
				<div className="row text-align-center">
					<div className="col-lg-12">
						<h3 className="last-campaign admin-last-campaign text-left">
							~~ Suggested Topic List ~~
						</h3>
					</div>
				</div>
			</div>

			<br />

			<table className="table table-bordered">
				<thead>
					<tr>
						<th scope="col" className="font-weight-bold">
							Sr.
						</th>
						<th scope="col" className="font-weight-bold">
							Topic
						</th>
						<th scope="col" className="font-weight-bold">
							Description
						</th>
						<th scope="col" className="font-weight-bold">
							Suggested By
						</th>
						<th scope="col" className="font-weight-bold">
							Suggested Date
						</th>

						<th scope="col" className="font-weight-bold">
							Actions
						</th>
					</tr>
				</thead>
				<tbody>
					{topicData &&
						topicData.map((item, index) => (
							<tr key={index}>
								<th scope="row">{index + 1}</th>
								<td>
									<span>
										<strong>{item.topic}</strong>
									</span>
								</td>
								<td>{item.desc}</td>
								<td>{item.byAuthor && item.byAuthor}</td>
								<td>
									<Moment format="DD/MM/YYYY">{item.createdAt}</Moment>
								</td>
								<td style={{ alignItems: 'center' }}>
									<div className="d-flex">
										<Link to={`/edit-suggested-topic-admin/${item._id}`}>
											<EditIcon />
										</Link>
										&emsp;
										<DeleteIcon
											onClick={async () => {
												await deleteSuggestedTopic(item._id);
												alert('Deleted');
												suggestedQuotes();
											}}
										/>
										&emsp;
									</div>
								</td>
							</tr>
						))}
				</tbody>
			</table>
		</div>
	);
}

export default SuggestedQuotes;
