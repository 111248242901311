import React, { useState, useEffect } from "react";
import {
  suggestQuote,
  getQuoteCategory,
  getUpcomingCampaignForUser,
  postQuoteForUpcomingCampaign,
  getAllUserAuthor,
} from "../Api";
import { Helmet } from "react-helmet-async";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Button, Alert, Image } from "react-bootstrap";
import "./SuggestQuote.css";
import "./quotes.scss";

export default function SuggestQuoteCategory() {
  const [checked, setChecked] = useState(false);
  const [isError, setisError] = useState(null);
  const [isSuccess, setisSuccess] = useState(null);
  const [userSuggestedQuote, setuserSuggestedQuote] = useState(null);
  const [quoteAuthorName, setquoteAuthorName] = useState(null);
  const [userDetail, setuserDetail] = useState(null);
  const [hashtagString, sethashtagString] = useState(null);
  const [selectedCategory, setselectedCategory] = useState("Choose Category");
  const [selectedCategoryId, setselectedCategoryId] = useState("");
  const [isState, setIsState] = useState("");
  const router = useHistory();
  const params = useParams();
  const [selectedCampaign, setselectedCampaign] = useState(
    "Select the Upcoming Campaign"
  );
  const [selectedCampaignId, setselectedCampaignId] = useState("");
  const [selectedOption, setselectedOption] = useState("Select");
  const [selectedAuthor, setSelectedAuthor] = useState("");
  const [authors, setauthors] = useState([]);

  const [categoryAll, setcategoryAll] = useState(null);
  const [upcomingCampaign, setUpcomingCampaign] = useState([]);
  const [showCampaign, setShowCampaign] = useState(false);
  const [userData, setUserData] = useState(null);

  const handleChange = () => {
    setquoteAuthorName(userDetail.name);
    // if (authors && authors.length > 0) {
    // 	const authDetail = authors.find(
    // 		item => item.userId === userDetail.userId
    // 	);
    // 	setSelectedAuthor(authDetail._id);
    // }
    setChecked(false);
  };

  const sendSuggestedQuote = async () => {
    setIsState("loading");
    let data = {
      quoteMsg: userSuggestedQuote,
      quoteAuthor: quoteAuthorName,
      hashTags: hashtagString,
      quoteCategoryId: selectedCategoryId,
    };

    let res = await suggestQuote(data);

    if (res.error) {
      setisError(true);
      setisSuccess(false);
      alert("Quote Not Submited");
    } else {
      setisSuccess(true);
      setisError(false);
      setIsState("submitted");
    }
  };
  console.log(params);
  useEffect(() => {
    if (params.campaign) {
      setselectedCampaign(params.campaign);
      setselectedCampaignId(params.id);
      setShowCampaign(true);
    }
  }, [0]);
  const quoteForUpcomingCamp = async () => {
    setIsState("loading");
    let author_id;
    if (authors && authors.length > 0) {
      const authDetail = authors.find(
        (item) => item.userId === userDetail.userId
      );
      console.log(authDetail);
      author_id = authDetail?._id;
      if (authDetail) {
        let data = {
          quoteMsg: userSuggestedQuote,
          authorId: author_id,
          // authorName: '' for custom author
        };

        let res = await postQuoteForUpcomingCampaign(selectedCampaignId, data);
        console.log(selectedCampaign, data);
        if (res.error) {
          setisError(true);
          setisSuccess(false);
        } else {
          setisSuccess(true);
          setisError(false);
          setIsState("submitted");
        }
      }
    }
  };

  useEffect(() => {
    var data = JSON.parse(localStorage.getItem("InspirerWorld"));
    setUserData(data);
    if (data) {
      setuserDetail(data);
      console.log("Data is", data);
      getCategory();
    } else {
      router.push({
        pathname: "/login",
        state: {
          type: "suggestquote",
          url: "/suggestquote/" + params.campaign + "/" + params.id,
        },
      });
    }
    return () => {};
  }, [1]);

  const getCategory = async () => {
    let res = await getQuoteCategory();
    let res1 = await getUpcomingCampaignForUser();
    const res3 = await getAllUserAuthor();
    if (res3 && res3.res && res3.res.data) {
      setauthors(res3.res.data.authors);
      console.log(authors, userDetail);
    }
    if (res.error) {
    } else {
      console.log("Res category is", res1);
      setcategoryAll(res.data.quoteCategories);
      setUpcomingCampaign(res1.res.data.upcomingCampaigns.reverse());
    }
  };

  return (
    <>
      {userData && (
        <>
          {" "}
          <Helmet>
            <title>Suggesting Quote</title>
          </Helmet>
          {/* <ProfileStatsBar /> */}
          <div className="container custom-suggest-quote mt-5 suggestQuotes">
            <div className="row">
              <div
                className="col-lg-9 col-md-12"
                style={{ paddingLeft: 0, paddingRight: 0, marginTop: "-10px" }}
              >
                <div style={{ margin: "10px" }}>
                  <Autocomplete
                    style={{ width: "100%" }}
                    multiple={false}
                    options={upcomingCampaign}
                    defaultValue={{
                      title: selectedCampaign,
                    }}
                    onChange={(e, item) => {
                      if (item && item.title) {
                        setselectedCampaign(item.title);
                        setselectedCampaignId(item._id);
                        router.push({
                          pathname:
                            "/suggestquote/" +
                            item.title.replaceAll(" ", "-") +
                            "/" +
                            item._id,
                        });
                        setShowCampaign(true);
                        setIsState("");
                        setisSuccess(false);
                      } else {
                        setselectedCampaign("Select the Upcoming Campaign");
                        setselectedCampaignId("");
                        setShowCampaign(false);
                        setIsState("");
                        setisSuccess(false);
                      }
                    }}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className=" choosing-option-autocomplete"
                        variant="standard"
                        label=""
                        placeholder={"Campaign..."}
                      />
                    )}
                  />
                </div>
                <div className="button-half-div" style={{ display: "none" }}>
                  <button
                    className="btn2 rounded btn-danger"
                    style={{ backgroundColor: "#f53d55" }}
                  >
                    <div className="dropdown">
                      <button
                        style={{
                          border: "none",
                          padding: 0,
                        }}
                        className="btn btn-secondary long-text-wrap dropdown-toggle navbar-dropdown"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <strong>{selectedCampaign} </strong>
                      </button>
                      <div
                        className="dropdown-menu text-center res-left"
                        style={{
                          padding: "10px",
                          cursor: "pointer",
                          overflow: "scroll",
                          maxHeight: "400px",
                        }}
                        aria-labelledby="dropdownMenuButton"
                      >
                        {upcomingCampaign &&
                          upcomingCampaign.map((item, index) => {
                            if (index < 10) {
                              return (
                                <p
                                  onClick={() => {
                                    setselectedCampaign(item.title);
                                    setselectedCampaignId(item._id);
                                    router.push({
                                      pathname:
                                        "/suggestquote/" +
                                        item.title.replaceAll(" ", "-") +
                                        "/" +
                                        item._id,
                                    });
                                    setShowCampaign(true);
                                  }}
                                >
                                  <li>{item.title}</li>
                                </p>
                              );
                            }
                          })}
                      </div>
                    </div>
                  </button>
                </div>

                <div className="suggest-quote-user-profile-container">
                  {userDetail && (
                    <Image
                      className="suggestquote-user-profile-picture"
                      src={userDetail.profileImg}
                      rounded
                      roundedCircle
                      thumbnail
                      fluid
                    />
                  )}
                  <strong
                    className="suggestquote-user-name"
                    style={{ fontWeight: "bold" }}
                  >
                    &nbsp;{userDetail && userDetail.name} |&nbsp;
                    {!showCampaign
                      ? "Suggesting Quote"
                      : "Suggest for Upcoming Campaign"}
                  </strong>
                </div>
                {!showCampaign ? (
                  <p className="suggestMsg">
                    Before adding a new quote please first do a search and make
                    sure it doesn't already exist in the database.
                  </p>
                ) : (
                  <p
                    className="suggestMsg"
                    style={{ color: "#fb5757 ", fontWeight: "bold" }}
                  >
                    If you suggest a quote for the weekly campaigns, it should
                    be genuinely your creation. We should not accept plagiarized
                    or copy-paste quotes.
                  </p>
                )}

                {!showCampaign ? (
                  <div>
                    <div
                      style={{
                        padding: "10px",
                      }}
                      className="form-group"
                    >
                      <textarea
                        onChange={(e) => setuserSuggestedQuote(e.target.value)}
                        className="form-control suggest-quote-quote-textarea"
                        placeholder="your quote here..."
                        style={{ background: "#e5e5e5", borderRadius: "0px  " }}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "10px",
                        alignItems: "stretch",
                      }}
                      className="form-group"
                    >
                      <div className="quoteSuggestLabel">
                        Tags{" "}
                        <p className="suggestMsg">
                          (Comma separated: inspirational, science, humor, etc.
                          Limit 10)
                        </p>
                      </div>

                      <div style={{ width: "100%" }}>
                        <input
                          onChange={(e) => sethashtagString(e.target.value)}
                          className="form-control addhashtag"
                          style={{
                            background: "#e5e5e5",
                            borderRadius: "0px",
                            padding: "5px 20px",
                          }}
                        />
                      </div>
                    </div>

                    {checked && (
                      <div
                        style={{
                          padding: "10px",
                        }}
                        className="form-group author-field"
                      >
                        <input
                          onChange={(e) => setquoteAuthorName(e.target.value)}
                          className="form-control"
                          placeholder="Author Name"
                        />
                      </div>
                    )}
                    {checked && (
                      <div
                        id="suggestQuotesID"
                        className="searchInput form-group"
                        style={{ margin: "0 10px", display: "none" }}
                      >
                        <Autocomplete
                          style={{ width: "100%" }}
                          multiple={false}
                          options={authors}
                          defaultValue={{
                            title: selectedOption,
                          }}
                          onChange={(e, item) => {
                            if (item && item.name) {
                              setSelectedAuthor(item._id);
                              setselectedOption(item.title);
                            } else {
                              setSelectedAuthor();
                              setselectedOption("Author Name...");
                            }
                          }}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className=" choosing-option-autocomplete"
                              variant="standard"
                              label=""
                              placeholder={"Author Name..."}
                            />
                          )}
                        />
                      </div>
                    )}

                    {isError && (
                      <Alert variant="danger">Oops Some Error Occurred !</Alert>
                    )}

                    {isSuccess && (
                      <Alert variant="success">
                        Quote Submitted Successfully !
                      </Alert>
                    )}

                    <div className="button-half-div">
                      <button
                        variant="info"
                        className="btn1 btn3 rounded"
                        onClick={() => {
                          document.getElementById(
                            "authorbtn"
                          ).style.background = "#146d9c";
                          setChecked(!checked);
                        }}
                        style={{ fontWeight: "bold" }}
                      >
                        {" "}
                        Quote Author Name
                      </button>

                      <button
                        variant="info"
                        id="authorbtn"
                        className="btn2 btn3 rounded"
                        onClick={() => {
                          document.getElementById(
                            "authorbtn"
                          ).style.background = "green";
                          handleChange();
                        }}
                        style={{ fontWeight: "bold" }}
                      >
                        I'm the author*
                      </button>
                    </div>

                    <div className="suggest-quote-submit-button-div">
                      {isState == "loading" ? (
                        <Button variant="info" className="btn3_submit">
                          Loading....
                        </Button>
                      ) : isState === "submitted" ? (
                        <Button variant="info" className="btn3_submit" disabled>
                          Quote Submitted
                        </Button>
                      ) : (
                        <Button
                          variant="info"
                          className="btn3_submit"
                          onClick={() => sendSuggestedQuote()}
                        >
                          Submit Quotess
                        </Button>
                      )}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div
                      style={{
                        padding: "10px",
                      }}
                      className="form-group"
                    >
                      <textarea
                        onChange={(e) => setuserSuggestedQuote(e.target.value)}
                        className="form-control suggest-quote-quote-textarea"
                        placeholder="your quote here..."
                      />
                    </div>

                    {isError && (
                      <Alert variant="danger">Oops Some Error Occurred !</Alert>
                    )}

                    {isSuccess && (
                      <Alert variant="success">
                        Quote Submitted Successfully !
                      </Alert>
                    )}

                    <div
                      className="button-half-div"
                      style={{ display: "none" }}
                    >
                      <button
                        variant="info"
                        className="btn1 btn3 rounded"
                        onClick={() => {
                          document.getElementById("imAuthor").style.background =
                            "#146d9c";
                          setChecked(!checked);
                        }}
                      >
                        {" "}
                        Quote Author Name
                      </button>

                      <button
                        variant="info"
                        id="imAuthor"
                        className="btn2 btn3 rounded"
                        onClick={() => {
                          document.getElementById("imAuthor").style.background =
                            "green";
                          handleChange();
                        }}
                      >
                        I am the Author*
                      </button>
                    </div>

                    <div className="suggest-quote-submit-button-div">
                      {isState == "loading" ? (
                        <Button variant="info" className="btn3_submit">
                          Loading....
                        </Button>
                      ) : isState === "submitted" ? (
                        <Button variant="info" className="btn3_submit" disabled>
                          Quote Submitted
                        </Button>
                      ) : (
                        <Button
                          variant="info"
                          className="btn3_submit"
                          onClick={() => quoteForUpcomingCamp()}
                        >
                          Submit Quote
                        </Button>
                      )}
                    </div>
                  </div>
                )}
              </div>

              {/* <SidebarTrending /> */}
              <div className="col-lg-3 quotesGuidLine">
                <div>
                  <h4>Quote Guidelines</h4>
                  <ul>
                    <li>
                      This website accepts quotes from notable people.
                      Typically, a person is notable if they qualify and have
                      been the subject of published secondary source material
                      that is authentic, intellectually independent, and
                      independent of the subject.
                    </li>
                    <li>
                      The quotes can be accepted from different sources like
                      books, spoken words, news articles, etc provided they meet
                      the above criteria.
                    </li>
                    <li>
                      For entering the quote, don't enter the birthdate or which
                      the quote has taken from; only enter the author's field,
                      and you will get the quote.
                    </li>
                    <li>
                      Please don't use spaces between the initials (H.G. Wells,
                      J.K. Rowling); if you enter the author names.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
