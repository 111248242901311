import React, { useState, useEffect } from 'react'
import SignupImage from '../../images/signup.jpg'
import { SignupUser, UploadCampaign } from '../Api'
import { Modal, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import ImageCropper from '../ImageCropper/ImageCropper'
import uploadImageCampaign from './uploadImageCampaign'

export default function CreateCampaign() {
  
  let location=useHistory();
  const [loading, setLoading] = useState(false)
  const [name, setname] = useState('')
  const [targetlikes, settargetlikes] = useState('')
  const [targetcomment, settargetcomment] = useState('')
  const [targetviews, settargetviews] = useState('')
  const [startdate, setstartdate] = useState('')
  const [campDesc, setcampDesc] = useState('')
  const [dimensions, setDimensions] = useState()
  const [scaleX, setscaleX] = useState(0)
  const [scaleY, setscaleY] = useState(0)

  const [selectedImage, setSelectedImage] = useState("");
  const [show, setShow] = useState(false);
  const [Error, setError] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const update_dimensions = (data,scaleX,scaleY) => {
    setDimensions(data)
    setscaleX(scaleX)
    setscaleY(scaleY)
    console.log("Parent =>", data)
  }
  const handleSignup = async (e) => {
    e.preventDefault()
    setLoading(true)
    const myUploadedImage = await uploadImageCampaign(selectedImage)
   const data = {
    "title":name,
    "desc":campDesc,
    "startsOn":startdate,
    "artWork":{
        "imgUrl":myUploadedImage,
        "x":dimensions.x*scaleX,
        "y":dimensions.y*scaleY,
        "h":dimensions.height*scaleY,
        "w":dimensions.width*scaleX
    }
  }
  const res=await UploadCampaign(data)
  if(res.status===201){
    setLoading(false)
    location.push({
      pathname: '/admin-campaign',
      state: { detail: 'Success' }
    })
  }
  else{
    setLoading(false)
    alert(" Something went Wrong")
    
  }

  }


  const updateImage = (data) => {
    setSelectedImage(data)
  }

  return (
    <div className="container">
      <div className="row">

        <div className="col-lg-1"></div>
        <br />
        <br />
        <br />

        <div className="col-lg-10 mb-2 contact-form__wrapper p-1 order-lg-1">

          <center>
            <h2>Create Camapign</h2>
          </center>

          <br />



          <form action="#" className="contact-form form-validate" onSubmit={handleSignup}>
            <div className="row">
              <div className="col-sm-12 mb-3">
                <div className="form-group">
                  <label className="required-field" htmlFor="firstName">Camapaign Name</label>
                  <input onChange={(e) => setname(e.target.value)} type="text" className="form-control" id="firstName" name="firstName" placeholder="title" />
                </div>
              </div>

              <div className="col-sm-6 mb-3">
                <div className="form-group">
                  <label className="required-field" htmlFor="email">Target Likes</label>
                  <input onChange={(e) => settargetlikes(e.target.value)} type="text" className="form-control" id="email" name="email" placeholder="target likes" />
                </div>
              </div>



              <div className="col-sm-6 mb-3">
                <div className="form-group">
                  <label htmlFor="phone">Target Comments</label>
                  <input onChange={(e) => settargetcomment(e.target.value)} type="text" className="form-control" id="phone" name="phone" placeholder="Target Comments" />
                </div>
              </div>


              <div className="col-sm-6 mb-3">
                <div className="form-group">
                  <label htmlFor="phone">Target Views</label>
                  <input onChange={(e) => settargetviews(e.target.value)} type="text" className="form-control" id="phone" name="phone" placeholder="Target Views" />
                </div>
              </div>



              <div className="col-sm-6 mb-3">
                <div className="form-group">
                  <label htmlFor="phone">Started On</label>
                  <input type="date" onChange={(e) => setstartdate(e.target.value)} className="form-control" id="phone" name="phone" placeholder="Date of campaign" />
                </div>
              </div>



              <div className="col-sm-12 mb-3">
                <div className="form-group">
                  <label htmlFor="phone">Campaign Description</label>
                  <textarea onChange={(e) => setcampDesc(e.target.value)} type="text" className="form-control" id="phone" name="phone" placeholder="description" />
                </div>
              </div>


              <div className="col-sm-12 mb-3">
                <div className="form-group">
                  <label htmlFor="phone">ArtWork : </label> &nbsp;
                        <ImageCropper update_dimensions={(item,scaleX,scaleY) => update_dimensions(item,scaleX,scaleY)} updateImage={(item)=>updateImage(item)}/>
                </div>
              </div>


              <div className="col-sm-12 mb-3">
              {!loading?
                <input
                type="submit"
                value="Launch Campaign"
                name="submit"
                className="btn btn-primary"
                />
              : <input
              type="submit"
              value="Loading..."
              name="submit"
              className="btn btn-primary"
              />
              }
              </div>
           
            </div>
          </form>
        </div> {/* End Contact Form Wrapper */}




      </div>
    </div>
  )
}
