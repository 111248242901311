import React from 'react';
import PreviousCampaignClaimDiv from './PreviousCampaignClaimDiv';
import { Link } from 'react-router-dom';
export default function PreviousCampaignDiv({ campaignData }) {
	// console.log('Campaign Data is',campaignData)

	return (
		<div>
			<button className="suggest-quote-button sidebar-heading-button radius-5">
				<strong style={{ fontSize: '14px', fontWeight: 'bold' }}>
					Previous Campaign
				</strong>
				<Link style={{ color: 'white', fontWeight: '700' }} to="/campaigns">
					See all
				</Link>
			</button>

			<div className="campaign-list-sidebar">
				{campaignData &&
					campaignData.map((item, index) => {
						return <PreviousCampaignClaimDiv item={item} key={index} />;
					})}
			</div>
		</div>
	);
}
