import resizeImg from "./resizeImg";

const resizeProfilePic = async (picture, item) => {
    // console.log("Phase 2")
    let temp_img = null
    new Promise((resolve, reject) => {
      var img=new Image();
      img.crossOrigin='anonymous';
      img.src=picture;
      
      img.onload = new Promise(async (resolve, reject) => {
        // console.log("Phase 3"); console.log(img.src);
        temp_img = resizeImg(img,item.artWork.h,item.artWork.w);
        // console.log("Phase 8", temp_img);
        return temp_img
      
      })
      img.src=picture;
    })
    // console.log("Phase 9", temp_img)s
        return temp_img
  }
  export default resizeProfilePic