import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import {  getMyTeams } from '../Api'



export default function MyTeam() {

    const [searchTerm, setsearchTerm] = useState('')
    const [entryToShow, setentryToShow] = useState(25)
    const [teamList, setteamList] = useState(null)


    const getTeams= async ()=>{

        let res= await getMyTeams()
        if(res.error){
          console.log(res.error)
        }
        else{
         setteamList(res.res.data.teams)
      
         console.log(res.res.data.teams)
       }
      }

      useEffect(()=>{
      
        getTeams()
      },[])





    return (
        <div>
            


            <div className='dashboard-main-container'>
      <div className='teams-title'>
        <h3 className='last-campaign admin-last-campaign text-left'>
          My Teams List
        </h3>
      
      </div>

      <div className='dashboard-container admin-campaign'>
        <div className='teams-search'>
          {/* <label className='teams-select'>
            Show
            <select
              name='datatable-buttons_length'
              aria-controls='datatable-buttons'
              className='custom-select teams-custom-select'
              onChange={e=>setentryToShow(e.target.value)}
            >
              <option  value='10'>10</option>
              <option  value='25'>25</option>
             
            </select>
            entries
          </label> */}

          <label className='teams-searchbar'>
            Search:
            <input
              type='search'
              className='form-control form-control-sm'
              placeholder=''
              aria-controls='datatable-buttons'
              onChange={e=>setsearchTerm(e.target.value)}
            />
          </label>
        </div>

        <div className='container'>
          <div className='row text-align-center'>
            <div className='col-lg-6'></div>

            <div className='col-lg-6'>
              <h3 className='last-campaign admin-last-campaign text-right'></h3>
            </div>
          </div>
        </div>

        <br />

        <table className='table table-bordered'>
          <thead>
            <tr>
              <th
                scope='col'
                className='font-weight-bold'
                style={{ width: '10%' }}
              >
                <div className='up-down-container'>
                <strong> Sr.</strong>
                 
                </div>
              </th>
              <th
                scope='col'
                className='font-weight-bold'
                style={{ width: '80%' }}
              >
                <div className='up-down-container'>
                 <strong> Team Name</strong>
                 
                </div>
              </th>
              <th scope='col' className='font-weight-bold'>
                <div className='up-down-container'>
                <strong>Action</strong>
                 
                </div>
              </th>
            </tr>
          </thead>
          <tbody>


            { teamList && teamList
              .filter((item,index)=>{
                if(item.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) && index<entryToShow)
                  return item
              })
              .map((item,index)=>{
              return(
                <tr key={index+1}>
              <th scope='row'>{index+1}</th>
              <td  style={{textTransform : 'capitalize'}}><Link to={`/myteamlist/${item._id}/${item.title}`}>{item.title}</Link></td>
              <td>
              <Link to={`/myteamlist/${item._id}/${item.title}`}> <img className='teams-views' src='images/views.svg' alt='' />
              </Link></td>
            </tr>
              )
            }) }
            

          </tbody>
        </table>




        </div>
        </div>
        </div>
    )
}
