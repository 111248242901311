import React, { useState, useEffect } from "react";
import ProfileInput from "./ProfileInput";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { Modal, Button } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import axios from "axios";
import { Link } from "react-router-dom";
import { updateUserProfile, getUserCampaigns, getUserQuotes } from "../Api";
import Moment from "react-moment";
import { Avatar } from "@material-ui/core";
//import likes from '../../../public/images/likes.svg'
export default function ProfileDisplay({ profile }) {
  console.log(profile);

  const [allCampaigns, setallCampaigns] = useState([]);
  const [allQuotes, setallQuotes] = useState([]);
  const [show, setShow] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [modalTitle, setmodalTitle] = useState("");
  const [modalMsg, setmodalMsg] = useState("");

  const handleClose = () => {
    setShow(false);
    setshowModal(false);
  };
  const handleShow = () => setShow(true);
  const [userImage, setuserImage] = useState(null);
  const [userImageUrl, setuserImageUrl] = useState(profile.profileImg);

  const [email, setemail] = useState(profile.email);
  const [whatsapp, setwhatsapp] = useState(profile.whatsapp);
  const [linkedinUrl, setlinkedinUrl] = useState(profile.linkedinUrl);
  const [instagramUrl, setinstagramUrl] = useState(profile.instagramUrl);

  useEffect(() => {
    getUser();
    getUserQuote();
    return () => {};
  }, []);

  //  const readFile = (file) => {

  //       const reader = new FileReader();
  //       reader.addEventListener(
  //         "load",
  //         () => {
  //           //resolve(reader.result);
  //         },
  //         false
  //       );
  //       reader.readAsDataURL(file);

  //   };

  const getUser = async () => {
    const res = await getUserCampaigns(profile._id);

    if (res.error) {
      // setmodalHeading('Oops')
      // setmodalText(res.data)
      // setmodalButton('Okay !')
      // setShow(true)
      // return setError(true)
    } else {
      console.log("user Camp data is", res.res.data);
      setallCampaigns(res.res.data.campaigns);
    }
  };
  const getUserQuote = async () => {
    const res = await getUserQuotes(profile._id);
    console.log(res, "quotes");
    if (res.error) {
      // setmodalHeading('Oops')
      // setmodalText(res.data)
      // setmodalButton('Okay !')
      // setShow(true)
      // return setError(true)
    } else {
      console.log("user Camp data is", res.res.data);
      setallQuotes(res.res.data.quotes);
    }
  };
  const imageChange = (image) => {
    setuserImage(image);
    console.log("File is", image);
    const url = URL.createObjectURL(image);
    setuserImageUrl(url);
  };

  const convertImgTo64 = async () => {
    await axios
      .get(userImage, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        let newImg =
          `data:image/jpeg;base64,` +
          Buffer.from(response.data, "binary").toString("base64");
        setuserImage(newImg);
      });
  };

  const UpdateProfile = async () => {
    // const res = await updateUserProfile()
    // await convertImgTo64()
    console.log("user Image is", userImage);
  };

  return (
    <div className="profile-display">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="file"
            id="myfile"
            name="myfile"
            onChange={(e) => {
              imageChange(e.target.files[0]);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Upload</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMsg}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Okay</Button>
        </Modal.Footer>
      </Modal>

      <div className="row m-auto">
        <div className="user-img col-md-4  ml-5 col-12">
          <div className="dp ">
            <img src={userImageUrl} alt="user-img" />
          </div>
        </div>
        <div className="stats col-md-3 col-12">
          <div className="stat  m-4">
            <img src="images/views.svg" alt="views" />
            <h6>{profile.views}</h6>
          </div>
          <div className="stat  m-4">
            <img src="images/comments.svg" alt="views" />
            <h6>{profile.comments}</h6>
          </div>
          <div className="stat  m-4">
            <img src="images/likes.svg" alt="views" />
            <h6>{profile.likes}</h6>
          </div>
        </div>
      </div>

      <div className="profile m-auto profile-edit p-2">
        <div className="col-2">
          <Avatar alt="Remy Sharp" src={userImageUrl} />
        </div>
        <div className="col-10 ml-2">
          <h4 style={{color:"white"}}>{profile.name}</h4>
        </div>
      </div>

      <div className="profile m-auto profile-edit p-2">
        <div className="col-2">
          <img className="input-field-icon" src="/images/email.svg" />
        </div>
        <div className="col-10 ml-2">
          <h4 style={{color:"white"}}>{email}</h4>
        </div>
      </div>
      <div className="profile m-auto profile-edit p-2">
        <div className="col-2">
          <img className="input-field-icon" src="/images/phone.svg" />
        </div>
        <div className="col-10 ml-2">
          <h4 style={{color:"white"}}>{profile.phoneNumber}</h4>
        </div>
      </div>
      <div className="profile m-auto profile-edit p-2">
        <div className="col-2">
          <img className="input-field-icon" src="/images/linkedin.svg" />
        </div>
        <div className="col-10 ml-2">
          <h4 style={{color:"white"}}>{linkedinUrl}</h4>
        </div>
      </div>

      <div className="profile m-auto profile-edit p-2 mt-5">
        <div className="col-2">
          <img className="input-field-icon" src="/images/instagram.svg" />
        </div>
        <div className="col-10 ml-2">
          <h4 style={{color:"white"}}>{instagramUrl}</h4>
        </div>
      </div>

      <div className="container">
       <div>
          <div className="dashboard-container admin-campaign">
            <div className="container">
              <div className="row text-align-center">
                <div className="col-lg-6">
                  <h3 className="last-campaign admin-last-campaign text-left">
                    ~~User Campaigns List ~~
                  </h3>
                </div>
              </div>
            </div>

            <br />

            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col" className="font-weight-bold">
                    Sr.
                  </th>
                  <th scope="col" className="font-weight-bold">
                    Campaign Title
                  </th>
                  <th scope="col" className="font-weight-bold">
                    Started On
                  </th>
                  <th scope="col" className="font-weight-bold">
                    Initiated By
                  </th>

                  <th scope="col" className="font-weight-bold">
                    Artwork
                  </th>
                </tr>
              </thead>
              <tbody>
                {allCampaigns &&
                  allCampaigns.map((item, index) => {
                    return (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{item.title}</td>
                        <td>
                          <Moment format="DD/MM/YYYY">{item.startsOn}</Moment>
                        </td>
                        <td>Admin</td>
                        <td>
                          <img
                            style={{ maxWidth: "70px" }}
                            src={item.artWork.imgUrl}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>

        <div className="dashboard-container admin-campaign">
          <div className="container">
            <div className="row text-align-center">
              <div className="col-lg-6">
                <h3 className="last-campaign admin-last-campaign text-left">
                  ~~User Quote List ~~
                </h3>
              </div>
            </div>
          </div>

          <br />

          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col" className="font-weight-bold">
                  Sr.
                </th>
                <th scope="col" className="font-weight-bold">
                  Quote Title
                </th>
                <th scope="col" className="font-weight-bold">
                  Started On
                </th>
                <th scope="col" className="font-weight-bold">
                  Initiated By
                </th>

                <th scope="col" className="font-weight-bold">
                  Artwork
                </th>
              </tr>
            </thead>
            <tbody>
              {allQuotes &&
                allQuotes.map((item, index) => {
                  return (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{item.title}</td>
                      <td>
                        <Moment format="DD/MM/YYYY">{item.startsOn}</Moment>
                      </td>
                      <td>Admin</td>
                      <td>
                        <img
                          style={{ maxWidth: "70px" }}
                          src={item.artWork.imgUrl}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
