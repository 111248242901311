import React, { useState, useEffect } from 'react';
import { FaLinkedin, FaWhatsapp } from 'react-icons/fa';
import EditIcon from '@material-ui/icons/Edit';
import Pagination from '../Pagination/index';

import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import {
	ActivatedUser,
	DeactivatedUser,
	getAllUsers,
	getDeactivatedUsers
} from '../Api';
export default function AlDeactivatedMember(props) {
	useEffect(() => {
		getUsers();
	}, []);
	const [searchTerm, setsearchTerm] = useState('');
	const [showSuccess, setshowSuccess] = useState(false);
	const [page, setPage] = useState(0);
	const [totalpage, setTotalPage] = useState(0);
	const [memberList, setmemberList] = useState([]);
	const [entryToShow, setentryToShow] = useState(null);
	const [entry, setEntry] = useState(10);
	const [memberListFilter, setmemberListFilter] = useState([]);
	const location = useLocation();
	const params = useParams();
	useEffect(() => {
		if (location.state) {
			setshowSuccess(true);
		}
		setInterval(() => {
			setshowSuccess(false);
		}, 3000);
	}, [location.pathname]);
	console.log('here');
	const getUsers = async () => {
		let res = await getDeactivatedUsers();
		if (res.error) {
			console.log(res.error);
		} else {
			setmemberList(res.res.data.users);

			setmemberListFilter(res.res.data.users);
		}
	};
	// useEffect(() => {
	// 	setTotalPage(Math.ceil(memberList.length / entry));
	// 	let array = memberList.slice(page * entry, (page + 1) * entry);
	// 	console.log(array);
	// 	setmemberListFilter(array);
	// }, [entry, page]);

	useEffect(() => {
		if (memberList.length > 0) {
			const filteredList = memberList.filter((item, index) => {
				if (
					item.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
				) {
					return item;
				}
			});
			setTotalPage(Math.ceil(filteredList.length / entry));
			let array = filteredList.slice(page * entry, (page + 1) * entry);
			// setAllMemberListFilter(filteredList);
			setmemberListFilter(array);
		}
	}, [entry, page, memberList, searchTerm]);

	return (
		<div>
			<div className="dashboard-main-container">
				<div className="teams-title">
					<h3
						className="last-campaign admin-last-campaign text-left"
						style={{ textTransform: 'capitalize' }}
					>
						All deactivated Members List
					</h3>
				</div>

				<div className="dashboard-container admin-campaign">
					<div className="teams-search">
						<label className="teams-select">
							Show
							<select
								name="datatable-buttons_length"
								aria-controls="datatable-buttons"
								className="custom-select teams-custom-select"
								onChange={e => {
									setEntry(e.target.value);
									setPage(0);
								}}
							>
								<option value="10">10</option>
								<option value="25">25</option>
								<option value="50">50</option>
								<option value="100">100</option>
							</select>
							entries
						</label>

						<label className="teams-searchbar">
							Search:
							<input
								type="search"
								className="form-control form-control-sm"
								placeholder=""
								aria-controls="datatable-buttons"
								onChange={e => setsearchTerm(e.target.value)}
							/>
						</label>
					</div>

					<div className="container">
						<div className="row text-align-center">
							<div className="col-lg-6"></div>

							<div className="col-lg-6">
								<h3 className="last-campaign admin-last-campaign text-right"></h3>
							</div>
						</div>
					</div>

					<br />

					<table className="table table-bordered">
						<thead>
							<tr>
								<th
									scope="col"
									className="font-weight-bold"
									style={{ width: '10%' }}
								>
									<div className="up-down-container">
										<strong> Sr.</strong>
										<div className="up-down-arrows"></div>
									</div>
								</th>
								<th
									scope="col"
									className="font-weight-bold"
									style={{ width: '80%' }}
								>
									<div className="up-down-container">
										<strong> Member Name</strong>
										<div className="up-down-arrows"></div>
									</div>
								</th>

								<th
									scope="col"
									className="font-weight-bold"
									style={{ width: '80%' }}
								>
									<div className="up-down-container">
										<strong> Email</strong>
										<div className="up-down-arrows"></div>
									</div>
								</th>
								<th
									scope="col"
									className="font-weight-bold"
									style={{ width: '80%' }}
								>
									<div className="up-down-container">
										<strong> Regular Content Creator </strong>
										<div className="up-down-arrows"></div>
									</div>
								</th>

								<th
									scope="col"
									className="font-weight-bold"
									style={{ width: '80%' }}
								>
									<div className="up-down-container">
										<strong> Activeness</strong>
										<div className="up-down-arrows"></div>
									</div>
								</th>
								<th
									scope="col"
									className="font-weight-bold"
									style={{ width: '80%' }}
								>
									<div className="up-down-container">
										<strong> Edit </strong>
										<div className="up-down-arrows"></div>
									</div>
								</th>

								<th
									scope="col"
									className="font-weight-bold"
									style={{ width: '80%' }}
								>
									<div className="up-down-container">
										<strong> Social</strong>
										<div className="up-down-arrows"></div>
									</div>
								</th>
								<th
									scope="col"
									className="font-weight-bold"
									style={{ width: '80%' }}
								>
									<div className="up-down-container">
										<strong> Active </strong>
										<div className="up-down-arrows"></div>
									</div>
								</th>

								{/* <th scope="col" className="font-weight-bold">
                  <div className="up-down-container">
                    <strong>Action</strong>
                    <div className="up-down-arrows"></div>
                  </div>
                </th> */}
							</tr>
						</thead>
						<tbody>
							{memberListFilter &&
								memberListFilter
									.filter((item, index) => {
										if (
											item.name
												.toLocaleLowerCase()
												.includes(searchTerm.toLocaleLowerCase()) &&
											item.isContentCreator === true
										) {
											return item;
										}
									})
									.map((item, index) => {
										if (item.isContentCreator) {
											return (
												<tr key={index + 1}>
													<th scope="row">{entry * page + index + 1}</th>
													<td>
														<Link
															to={
																'/allmembers/' +
																item.name +
																'/profile/' +
																item._id
															}
														>
															{item.name}
														</Link>
													</td>
													<td>{item.email}</td>
													<td>{item.isContentCreator ? 'Yes' : 'No'}</td>

													<td>{item.activity}%</td>
													<td>
														<Link
															to={`/allmembers/${item.name}/profile/${item._id}`}
														>
															<EditIcon />
														</Link>
													</td>

													<td>
														<div
															style={{
																display: 'flex',
																justifyContent: 'space-between',
																alignItems: 'center'
															}}
														>
															<a
																href={`https://wa.me/${item.phoneNumber}?text=`}
																target="_blank"
															>
																<img
																	width="30"
																	src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
																/>
															</a>
															<a href={item.linkedinUrl} target="_blank">
																<img
																	width="25"
																	src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c9/Linkedin.svg/1200px-Linkedin.svg.png"
																/>
															</a>
														</div>
													</td>
													<td>
														{item.type === 'user' ? (
															item.deactivated ? (
																<button
																	className="btn btn-active btn-info create-team-btn"
																	onClick={async () => {
																		await ActivatedUser(item._id);
																		getUsers();
																	}}
																>
																	Activate
																</button>
															) : (
																<button
																	className="btn btn-active btn-info create-team-btn"
																	onClick={async () => {
																		await DeactivatedUser(item._id);
																		getUsers();
																	}}
																>
																	Deactivated
																</button>
															)
														) : (
															"Owner Can't be Deactivated"
														)}
													</td>
												</tr>
											);
										}
									})}
							{memberListFilter &&
								memberListFilter
									.filter((item, index) => {
										if (
											item.name
												.toLocaleLowerCase()
												.includes(searchTerm.toLocaleLowerCase())
										) {
											return item;
										}
									})
									.map((item, index) => {
										if (!item.isContentCreator) {
											return (
												<tr key={entry * page + index + 1}>
													<th scope="row">{index + 1}</th>
													<td>
														<Link
															to={
																'/allmembers/' +
																item.name +
																'/profile/' +
																item._id
															}
														>
															{item.name}
														</Link>
													</td>
													<td>{item.email}</td>
													<td>{item.isContentCreator ? 'Yes' : 'No'}</td>

													<td>{item.activity}%</td>
													<td>
														<Link
															to={`/allmembers/${item.name}/profile/${item._id}`}
														>
															<EditIcon />
														</Link>
													</td>

													<td>
														<div
															style={{
																display: 'flex',
																justifyContent: 'space-between',
																alignItems: 'center'
															}}
														>
															<a
																href={`https://wa.me/${item.phoneNumber}?text=`}
																target="_blank"
															>
																<img
																	width="30"
																	src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
																/>
															</a>
															<a href={item.linkedinUrl} target="_blank">
																<img
																	width="25"
																	src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c9/Linkedin.svg/1200px-Linkedin.svg.png"
																/>
															</a>
														</div>
													</td>
													<td>
														{item.type === 'user' ? (
															item.deactivated ? (
																<button
																	className="btn btn-active btn-info create-team-btn"
																	onClick={async () => {
																		await ActivatedUser(item._id);
																		getUsers();
																	}}
																>
																	Activate
																</button>
															) : (
																<button
																	className="btn btn-active btn-info create-team-btn"
																	onClick={async () => {
																		await DeactivatedUser(item._id);
																		getUsers();
																	}}
																>
																	Deactivated
																</button>
															)
														) : (
															"Owner Can't be Deactivated"
														)}
													</td>
												</tr>
											);
										}
									})}
						</tbody>
					</table>
					<Pagination
						page={page}
						entryToShow={10}
						setPage={setPage}
						totalpage={totalpage}
						totalData={memberListFilter.length}
					/>
				</div>
				<div></div>
			</div>
		</div>
	);
}
