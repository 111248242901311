import React, { useState, useEffect } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Rating from '@material-ui/lab/Rating';
import { Link, useParams } from 'react-router-dom';
import { Modal, Image, Button } from 'react-bootstrap';
import CommentIcon from '@material-ui/icons/Comment';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import { updateCampaignStats } from '../Api';

import { Visibility } from '@material-ui/icons';

export default function CampaignCard({
	item,
	RateCampaign,
	getCampaigns,
	ratingDetails,
	index
}) {
	// console.log('Item is',item)
	const location = useParams();

	const [value, setValue] = useState(-1);
	const [Pic2, setPic2] = useState('');
	const [show, setShow] = useState(false);
	const [showList, setShowList] = useState(false);
	const [likes, setlikes] = useState(0);
	const [views, setviews] = useState(0);
	const [linkedinUrl, setlinkedinUrl] = useState('');
	const [comments, setcomments] = useState(0);
	const handleListClose = () => setShowList(false);
	const handleListShow = () => setShowList(true);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [participants, setParticipants] = useState([]);
	const [winner, setWinner] = useState(0);
	const [rating, setRating] = useState(null);
	const [Irating, setIRating] = useState([{}, {}, {}, {}, {}]);
	useEffect(() => {
		var Pic2 = JSON.parse(localStorage.getItem('InspirerWorld'));
		setPic2(Pic2.profileImg);
		let a = 0;
		if (item.winner) {
			if (item.winner.contentCreator) {
				a = winner + 1;
			}
			if (item.winner.engager) {
				setWinner(winner + 1);
				a = winner + 1;
			}
			if (item.winner.viewership) {
				setWinner(winner + 1);
				a = winner + 1;
			}
			setWinner(a);
		}
	}, [0]);

	const updateStats = async () => {
		let data = {
			likes,
			comments,
			views,
			linkedinUrl
		};

		const res = await updateCampaignStats(item._id, data);
		if (res.error) {
			alert('Some Error occurred');
		} else {
			getCampaigns();
			return handleClose();
		}
	};

	return (
		<div className="col-sm-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Update Stats</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						<div className="form-group">
							<label htmlFor="password">
								Likes{' '}
								<img
									style={{ marginTop: '-4px' }}
									src="https://img.icons8.com/material-sharp/20/101f41/facebook-like.png"
								/>{' '}
							</label>
							<input
								type="number"
								onChange={e => setlikes(e.target.value)}
								className="form-control"
								id="password"
								placeholder="Number of likes"
							/>
						</div>

						<div className="form-group">
							<label htmlFor="password">
								{' '}
								Comments{' '}
								<img
									style={{ marginTop: '-4px' }}
									src="https://img.icons8.com/material-rounded/20/101f41/topic.png"
								/>{' '}
							</label>
							<input
								type="number"
								onChange={e => setcomments(e.target.value)}
								className="form-control"
								id="password"
								placeholder="Number of Comments"
							/>
						</div>

						<div className="form-group">
							<label htmlFor="password">
								Views{' '}
								<img
									style={{ marginTop: '-4px' }}
									src="https://img.icons8.com/material-sharp/20/101f41/visible.png"
								/>
							</label>
							<input
								type="number"
								onChange={e => setviews(e.target.value)}
								className="form-control"
								id="password"
								placeholder="Number of Views"
							/>
						</div>

						<div className="form-group">
							<label htmlFor="password">Linkedin Url :</label>
							<input
								type="text"
								onChange={e => setlinkedinUrl(e.target.value)}
								className="form-control"
								id="password"
								placeholder="Link of the Post"
							/>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
					<Button variant="primary" onClick={() => updateStats()}>
						Update
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal
				show={showList}
				onHide={handleListClose}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>Participantes</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						{participants.map(item => {
							return (
								<div className="row form-group bg-light rounded p-3">
									<div className="col-md-3 col-5 text-center">
										<h6> {item.userId ? item.userId.name : null}</h6>
									</div>
									<div className="col-md-5 col-5 text-center">
										<h6>{item.userId.email}</h6>
									</div>
									<div className="col-md-4 col-12 text-center">
										<Visibility style={{ marginLeft: '6px' }} />
										<span>{item.views}</span>
										<CommentIcon style={{ marginLeft: '6px' }} />
										<span>{item.comments}</span>
										<ThumbUpAltIcon style={{ marginLeft: '6px' }} />

										<span>{item.likes}</span>
									</div>
								</div>
							);
						})}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleListClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>

			{console.log('item', item)}
			<div
				className={
					index === 0 ? 'campaign-card card' : 'campaign-card card mt-4'
				}
			>
				<div className="campaign-header-div mycampainParticipantCard">
					<div className="trendingcard-leftside">
						{' '}
						{/* <p className="serial-number-div">{index + 1}</p> */}
						{item.userId ? (
							<Link
								to={'/user/' + item.userId.name + '/profile/' + item.userId._id}
							>
								<h5
									className="card-title"
									style={{ display: 'flex', alignItems: 'center' }}
								>
									<Image
										className="winner-dp-image trending-quotes-dp img-thumbnail"
										style={{
											height: '35px',
											width: '35px',
											marginRight: '10px'
										}}
										src={
											item.userId && item.userId?.profileImg
												? item.userId?.profileImg
												: Pic2
										}
										rounded
										roundedCircle
									/>{' '}
									<span
										style={{
											fontSize: '14px',
											fontWeight: 'bold',
											display: 'flex',
											flexDirection: 'column',
											color: '#146d9c'
										}}
									>
										{item.userId ? item.userId.name : null}
									</span>
								</h5>
							</Link>
						) : null}
					</div>
					<div class="dropdown dropleft  bg-primary text-light radius-5 mt-auto mb-auto pl-2 pr-2 ">
						<div
							style={{ cursor: 'pointer', color: 'white' }}
							type="button"
							id="dropdownMenuButton"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
							<h6
								className="text-light mt-1"
								style={{ fontSize: '14px', fontWeight: 'bold' }}
							>
								Add Content Rate
							</h6>
						</div>

						<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
							<a class="dropdown-item f-25 d-flex">
								{Irating.map((ele, index) => {
									return (
										<div
											onClick={() => {
												setValue(index + 1);
												RateCampaign(item.userId._id, index + 1);
											}}
										>
											{value > index ? (
												<svg
													style={{ cursor: 'pointer', fontSize: '10px' }}
													xmlns="http://www.w3.org/2000/svg"
													width="16"
													height="16"
													fill="currentColor"
													class="bi bi-star-fill svg"
													viewBox="0 0 16 16"
												>
													<path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
												</svg>
											) : (
												<svg
													style={{ cursor: 'pointer', fontSize: '10px' }}
													xmlns="http://www.w3.org/2000/svg"
													width="16"
													height="16"
													fill="currentColor"
													class="bi bi-star svg"
													viewBox="0 0 16 16"
												>
													<path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
												</svg>
											)}
										</div>
									);
								})}
							</a>
						</div>
					</div>
				</div>

				<div className="trendingcard-image-div" style={{ padding: '10px' }}>
					{item && (
						<img src={item.imageUrl} className="card-img-top" alt="..." />
					)}
				</div>

				<div className="card-body" style={{ padding: 0 }}>
					<div
						className="trendingcard-header trendingcard-footer row"
						style={{ justifyContent: 'space-between' }}
					>
						<div className="col-3 text-center">
							<h6 className="footer-content-title">
								<img
									style={{ marginTop: '-4px', marginLeft: '2px' }}
									src="https://img.icons8.com/material-sharp/20/101f41/facebook-like.png"
								/>{' '}
								: {item.likes}{' '}
							</h6>
						</div>
						<div className="col-3 text-center">
							<h6 className="footer-content-title">
								<img
									style={{ marginTop: '-4px', marginLeft: '2px' }}
									src="https://img.icons8.com/material-rounded/20/101f41/topic.png"
								/>{' '}
								: {item.comments}{' '}
							</h6>
						</div>
						<div className="col-3 text-center">
							<h6 className="footer-content-title">
								<img
									style={{ marginTop: '-4px', marginLeft: '2px' }}
									src="https://img.icons8.com/material-sharp/20/101f41/visible.png"
								/>{' '}
								: {item.views}
							</h6>
						</div>
						<div className="col-3 text-center p-0 m-0 bg-primary trending-card-trophy">
							<div className="d-flex margin-auto">
								<Link
									className="margin-auto w-100"
									style={{ color: 'white' }}
									to="/winners"
								>
									<img
										style={{
											marginTop: '8px',
											marginLeft: '2px',
											width: '20px',
											height: '20px'
										}}
										src="./images/Winner-Fill.png"
									/>{' '}
									:
									<span className="footer-content-title text-light footer-winner ">
										{winner}
									</span>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <div className="campaign-card card" style={{height:'80vh'}}>
        <div className="card-image">
       
          {item.artWork && (
            <img
              src={NewArtwork || item.artWork.imgUrl}
              className="card-img-top"
              alt="..."
            />
          )}
        </div>

        <div className="card-body">
          <h5 className="card-title">{item.title}</h5>
          <p className="card-text">{item.desc}</p>
          <p style={{ display: "flex", justifyContent: "space-between" }}>
            <span>
              <img
                style={{ marginTop: "-4px" }}
                src="https://img.icons8.com/material-sharp/20/101f41/facebook-like.png"
              />{" "}
              : {item.likes}
            </span>
            <span>
              <img
                style={{ marginTop: "-4px" }}
                src="https://img.icons8.com/material-sharp/20/101f41/visible.png"
              />{" "}
              :  {item.views}
            </span>
            <span>
              <img
                style={{ marginTop: "-4px" }}
                src="https://img.icons8.com/material-rounded/20/101f41/topic.png"
              />{" "}
              : {item.comments}
            </span>
          </p>
          {!disabledClaim && <a style={{ color: "white" }} className="btn btn-primary" onClick={()=>finalFuncToDownloadProfilePic(item._id)}>
            Claim Artwork
          </a>}
          {disabledClaim && <a style={{ color: "white" }} className="btn btn-primary">
            Claiming...
          </a>}
          <br />

          {!disabled && <span style={{ display: "flex", flexDirection: "row" }}>
           
            <a
              style={{
                marginTop: "5px",
                fontSize: "14px",
                marginRight: 0,
                color: "white",
              }}
              
              className="btn btn-warning"
              onClick={() => finalFuncToResizeProfilePic(item._id)}
            >
              View Artwork
            </a>
          </span>}
          {disabled && <span style={{ display: "flex", flexDirection: "row" }}>
           
            <a
              style={{
                margin: "5px",
                fontSize: "14px",
                marginRight: 0,
                color: "white",
              }}
              
              className="btn btn-warning"
            >
              Loading...
            </a>
          </span>}
        </div>
      </div>*/}
		</div>
	);
}
