import React from 'react';

export default function Services() {
	return (
		<div>
			<section className="section res-margin  section-block-feature responsive-pad-remove  gradient-light--lean-left">
				<div className="container">
					<div className="row mx-3 mt-5" style={{ display: 'flex' }}>
						<div className="col-md-4 px-4 responsive-pad-remove  col-sm-12  order-md-1  ">
							<h4
								className="mt-2 mb-4 primary-text-color"
								style={{ fontWeight: 'bold', color: '#146d9c' }}
							>
								Inspirer World
								<br />
								brings out the best in YOU
							</h4>
							<p className="mb-5" style={{ textAlign: 'justify' }}>
								We help members to discover true potentials. Through Campaigns,
								we guide our members to enhance their writing skills. Campaigns
								are meant to initiate a healthy discussion about a topic related
								to Self-Development. These Campaigns have helped members unveil
								their hidden talents, and several inactive members have begun to
								create fantastic content. We believe that a little push of
								encouragement and guidance in the right direction helps
								individuals unleash their potentials.
							</p>
							{/* <a href="#0" className="text-link link--right-icon text-uppercase">Connect All Your Channels Now <i className="link__icon fa fa-chevron-right" /></a> */}
						</div>
						<div className="col-md-4 px-4 responsive-pad-remove  col-sm-12">
							<h4
								className="mt-2 mb-4 primary-text-color"
								style={{ fontWeight: 'bold', color: '#146d9c' }}
							>
								Inspirer World
								<br />
								Engage, Encourage, and Enlighten
							</h4>
							<p className="mb-5" style={{ textAlign: 'justify' }}>
								To inculcate our ideology in our culture, we encourage our
								members to engage insightfully on each other’s posts, so both
								can be benefitted. Our members gain visibility through
								collaboration. Engagement on other’s posts channel their thought
								process and give each member exposure to several point of view.
								It forces them to think in a different way to respond to each
								point of view. It broadens their perspectives and help them
								understand different angles of the same topic
							</p>
							{/* <a href="#0" className="text-link link--right-icon text-uppercase">Centralize The Influencer Data Now <i className="link__icon fa fa-chevron-right" /></a> */}
						</div>
						<div
							style={{ position: 'relative' }}
							className="col-md-4 px-4 responsive-pad-remove col-sm-12 order-md-1  "
						>
							<h4
								className="mt-2 mb-4 primary-text-color"
								style={{ fontWeight: 'bold', color: '#146d9c' }}
							>
								Highest
								<br />
								Viewership
							</h4>
							<p className="mb-5" style={{ textAlign: 'justify' }}>
								Social media plays a crucial part in marketing businesses or a
								personal brand. Gaining visibility on any platform is essential
								to create a buzz around your brand. Viewership on the posts is
								simply a reward one gets by joining Inspirer World. Through
								collaborative efforts by all members, each one grows gracefully
								and enhances visibility on the platform.
							</p>
							{/* <a href="#0" className="text-link link--right-icon text-uppercase">Connect Your Team Now <i className="link__icon fa fa-chevron-right" /></a> */}
						</div>
					</div>
					
				</div>
			</section>
			{/* END Section Block Feature */}
			
		</div>
	);
}
