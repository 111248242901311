import React, { useState, useEffect } from "react";
import StatsCard from "../Dashboard/StatsCard";
import { Link } from "react-router-dom";
import { getTeamDetails, removeTeamMember,getAllUsers,addTeamMember,changeTeamLeader } from "../Api";
import { FaLinkedin, FaRegTimesCircle,FaEdit } from "react-icons/fa";
import EditIcon from '@material-ui/icons/Edit'

import Tooltip from "@material-ui/core/Tooltip";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {Button,Modal} from 'react-bootstrap'


export default function TeamDetail(props) {
  let id = props.match.params.teamId;

  const [searchTerm, setsearchTerm] = useState("");
  const [entryToShow, setentryToShow] = useState(25);
  const [teamData, setteamData] = useState(null);
  const [allMembers, setAllMembers] = useState([])
  const [memberList, setmemberList] = useState([]);
const [addMember, setaddMember] = useState(null)
const [addLeader, setaddLeader] = useState(null)
const [show, setShow] = useState(false);
const [showSuccess, setSuccess] = useState(false);
const [Leadershow, setLeaderShow] = useState(false);
const handleClose = () => {setShow(false) ; setLeaderShow(false) ; setSuccess(false)};
const handleShow = () => setShow(true);



  useEffect(() => {
    getTeamData();
    getUsers()
    return () => {};
  }, [1]);

  const getTeamData = async () => {
    const res = await getTeamDetails(id);
    if (res.error) {
      alert("Some Error Occurred !");
    } else {
      console.log("Res of Team is", res.res.data.team);
      setmemberList(res.res.data.team.members);
      setteamData(res.res.data.team);
    }
  };

  const removeMember = async (memberId) => {
    const res = await removeTeamMember(id, memberId);
    if (res.error) {
      alert("Some Error Occurred !");
    } else {
      getTeamData();
      alert("Team Member Removed");
    }
  };


  const getUsers= async ()=>{

    let res= await getAllUsers()
    if(res.error){
      console.log(res.error)
    }
    else{
   //  let Teams=res.res.data
  setAllMembers(res.res.data.users)
      console.log(res.res.data)
   }
  }



  const addNewMember = async() => {

if(addMember==null){
  alert("Select a valid name")
  return
}

    const res = await addTeamMember(id, addMember);
    if (res.error) {
      alert("Some Error Occurred !");
      return 
    } else {
     
      alert("Team Member Added");
      handleClose()
     return getTeamData();
    }
  };


const MakeNewTeamLeader = async()=>{
  const res = await changeTeamLeader(id,addLeader)
  if(res.error){
    alert("Some Error occurred in Changing leader")
  }else{
    console.log('leader changed',res.res)
    setSuccess(true)
    getTeamData();
    getUsers()
   setTimeout(function (){
     console.log('Timeout done')
   },3000)
  }

return handleClose()

}



  return (
    <div>
      {teamData && (
        <div className="container-fluid">
          <br />
          <h3>---Team Details---</h3>
          <br />
          <h4>Team Name : {teamData.title}</h4>
          <br />
          <h4>Team Leader : {teamData.leader.name} <FaEdit style={{width : '3%' }}
          onClick={()=>setLeaderShow(true)}
          /></h4>

          <br />
          <br />
          <h4>Total Stats</h4>
          <br />

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <StatsCard
              img="../../images/white-copy.svg"
              title="Team Members"
              value="6"
            />

            <StatsCard
              img="../../images/white-copy.svg"
              title="Total Campaigns"
              value="56"
            />

            <StatsCard
              img="../../images/white-copy.svg"
              title="Total Views"
              value="56"
            />

            <StatsCard
              img="../../images/white-copy.svg"
              title="Total Likes"
              value="56"
            />

            <StatsCard
              img="../../images/white-copy.svg"
              title="Total Comments"
              value="56"
            />
          </div>
        </div>
      )}


<Modal show={Leadershow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Team Leader</Modal.Title>
        </Modal.Header>
        <Modal.Body>


       { showSuccess && <div class="alert alert-success" role="alert">
  Leader Changed Successfully !
</div> }

<span>Type Name :</span>
<br/>

        <Autocomplete
        style={{width:'100%'}}
       
        id="combo-box-demo"
        options={allMembers}
      onChange={(e,data)=>setaddLeader(data._id)}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            className=' choosing-option-autocomplete'
            variant="standard"
            autoHighlight
            label="Single Value"
            placeholder="Select Members"
          
          />
        )}
      />

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={()=>MakeNewTeamLeader()}>
            Change Leader
          </Button>
        </Modal.Footer>
      </Modal>






      {/* Table list of team members  */}
      {/* Table list of team members  */}
      {/* Table list of team members  */}
      <br />
      <br />

      <div className="dashboard-main-container">
        <div className="teams-title">
          <h3
            className="last-campaign admin-last-campaign text-left"
            style={{ textTransform: "capitalize" }}
          >
            Team Member List
          </h3>
          <Link onClick={handleShow}>
            <button className="btn btn-info create-team-btn">Add Member</button>
          </Link>
        </div>

        <div className="dashboard-container admin-campaign">
          <div className="teams-search">
            <label className="teams-select">
              Show
              <select
                name="datatable-buttons_length"
                aria-controls="datatable-buttons"
                className="custom-select teams-custom-select"
                onChange={(e) => setentryToShow(e.target.value)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              entries
            </label>

            <label className="teams-searchbar">
              Search:
              <input
                type="search"
                className="form-control form-control-sm"
                placeholder=""
                aria-controls="datatable-buttons"
                onChange={(e) => setsearchTerm(e.target.value)}
              />
            </label>
          </div>


<br/>

<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
<span>Type Name :</span>
<br/>

        <Autocomplete
        style={{width:'100%'}}
       
        id="combo-box-demo"
        options={allMembers}
      onChange={(e,data)=>setaddMember(data._id)}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            className=' choosing-option-autocomplete'
            variant="standard"
            autoHighlight
            label="Single Value"
            placeholder="Select Members"
          
          />
        )}
      />

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={addNewMember}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>



       

<br/>
          <div className="container">
            <div className="row text-align-center">
              <div className="col-lg-6"></div>

              <div className="col-lg-6">
                <h3 className="last-campaign admin-last-campaign text-right"></h3>
              </div>
            </div>
          </div>

          <br />

          <table className="table table-bordered">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="font-weight-bold"
                  style={{ width: "10%" }}
                >
                  <div className="up-down-container">
                    <strong> Sr.</strong>
                    <div className="up-down-arrows"></div>
                  </div>
                </th>
                <th
                  
                  className="font-weight-bold"
                  style={{ width: "80%" }}
                >
                  <div className="up-down-container">
                    <strong> Member </strong>
                    <div className="up-down-arrows"></div>
                  </div>
                </th>

                <th
                  scope="col"
                  className="font-weight-bold"
                  style={{ width: "80%" }}
                >
                  <div className="up-down-container">
                    <strong> Email</strong>
                    <div className="up-down-arrows"></div>
                  </div>
                </th>

                <th
                  scope="col"
                  className="font-weight-bold"
                  style={{ width: "80%" }}
                >
                  <div className="up-down-container">
                    <strong> Campaign Participated</strong>
                    <div className="up-down-arrows"></div>
                  </div>
                </th>
                <th
                  scope="col"
                  className="font-weight-bold"
                  style={{ width: "80%" }}
                >
                  <div className="up-down-container">
                    <strong> Social</strong>
                    <div className="up-down-arrows"></div>
                  </div>
                </th>

                <th scope="col" className="font-weight-bold">
                  <div className="up-down-container">
                    <strong>Edit</strong>
                    <div className="up-down-arrows"></div>
                  </div>
                </th>
                <th
                  scope="col"
                  className="font-weight-bold"
                  style={{ width: "80%" }}
                >
                  <div className="up-down-container">
                    <strong> Activeness</strong>
                    <div className="up-down-arrows"></div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {memberList &&
                memberList
                  .filter((item, index) => {
                    if (
                      (item.name + "")
                        .toLowerCase()
                        .includes(searchTerm.toLocaleLowerCase()) &&
                      index < entryToShow
                    )
                      return item;
                  })
                  .map((item, index) => {
                    return (
                      <tr key={index + 1}>
                        <th scope="row">{index + 1}</th>
                        <td>
                        <Link to={'/allmembers/'+item.name+'/profile/'+item._id}>
                          {item.name}</Link>
                          </td>
                        <td>{item.email}</td>
                        <td>{item.campaigns.length}</td>
                       
                        <td>
                          <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                            <a href={`https://wa.me/${item.phoneNumber}?text=`} target="_blank"><img width="30" src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"/></a>
                            <a href={item.linkedinUrl} target="_blank"><img width="25" src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c9/Linkedin.svg/1200px-Linkedin.svg.png"/></a>
                          </div>
                        </td>
                        <td>

  <Link to={`/allmembers/${item.name}/profile/${item._id}`}><EditIcon/></Link>
                        </td>
                        <td>{item.activity}%</td>

                      </tr>
                    );
                  })}
            </tbody>
          </table>

          <div className="prev-next-row">
            <div className="entries-text">
            
              Showing {(entryToShow>memberList.length) ? (memberList.length) : (entryToShow)} of {memberList.length} entries
            </div>
            {/* <div className='prev-next'>
            <div>Previous</div> <div className='prev-count'>1</div>{' '}
            <div>Next</div>
          </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
