import React, { useState, useEffect } from "react";

import axios from "axios";
import {
  getCampaignParticipantsForUser,
  participateInCampaign,
  updateCampaignStats,
  viewArtwork,
} from "../Api";
import fileDownload from "js-file-download";
export default function CampaignCard({ item, getCampaigns, index }) {
  // console.log('Item is',item)

  const [NewArtwork, setNewArtwork] = useState(null);
  const [disabledClaim, setDisabledClaim] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [Pic2, setPic2] = useState("");
  const [imgUrl, setimgUrl] = useState(null);
  const [ModifieProfilePic, setModifieProfilePic] = useState(null);
  const [show, setShow] = useState(false);
  const [showList, setShowList] = useState(false);
  const [likes, setlikes] = useState(0);
  const [views, setviews] = useState(0);
  const [linkedinUrl, setlinkedinUrl] = useState("");
  const [comments, setcomments] = useState(0);
  const handleListClose = () => setShowList(false);
  const handleListShow = () => setShowList(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [participants, setParticipants] = useState([]);
  const [winner, setWinner] = useState(0);
  useEffect(() => {
    var Pic2 = JSON.parse(
      localStorage.getItem("InspirerWorld")
    ).profileImg;
    setPic2(Pic2);
    let a = 0;
    if (item.winner) {
      if (item.winner.contentCreator) {
        a = winner + 1;
      }
      if (item.winner.engager) {
        setWinner(winner + 1);
        a = winner + 1;
      }
      if (item.winner.viewership) {
        setWinner(winner + 1);
        a = winner + 1;
      }
      setWinner(a);
    }
    console.log(item);
  }, []);

  const updateStats = async () => {
    let data = {
      likes,
      comments,
      views,
      linkedinUrl,
    };

    const res = await updateCampaignStats(item._id, data);
    if (res.error) {
      alert("Some Error occurred");
    } else {
      getCampaigns();
      return handleClose();
    }
  };

  const showParticipantesList = async () => {
    const res = await getCampaignParticipantsForUser(item._id);
    if (res.error) {
      alert("Some Error occurred");
    } else {
      console.log(res.data);
      setParticipants(res.data.participants);
      console.log(participants);
      setTimeout(() => {
        handleListShow();
      }, 500);
    }
  };

  const finalFuncToResizeProfilePic = async (id) => {
    setDisabled(true);
    const res1 = await viewArtwork(id);
    console.log("viewArtwork =>", res1);
    setNewArtwork(res1.data);
    setDisabled(false);
    finalFuncToDownloadProfilePic(id);
  };

  const finalFuncToDownloadProfilePic = async (id) => {
    setDisabledClaim(true);
    const res2 = await participateInCampaign(id);
    let url = res2.data.userArtWork;
    let filename = `${item.title}.jpeg`;
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
    setDisabledClaim(false);
  };

  return (
    <div className="col-sm-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
      {index === 0 ? (
        <div className="row learn-card">
          <div className="col-sm-12  m-2 show-mobile  btn btn-primary  ">
            {item.title}
          </div>
          <div className=" col-sm-12 col-md-6 p-4 frame-padding">
            <div>
              <iframe width="320" height="257" className='iframe' src={item.url}></iframe>
            </div>
          </div>
          <div className="col-4 show-desktop mt-6">
            <h3 className="marginTop-20">{item.title}</h3>
          </div>
        </div>
      ) : (
        <div className="row learn-card mt-4">
           <div className="col-sm-12 show-mobile  mt-6">
            <h3 className=" btn btn-primary">{item.title}</h3>
          </div>
          <div className=" col-sm-12 col-md-6 p-4">
            <div>
              <iframe width="320" height="257" className='iframe' src={item.url}></iframe>
            </div>
          </div>
          <div className="col-4 show-desktop mt-6">
            <h3 className="marginTop-20">{item.title}</h3>
          </div>
        </div>
      )}
    </div>
  );
}
