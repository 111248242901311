import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import "./LoadingModal.scss";

export default function LoadingModal({ loading, message }) {
  return (
    <div>
      <Modal
        show={loading}
        backdrop="static"
        keyboard={false}
        dialogClassName="loading-modal-ctn"
        className="modal-obj-ctn"
      >
        <Modal.Body className="loading-modal-body">
          {message}
          <Spinner
            animation="border"
            role="status"
            style={{ color: "#146D9C", margin: "5px 0px" }}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
