import React, { useState, useEffect } from 'react';
import Trendup from '../../images/trendup.svg';
import QuotesCard from './QuotesCard';
import user_pic from '../../images/user.png';
import fileDownload from 'js-file-download';
import { Helmet } from 'react-helmet-async';

import {
	suggestQuote,
	getAllQuotes,
	getQuoteCategory,
	getMyQuotes,
	getUpcomingAndSuggestedQuotes
} from '../Api';

import TagList from '../TagList';

import ProfileStatsBar from '../Profile/ProfileStatsBar';

import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Tab, Nav } from 'react-bootstrap';
import SidebarTrending from './Sidebar';

import './quotes.css';

export default function QuoteByAuthor(props) {
	const [search, setsearch] = useState('');
	const [showModal, setShowModal] = useState(false);
	let id = props.match.params;
	const auth_Name = id.author.replace('_', ' ');
	// console.log(id);
	const [disabledClaim, setDisabledClaim] = useState(false);
	const [profileImgExist, setProfileImgExist] = useState(false);

	const [quotesData, setquotesData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [originalData, setoriginalData] = useState(null);
	const [selectedCategory, setselectedCategory] = useState('All Category');
	const [categoryAll, setcategoryAll] = useState(null);
	const [show, setShow] = useState(false);
	const [userSuggestedQuote, setuserSuggestedQuote] = useState(null);
	const [quoteAuthorName, setquoteAuthorName] = useState(null);
	const [userDetail, setuserDetail] = useState(null);
	// code for suggest quote

	const [tagData, setTagData] = useState([]);
	const [authorName, setAuthorName] = useState([]);
	const history = useHistory();
	const [checked, setChecked] = useState(false);
	const [isError, setisError] = useState(null);
	const [suggestedData, setsuggestedData] = useState([]);
	const [upcomingsuggestedData, setupcomingsuggestedData] = useState([]);
	const [isSuccess, setisSuccess] = useState(null);
	const [categoryWise, showCategoryWise] = useState(true);
	const [quoteDownloads, setquoteDownloads] = useState('');
	const params = useLocation();
	const handleClose = () => {
		setShow(false);
		setisSuccess(null);
		setisError(null);
		setChecked(false);
	};

	const handleShow = () => setShow(true);
	useEffect(() => {
		getQuotes();
	}, [1]);
	useEffect(() => {
		getCategory();
	}, [1]);

	const setCategory = category => {
		setselectedCategory(category);
	};
	const setFilterByName = name => {
		let data = originalData.filter(item => {
			if (item.author.name.toLowerCase() == name.toLowerCase()) {
				return item;
			} else if (item.madeBy.name.toLowerCase() == name.toLowerCase()) {
				return item;
			}
		});

		setquotesData(data);
	};
	const setFilterByUrl = (name, Qdata) => {
		let data = Qdata.filter(item => {
			if (
				item?.author &&
				item.author?.name.toLowerCase() == name.toLowerCase()
			) {
				return item;
			} else if (
				item?.madeBy &&
				item.madeBy?.name.toLowerCase() == name.toLowerCase()
			) {
				return item;
			}
		});
		setquotesData(data);
		setoriginalData(data);
	};
	const setTags = tag => {
		console.log(tag);
		let res = [];
		let data = originalData.filter(item => {
			item.tags.filter(tagitem => {
				if (tag === tagitem) {
					// console.log(item)
					res.push(item);
					return item;
				}
			});
			//   console.log(res)
			return res;
		});

		setquotesData(res);
	};
	const getQuotes = async () => {
		setLoading(true);
		const res = await getUpcomingAndSuggestedQuotes();
		console.log(res.res.data, 'upcoming');
		setsuggestedData(res.res.data.quoteSuggestions);
		setupcomingsuggestedData(res.res.data.upcomingCampaigns);
		//const res = await getAllCampaigns();
		const response = await getAllQuotes();

		setquotesData(response.data.quotes);
		setoriginalData(response.data.quotes);
		if (id.author) {
			let name = id.author.replace('_', ' ');
			setFilterByUrl(name, response.data.quotes);
			setAuthorName(name);
		}
		let tagArray = [];
		for (const item of response.data.quotes) {
			if (item.tags.length > 0) {
				tagArray = tagArray.concat(item.tags);
			}
		}
		setTagData(tagArray);
		return setLoading(false);
	};

	const getCategory = async () => {
		let res = await getQuoteCategory();
		if (res.error) {
		} else {
			console.log('Res category is', res);
			setcategoryAll(res.data.quoteCategories);
		}
	};

	const getUserQuotes = async () => {
		let res = await getMyQuotes();
		console.log('my quotes', res.res.data.quotes.length);
		setquoteDownloads(res.res.data.quotes.length);
	};

	const [currentActiveTab, setcurrentActiveTab] = useState('trendingQuotes');

	//this is for category filter
	useEffect(() => {
		// console.log(selectedCategory)

		if (quotesData && selectedCategory !== 'All Category') {
			let data = originalData.filter(item => {
				if (item.category.toLowerCase() == selectedCategory.toLowerCase())
					return item;
			});

			setquotesData(data);
			console.log(`Quotes for ${selectedCategory} are `, data);
		} else {
			setquotesData(originalData);
		}

		return () => {};
	}, [selectedCategory]);

	const handleChange = event => {
		if (event.target.checked) {
			setquoteAuthorName(userDetail.name);
		}
		setChecked(event.target.checked);
	};

	const sendSuggestedQuote = async () => {
		let data = {
			quoteMsg: userSuggestedQuote,
			quoteAuthor: quoteAuthorName
		};

		let res = await suggestQuote(data);

		if (res.error) {
			setisError(true);
			setisSuccess(false);
		} else {
			setisSuccess(true);
			setisError(false);
		}
	};

	const changeActiveTab = screenName => {
		setcurrentActiveTab(screenName);
	};

	return (
		<>
			<Helmet>
				<title>{auth_Name}</title>
			</Helmet>
			<div className="authorQuotesScreen">
				<br />

				<div className="container ">
					<div className="row  ">
						<div className="col-lg-9 ">
							<Tab.Container
								defaultActiveKey="third"
								unmountOnExit={true}
								activeKey={currentActiveTab}
							>
								<br />
								<h2 className="quotesTitle">{auth_Name} Quotes</h2>
								<Tab.Content style={{ width: '100%' }}>
									<Tab.Pane lg={3} eventKey="campaignQuotes">
										<div>
											<div className="row">
												<div
													className="button-half-div col-12"
													style={{ marginTop: '-25px' }}
												>
													<button
														variant="info"
														id="cwise"
														className="btn1 btn3 rounded"
														onClick={() => {
															document.getElementById(
																'authorbtn'
															).style.background = '#146d9c';
															document.getElementById(
																'cwise'
															).style.background = 'green';
															showCategoryWise(true);
														}}
													>
														{' '}
														Campaign Wise
													</button>

													<button
														variant="info"
														id="authorbtn"
														className="btn2 btn3 rounded"
														onClick={() => {
															showCategoryWise(false);
															document.getElementById(
																'authorbtn'
															).style.background = 'green';
															document.getElementById(
																'cwise'
															).style.background = '#146d9c';
														}}
														style={{ fontWeight: 'bold' }}
													>
														Category Wise
													</button>
												</div>
											</div>
											{!categoryWise
												? suggestedData.map(item => {
														return (
															<div className="new-quote-cards-container">
																<div className="new-quote-cards-left">
																	<div className="trendingcard-image-div">
																		{/* <img  src={imgUrl} /> */}

																		<img
																			src={item.artWork.imgUrl}
																			className="card-img-top"
																			alt="..."
																		/>
																	</div>
																	<div className="quote-container-div">
																		{/* <h5>{item.title}</h5> */}
																		<p className="card-text quote-card-quote-text">
																			{item.quoteMsg}
																		</p>
																	</div>

																	<br />

																	<div className="save-div">
																		<div className="additional-info">
																			<div className="individual-info">
																				<div className="info-heading">
																					<span>Category</span>
																				</div>
																				<div
																					className="info-value"
																					style={{ cursor: 'pointer' }}
																					onClick={() =>
																						setCategory(item.category)
																					}
																				>
																					<span>{item.category}</span>
																				</div>
																			</div>
																			<div className="individual-info">
																				<div className="info-heading">
																					<span>Tags</span>
																				</div>
																				<div className="info-value">
																					{item.hashTags.map(i => {
																						return (
																							<span
																								style={{ cursor: 'pointer' }}
																							>
																								#{i}
																							</span>
																						);
																					})}
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="trending-quotes-card-button-container">
																		{disabledClaim ? (
																			<div className="new-quote-cards-right">
																				<span className="cmapign-download">
																					Claiming...
																				</span>
																			</div>
																		) : (
																			<button
																				className="trending-quotes-claim-button"
																				// onClick={() => finalFuncToDownloadProfilePic(item._id)}
																			>
																				{' '}
																				<Link to="/login">
																					<p className="block-claim-text">
																						<img
																							src={user_pic}
																							className="user-pic-icon"
																						/>
																						Sign-in to Claim
																					</p>
																				</Link>
																			</button>
																		)}
																	</div>
																</div>
															</div>
														);
												  })
												: upcomingsuggestedData.map((item, index) => {
														console.log(item);
														return item.quoteSuggestions.map(element => {
															return (
																<div
																	className={'new-quote-cards-container mt-2 '}
																>
																	<div className="new-quote-cards-left">
																		<div className="trendingcard-image-div">
																			{/* <img  src={imgUrl} /> */}

																			<img
																				src={element.artWork.imgUrl}
																				className="card-img-top"
																				alt="..."
																			/>
																		</div>
																		<div className="quote-container-div">
																			{/* <h5>{item.title}</h5> */}
																			<p className="card-text quote-card-quote-text">
																				{element.quoteMsg}
																			</p>
																		</div>

																		<br />
																		<div className="save-div">
																			<div className="additional-info">
																				<div className="individual-info">
																					<div className="info-heading">
																						<span>Campaign</span>
																					</div>
																					<div
																						className="info-value"
																						style={{ cursor: 'pointer' }}
																						onClick={() =>
																							setCategory(item.category)
																						}
																					>
																						<span>{item.title}</span>
																					</div>
																				</div>
																			</div>
																			<div className="individual-info">
																				<div className="info-heading">
																					<span>Author</span>
																				</div>
																				<div className="info-value">
																					{element.quoteAuthor}
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="trending-quotes-card-button-container">
																		{disabledClaim ? (
																			<div className="new-quote-cards-right">
																				<span className="cmapign-download">
																					Claiming...
																				</span>
																			</div>
																		) : (
																			<button
																				className="trending-quotes-claim-button"
																				// onClick={() => finalFuncToDownloadProfilePic(item._id)}
																			>
																				{' '}
																				<Link to="/login">
																					<p className="block-claim-text">
																						<img
																							src={user_pic}
																							className="user-pic-icon"
																						/>
																						Sign-in to Claim
																					</p>
																				</Link>
																			</button>
																		)}
																	</div>
																</div>
															);
														});
												  })}
										</div>
									</Tab.Pane>

									<Tab.Pane lg={3} eventKey="trendingQuotes">
										<div className="row">
											<div className="col-sm-12  float-right mb-3">
												<div className="form-group">
													<input
														onChange={e => setsearch(e.target.value)}
														type="text"
														className="form-control "
														id="firstName"
														name="firstName"
														placeholder="Search Quote by quote description"
													/>
												</div>
											</div>
										</div>

										<div style={{ display: 'flex', flexDirection: 'column' }}>
											{quotesData &&
												quotesData
													.filter((item, index) => {
														if (
															item.desc
																.toLocaleLowerCase()
																.includes(search.toLocaleLowerCase())
														)
															return item;
													})
													.map((item, index) => {
														return (
															<QuotesCard
																getQuotes={getQuotes}
																setCategory={setCategory}
																setTags={setTags}
																setFilterByName={setFilterByName}
																changeActiveTab={changeActiveTab}
																key={index}
																item={item}
															/>
														);
													})}

											{quotesData && quotesData.length < 1 && (
												<div>
													<center>
														<img
															style={{ width: '50%' }}
															src="https://cdn.dribbble.com/users/2071065/screenshots/6559618/dribble_4-19_4x.png?compress=1&resize=800x600"
														/>
														<h4>Oops ! No Quotes in this Category...</h4>
													</center>
												</div>
											)}
										</div>
									</Tab.Pane>
								</Tab.Content>
							</Tab.Container>
						</div>

						{/* <SidebarTrending /> */}
						<TagList tagData={tagData} setTags={setTags} />
					</div>
				</div>
			</div>
		</>
	);
}
