import React from 'react';
import Team from './Team';
import Footer from '../Navbar/Footer';
import { Helmet } from 'react-helmet-async';

export default function About() {
	return (
		<>
			<Helmet>
				<title>About us</title>
			</Helmet>
			<div>
				<br />

				<section className="section section-block-feature gradient-light--lean-left">
					<div className="container">
						<div
							className="row"
							style={{ display: 'flex', alignItems: 'center' }}
						>
							<div className="col-md-12 order-md-1 text-center text-md-left">
								<div className=" mb-4">
									{/* <img src="images/icn_fluxo_stacks.svg" alt="Feature Icon" className="feature-icon" /> */}
								</div>

								<h2
									style={{ fontWeight: 'bold', color: '#146d9c' }}
									className="mt-2 mb-4"
								>
									What is Inspirer World?
								</h2>
								<p className="mb-5">
									It is a volunteer platform, designed to help members grow
									gracefully, by empowering them in creating a strong personal
									brand through
								</p>

								{/* <a href="#0" className="text-link link--right-icon text-uppercase">Connect All Your Channels Now <i className="link__icon fa fa-chevron-right" /></a> */}
							</div>
							<div className="col-md-12  text-center text-md-left">
								<div className="mb-4">
									{/* <img src="images/icn_fluxo_chart.svg" alt="Feature Icon" className="feature-icon" /> */}
								</div>
								<h2
									style={{ fontWeight: 'bold', color: '#146d9c' }}
									className="mt-2 mb-4"
								>
									How do we achieve it?
								</h2>
								<p className="mb-5">
									Meticulously planned campaigns are run every week, in which
									100s of members participate from across the globe. A topic on
									self-development is selected, and all group members create
									content around the same topic with their customized poster.
									Our admin panel helps each member in creating impactful
									content.
								</p>
								{/* <a href="#0" className="text-link link--right-icon text-uppercase">Centralize The Influencer Data Now <i className="link__icon fa fa-chevron-right" /></a> */}
							</div>
							<div className="col-md-12 order-md-2 text-center text-md-left">
								<div className="mb-4">
									{/* <img src="images/icn_fluxo_users.svg" alt="Feature Icon" className="feature-icon" /> */}
								</div>
								<h2
									style={{ fontWeight: 'bold', color: '#146d9c' }}
									className="mt-2 mb-4"
								>
									How does it help?
								</h2>
								<p className="mb-5">
									It helps them ponder and indulge in deeper introspection. With
									the collaboration of 100’s of members, everyone’s post gets a
									boost, and members gain incredible visibility.
								</p>
								{/* <a href="#0" className="text-link link--right-icon text-uppercase">Connect Your Team Now <i className="link__icon fa fa-chevron-right" /></a> */}
							</div>

							<div className="col-md-12 order-md-1 text-center text-md-left">
								<div className="mb-4">
									{/* <img src="images/icn_fluxo_users.svg" alt="Feature Icon" className="feature-icon" /> */}
								</div>
								<h2
									style={{ fontWeight: 'bold', color: '#146d9c' }}
									className="mt-2 mb-4"
								>
									The reward for the Members
								</h2>
								<p
									className="mb-5 text-align-left"
									style={{
										textAlign: 'left',
										width: '94%',
										marginLeft: 'auto'
									}}
								>
									A ‘Customized Proﬁle Pic’ is awarded to those who perform
									outstandingly in these campaigns in terms of:
									<br />
									<br />
									<ul style={{ listStyleType: 'disc', marginLeft: '10px' }}>
										<li>Creating Best Content</li>
										<li>Gaining highest viewership</li>
										<li>Engaging highest on others post</li>
									</ul>
								</p>
								{/* <a href="#0" className="text-link link--right-icon text-uppercase">Connect Your Team Now <i className="link__icon fa fa-chevron-right" /></a> */}
							</div>
						</div>
					</div>
				</section>

				{/* END Section Block Feature */}

				<br />

				{/* <Team /> */}

				<Footer />
			</div>
		</>
	);
}
