import React from 'react';

function StatsComponent({ link, value, w, lastItem = false }) {
	const txtStyle = {
		fontWeight: 'bold',
		fontSize: '18px',
		lineHeight: '22px'
	};
	return (
		<div
			className="radius-5"
			style={{
				display: 'flex',
				alignItems: 'center',
				backgroundColor: lastItem ? '#166D9C' : '#E5E5E5',
				minHeight: '40px',
				width: '100%',
				justifyContent: 'center',
				marginRight: lastItem ? '0px' : '5px'
			}}
		>
			<img
				src={link}
				style={{ maxWidth: w, width: '16%', marginRight: '8px' }}
			/>
			<span
				className="social-count"
				style={{ color: lastItem ? '#ffffff' : '#166D9C' }}
			>
				{value}
			</span>
		</div>
	);
}

export default StatsComponent;
