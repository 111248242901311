import React, { useState, useEffect } from 'react';
import {
	addContentToCampain,
	getQuoteCategory,
	getUpcomingCampaignForUser,
	postQuoteForUpcomingCampaign
} from '../Api';
import { Helmet } from 'react-helmet-async';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { Button, Alert, Image } from 'react-bootstrap';
import './SuggestQuote.css';
import './quotes.scss';

export default function SuggestQuote({ match }) {
	const [checked, setChecked] = useState(false);
	const [isError, setisError] = useState(null);
	const [isSuccess, setisSuccess] = useState(null);
	const [userSuggestedQuote, setuserSuggestedQuote] = useState(null);
	const [quoteAuthorName, setquoteAuthorName] = useState(null);
	const [userDetail, setuserDetail] = useState(null);
	const [hashtagString, sethashtagString] = useState(null);
	const [selectedCategory, setselectedCategory] = useState('Choose Category');
	const [selectedCategoryId, setselectedCategoryId] = useState('');

	const [selectedCampaign, setselectedCampaign] = useState(
		'For Upcoming Campaign'
	);
	const [selectedCampaignId, setselectedCampaignId] = useState('');
	const [categoryAll, setcategoryAll] = useState(null);
	const [upcomingCampaign, setUpcomingCampaign] = useState([]);
	const [showCampaign, setShowCampaign] = useState(false);
	const [content, setContent] = useState();

	const sendSuggestedQuote = async () => {
		const cur_body = content;
		let data = {
			body: cur_body.replaceAll('\n', '<br>')
		};

		let res = await addContentToCampain(match.params.id, data);

		if (res.error) {
			setisError(true);
			setisSuccess(false);
		} else {
			setisSuccess(true);
			setisError(false);
		}
	};

	useEffect(() => {
		var data = JSON.parse(localStorage.getItem('InspirerWorld'));
		if (data) {
			setuserDetail(data);
			console.log('Data is', data);
		}

		getCategory();
		return () => {};
	}, [1]);

	const getCategory = async () => {
		let res = await getQuoteCategory();
		let res1 = await getUpcomingCampaignForUser();
		if (res.error) {
		} else {
			console.log('Res category is', res1);
			setcategoryAll(res.data.quoteCategories);
			setUpcomingCampaign(res1.res.data.upcomingCampaigns.reverse());
		}
	};

	return (
		<>
			<Helmet>
				<title>Add Contents</title>
			</Helmet>
			<div className="container custom-suggest-quote mt-5 suggestQuotes">
				<div className="row">
					<div
						className="col-lg-12 col-md-12"
						style={{ paddingLeft: 0, paddingRight: 0, marginTop: '-10px' }}
					>
						<div className="button-half-div" style={{ display: 'none' }}>
							<button
								className="btn2 rounded btn-danger"
								style={{ backgroundColor: '#f53d55' }}
							>
								<div className="dropdown">
									<button
										style={{
											border: 'none',
											padding: 0
										}}
										className="btn btn-secondary long-text-wrap dropdown-toggle navbar-dropdown"
										type="button"
										id="dropdownMenuButton"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										<strong>{selectedCampaign} </strong>
									</button>
									<div
										className="dropdown-menu text-center res-left"
										style={{
											padding: '10px',
											cursor: 'pointer',
											overflow: 'scroll',
											maxHeight: '400px'
										}}
										aria-labelledby="dropdownMenuButton"
									>
										{upcomingCampaign &&
											upcomingCampaign.map((item, index) => {
												if (index < 10) {
													return (
														<p
															onClick={() => {
																setselectedCampaign(item.title);
																setselectedCampaignId(item._id);
																setShowCampaign(true);
															}}
														>
															<li>{item.title}</li>
														</p>
													);
												}
											})}
									</div>
								</div>
							</button>
						</div>

						<div className="suggest-quote-user-profile-container">
							{userDetail && (
								<Image
									className="suggestquote-user-profile-picture"
									src={userDetail.profileImg}
									rounded
									roundedCircle
									thumbnail
									fluid
								/>
							)}
							<strong
								className="suggestquote-user-name"
								style={{ fontWeight: 'bold' }}
							>
								&nbsp;{userDetail && userDetail.name}
							</strong>
						</div>

						<p className="quoteSuggestLabel" style={{ paddingLeft: '10px' }}>
							Description
						</p>
						<div>
							<div
								style={{
									padding: '10px'
								}}
								className="form-group"
							>
								<textarea
									onChange={e => setContent(e.target.value)}
									className="form-control suggest-quote-quote-textarea"
									placeholder="Add description..."
									style={{ background: '#e5e5e5', borderRadius: '0px  ' }}
								/>
							</div>

							{isError && (
								<Alert variant="danger">Oops Some Error Occurred !</Alert>
							)}

							{isSuccess && (
								<Alert variant="success">
									Content Submitted Successfully !
								</Alert>
							)}

							<div className="suggest-quote-submit-button-div">
								<Button
									variant="info"
									className="btn3_submit"
									onClick={() => sendSuggestedQuote()}
								>
									Submit
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
