import axios from "axios";
const CLOUDINARY_URL="https://api.cloudinary.com/v1_1/inspirer-world/image/upload/"
// const BASEURL="https://invt-project.herokuapp.com"
const PRESET="Inspirer"
const KEY="482942858718688"

export default async function uploadImageCampaign (img) {
        // Initial FormData
        const formData = new FormData();
        formData.append("file", img);
        formData.append("tags", `codeinfuse, medium, gist`);
        formData.append('upload_preset', PRESET);
        formData.append("api_key", KEY); // Replace API key with your own Cloudinary key
        formData.append("timestamp", (Date.now() / 1000) | 0);
        
       var res = await axios.post(CLOUDINARY_URL, formData, {
        headers: { "X-Requested-With": "XMLHttpRequest" },
      })
      return res.data.secure_url

}
