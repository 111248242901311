import React, { useState, useEffect } from 'react';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import Cropper from 'react-easy-crop';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Modal, Button, Spinner } from 'react-bootstrap';
import CommentIcon from '@material-ui/icons/Comment';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import {
	checkImageExistence,
	getCampaignParticipantsForUser,
	participateInCampaign,
	updateCampaignStats,
	updateUserProfileImage,
	viewArtwork,
	setContentCreator,
	getMyCampaigns
} from '../Api';
import resizeProfilePic from './HelperFunctions/resizeProfilePic';
import fileDownload from 'js-file-download';
import { BiTrophy, BiLike } from 'react-icons/bi';
import { Visibility } from '@material-ui/icons';
import StatsComponent from '../TeamLeader/StatsComponent';

import tropy_img2 from '../../images/Winner-Fill.png';
import Comments_img from '../../images/Comments.png';
import View_img from '../../images/VIew.png';
import Like_img from '../../images/Like.png';
import { uploadToCloudinary } from '../ImageCropper/canvasUtils';
import LoadingModal from '../LoadingModal/LoadingModal';

export default function CampaignCard({ c_number, item, getCampaigns, index }) {
	// console.log('Item is',item)

	const [NewArtwork, setNewArtwork] = useState(null);

	const [disabledClaim, setDisabledClaim] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [Pic2, setPic2] = useState('');
	const [imgUrl, setimgUrl] = useState(null);
	const [showList, setShowList] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [likes, setlikes] = useState(0);
	const [views, setviews] = useState(0);
	const [myCampaign, setMyCampaign] = useState([]);
	const [linkedinUrl, setlinkedinUrl] = useState('');
	const [comments, setcomments] = useState(0);
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [userImageUrl, setuserImageUrl] = useState(null);
	const [userImage, setuserImage] = useState(null);
	const [showCropper, setShowCropper] = useState(false);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState('');
	const handleListClose = () => setShowList(false);
	const handleListShow = () => setShowList(true);
	const handleShow = () => setShowList(true);
	// const handleListShow = () => setShowList(true);
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleCloseProfile = () => setShowModal(false);
	const handleShowProfile = () => setShowModal(true);
	const [participants, setParticipants] = useState([]);
	const [winner, setWinner] = useState(0);
	const [profileImgExist, setProfileImgExist] = useState(false);
	const [regularCreator, setRegularCreator] = useState(false);
	const [isDownloadingImg, setIsDownloadingImg] = useState(false);
	useEffect(() => {
		checkImage();
		getMyCamp();
		var Pic2 = JSON.parse(localStorage.getItem('InspirerWorld')).profileImg;
		setPic2(Pic2);
		let a = 0;
		if (item.winner) {
			if (item.winner.contentCreator) {
				a = winner + 1;
			}
			if (item.winner.engager) {
				setWinner(winner + 1);
				a = winner + 1;
			}
			if (item.winner.viewership) {
				setWinner(winner + 1);
				a = winner + 1;
			}
			setWinner(a);
		}
		// console.log(item);
	}, []);
	const checkImage = async () => {
		let res = await checkImageExistence();
		// console.log('exist',res)
		setProfileImgExist(res.data.isSet);
	};
	const getMyCamp = async () => {
		const res = await getMyCampaigns();
		setMyCampaign(res.res.data.campaigns);
	};
	const updateStats = async () => {
		let data = {
			likes,
			comments,
			views,
			linkedinUrl
		};

		const res = await updateCampaignStats(item._id, data);
		if (res.error) {
			alert('Some Error occurred');
		} else {
			getCampaigns();
			return handleClose();
		}
	};
	const imageChange = async image => {
		setuserImage(image);
		console.log('File is', image);
		//  const url = URL.createObjectURL(image);
		const url = URL.createObjectURL(image);
		setuserImageUrl(url);
		setShowCropper(true);
		//setShowCropper(true);
	};

	const onCropComplete = (croppedArea, croppedAreaPixels) => {
		console.log(croppedArea, croppedAreaPixels);
		setCroppedAreaPixels(croppedAreaPixels);
	};
	const imageUploader = async () => {
		const res = await uploadToCloudinary(
			userImageUrl,
			croppedAreaPixels,
			userImageUrl
		);

		let data = { profileImg: res.url };
		// console.log(res);
		let resBackend = await updateUserProfileImage(data);
		let resContent = await setContentCreator({
			isContentCreator: regularCreator
		});
		if (!resBackend.error) {
			const done = JSON.parse(localStorage.getItem('InspirerWorld'));
			console.log(res);
			let newdata = {
				token: done.token,
				isLeader: done.isLeader,
				userId: done.userId,
				teamId: done.teamId,
				type: done.type,
				profileImg: res.url,
				name: done.name,
				expiry: done?.expiry
			};
			console.log(newdata.profileImg, 'succes', resContent);

			await localStorage.setItem('InspirerWorld', JSON.stringify(newdata));
			// console.log("success")
			setProfileImgExist(true);
			handleCloseProfile();
			window.location.reload();
		} else {
			console.log(resBackend);
		}
	};
	const showParticipantesList = async () => {
		const res = await getCampaignParticipantsForUser(item._id);
		if (res.error) {
			alert('Some Error occurred');
		} else {
			console.log(res.data);
			setParticipants(res.data.participants);
			console.log(participants);
			setTimeout(() => {
				handleListShow();
			}, 500);
		}
	};

	const finalFuncToResizeProfilePic = async id => {
		if (profileImgExist) {
			setDisabled(true);
			setIsDownloadingImg(true);
			const res1 = await viewArtwork(id);
			// let url = res1.data;
			let filename = `${item.title}.jpeg`;
			// console.log('viewArtwork =>', res1);
			if (res1.data) {
				const artworkData = await axios.get(res1?.data, {
					responseType: 'blob'
				});
				fileDownload(artworkData?.data, filename);
				setNewArtwork(res1?.data);
			}
			setIsDownloadingImg(false);
			setDisabled(false);
			finalFuncToDownloadProfilePic(id);
		} else {
			handleShowProfile();
		}
	};

	const finalFuncToDownloadProfilePic = async id => {
		setDisabledClaim(true);
		const res2 = await participateInCampaign(id);
		// let url = res2.data.userArtWork;
		// let filename = `${item.title}.jpeg`;
		// axios
		//   .get(url, {
		//     responseType: "blob",
		//   })
		//   .then((res) => {
		//     fileDownload(res.data, filename);
		//   });
		getMyCamp();
		setDisabledClaim(false);
	};

	return (
		<div className="col-sm-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
			<Modal show={showCropper}>
				<Modal.Header closeButton={() => setShowCropper(false)}>
					<Modal.Title>Upload Image</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div
						id="crop-section"
						style={{
							width: '100vw',
							top: '0',
							bottom: '0',
							left: '0',
							right: '0',
							height: '50vh'
						}}
					>
						<Cropper
							style={{ zIndex: 100, backgroundColor: 'black' }}
							image={userImageUrl}
							crop={crop}
							zoom={zoom}
							aspect={780 / 780}
							onCropChange={crop => {
								setCrop(crop);
							}}
							onCropComplete={onCropComplete}
						/>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<input
						type="range"
						value={zoom}
						min="1"
						max="3"
						step="0.1"
						onChange={e => {
							setZoom(e.target.value);
						}}
						className="custom-range"
						id="customRange1"
					/>
					<br />
					<Button onClick={() => setShowCropper(false)}>Crop</Button>
				</Modal.Footer>
			</Modal>

			<Modal show={showModal} onHide={handleCloseProfile}>
				<Modal.Body>
					<div className="container row m-auto">
						<div className="row">
							<div className="col-12 p-2 text-center mb-5 m-auto">
								<h4>Thank for showing interest in Inspire World </h4>
								<span>Please let just know about you </span>
							</div>

							<div className="col-8 text-center ml-auto mr-auto mt-10 ">
								<h5 className="mt-4">Update your Profile Picture</h5>
								<input
									type="file"
									onChange={e => {
										imageChange(e.target.files[0]);
									}}
									id="chooseImage"
									className="d-none"
								/>
								<button
									className="upload-half-buttons mt-3  m-auto"
									onClick={() => document.getElementById('chooseImage').click()}
								>
									Click here to upload your picture
								</button>
							</div>
							<div className="col-8  ml-auto mr-auto mt-10 text-center ">
								<h5 className="mt-4">Are You a regular content Creator?</h5>

								<button
									id="CreatorTrue"
									onClick={() => {
										document.getElementById('CreatorTrue').style.background =
											'green';
										document.getElementById('CreatorFalse').style.background =
											'#146d9c';
										setRegularCreator(true);
									}}
									className="upload-half-buttons rounded   m-auto"
								>
									yes
								</button>
								{'                     '}

								<button
									id="CreatorFalse"
									onClick={e => {
										document.getElementById('CreatorTrue').style.background =
											'#146d9c';
										document.getElementById('CreatorFalse').style.background =
											'green';

										setRegularCreator(false);
									}}
									className="upload-half-buttons rounded   m-auto"
								>
									No
								</button>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseProfile}>
						Close
					</Button>
					<Button variant="primary" onClick={imageUploader}>
						Update
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Update Stats</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						<div className="form-group">
							<label htmlFor="password">
								Likes{' '}
								<img
									style={{ marginTop: '-4px' }}
									src="https://img.icons8.com/material-sharp/20/101f41/facebook-like.png"
								/>{' '}
							</label>
							<input
								type="number"
								onChange={e => setlikes(e.target.value)}
								className="form-control"
								id="password"
								placeholder="Number of likes"
							/>
						</div>

						<div className="form-group">
							<label htmlFor="password">
								{' '}
								Comments{' '}
								<img
									style={{ marginTop: '-4px' }}
									src="https://img.icons8.com/material-rounded/20/101f41/topic.png"
								/>{' '}
							</label>
							<input
								type="number"
								onChange={e => setcomments(e.target.value)}
								className="form-control"
								id="password"
								placeholder="Number of Comments"
							/>
						</div>

						<div className="form-group">
							<label htmlFor="password">
								Views{' '}
								<img
									style={{ marginTop: '-4px' }}
									src="https://img.icons8.com/material-sharp/20/101f41/visible.png"
								/>
							</label>
							<input
								type="number"
								onChange={e => setviews(e.target.value)}
								className="form-control"
								id="password"
								placeholder="Number of Views"
							/>
						</div>

						<div className="form-group">
							<label htmlFor="password">Linkedin Url :</label>
							<input
								type="text"
								onChange={e => setlinkedinUrl(e.target.value)}
								className="form-control"
								id="password"
								placeholder="Link of the Post"
							/>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
					<Button variant="primary" onClick={() => updateStats()}>
						Update
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal
				show={showList}
				onHide={handleListClose}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>Participants</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						{participants.map(item => {
							return (
								<div className="row form-group bg-light rounded p-3">
									<div className="col-md-3 col-5 text-center">
										<h6>{item.userId.name}</h6>
									</div>
									<div className="col-md-5 col-5 text-center">
										<h6>{item.userId.email}</h6>
									</div>
									<div className="col-md-4 col-12 text-center">
										<Visibility style={{ marginLeft: '6px' }} />
										<span>{item.views}</span>
										<CommentIcon style={{ marginLeft: '6px' }} />
										<span>{item.comments}</span>
										<ThumbUpAltIcon style={{ marginLeft: '6px' }} />

										<span>{item.likes}</span>
									</div>
								</div>
							);
						})}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleListClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>

			<LoadingModal loading={isDownloadingImg} message="Downloading Artwork" />
			{/* <div className="trending-card">
        <div className="campaign-header-div">
          <div className="trendingcard-leftside">
            {" "}
            <p className="serial-number-div">{index + 1}</p>
            <h5 className="card-title">{item.title}</h5>
          </div>

          <div className="trendingcard-rightside">
            <button className="btn btn-primary trendingcard-claimbutton mr-2">
              Claim Artwork{" "}
            </button>
            <button
              className="btn btn-primary trendingcard-claimbutton"
              
            >
              Click here to see all participants{" "}
            </button>
          </div>
        </div>

        <div className="trendingcard-image-div">
          {item.artWork && (
            <img
              src={NewArtwork || item.artWork.imgUrl}
              className="card-img-top"
              alt="..."
            />
          )}
        </div>

        <div className="trendingcard-header trendingcard-footer">
          <h6 className="footer-content-title">
            <img
              style={{ marginTop: "-4px" }}
              src="https://img.icons8.com/material-sharp/20/101f41/facebook-like.png"
            />{" "}
            : {item.likes}
          </h6>

          <h6 className="footer-content-title">
            <img
              style={{ marginTop: "-4px" }}
              src="https://img.icons8.com/material-rounded/20/101f41/topic.png"
            />{" "}
            : {item.comments}
          </h6>

          <h6 className="footer-content-title">
            <img
              style={{ marginTop: "-4px" }}
              src="https://img.icons8.com/material-sharp/20/101f41/visible.png"
            />{" "}
            : {item.views}
          </h6>

          <h6 className="footer-content-title view-winner-title">
            <Link style={{ color: "#146d9c" }} to="/winners">
              <BiTrophy size={26} />: Winners
            </Link>
          </h6>
        </div>
      </div> */}

			{index === 0 ? (
				<div className="campaign-card card">
					<div className="campaign-header-div campainCard">
						<div className="trendingcard-leftside">
							{' '}
							<p
								className="serial-number-div"
								style={{ fontWeight: 'bold', marginRight: '10px' }}
							>
								{c_number} {'  '}
							</p>
							<Link
								to={{
									pathname: `/campaign/${item._id}/participants`,
									state: { _id: item._id }
								}}
							>
								{' '}
								<h5 className="card-title" style={{ fontWeight: 'bold' }}>
									{item.title}
								</h5>
							</Link>
						</div>
						<div className="dropdown dropleft">
							<div
								style={{ cursor: 'pointer', color: 'white' }}
								type="button"
								className="transparent-bg"
								id="dropdownMenuButton"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								<svg
									width="50"
									className="margin-left-20 svg-width"
									height="6"
									viewBox="0 0 25 6"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M6.06 2.94C6.06 1.29 4.68 0 3.06 0C1.38 0 0 1.35 0 2.97C0 4.62 1.41 5.88 3.06 5.88C4.68 5.88 6.06 4.59 6.06 2.94Z"
										fill="#146d9c"
									/>
									<path
										d="M15.0541 2.94C15.0541 1.29 13.6741 0 12.0541 0C10.3741 0 8.99414 1.35 8.99414 2.97C8.99414 4.62 10.4041 5.88 12.0541 5.88C13.6741 5.88 15.0541 4.59 15.0541 2.94Z"
										fill="#146d9c"
									/>
									<path
										d="M24.0483 2.94C24.0483 1.29 22.6683 0 21.0483 0C19.3683 0 17.9883 1.35 17.9883 2.97C17.9883 4.62 19.3983 5.88 21.0483 5.88C22.6683 5.88 24.0483 4.59 24.0483 2.94Z"
										fill="#146d9c"
									/>
								</svg>
							</div>
							<div
								className="dropdown-menu"
								aria-labelledby="dropdownMenuButton"
							>
								<a className="dropdown-item f-25">
									<button
										className="btn btn-primary trendingcard-claimbutton mr-2"
										onClick={() => finalFuncToResizeProfilePic(item._id)}
									>
										{myCampaign.find(element => element._id == item._id)
											? ' Reclaim Artwork'
											: ' Claim Artwork'}
									</button>
								</a>
								<a className="dropdown-item f-25">
									<Link
										to={{
											pathname: `/campaign/${item._id}/participants`,
											state: { _id: item._id }
										}}
									>
										<button className="btn btn-primary trendingcard-claimbutton w-10 ">
											Click here to see all participants{' '}
										</button>
									</Link>
								</a>
							</div>
						</div>
					</div>

					<div
						className="trendingcard-image-div"
						style={{ padding: '15px 10px' }}
					>
						{/* <img  src={imgUrl} /> */}
						{item.artWork && (
							<img
								src={item.artWork.imgUrl}
								className="card-img-top"
								alt="..."
							/>
						)}
					</div>

					<div className="card-body" style={{ padding: 0, paddingLeft: '0' }}>
						<div
							className="trendingcard-header trendingcard-footer row"
							style={{ justifyContent: 'space-between' }}
						>
							<div className="col-3 text-center p-0 m-0">
								<StatsComponent link={Like_img} value={item.likes} w={33} />
							</div>
							<div className="col-3 text-center p-0 m-0">
								<StatsComponent
									link={Comments_img}
									value={item.comments}
									w={33}
								/>
							</div>
							<div className="col-3 text-center p-0 m-0">
								<StatsComponent link={View_img} value={item.views} w={33} />
							</div>
							<div className="col-3 text-center p-0 m-0 bg-primary trending-card-trophy">
								<div className="d-flex margin-auto">
									<Link
										className="margin-auto w-100"
										style={{ color: 'white' }}
										to="/winners"
									>
										<StatsComponent
											link={tropy_img2}
											value={winner}
											w={33}
											lastItem={true}
										/>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className="campaign-card card mt-4">
					<div className="campaign-header-div campainCard">
						<div className="trendingcard-leftside">
							{' '}
							<p
								className="serial-number-div"
								style={{ fontWeight: 'bold', marginRight: '10px' }}
							>
								{c_number} {'  '}
							</p>
							<h5 className="card-title" style={{ fontWeight: 'bold' }}>
								{item.title}
							</h5>
						</div>

						<div className="dropdown dropleft">
							<div
								style={{ cursor: 'pointer', color: 'white' }}
								type="button"
								className="transparent-bg"
								id="dropdownMenuButton"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								<svg
									width="50"
									className="margin-left-20 svg-width"
									height="6"
									viewBox="0 0 25 6"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M6.06 2.94C6.06 1.29 4.68 0 3.06 0C1.38 0 0 1.35 0 2.97C0 4.62 1.41 5.88 3.06 5.88C4.68 5.88 6.06 4.59 6.06 2.94Z"
										fill="#146d9c"
									/>
									<path
										d="M15.0541 2.94C15.0541 1.29 13.6741 0 12.0541 0C10.3741 0 8.99414 1.35 8.99414 2.97C8.99414 4.62 10.4041 5.88 12.0541 5.88C13.6741 5.88 15.0541 4.59 15.0541 2.94Z"
										fill="#146d9c"
									/>
									<path
										d="M24.0483 2.94C24.0483 1.29 22.6683 0 21.0483 0C19.3683 0 17.9883 1.35 17.9883 2.97C17.9883 4.62 19.3983 5.88 21.0483 5.88C22.6683 5.88 24.0483 4.59 24.0483 2.94Z"
										fill="#146d9c"
									/>
								</svg>
							</div>{' '}
							<div
								className="dropdown-menu"
								aria-labelledby="dropdownMenuButton"
							>
								<a className="dropdown-item">
									<button
										className="btn btn-primary trendingcard-claimbutton mr-2"
										onClick={() => finalFuncToResizeProfilePic(item._id)}
									>
										{myCampaign.find(element => element._id == item._id)
											? 'Reclaim Artwork'
											: ' Claim Artwork'}
									</button>
								</a>
								<a className="dropdown-item">
									<Link
										to={{
											pathname: `/campaign/${item._id}/participants`,
											state: { _id: item._id }
										}}
									>
										<button className="btn btn-primary trendingcard-claimbutton w-10">
											Click here to see all participants{' '}
										</button>
									</Link>
								</a>
							</div>
						</div>
					</div>

					<div
						className="trendingcard-image-div"
						style={{ padding: '15px 10px' }}
					>
						{/* <img  src={imgUrl} /> */}
						{item.artWork && (
							<img
								src={item.artWork.imgUrl}
								className="card-img-top"
								alt="..."
							/>
						)}
					</div>

					<div className="card-body" style={{ padding: 0 }}>
						<div
							className="trendingcard-header trendingcard-footer row"
							style={{ justifyContent: 'space-between' }}
						>
							<div className="col-3 text-center p-0 m-0">
								<StatsComponent link={Like_img} value={item.likes} w={33} />
							</div>
							<div className="col-3 text-center p-0 m-0">
								<StatsComponent
									link={Comments_img}
									value={item.comments}
									w={33}
								/>
							</div>
							<div className="col-3 text-center p-0 m-0">
								<StatsComponent link={View_img} value={item.views} w={33} />
							</div>
							<div className="col-3 text-center p-0 m-0 bg-primary trending-card-trophy">
								<div className="d-flex margin-auto">
									<Link
										className="margin-auto w-100"
										style={{ color: 'white' }}
										to="/winners"
									>
										<StatsComponent
											link={tropy_img2}
											value={winner}
											w={33}
											lastItem={true}
										/>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
