import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import TagList from '../TagList/campaignList';

import CampaignContentCard from './CampaignContentCard';
import {
	getCampaignsContentById,
	getCampaignsList
} from '../Api';

import CustomLoader from '../CustomLoader';
import './winner.scss';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Footer from '../Navbar/Footer';

export default function CampaignsWiseContent() {
	const [campaignData, setcampaignData] = useState([]);
	const [campaignDetails, setCampaignDetails] = useState({});
	const [campaignDataList, setcampaignDataList] = useState([]);
	const [loading, setLoading] = useState(false);
  let location=useParams()
	useEffect(() => {
		console.log(location.id)
		setLoading(true);
		campaignContentByID(location.id);
	
	}, [location]);
useEffect(() => {
	getCampaigns();
}, [location]);
	const getCampaigns = async () => {
	//	setLoading(true);
	//	campaignContentByID(location._id);
		const res = await getCampaignsList();
		if (!res.error && res.res) {
			if (res.res.data && res.res.data.campaigns) {
				if (res.res.data.campaigns.length > 0) {
					const campaign_name_list = res.res.data.campaigns.reverse();
					setcampaignDataList(campaign_name_list);
					console.log('campaign_name_list', location);
				
				}
			}
		}
	//	setLoading(false);
		return;
	};

	const campaignContentByID = async id => {
	//	setLoading(true);
		const res = await getCampaignsContentById(id);
		if (!res.error && res.res) {
			if (res.res.data && res.res.data.campaign) {
				if (
					res.res.data.campaign.participants &&
					res.res.data.campaign.participants.length > 0
				) {
					console.log('res.res.data.campaign.participants', res.res.data.campaign);
					setCampaignDetails({title:res.res.data.campaign.title,_id:res.res.data.campaign._id});
					setcampaignData(
						res.res.data.campaign.participants.filter(
							item => item.contentId && item.contentId.published
						)
					);
				}
			}
		}
		setLoading(false);
	};

	return (
		<>
			<Helmet>
				<title>Campaign</title>
			</Helmet>
			<div className="myCompainContainer">
				<br className="lineBreaker" />
				{loading&& <div style={{minHeight:'100vh'}}>	<CustomLoader loading={loading} />
			</div>}
{!loading &&
				<div className="container containerWrapper mt-2">
					<div className="row mb-3">
						<div className="col-lg-9  col-sm-9 " style={{ marginLeft: '0' }}>
							{campaignData &&
								campaignData.map((item, index) => {
									return (
										<CampaignContentCard
											getMyCampaigns={getCampaigns}
											key={index}
											item={item}
											index={index}
											campaignDetails={campaignDetails}
										/>
									);
								})}
						</div>

						<TagList
							campainList={true}
							tagData={campaignDataList}
							setTags={campaignContentByID}
							
						/>
					</div>
				</div>
}</div>
					<Footer/>
		</>
	);
}
