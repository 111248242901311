import React from 'react';
import { useHistory } from 'react-router-dom';
import './index.scss';

export default function TagList({
	onCallback,
	setTags,
	tagData = [],
	islogout = false
}) {
	const history = useHistory();
	const tagList = [...new Set(tagData)];
	return (
		<div className="col-lg-3 tagList">
			<div className="d-flex btnList">
				<span className="myQuotes">
					<a
						href="/myquotes"
						onClick={event => {
							if (islogout) {
								event.preventDefault();
								history.push({
									pathname: '/login',
									state: 'Suggested'
								});
							}
						}}
					>
						My Quotes
					</a>
				</span>
				<span>
					<a
						href="/suggestquote"
						onClick={event => {
							if (islogout) {
								event.preventDefault();
								history.push({
									pathname: '/login',
									state: 'Suggested'
								});
							}
						}}
					>
						Suggest A Quotes
					</a>
				</span>
			</div>
			<ul className="tagWrapper">
				{tagList.map((item, index) => (
					<li key={`tag-no-${index}`} onClick={() => setTags(item)}>
						{item}
					</li>
				))}
			</ul>
		</div>
	);
}
