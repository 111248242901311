import React, { useState, useEffect } from "react";
import mergeImages from "merge-images";
import Resizer from "react-image-file-resizer";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { participateInQuotes, viewQuote,updateQuoteStats } from "../Api";
import fileDownload from "js-file-download";


export default function CampaignCard({item,getQuotes}) {
    
  const [imgUrl, setimgUrl] = useState(null)
  const [ModifieProfilePic, setModifieProfilePic] = useState(null)

  var Pic2 = JSON.parse(localStorage.getItem("InspirerWorld")).profileImg
  // var Pic2 = 'https://i.imgur.com/DUb9Skz.png'

 
// console.log('Quote is',item)
  
  const [DisabledClaim, setDisabledClaim] = useState(false);
  const [show, setShow] = useState(false);
  const [likes, setlikes] = useState(item.likes);
  const [comments, setcomments] = useState(item.comments); 
  const [views, setviews] = useState(item.views);
 
  const [linkedinUrl, setlinkedinUrl] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const mergeImageDownload = async()=>{
    setDisabledClaim(true)
    const res2 = await viewQuote(item._id)
    // let url = res2.data.userArtWork
    // let filename = `${item.title}.jpeg`
    var a = document.createElement("a"); //Create <a>
    a.href = res2.data; //Image Base64 Goes here
    a.download = `${item.title}.jpeg`; //File name Here
    a.click(); //Downloaded file
    // fileDownload(res2.data, filename);
    setDisabledClaim(false)

  }

  const updateStats =async()=>{
    let data  = {
      likes,comments,views,linkedinUrl
    }
    
console.log('Stats is',data)

        const res = await updateQuoteStats(item._id,data)
        if(res.error){
          alert('Some Error occurred')
        }else{
          getQuotes()
          return handleClose()
        }
    }
    


  return ( 
      <div style={{maxHeight:'50vh'}} className="campaign-card mb-2 col-lg-3 ml-5 mr-auto  myquotes-card-col card">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Stats</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="form-group">
              <label htmlFor="password">
                Likes{" "}
                <img
                  style={{ marginTop: "-4px" }}
                  src="https://img.icons8.com/material-sharp/20/101f41/facebook-like.png"
                />{" "}
              </label>
              <input
                type="number"
                onChange={(e) => setlikes(e.target.value)}
                className="form-control"
                id="password"
                defaultValue={likes}
                placeholder="Number of likes"
              />
            </div>

            <div className="form-group">
              <label htmlFor="password">
                {" "}
                Comments{" "}
                <img
                  style={{ marginTop: "-4px" }}
                  src="https://img.icons8.com/material-rounded/20/101f41/topic.png"
                />{" "}
              </label>
              <input
                type="number"
                onChange={(e) => setcomments(e.target.value)}
                className="form-control"
                id="password"
                defaultValue={comments}
                placeholder="Number of Comments"
              />
            </div>

            <div className="form-group">
              <label htmlFor="password">
                Views{" "}
                <img
                  style={{ marginTop: "-4px" }}
                  src="https://img.icons8.com/material-sharp/20/101f41/visible.png"
                />{" "}
              </label>
              <input
                type="number"
                onChange={(e) => setviews(e.target.value)}
                className="form-control"
                id="password"
                defaultValue={views}
                placeholder="Number of Views"
              />
            </div>

            <div className="form-group">
              <label htmlFor="password">
              Linkedin Url :
               
              </label>
              <input
                type="text"
                onChange={(e) => setlinkedinUrl(e.target.value)}
                className="form-control"
                id="password"
                placeholder="Link of the Post"
              />
            </div>


          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={()=>{updateStats()}}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

        <div className="card-image">
          {/* <img  src={imgUrl} /> */}
          {item.artWork && (
            <img
              src={imgUrl || item.artWork.imgUrl}
              className="card-img-top"
              alt="..."
            />
          )}
        </div>

        <div className="card-body">
     
         {!DisabledClaim && <a style={{ color: "white" }} className="btn btn-primary res-btn-size-12" onClick={mergeImageDownload}>
            Reclaim
          </a>}
         {DisabledClaim && <a style={{ color: "white",fontSize: '14px' }} className="btn btn-primary" >
            Downloading...
          </a>}
          {/* <p style={{ color: "white" , marginTop : '10px' }} className="btn btn-warning" onClick={handleShow}>
            Update Stats
          </p> */}
          <span style={{ display: "flex", flexDirection: "row" }}>
        </span>
        </div>
      </div>
   

  );
}
