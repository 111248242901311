import React, { useState, useEffect } from 'react';
import './sidebarTrending.css';
import { getUserStats } from '../Api';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Image from 'react-bootstrap/Image';

export default function SidebarTrending() {
	const [isAuth, setisAuth] = useState(false);

	const [user, setUser] = useState('');
	const [isUser, setisUser] = useState(true);
	const history = useHistory();
	const location = useLocation();
	const [userStats, setUserStats] = useState({});
	useEffect(() => {
		getStats();
		var data = JSON.parse(localStorage.getItem('InspirerWorld'));

		if (data) {
			if (data.type === 'user') {
				setisAuth(true);
				setisUser(true);
				setUser(data);
				console.log('User is leader', data);
			} else {
				setisAuth(true);
				setisUser(false);
				history.push('/dashboard');
				setUser(data);
			}
		}
		return () => {};
	}, []);

	const getStats = async () => {
		let res = await getUserStats();
		console.log(res, 'stats');
		setUserStats(res.data);
	};

	return (
		<div
			className="col-lg-2  p-2  responsive-profile  sidebar-profile sidebarProfile"
			style={{ display: 'flex', flexDirection: 'column', paddingTop: '10px' }}
		>
			<Link to="/profile">
				<button
					className="suggest-quote-button radius-5"
					style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
				>
					<span
						className="float-right mb-1"
						style={{ fontSize: '10px', fontWeight: 500 }}
					>
						Edit
					</span>
				</button>
			</Link>
			<Link to="/profile" className="text-light "></Link>

			<div
				className="bg-primary radius-5 "
				style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
			>
				<div className="text-center mt-1">
					<Image
						style={{
							height: '72px',
							width: '72px',
							objectFit: 'cover',
							marginTop: '-30px',
							borderWidth: '3px',
							borderColor: '#f53d55',
							padding: '2px',
							background: 'transparent'
						}}
						className="img-thumbnail "
						src={user.profileImg}
						rounded
						roundedCircle
					/>

					<h6
						style={{ fontSize: '14px', fontWeight: 'bold' }}
						className="text-light  mt-3"
					>
						{user && user.name.toUpperCase()}
					</h6>
				</div>
				<div className="m-1">
					<div
						className="sidebar-list-item font-weight-normal"
						style={{ alignItems: 'unset' }}
					>
						<div style={{ padding: '10px' }}>
							<Link to="/mycampaigns">
								<span style={{ fontSize: '10px' }}>
									<strong>Campaign participant</strong>
								</span>
							</Link>
						</div>

						<div
							style={{
								padding: '10px',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								marginLeft: '7px'
							}}
						>
							<a classname="claim-hover" style={{ fontSize: '10px' }}>
								{userStats.noOfCampaignsParticipated}
							</a>
						</div>
					</div>
					<div className="sidebar-list-item" style={{ alignItems: 'unset' }}>
						<div style={{ padding: '10px' }}>
							<Link to="/mydownloadedquotes">
								<span style={{ fontSize: '10px' }}>
									<strong>Downloaded Quotes</strong>
								</span>
							</Link>
						</div>

						<div
							style={{
								padding: '10px',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								marginLeft: '7px'
							}}
						>
							<a classname="claim-hover" style={{ fontSize: '10px' }}>
								{userStats.noOfQuotesParticipated}
							</a>
						</div>
					</div>{' '}
					<div className="sidebar-list-item" style={{ alignItems: 'unset' }}>
						<div style={{ padding: '10px' }}>
							<Link to="/mysavedquotes">
								<span style={{ fontSize: '10px' }}>
									<strong>Saved Quotes</strong>
								</span>
							</Link>
						</div>

						<div
							style={{
								padding: '10px',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								marginLeft: '7px'
							}}
						>
							<a classname="claim-hover" style={{ fontSize: '10px' }}>
								{userStats.noOfSavedQuotes}
							</a>
						</div>
					</div>{' '}
					<div className="sidebar-list-item" style={{ alignItems: 'unset' }}>
						<div style={{ padding: '10px' }}>
							<Link to="/myAchievments">
								<span style={{ fontSize: '10px' }}>
									<strong>Achievements</strong>
								</span>
							</Link>
						</div>

						<div
							style={{
								padding: '10px',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								marginLeft: '7px'
							}}
						>
							<a classname="claim-hover" style={{ fontSize: '10px' }}>
								{userStats.noOfTimesWinner}
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
