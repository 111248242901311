import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Logo1 from '../../images/Logo-A.png';
import Home_Fill from '../../images/Home-Fill.png';
import Campaign_Fill from '../../images/Campaign-Fill.png';
import Winner_Fill from '../../images/Winner-Fill.png';
import Quotes_Fill from '../../images/Quotes-Fill.png';
import { useHistory } from 'react-router-dom';
import { FaUserAlt } from 'react-icons/fa';
import { BiLogOutCircle } from 'react-icons/bi';
import Image from 'react-bootstrap/Image';
import { FaUsers } from 'react-icons/fa';

import { useLocation } from 'react-router-dom';
import './styleNav.css';

import { getBirthdays, getUserStats, VerifyToken } from '../Api';

export default function Navbar() {
	const [isAuth, setisAuth] = useState(false);
	const [user, setUser] = useState('');
	const [isUser, setisUser] = useState(true);
	const history = useHistory();
	const location = useLocation();
	const [userStats, setUserStats] = useState({});

	useEffect(() => {
		var data = JSON.parse(localStorage.getItem('InspirerWorld'));
		const now = new Date();
		if (data && data.expiry > now.getTime()) {
			if (data.type === 'user') {
				setisAuth(true);
				getStats();
				setisUser(true);
				setUser(data);
				console.log('User is leader', data);
				checkLogin();
			} else {
				setisAuth(true);
				setisUser(false);

				checkLogin();
				setUser(data);
			}
		}
		return () => {};
	}, []);

	const checkLogin = async () => {
		let res = await VerifyToken();
		console.log(res, 'Token');
		if (res.res.data) {
			if (!res.res.data.valid) {
				handleLogout();
			}
		}
	};
	const getStats = async () => {
		let res = await getUserStats();
		console.log(res, 'stats');
		if (res && res.data) {
			setUserStats(res.data);
		}
	};
	const sidebarClose = () => {
		document.getElementById('toggle-sidebar').click();
	};
	const handleMenuClose = () => {
		let menu = document.getElementById('navbarsExampleDefault');
		menu.classList.remove('open');
	};
	const handleLogout = () => {
		window.location.href = '/';
		localStorage.removeItem('InspirerWorld');
		setisAuth(false);
		setUser('');
	};
	const [notifcation, setNotification] = useState([]);
	const [show, setShow] = useState(false);
	const handleClose = () => {
		setShow(false);
	};
	const handleShow = async () => {
		setShow(true);
	};
	console.log('isAuth && isUser', isAuth, isUser);
	return (
		<div
			className={`navbar-container ${
				isAuth && isUser ? 'userLoginNavbar' : ''
			}`}
		>
			<header className="header">
				<nav className={`navbar navbar-expand-lg`}>
					<div className="container position-relative">
						{(!isAuth || !isUser) && (
							<a className="navbar-brand" href="/">
								<img src={Logo1} style={{ maxWidth: '150px' }} alt="LinkediN" />
							</a>
						)}
						<button
							className={`navbar-toggler border-0`}
							type="button"
							data-toggle="offcanvas"
							id="toggle-sidebar"
						>
							<i className="navbar-toggler__bar navbar-toggler__bar--top" />
							<i className="navbar-toggler__bar navbar-toggler__bar--middle" />
							<i className="navbar-toggler__bar navbar-toggler__bar--bottom" />
						</button>
						{/* Navbar Toggle Button */}
						<div
							className={
								!isAuth || !isUser
									? 'offcanvas-collapse ml-auto'
									: 'offcanvas-collapse'
							}
							id="navbarsExampleDefault"
						>
							<ul className="navbar-nav">
								{!isAuth && (
									<>
										{/* <li className="nav-item" onClick={() => handleMenuClose()}>
                      <Link to="/">
                        <a className="nav-link">Home</a>
                      </Link>
                    </li> */}
										{/* <li className="nav-item" onClick={() => handleMenuClose()}>
											<Link to="/services">
												<a className="nav-link">Services</a>
											</Link>
										</li> */}

										<li className="nav-item" onClick={() => handleMenuClose()}>
											<Link to="/all-campaigns">
												{/* <Link to="#"> */}
												<a className="nav-link">Campaigns</a>
											</Link>
										</li>

										<li className="nav-item" onClick={() => handleMenuClose()}>
											<Link to="/allquotes">
												<a className="nav-link">Quotes</a>
											</Link>
										</li>

										<li className="nav-item" onClick={() => handleMenuClose()}>
											<Link to="/about">
												<a className="nav-link">About</a>
											</Link>
										</li>

										{/* <li className="nav-item" onClick={() => handleMenuClose()}>
											<Link to="/contact">
												<a className="nav-link">Contact</a>
											</Link>
										</li> */}

										<li className="nav-item" onClick={() => handleMenuClose()}>
											<Link to="/login">
												<a className="nav-link">Login</a>
											</Link>
										</li>
									</>
								)}

								{/* if admin is logged in */}

								{isAuth && !isUser && (
									<>
										<div className="dropdown">
											<button
												style={{
													background: 'none',
													color: 'white',
													border: 'none',
													width: '200px'
												}}
												className="btn btn-secondary dropdown-toggle"
												type="button"
												id="dropdownMenuButton"
												data-toggle="dropdown"
												aria-haspopup="true"
												aria-expanded="false"
											>
												<Image
													style={{
														height: '40px',
														width: '40px',
														objectFit: 'cover'
													}}
													src={user.profileImg}
													rounded
													roundedCircle
													thumbnail
													fluid
												/>
												&nbsp; {user.name}
											</button>

											<div
												className="dropdown-menu"
												style={{ padding: '10px' }}
												aria-labelledby="dropdownMenuButton"
											>
												<Link to="/profile">
													<a style={{ display: 'flex', alignItems: 'center' }}>
														<FaUserAlt color="#146d9c" /> Profile
													</a>
												</Link>

												<br />
												<Link onClick={handleLogout}>
													<a
														style={{
															display: 'flex',
															alignItems: 'center',
															color: '#f46a6a'
														}}
													>
														{' '}
														<BiLogOutCircle /> Logout
													</a>
												</Link>
											</div>
										</div>
									</>
								)}

								{/* If the user is logged in show these in navbar  */}
								{isAuth && isUser && (
									<ul className="show-mobile-nav">
										<div
											className="sidebar-list-item"
											style={{ alignItems: 'unset' }}
										>
											<div style={{ padding: '10px' }}>
												<Link to="/mycampaigns" onClick={sidebarClose}>
													<span
														style={{ color: 'white', fontSize: '14px' }}
														className="font-normal"
													>
														<strong>Campaign participant</strong>
													</span>
												</Link>{' '}
											</div>

											<div style={{ padding: '10px' }}>
												<a
													classname="claim-hover"
													style={{ color: 'white', fontWeight: 'bold' }}
												>
													{userStats.noOfCampaignsParticipated}
												</a>
											</div>
										</div>
										<div
											className="sidebar-list-item"
											style={{ alignItems: 'unset' }}
										>
											<div style={{ padding: '10px' }}>
												<Link to="/myquotes" onClick={sidebarClose}>
													<span
														style={{ color: 'white', fontSize: '14px' }}
														className="font-normal"
													>
														<strong>Downloaded Quotes</strong>
													</span>
												</Link>{' '}
											</div>

											<div style={{ padding: '10px' }}>
												<a
													classname="claim-hover"
													style={{ color: 'white', fontWeight: 'bold' }}
												>
													{userStats.noOfQuotesParticipated}
												</a>
											</div>
										</div>
										<div
											className="sidebar-list-item"
											style={{ alignItems: 'unset' }}
										>
											<div style={{ padding: '10px' }}>
												<Link to="/mysavedquotes" onClick={sidebarClose}>
													<span
														style={{ color: 'white', fontSize: '14px' }}
														className="font-normal"
													>
														<strong>Saved Quotes</strong>
													</span>
												</Link>{' '}
											</div>

											<div
												style={{
													padding: '10px',
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'center',
													marginLeft: '7px'
												}}
											>
												<a
													classname="claim-hover"
													style={{ color: 'white', fontWeight: 'bold' }}
												>
													{userStats.noOfSavedQuotes}
												</a>
											</div>
										</div>
										<div
											className="sidebar-list-item"
											style={{ alignItems: 'unset' }}
										>
											<div style={{ padding: '10px' }}>
												<Link to="/myAchievments" onClick={sidebarClose}>
													<span
														style={{ color: 'white', fontSize: '14px' }}
														className="font-normal"
													>
														<strong>Achievements</strong>
													</span>
												</Link>
											</div>

											<div
												style={{
													padding: '10px',
													display: 'flex',
													flexDirection: 'column',
													justifyContent: 'center',
													marginLeft: '7px'
												}}
											>
												<a
													classname="claim-hover"
													style={{ color: 'white', fontWeight: 'bold' }}
												>
													{userStats.noOfTimesWinner}
												</a>
											</div>
										</div>

										<div className="dropdown">
											<button
												style={{
													background: 'none',
													border: 'none',
													paddingLeft: '10px'
												}}
												className="btn btn-secondary dropdown-toggle navbar-dropdown"
												type="button"
												id="dropdownMenuButton"
												data-toggle="dropdown"
												aria-haspopup="true"
												aria-expanded="false"
											>
												<Image
													style={{
														height: '40px',
														width: '40px',
														objectFit: 'cover'
													}}
													src={user.profileImg}
													rounded
													roundedCircle
													thumbnail
													fluid
												/>{' '}
												{user.name}
											</button>
											<div
												className="dropdown-menu"
												style={{ padding: '10px' }}
												aria-labelledby="dropdownMenuButton"
											>
												{user.isLeader && (
													<Link
														to={'/myteamlist/' + user.teamId}
														onClick={() => handleMenuClose()}
													>
														<a
															style={{ display: 'flex', alignItems: 'center' }}
														>
															<FaUsers color="#146d9c" />
															My Team
														</a>
														<br />
													</Link>
												)}

												<Link to="/profile" onClick={() => handleMenuClose()}>
													<a style={{ display: 'flex', alignItems: 'center' }}>
														<FaUserAlt color="#146d9c" /> Profile
													</a>
												</Link>

												<br />
												<Link onClick={handleLogout}>
													<a
														style={{
															display: 'flex',
															alignItems: 'center',
															color: '#f46a6a'
														}}
													>
														{' '}
														<BiLogOutCircle /> Logout
													</a>
												</Link>
											</div>
										</div>
									</ul>
								)}

								{isAuth && isUser && (
									<ul className="show-desktop-nav">
										<Link className="navbar-brand" to="/mycampaigns">
											<img
												src={Logo1}
												style={{
													maxWidth: '137px',
													marginRight: '10px',
													borderRadius: 0
												}}
												alt="LinkediN"
											/>
										</Link>
										<input
											type="text"
											className="form-control nav-search"
											placeholder="Search"
											style={{ width: '300px' }}
										/>

										<Link className="nav-item shift-right" to="/mycampaigns">
											<a className="nav-link" style={{ paddingRight: 0 }}>
												<img style={{ width: '24px' }} src={Home_Fill} />
												<span style={{ fontSize: '12px' }}>Home</span>

												{location.pathname === '/mycampaigns' && (
													<center>
														<div
															style={{
																backgroundColor: '#fff',
																height: '2px',
																marginTop: '5px'
															}}
														></div>
													</center>
												)}
											</a>
										</Link>

										<Link className="nav-item" to="/campaigns">
											<a className="nav-link" style={{ paddingRight: 0 }}>
												<img style={{ width: '28px' }} src={Campaign_Fill} />
												<span style={{ fontSize: '12px' }}>Campaigns</span>
												{location.pathname === '/campaigns' && (
													<center>
														<div
															style={{
																backgroundColor: '#fff',
																height: '2px',
																marginTop: '5px'
															}}
														></div>
													</center>
												)}
											</a>
										</Link>

										<Link className="nav-item" to="/winners">
											<a className="nav-link" style={{ paddingRight: 0 }}>
												<img style={{ width: '20px' }} src={Winner_Fill} />
												<span style={{ fontSize: '12px' }}>Winners</span>
												{location.pathname === '/winners' && (
													<center>
														<div
															style={{
																backgroundColor: '#fff',
																height: '2px',
																marginTop: '5px'
															}}
														></div>
													</center>
												)}
											</a>
										</Link>

										<Link className="nav-item" to="/quotes">
											<a className="nav-link" style={{ paddingRight: 0 }}>
												<img style={{ width: '24px' }} src={Quotes_Fill} />
												<span style={{ fontSize: '12px' }}>Quotes</span>
												{location.pathname === '/quotes' && (
													<center>
														<div
															style={{
																width: '40px',
																backgroundColor: '#fff',
																height: '2px',
																marginTop: '5px'
															}}
														></div>
													</center>
												)}
											</a>
										</Link>

										<div className="dropdown">
											<button
												style={{
													background: 'none',
													border: 'none',
													paddingLeft: '10px',
													width: '100px',
													marginLeft: '20px'
												}}
												className="btn btn-secondary dropdown-toggle navbar-dropdown"
												type="button"
												id="dropdownMenuButton"
												data-toggle="dropdown"
												aria-haspopup="true"
												aria-expanded="false"
											>
												<Image
													style={{
														height: '40px',
														width: '40px',
														objectFit: 'cover'
													}}
													src={user.profileImg}
													rounded
													roundedCircle
													thumbnail
													fluid
												/>{' '}
												<br />
												<span className="ml-2">Me</span>
											</button>
											<div
												className="dropdown-menu"
												style={{ padding: '10px' }}
												aria-labelledby="dropdownMenuButton"
											>
												{user.isLeader && (
													<Link to={'/myteamlist/' + user.teamId}>
														<a
															style={{ display: 'flex', alignItems: 'center' }}
														>
															<FaUserAlt color="#146d9c" /> My Team
														</a>
														<br />
													</Link>
												)}

												<Link to="/profile">
													<a style={{ display: 'flex', alignItems: 'center' }}>
														<FaUserAlt color="#146d9c" /> Profile
													</a>
												</Link>

												<br />
												<Link onClick={handleLogout}>
													<a
														style={{
															display: 'flex',
															alignItems: 'center',
															color: '#f46a6a'
														}}
													>
														{' '}
														<BiLogOutCircle /> Logout
													</a>
												</Link>
											</div>
										</div>
									</ul>
								)}

								{/* If the user is logged in show these in navbar  */}
							</ul>
						</div>
					</div>
				</nav>
				{/* END Navbar */}
			</header>
		</div>
	);
}
