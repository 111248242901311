import React, { useState, useEffect } from 'react'
import SignupImage from '../../images/signup.jpg'
import { editQuoteDetail, editSuggestedQuotes, getAllAuthor, getQuoteCategoryAdmin, getQuoteDetail, getSuggestedQuotes, getSuggestedQuotesDetails, SignupUser, UploadCampaign } from '../Api'
import { Modal, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import ImageCropper from '../ImageCropper/ImageCropper'
//import ImageCropper from "../ImageCropper/ImageCropper";
import { uploadToCloudinary } from "../ImageCropper/canvasUtils";
import uploadImageCampaign from '../AdminCampaign/uploadImageCampaign'


export default function EditSuggestedQuote({match}) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [dataLoaded, setDataLoaded] = useState(false)
  const [loading, setLoading] = useState(false)

  const [campDesc, setcampDesc] = useState('')
  const [Category, setCategory] = useState(null);
  const [categoryData, setcategoryData] = useState([]);
  const [name, setname] = useState("");

  const [tags, setTags] = useState("");
 // const [campDesc, setcampDesc] = useState("");
 let location=useHistory();
 const [CategoryId, setCategoryId] = useState(false)
 const [userImageUrl, setuserImageUrl] = useState('');
 const [croppedAreaPixels, setCroppedAreaPixels] = useState("");
 
  const [authors, setauthors] = useState([]);
  const [selectedAuthor, setSelectedAuthor] = useState("");
  const [quoteData, setquoteData] =useState({});
  const [defaultAuthor, setDefaultAuthor] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [show, setShow] = useState(false);
  const [Error, setError] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [dimensions, setDimensions] = useState(null);
  const [scaleX, setscaleX] = useState(null);
  const [scaleY, setscaleY] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels);
    setCroppedAreaPixels(croppedAreaPixels);
  };
  const [userImage, setuserImage] = useState(null);
  const [croppedAreaPixelsArray, setCroppedAreaPixelsArray] = useState([]);

    useEffect(() => {
      getCategory()
        fetchSuggestedQuoteDetail(match.params.id)
    }, [])
    const fetchSuggestedQuoteDetail = async (quotesId) => {
        const res = await getSuggestedQuotesDetails(quotesId)
        console.log(res)
        setquoteData(res.res.data.quote)
        setSelectedAuthor(res.res.data.quote.quoteAuthor)
     let hashTags=res.res.data.quote.hashTags?.join(",")
     setTags(hashTags)
     setcampDesc(res.res.data.quote.quoteMsg)
     console.log(campDesc)
     setCategory(res.res.data.quote.category)
     setuserImage(res.res.data.quote.imageUrl)
     categoryData.find(ele=>{
      if(ele.title==Category){
        setCategoryId(ele._id)
      }
    })
    }
    const update_dimensions = (data, scaleX, scaleY) => {
      setDimensions(data);
      setscaleX(scaleX);
      setscaleY(scaleY);
      console.log("Parent =>", data);
    };
  const handleSubmit = async (e) => {
    e.preventDefault()
  let  croppedImage = await uploadImageCampaign(selectedImage);
    setLoading(true)
   const data = {
    "quoteMsg":campDesc,
    "quoteAuthor":selectedAuthor,
    "hashTags":tags,
    "quoteCategoryId":CategoryId,
    artWork: {
      imgUrl: croppedImage,
      x: dimensions.x * scaleX,
      y: dimensions.y * scaleY,
      h: dimensions.height * scaleY,
      w: dimensions.width * scaleX,
    },
  }
  console.log(data)
  const res=await editSuggestedQuotes(match.params.id,data)
  if(!res.error){
  
  setLoading(false)
  location.push({
    pathname: '/admin-quote',
    state: { detail: 'Success' }
  })
}
else{
  setLoading(false)
  alert(" Something went Wrong")

  
}
  }

  const imageChange = (image) => {
    setuserImage(image);
    console.log("File is", image);
    const url = URL.createObjectURL(image);
    setuserImageUrl(url);

    setShowCropper(true);
  };
  const updateImage = (data) => {
    setSelectedImage(data)
  }

  const getCategory = async (category) => {
    const res = await getQuoteCategoryAdmin();
    const res2 = await getAllAuthor();
    setauthors(res2.res.data.authors);
    console.log(res2.res.data);
    setcategoryData(res.data.quoteCategories);
    res.data.quoteCategories.map(item=>{
      if(category===item.title){
         setCategory(item._id);

      }
    })
  };
  return (
    <div className="container">
       
           <div className="row">
        <div className="col-lg-1"></div>
        <br />
        <br />
        <br />
        <div className="col-lg-10 mb-2 contact-form__wrapper p-1 order-lg-1">
          <center>
            <h2>Edit Quote</h2>
          </center>

          <br />

          <form
            action="#"
            className="contact-form form-validate"
            onSubmit={handleSubmit}
          >
            <div className="row">
              <div className="col-sm-12 mb-3">
                <div className="form-group">
                  <label className="required-field" htmlFor="firstName">
                    Author
                  </label>
                  <br />
                  <input
                    onChange={(e) => setname(e.target.value)}
                    type="text"
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    defaultValue={selectedAuthor}
                    placeholder="Author Name"
                  />
                </div>
              </div>
              <div className="col-sm-12 mb-3">
                <div className="form-group">
                  <label className="required-field" htmlFor="firstName">
                    Keywords
                  </label>
                  <input
                    onChange={(e) => setTags(e.target.value)}
                    type="text"
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    defaultValue={tags}
                    placeholder="#Motivation , #Engineer"
                  />
                </div>
              </div>

              <div className="col-sm-6 mb-3">
                <div className="form-group">
                  <label className="required-field" htmlFor="email">
                    Choose Category
                  </label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setCategoryId(e.target.value);
                    }}
                    //defaultValue={CategoryId}
                  >
                    {categoryData &&
                      categoryData.map((item) => {
                        return (
                          <option key={item._id} value={item._id}>
                            {item.title}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>

              <div className="col-sm-12 mb-3">
                <div className="form-group">
                  <label htmlFor="phone">Quote Description</label>
                  <textarea
                    onChange={(e) => setcampDesc(e.target.value)}
                    defaultValue={campDesc}
                    type="text"
                    className="form-control"
                    id="phone"
                    name="phone"
                    placeholder="description"
                  />
                </div>
              </div>
              <div className="col-sm-12 mb-3">
                <div className="form-group">
                  <label htmlFor="phone">ArtWork : </label> &nbsp;
                  <ImageCropper
                    update_dimensions={(item, scaleX, scaleY) =>
                      update_dimensions(item, scaleX, scaleY)
                    }
                    updateImage={(item) => updateImage(item)}
                  />
                </div>
              </div>

              <div className="col-sm-12 mb-3">
                {!loading?
                <input
                type="submit"
                value="Launch Quote"
                name="submit"
                className="btn btn-primary"
                />
              : <input
              type="submit"
              value="Loading..."
              name="submit"
              className="btn btn-primary"
              />
              }
              </div>
            </div>
          </form>
        </div>{" "}
        {/* End Contact Form Wrapper */}
      </div>
  


      </div>
    
  )
}
