import React, { useState, useEffect } from "react";

import { addLearnMore } from "../Api";

import { useHistory } from "react-router-dom";

export default function CreateLearnMore() {
  const history = useHistory();
  const [url, setUrl] = useState("");
  const [title, setTitle] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
 
  const handleSignup = async (e) => {
    e.preventDefault();
    if (title && url) {
      let data={
        title:title,url:url
      }
      let res = await addLearnMore(data);
      console.log(res)
      if (res) {
        alert(res.data.msg);
      } else {
        alert("Something went Wrong");
      }
    } else {
      alert("Fill Fields");
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-1"></div>
        <br />
        <br />
        <br />
        <div className="col-lg-10 mb-2 contact-form__wrapper p-1 order-lg-1">
          <center>
            <h2>Create Learn More</h2>
          </center>

          <br />

          <form
            action="#"
            className="contact-form form-validate"
            onSubmit={handleSignup}
          >
            <div className="row">
              <div className="col-sm-12 mb-3">
                <div className="form-group">
                  <label className="required-field" htmlFor="firstName">
                    Title
                  </label>
                  <input
                    onChange={(e) => setTitle(e.target.value)}
                    type="text"
                    className="form-control"
                    id="firstName"
                    name="Title"
                    placeholder="title"
                  />
                </div>
              </div>

              <div className="col-sm-12 mb-3">
                <div className="form-group">
                  <label className="required-field" htmlFor="email">
                    Url
                  </label>
                  <input
                    onChange={(e) => setUrl(e.target.value)}
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="url"
                  />
                </div>
              </div>
              <div className="col-sm-12 mb-3">
                <input type="submit" value="Add Learn More" name="submit" className="btn btn-primary" />
              </div>
            </div>
          </form>
        </div>{" "}
        {/* End Contact Form Wrapper */}
      </div>
    </div>
  );
}
