import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
	deleteQuoteUpcomingCampaign,
	editUpcomingCampaign,
	getUpcomingCampaign,
	postUpcomingCampaign
} from '../Api';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Modal, Button } from 'react-bootstrap';
import { Edit } from '@material-ui/icons';

function QuoteCategory() {
	const [newcategory, setnewcategory] = useState('');
	const [editcategory, seteditcategory] = useState('');
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [showEdit, setShowEdit] = useState(false);
	const handleCloseEdit = () => setShowEdit(false);
	const handleShowEdit = () => setShowEdit(true);
	const [data, setdata] = useState(null);
	const [activeId, setactiveId] = useState('');
	const [activename, setactivename] = useState('');

	useEffect(() => {
		getCategory();
		return () => {};
	}, []);

	const getCategory = async () => {
		const res = await getUpcomingCampaign();
		if (
			res &&
			res.res &&
			res.res.data &&
			res.res.data.upcomingCampaigns &&
			res.res.data.upcomingCampaigns.length > 0
		) {
			setdata(res.res.data.upcomingCampaigns.reverse());
		}
		// console.log(res.res.data.upcomingCampaigns)
	};
	const handleSubmit = async e => {
		e.preventDefault();
		const res = await postUpcomingCampaign({
			title: newcategory
		});
		alert('Upcoming Campaign Created!');
		handleClose();
		getCategory();
	};
	const handleSubmitEdit = async e => {
		e.preventDefault();

		const res = await editUpcomingCampaign(
			{
				title: editcategory
			},
			activeId
		);

		alert('Upcoming Campaign Edit!');
		handleCloseEdit();
		getCategory();
	};
	return (
		<div
			className="dashboard-container admin-campaign"
			style={{ marginTop: '20px' }}
		>
			<div className="container">
				<div className="row text-align-center">
					<div className="col-lg-6">
						<h3 className="last-campaign admin-last-campaign text-left">
							upcoming Campaigns
						</h3>
					</div>

					<div className="col-lg-3">
						<h3 className="last-campaign admin-last-campaign text-right">
							<button className="btn btn-info" onClick={handleShow}>
								Create Campaign
							</button>
						</h3>
					</div>
					<div className="col-lg-3">
						<h3 className="last-campaign admin-last-campaign text-right">
							<Link to="/admin-quote">
								<button className="btn btn-outline-info">
									Go back to Quotes
								</button>
							</Link>
						</h3>
					</div>
				</div>
			</div>

			<br />
			<table className="table table-bordered">
				<thead>
					<tr>
						<th
							scope="col"
							className="font-weight-bold"
							style={{ width: '12%' }}
						>
							Sr.
						</th>
						<th scope="col" className="font-weight-bold">
							Name
						</th>
						<th scope="col" className="font-weight-bold">
							Quotes
						</th>
						<th
							scope="col"
							className="font-weight-bold"
							style={{ width: '12%' }}
						>
							Actions
						</th>
					</tr>
				</thead>
				<tbody>
					{data &&
						data.map((item, index) => {
							return (
								<tr key={index}>
									<th scope="row">{index + 1}</th>
									<td>
										<Link to={'/upcomingCamapign-details/' + item._id}>
											{item.title}
										</Link>
									</td>
									<td>{item.noOfQuoteSuggestions}</td>

									<td>
										{/* <Link to={`/admin-quote/edit/${item._id}`}><EditIcon/></Link> &emsp;  */}
										<Edit
											onClick={() => {
												setactiveId(item._id);
												setactivename(item.title);
												handleShowEdit();
											}}
										/>
										<DeleteIcon
											onClick={async () => {
												await deleteQuoteUpcomingCampaign(item._id);
												getCategory();
											}}
										/>{' '}
										&emsp;
									</td>
								</tr>
							);
						})}
				</tbody>
			</table>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Create Upcoming Campaign</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form
						className="form container-fluid"
						onSubmit={e => handleSubmit(e)}
					>
						<div className="row">
							<div className="col-12">
								<div className="form-group">
									<label>Enter Campaign Name</label>
									<input
										type="text"
										placeholder="Enter text"
										required
										onChange={e => {
											setnewcategory(e.target.value);
										}}
									/>
								</div>
							</div>
							<div className="col-3">
								<Button variant="secondary" onClick={handleClose}>
									Close
								</Button>
							</div>
							<div className="col-3"></div>
							{/* <div className="col-3"></div> */}
							<div className="col-6">
								<input
									type="submit"
									className="btn btn-primary"
									style={{ float: 'right' }}
									value="Create"
								/>
							</div>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			<Modal show={showEdit} onHide={handleCloseEdit}>
				<Modal.Header closeButton>
					<Modal.Title>Create Upcoming Campaign</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="row">
						<div className="col-12">
							<div className="form-group">
								<label>Edit Campaign</label>
								<input
									type="text"
									placeholder="Enter text"
									defaultValue={activename}
									required
									onChange={e => {
										seteditcategory(e.target.value);
									}}
								/>
							</div>
						</div>
						<div className="col-3">
							<Button variant="secondary" onClick={handleCloseEdit}>
								Close
							</Button>
						</div>
						<div className="col-3"></div>
						{/* <div className="col-3"></div> */}
						<div className="col-6">
							<input
								type="submit"
								className="btn btn-primary"
								onClick={e => {
									handleSubmitEdit(e);
								}}
								style={{ float: 'right' }}
								value="Edit"
							/>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
}

export default QuoteCategory;
