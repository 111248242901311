import React, { useState, useEffect } from 'react';
import user_pic from '../../images/user.png';
import CustomLoader from '../CustomLoader';
import fileDownload from 'js-file-download';
import { uploadToCloudinary } from '../ImageCropper/canvasUtils';
import Cropper from 'react-easy-crop';
import SidebarTrending from '../SidebarComponent/SidebarTrending';
// import { Link } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { Image, Modal } from 'react-bootstrap';
import {
	setContentCreator,
	participateInQuotes,
	updateUserProfileImage,
	getUserQuoteDetails,
	checkImageExistence
} from '../Api';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
export default function Myquotes({ match }) {
	const [quoteData, setquoteData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const handleCloseProfile = () => setShowModal(false);
	const handleShowProfile = () => setShowModal(true);
	const [profileImgExist, setProfileImgExist] = useState(false);
	const [regularCreator, setRegularCreator] = useState(false);

	const [showModal, setShowModal] = useState(false);
	const [userImageUrl, setuserImageUrl] = useState(null);
	const [userImage, setuserImage] = useState(null);
	const [showCropper, setShowCropper] = useState(false);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState('');
	const [disabledClaim, setDisabledClaim] = useState(false);
	const onCropComplete = (croppedArea, croppedAreaPixels) => {
		console.log(croppedArea, croppedAreaPixels);
		setCroppedAreaPixels(croppedAreaPixels);
	};
	const imageChange = async image => {
		setuserImage(image);
		console.log('File is', image);
		//  const url = URL.createObjectURL(image);
		const url = URL.createObjectURL(image);
		setuserImageUrl(url);
		setShowCropper(true);
		//setShowCropper(true);
	};
	useEffect(() => {
		checkImage();
		getQuote();
	}, [0]);
	const getQuote = async () => {
		const id = match.params.id;
		if (id) {
			let res = await getUserQuoteDetails(id);
			console.log(res);
			setquoteData(res.res.data.quote);
		}
	};
	const imageUploader = async () => {
		const res = await uploadToCloudinary(
			userImageUrl,
			croppedAreaPixels,
			userImageUrl
		);

		let data = { profileImg: res.url };
		console.log(res);
		let resBackend = await updateUserProfileImage(data);
		let resContent = await setContentCreator({
			isContentCreator: regularCreator
		});
		if (!resBackend.error) {
			const done = JSON.parse(localStorage.getquotesData('InspirerWorld'));
			console.log(res);
			let newdata = {
				token: done.token,
				isLeader: done.isLeader,
				userId: done.userId,
				teamId: done.teamId,
				type: done.type,
				profileImg: res.url,
				name: done.name
			};
			console.log(newdata.profileImg, 'succes', resContent);

			//   localStorage.removequotesData('InspirerWorld')
			await localStorage.setquotesData(
				'InspirerWorld',
				JSON.stringify(newdata)
			);
			// console.log("success")
			setProfileImgExist(true);
			handleCloseProfile();
			window.location.reload();
		} else {
			console.log(resBackend);
		}
	};
	const checkImage = async () => {
		let res = await checkImageExistence();
		setProfileImgExist(res.data.isSet);
	};

	const finalFuncToDownloadProfilePic = async id => {
		console.log('hello i am running');
		if (profileImgExist) {
			setDisabledClaim(true);
			const res2 = await participateInQuotes(id);
			let url = res2.data.userArtWork;
			let filename = `${quoteData.desc}.jpeg`;
			axios
				.get(url, {
					responseType: 'blob'
				})
				.then(res => {
					fileDownload(res.data, filename);
				});
			setDisabledClaim(false);
		} else {
			handleShowProfile();
		}
	};

	return (
		<>
			{quoteData && (
				<Helmet>
					<title>{quoteData.desc}</title>
				</Helmet>
			)}
			<div>
				<div>
					<Modal show={showCropper}>
						<Modal.Header closeButton={() => setShowCropper(false)}>
							<Modal.Title>Upload Image</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div
								id="crop-section"
								style={{
									width: '100vw',
									top: '0',
									bottom: '0',
									left: '0',
									right: '0',
									height: '50vh'
								}}
							>
								<Cropper
									style={{ zIndex: 100, backgroundColor: 'black' }}
									image={userImageUrl}
									crop={crop}
									zoom={zoom}
									aspect={780 / 780}
									onCropChange={crop => {
										setCrop(crop);
									}}
									onCropComplete={onCropComplete}
								/>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<input
								type="range"
								value={zoom}
								min="1"
								max="3"
								step="0.1"
								onChange={e => {
									setZoom(e.target.value);
								}}
								className="custom-range"
								id="customRange1"
							/>
							<br />
							<Button onClick={() => setShowCropper(false)}>Crop</Button>
						</Modal.Footer>
					</Modal>

					<Modal show={showModal} onHide={handleCloseProfile}>
						<Modal.Body>
							<div className="container row m-auto">
								<div className="row">
									<div className="col-12 p-2 text-center mb-5 m-auto">
										<h4>Thank for showing interest in Inspire World </h4>
										<span>Please let just know about you </span>
									</div>

									<div className="col-8 text-center ml-auto mr-auto mt-10 ">
										<h5 className="mt-4">Update your Profile Picture</h5>
										<input
											type="file"
											onChange={e => {
												imageChange(e.target.files[0]);
											}}
											id="chooseImage"
											className="d-none"
										/>
										<button
											className="upload-half-buttons mt-3  m-auto"
											onClick={() =>
												document.getElementById('chooseImage').click()
											}
										>
											Click here to upload your picture
										</button>
									</div>
									<div className="col-8  ml-auto mr-auto mt-10 text-center ">
										<h5 className="mt-4">Are You a regular content Creator?</h5>

										<button
											id="CreatorTrue"
											onClick={() => {
												document.getElementById(
													'CreatorTrue'
												).style.background = 'green';
												document.getElementById(
													'CreatorFalse'
												).style.background = '#146d9c';
												setRegularCreator(true);
											}}
											className="upload-half-buttons rounded   m-auto"
										>
											yes
										</button>
										{'                     '}

										<button
											id="CreatorFalse"
											onClick={e => {
												document.getElementById(
													'CreatorTrue'
												).style.background = '#146d9c';
												document.getElementById(
													'CreatorFalse'
												).style.background = 'green';

												setRegularCreator(false);
											}}
											className="upload-half-buttons rounded   m-auto"
										>
											No
										</button>
									</div>
								</div>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={handleCloseProfile}>
								Close
							</Button>
							<Button
								variant="primary"
								id="uploadbtn"
								onClick={() => {
									document.getElementById('uploadbtn').style.background =
										'green';
									imageUploader();
								}}
							></Button>
						</Modal.Footer>
					</Modal>

					<br />

					<div className="container mt-2">
						<div className="row ">
							<div className="col-lg-12 col-md-12">
								{!quoteData && (
									<center>
										<h5>Loading.......</h5>
									</center>
								)}
								{quoteData && (
									<>
										<p className="card-text quote-card-quote-text">
											{quoteData.desc}
										</p>

										<div
											className="profile-short-div "
											style={{ background: '#F4F4F4' }}
										>
											<div className="profile-topbar-container">
												<div
													className=""
													style={{
														flexBasis: '50%',
														display: 'flex',
														alignquotesDatas: 'center'
													}}
												>
													{quoteData.author ? (
														<Image
															className="winner-dp-image trending-quotes-dp"
															src={quoteData.author.imageUrl}
															rounded
															roundedCircle
															thumbnail
															fluid
														/>
													) : (
														<Image
															className="winner-dp-image trending-quotes-dp"
															// src={quoteData.madeBy.profileImg}
															rounded
															roundedCircle
															thumbnail
															fluid
														/>
													)}
													{quoteData.author ? (
														<Link
															to={
																'/quotes/' +
																quoteData.author.name.replace(' ', '_')
															}
															style={{
																display: 'flex',
																alignItems: 'center'
															}}
														>
															{' '}
															<strong
																style={{
																	cursor: 'pointer',
																	fontWeight: 'bold'
																}}
																className="profile-topbar-name"
															>
																{quoteData.author.name}
															</strong>
														</Link>
													) : (
														<Link
															to={'/quotes/' + quoteData.madeBy.name}
															style={{
																display: 'flex',
																alignItems: 'center'
															}}
														>
															{' '}
															<strong
																style={{
																	cursor: 'pointer',
																	fontWeight: 'bold'
																}}
																className="profile-topbar-name"
															>
																{quoteData.madeBy.name.replace(' ', '_')}
															</strong>
														</Link>
													)}
												</div>
												{/* <div className="col-lg-1 col-md-1"></div> */}
												<div
													className="text-right d-flex"
													style={{
														flexBasis: '50%',
														justifyContent: 'flex-end'
													}}
												></div>
											</div>
										</div>

										<div className="new-quote-cards-container">
											<div className="new-quote-cards-left">
												<div className="trendingcard-image-div">
													{/* <img  src={imgUrl} /> */}

													<img
														src={quoteData.artWork.imgUrl}
														className="card-img-top"
														alt={quoteData.desc}
													/>
													<div className="quote-container-div">
														{/* <h5>{quoteData.title}</h5> */}

														<p className="card-text quote-card-quote-text">
															{quoteData.desc}
														</p>
													</div>
												</div>

												<br />

												<div className="save-div">
													<div className="additional-info">
														<div className="individual-info">
															<div className="info-heading">
																<span>Tags</span>
															</div>
															<div className="info-value">
																{quoteData.tags.map(i => {
																	return (
																		<span style={{ cursor: 'pointer' }}>
																			#{i}
																		</span>
																	);
																})}
															</div>
														</div>
													</div>
												</div>
											</div>

											<div className="trending-quotes-card-button-container">
												{disabledClaim ? (
													<div className="new-quote-cards-right">
														<span className="cmapign-download">
															Claiming...
														</span>
													</div>
												) : (
													<button
														className="trending-quotes-claim-button"
														onClick={() =>
															finalFuncToDownloadProfilePic(quoteData._id)
														}
													>
														{' '}
														<p className="block-claim-text">
															<img src={user_pic} className="user-pic-icon" />
															Click here to claim your quote poster
														</p>
													</button>
												)}
											</div>
										</div>
									</>
								)}
							</div>

							{/* <SidebarTrending /> */}
						</div>
					</div>
				</div>

				<br />

				{/* <center><h2 className="section__title mb-2">My quotes
        <img style={{marginLeft : '10px'}} src={Trendup}/></h2></center> 

 */}

				<CustomLoader loading={loading} />
			</div>
		</>
	);
}
