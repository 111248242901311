import React, { useState, useEffect } from 'react';
import { FaLinkedin, FaWhatsapp } from 'react-icons/fa';
import EditIcon from '@material-ui/icons/Edit';
import Pagination from '../Pagination/index';

import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { ActivatedUser, DeactivatedUser, getAllUsers } from '../Api';
export default function AllMember(props) {
	useEffect(() => {
		getUsers();
	}, []);
	const [searchTerm, setsearchTerm] = useState('');
	const [showSuccess, setshowSuccess] = useState(false);
	const [page, setPage] = useState(0);
	const [totalpage, setTotalPage] = useState(0);
	const [memberList, setmemberList] = useState([]);
	const [entryToShow, setentryToShow] = useState(null);
	const [entry, setEntry] = useState(10);

	const [allMemberListFilter, setAllMemberListFilter] = useState([]);
	const [memberListFilter, setmemberListFilter] = useState([]);
	const location = useLocation();
	const params = useParams();
	useEffect(() => {
		if (location.state) {
			setshowSuccess(true);
		}
		setInterval(() => {
			setshowSuccess(false);
		}, 3000);
	}, [location.pathname]);
	const getUsers = async () => {
		let res = await getAllUsers();
		if (res.error) {
			console.log(res.error);
		} else {
			let temp4 = [];
			res.res.data.users.forEach(member => {
				if (!member.deactivated) {
					temp4.push(member);
				}
			});
			setmemberList(temp4);
		}
	};

	useEffect(() => {
		if (memberList.length > 0) {
			const filteredList = memberList.filter((item, index) => {
				if (
					item.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
				) {
					return item;
				}
			});
			setTotalPage(Math.ceil(filteredList.length / entry));
			let array = filteredList.slice(page * entry, (page + 1) * entry);
			setAllMemberListFilter(filteredList);
			setmemberListFilter(array);
		}
	}, [entry, page, memberList, searchTerm]);

	useEffect(() => {
		switch (entryToShow) {
			case 'all':
				setAllMemberListFilter(memberList);
				setmemberListFilter(memberList);
				break;
			case 'active':
				let temp1 = [];
				memberList.forEach(member => {
					if (member.activity > 75) {
						temp1.push(member);
					}
				});
				setAllMemberListFilter(temp1);
				setmemberListFilter(temp1);
				break;
			case 'partial':
				let temp2 = [];
				memberList.forEach(member => {
					if (member.activity > 25 && member.activity <= 75) {
						temp2.push(member);
					}
				});
				setAllMemberListFilter(temp2);
				setmemberListFilter(temp2);
				break;
			case 'random':
				let temp3 = [];
				memberList.forEach(member => {
					if (member.activity > 1 && member.activity <= 25) {
						temp3.push(member);
					}
				});
				setAllMemberListFilter(temp3);
				setmemberListFilter(temp3);
				break;
			case 'na':
				let temp4 = [];
				memberList.forEach(member => {
					if (member.activity <= 1) {
						temp4.push(member);
					}
				});
				setAllMemberListFilter(temp4);
				setmemberListFilter(temp4);
				break;
			case 'nr':
				let temp5 = [];
				memberList.forEach(member => {
					if (
						new Date().getTime() <
						new Date(member.createdAt).getTime() + 604800000
					) {
						temp5.push(member);
					}
				});
				setAllMemberListFilter(temp5);
				setmemberListFilter(temp5);
				break;
			default:
				setAllMemberListFilter(memberList);
				setmemberListFilter(memberList);
		}
	}, [entryToShow]);

	return (
		<div className="allMemberWrapper">
			<div className="dashboard-main-container">
				{/* <div className="teams-title" style={{ marginLeft: '2em' }}>
					<h3
						className="last-campaign admin-last-campaign text-left"
						style={{ textTransform: 'capitalize' }}
					>
						{props.match.params.type} List
					</h3>
					<Link to="/add-member">
						<button className="btn btn-info create-team-btn">Add Member</button>
					</Link>
				</div> */}
				{showSuccess ? (
					<div className="text-center">
						<h3
							className=" text-light p-1 m-5 bg-success"
							style={{ textTransform: 'capitalize' }}
						>
							{location.state.detail}
						</h3>
					</div>
				) : null}
				<div className="dashboard-container admin-campaign">
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<div className="teams-search mb-3">
							<label className="teams-select">
								{/* <span style={{ whiteSpace: 'nowrap' }}>Activity Status :</span> */}
								<select
									style={{ paddingRight: '30px' }}
									name="datatable-buttons_length"
									aria-controls="datatable-buttons"
									className="custom-select teams-custom-select form-control"
									onChange={e => setentryToShow(e.target.value)}
								>
									<option value="all">Choose Activity Status</option>
									<option value="active">Active</option>
									<option value="partial">Partially Active</option>
									<option value="random">Random Active</option>
									<option value="na">Non Active</option>
									<option value="nr">New Registration</option>
								</select>
							</label>
							<Link to="/add-member">
								<button className="btn btn-info create-team-btn">
									Add Member
								</button>
							</Link>
						</div>
						<div className="teams-search">
							<label className="teams-select">
								Show
								<select
									style={{ paddingRight: '20px' }}
									name="datatable-buttons_length"
									aria-controls="datatable-buttons"
									className="custom-select teams-custom-select"
									onChange={e => {
										setEntry(e.target.value);
										setPage(0);
									}}
								>
									<option value="10">10</option>
									<option value="25">25</option>
									<option value="50">50</option>
									<option value="100">100</option>
								</select>
								entries
							</label>

							<label className="teams-searchbar">
								Search:
								<input
									type="search"
									className="form-control form-control-sm"
									placeholder=""
									aria-controls="datatable-buttons"
									onChange={e => setsearchTerm(e.target.value)}
								/>
							</label>
						</div>
					</div>

					<div className="container">
						<div className="row text-align-center">
							<div className="col-lg-6"></div>

							<div className="col-lg-6">
								<h3 className="last-campaign admin-last-campaign text-right"></h3>
							</div>
						</div>
					</div>

					<br />

					<table className="table table-bordered">
						<thead>
							<tr>
								<th
									scope="col"
									className="font-weight-bold"
									style={{ width: '10%' }}
								>
									<div className="up-down-container">
										<strong> Sr.</strong>
										<div className="up-down-arrows"></div>
									</div>
								</th>
								<th
									scope="col"
									className="font-weight-bold"
									style={{ width: '80%' }}
								>
									<div className="up-down-container">
										<strong> Member Name</strong>
										<div className="up-down-arrows"></div>
									</div>
								</th>

								<th
									scope="col"
									className="font-weight-bold"
									style={{ width: '80%' }}
								>
									<div className="up-down-container">
										<strong> Email</strong>
										<div className="up-down-arrows"></div>
									</div>
								</th>
								<th
									scope="col"
									className="font-weight-bold"
									style={{ width: '80%' }}
								>
									<div className="up-down-container">
										<strong> Regular Content Creator </strong>
										<div className="up-down-arrows"></div>
									</div>
								</th>
								<th
									scope="col"
									className="font-weight-bold"
									style={{ width: '80%' }}
								>
									<div className="up-down-container">
										<strong> Campaigns Participated</strong>
										<div className="up-down-arrows"></div>
									</div>
								</th>
								<th
									scope="col"
									className="font-weight-bold"
									style={{ width: '80%' }}
								>
									<div className="up-down-container">
										<strong> Activeness</strong>
										<div className="up-down-arrows"></div>
									</div>
								</th>
								{/* <th
									scope="col"
									className="font-weight-bold"
									style={{ width: '80%' }}
								>
									<div className="up-down-container">
										<strong> Edit </strong>
										<div className="up-down-arrows"></div>
									</div>
								</th> */}

								<th
									scope="col"
									className="font-weight-bold"
									style={{ width: '80%' }}
								>
									<div className="up-down-container">
										<strong> Actions</strong>
										<div className="up-down-arrows"></div>
									</div>
								</th>
								<th
									scope="col"
									className="font-weight-bold"
									style={{ width: '80%' }}
								>
									<div className="up-down-container">
										<strong> Active </strong>
										<div className="up-down-arrows"></div>
									</div>
								</th>

								{/* <th scope="col" className="font-weight-bold">
                  <div className="up-down-container">
                    <strong>Action</strong>
                    <div className="up-down-arrows"></div>
                  </div>
                </th> */}
							</tr>
						</thead>
						<tbody>
							{memberListFilter &&
								memberListFilter.map((item, index) => {
									if (!item.isContentCreator) {
										return (
											<tr key={index + 1}>
												<th scope="row">{entry * page + index + 1}</th>
												<td>
													<Link
														to={
															'/allmembers/' +
															item.name +
															'/profileview/' +
															item._id
														}
													>
														<div className="allMemberNameContainer">
															<img
																alt="profile dp"
																src={item?.profileImg}
																className="allMemberProfiledp"
															/>
															{item.name}
														</div>
													</Link>
												</td>
												<td>{item.email}</td>
												<td>{item.isContentCreator ? 'Yes' : 'No'}</td>
												<td>{item.campaigns[0]}</td>
												<td>{item.activity}%</td>

												<td>
													<div
														style={{
															display: 'flex',
															justifyContent: 'space-between',
															alignItems: 'center'
														}}
													>
														<Link
															className="mr-2"
															to={`/allmembers/${item.name}/profile/${item._id}`}
														>
															<EditIcon />
														</Link>
														<a
															className="mr-2"
															href={`https://wa.me/${item.phoneNumber}?text=`}
															target="_blank"
														>
															<img
																alt="logo"
																width="30"
																src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
															/>
														</a>
														<a href={item.linkedinUrl} target="_blank">
															<img
																alt="logo"
																width="25"
																src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c9/Linkedin.svg/1200px-Linkedin.svg.png"
															/>
														</a>
													</div>
												</td>
												<td>
													{item.type === 'user' ? (
														item.deactivated ? (
															<button
																className="btn btn-active btn-info create-team-btn"
																onClick={async () => {
																	await ActivatedUser(item._id);
																	getUsers();
																}}
															>
																Activate
															</button>
														) : (
															<button
																className="btn btn-active btn-info create-team-btn"
																onClick={async () => {
																	await DeactivatedUser(item._id);
																	getUsers();
																}}
															>
																Deactivated
															</button>
														)
													) : (
														"Owner Can't be Deactivated"
													)}
												</td>
											</tr>
										);
									} else {
										return (
											<tr key={index + 1}>
												<th scope="row">{entry * page + index + 1}</th>
												<td>
													<Link
														to={
															'/allmembers/' +
															item.name +
															'/profileview/' +
															item._id
														}
													>
														<div className="allMemberNameContainer">
															<img
																src={item?.profileImg}
																alt="profile dp"
																className="allMemberProfiledp"
															/>
															{item.name}
														</div>
													</Link>
												</td>
												<td>{item.email}</td>
												<td>{item.isContentCreator ? 'Yes' : 'No'}</td>
												<td>{item.campaigns[0]}</td>
												<td>{item.activity}%</td>

												<td>
													<div
														style={{
															display: 'flex',
															justifyContent: 'space-between',
															alignItems: 'center'
														}}
													>
														<Link
															className="mr-2"
															to={`/allmembers/${item.name}/profile/${item._id}`}
														>
															<EditIcon />
														</Link>
														<a
															href={`https://wa.me/${item.phoneNumber}?text=`}
															target="_blank"
															className="mr-2"
														>
															<img
																width="30"
																src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
															/>
														</a>
														<a href={item.linkedinUrl} target="_blank">
															<img
																width="25"
																src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c9/Linkedin.svg/1200px-Linkedin.svg.png"
															/>
														</a>
													</div>
												</td>
												<td>
													{item.type === 'user' ? (
														item.deactivated ? (
															<button
																className="btn btn-active btn-info create-team-btn"
																onClick={async () => {
																	await ActivatedUser(item._id);
																	getUsers();
																}}
															>
																Activate
															</button>
														) : (
															<button
																className="btn btn-active btn-info create-team-btn"
																onClick={async () => {
																	await DeactivatedUser(item._id);
																	getUsers();
																}}
															>
																Deactivated
															</button>
														)
													) : (
														"Owner Can't be Deactivated"
													)}
												</td>
											</tr>
										);
									}
								})}
						</tbody>
					</table>
					<Pagination
						page={page}
						entryToShow={10}
						setPage={setPage}
						totalpage={totalpage}
						totalData={allMemberListFilter.length}
					/>
				</div>
				<div></div>
			</div>
		</div>
	);
}
