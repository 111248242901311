import { Add } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { Link, useLocation } from 'react-router-dom';
import {
	getProfile,
	participateInCampaign,
	participateInQuotes,
	updateUserProfileImage,
	viewArtwork,
	getUserStats
} from '../Api';
import { uploadToCloudinary } from '../ImageCropper/canvasUtils';
import Cropper from 'react-easy-crop';
import fileDownload from 'js-file-download';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

export default function ProfileStatsBar(props) {
	const handleClose = () => {
		setShow(false);
		setshowModal(false);
	};

	const history = useHistory();
	const [croppedAreaPixelsArray, setCroppedAreaPixelsArray] = useState([]);

	const [show, setShow] = useState(false);
	const [showModal, setshowModal] = useState(false);
	const location = useLocation();
	const [showStats, setShowStats] = useState(false);

	const handleShow = () => setShow(true);
	const [NewArtwork, setNewArtwork] = useState(null);
	const [disabledClaim, setDisabledClaim] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [userImage, setuserImage] = useState(null);
	const [showCropper, setShowCropper] = useState(false);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState('');
	const [userImageUrl, setuserImageUrl] = useState('');
	const [userStats, setUserStats] = useState();
	const [userDetail, setuserDetail] = useState(null);
	var data = JSON.parse(localStorage.getItem('InspirerWorld'));
	const [profileData, setprofileData] = useState(null);

	const imageChange = async image => {
		setuserImage(image);
		console.log('File is', image);
		//  const url = URL.createObjectURL(image);
		const url = URL.createObjectURL(image);
		setuserImageUrl(url);
		setShowCropper(true);
		//setShowCropper(true);
	};

	const imageUploader = async () => {
		const res = await uploadToCloudinary(
			userImageUrl,
			croppedAreaPixels,
			userImageUrl
		);

		let data = { profileImg: res.url };
		let resBackend = await updateUserProfileImage(data);
		if (!resBackend.error) {
			const done = JSON.parse(localStorage.getItem('InspirerWorld'));
			console.log(res, 'succes', done);
			console.log(res);
			let newdata = {
				token: done.token,
				isLeader: done.isLeader,
				userId: done.userId,
				teamId: done.teamId,
				type: done.type,
				profileImg: res,
				name: done.name,
				expiry: done?.expiry
			};
			//   localStorage.removeItem('InspirerWorld')
			await localStorage.setItem('InspirerWorld', JSON.stringify(newdata));
			// console.log("success")
			setShowCropper(false);
			alert('Profile Image Changed');
		} else {
			console.log(resBackend);
		}
	};

	const finalFuncToDownloadProfilePic = async id => {
		setDisabledClaim(true);
		let res2;
		if (props.type === 'quote') {
			res2 = await participateInQuotes(id);
		} else {
			res2 = await participateInCampaign(id);
		}

		setDisabledClaim(false);
	};

	const finalFuncToResizeProfilePic = async id => {
		setDisabled(true);
		props.setIsDownloadingImg(true);
		const res1 = await viewArtwork(id);
		console.log('viewArtwork =>', res1);
		let filename = `${props.topCampaign.title}.jpeg`;
		console.log('viewArtwork =>', res1);
		axios
			.get(res1.data, {
				responseType: 'blob'
			})
			.then(res => {
				fileDownload(res.data, filename);
			});
		setNewArtwork(res1.data);
		setDisabled(false);
		finalFuncToDownloadProfilePic(id);
		props.setIsDownloadingImg(false);
	};

	const onCropComplete = (croppedArea, croppedAreaPixels) => {
		console.log(croppedArea, croppedAreaPixels);
		setCroppedAreaPixels(croppedAreaPixels);
	};

	useEffect(() => {
		if (location.pathname === '/mycampaigns') {
			setShowStats(true);
		}
		if (data) {
			setuserDetail(data);
			setuserImageUrl(data.profileImg);
			console.log(props);
			getData();
			getStats();
		}
		return () => {};
	}, [1]);

	const getData = async () => {
		let res = await getProfile();
		console.log(res.res.data.user);
		setprofileData(res.res.data.user);
	};
	const getStats = async () => {
		let res = await getUserStats();
		setUserStats(res.data);
	};

	return (
		<div
			className={`container claim-artwork-div  profileStatusBar ${
				userDetail && userDetail?.isLeader ? '' : 'isNotLeader'
			}`}
		>
			<Modal show={showCropper} onHide={handleClose}>
				<Modal.Header closeButton={() => setShowCropper(false)}>
					<Modal.Title>Upload Image</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div
						id="crop-section"
						style={{
							width: '100vw',
							top: '0',
							bottom: '0',
							left: '0',
							right: '0',
							height: '50vh'
						}}
					>
						<Cropper
							style={{ zIndex: 100, backgroundColor: 'black' }}
							image={userImageUrl}
							crop={crop}
							zoom={zoom}
							aspect={780 / 780}
							onCropChange={crop => {
								setCrop(crop);
							}}
							onCropComplete={onCropComplete}
						/>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<input
						type="range"
						value={zoom}
						min="1"
						max="3"
						step="0.1"
						onChange={e => {
							setZoom(e.target.value);
						}}
						className="custom-range"
						id="customRange1"
					/>
					<br />
					<Button onClick={imageUploader}>Crop</Button>
				</Modal.Footer>
			</Modal>
			<div className="statusBar">
				<div
					className="row justify-content-center user-top-bar-stats"
					style={{ background: '#166d9c', height: 94 }}
				>
					<div
						className="col-lg-10"
						style={{
							padding: '4px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-around'
						}}
					>
						<div
							// style={{ flex: 1 }}
							onClick={() => {
								history.push('/profile');
							}}
						>
							<Image
								style={{ height: '60px', width: '60px', objectFit: 'cover' }}
								src={userImageUrl}
								rounded
								roundedCircle
								thumbnail
								fluid
							/>
							<span
								style={{
									marginLeft: '-6px',
									marginTop: '10px',
									display: 'none'
								}}
								onClick={() => {
									document.getElementById('myfileUpload').click();
								}}
							>
								<Add
									size={30}
									style={{
										backgroundColor: '#146d9c',
										borderRadius: '7px',
										color: 'white'
									}}
								/>
							</span>
							<input
								type="file"
								style={{ display: 'none' }}
								id="myfileUpload"
								name="myfile"
								onChange={e => {
									imageChange(e.target.files[0]);
								}}
							/>
						</div>

						{profileData && (
							<div
								style={{
									flex: 1,
									display: 'flex',
									justifyContent: 'space-around',
									color: 'white !important',
									padding: '0 15px'
								}}
							>
								<span style={{ color: 'white', textAlign: 'center' }}>
									{' '}
									{profileData.likes} <br /> likes
								</span>

								<span style={{ color: 'white', textAlign: 'center' }}>
									{' '}
									{profileData.comments} <br /> comments
								</span>
								<span style={{ color: 'white', textAlign: 'center' }}>
									{' '}
									{profileData.views} <br /> views
								</span>
								{userStats && userStats.noOfTimesWinner && (
									<span style={{ color: 'white', textAlign: 'center' }}>
										{userStats.noOfTimesWinner} <br /> wins
									</span>
								)}
							</div>
						)}
					</div>
				</div>
				{userDetail && userDetail?.isLeader && (
					<Link className="myTeamBar" to={'/myteamlist/' + userDetail?.teamId}>
						My Team
					</Link>
				)}
			</div>
			{showStats ? (
				<>
					<br className="breakLineContainer" />
					<div className="container">
						<div className="row justify-content-between">
							<div
								className="top-half-buttons"
								style={{ width: '49%', background: 'rgb(0, 172, 7)' }}
							>
								<Link to="/learn-more">
									<strong style={{ fontSize: '14px', fontWeight: 'bold' }}>
										Learn More
									</strong>
								</Link>
							</div>
							{props.topCampaign ? (
								<div
									style={{ width: '49%' }}
									className="  top-half-buttons"
									onClick={() =>
										finalFuncToResizeProfilePic(props.topCampaign._id)
									}
								>
									<Link to="#">
										<strong style={{ fontSize: '14px', fontWeight: 'bold' }}>
											Claim Artwork
										</strong>
									</Link>
								</div>
							) : (
								<button
									disabled
									className="col-6 top-half-buttons bg-secondary"
								>
									<Link to="">
										<strong style={{ fontSize: '14px', fontWeight: 'bold' }}>
											Claim Artwork
										</strong>
									</Link>
								</button>
							)}
						</div>
					</div>
				</>
			) : null}
		</div>
	);
}
