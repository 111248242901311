import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  deleteAuthor,
  getUpcomingCampaign,
  postUpcomingCampaign,createAuthor,getAllAuthor
} from "../Api";
import DeleteIcon from "@material-ui/icons/Delete";
import { Modal, Button } from "react-bootstrap";
import Cropper from "react-easy-crop";
import { uploadToCloudinary } from "../ImageCropper/canvasUtils";
import { Edit } from "@material-ui/icons";

function QuoteCategory() {
  const [name, setname] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [userImage, setuserImage] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState("");
  const [userImageUrl, setuserImageUrl] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [data, setdata] = useState(null);
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels);
    setCroppedAreaPixels(croppedAreaPixels);
  };
  const imageUploader = async (e) => {
    e.preventDefault()
    const res = await uploadToCloudinary(
      userImageUrl,
      croppedAreaPixels,
      userImageUrl
    );

    let data = { imageUrl: res.url,name:name };
    const res2=await createAuthor(data);
    if(res2.data){
    alert("Author Created!");

    }
    else{
    alert("Something went Wrong");
      
    }
  }
  const imageChange = async (image) => {
    setuserImage(image);
    console.log("File is", image);
    //  const url = URL.createObjectURL(image);
    const url = URL.createObjectURL(image);
    setuserImageUrl(url);
    setShowCropper(true);
    //setShowCropper(true);
  };
  useEffect(() => {
    getCategory();
    return () => {};
  }, []);

  const getCategory = async () => {
    const res = await getAllAuthor();
    setdata(res.res.data.authors);
     console.log(res.res.data)
  };
 
  return (
    <div
      className="dashboard-container admin-campaign"
      style={{ marginTop: "20px" }}
    >
      <Modal show={showCropper} onHide={() => setShowCropper(false)}>
        <Modal.Header closeButton={() => setShowCropper(false)}>
          <Modal.Title>Upload Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            id="crop-section"
            style={{
              width: "100vw",
              top: "0",
              bottom: "0",
              left: "0",
              right: "0",
              height: "50vh",
            }}
          >
            <Cropper
              style={{ zIndex: 100, backgroundColor: "black" }}
              image={userImageUrl}
              crop={crop}
              zoom={zoom}
              aspect={780 / 780}
              onCropChange={(crop) => {
                setCrop(crop);
              }}
              onCropComplete={onCropComplete}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <input
            type="range"
            value={zoom}
            min="1"
            max="3"
            step="0.1"
            onChange={(e) => {
              setZoom(e.target.value);
            }}
            className="custom-range"
            id="customRange1"
          />
          <br />
          <Button onClick={()=>setShowCropper(false)}>Crop</Button>
        </Modal.Footer>
      </Modal>
      <div className="container">
        <div className="row text-align-center">
          <div className="col-lg-6">
            <h3 className="last-campaign admin-last-campaign text-left">
              upcoming Campaigns
            </h3>
          </div>

          <div className="col-lg-3">
            <h3 className="last-campaign admin-last-campaign text-right">
              <button className="btn btn-info" onClick={handleShow}>
                Create Author
              </button>
            </h3>
          </div>
          <div className="col-lg-3">
            <h3 className="last-campaign admin-last-campaign text-right">
              <Link to="/admin-quote">
                <button className="btn btn-outline-info">
                  Go back to Quotes
                </button>
              </Link>
            </h3>
          </div>
        </div>
      </div>

      <br />
      <table className="table table-bordered">
        <thead>
          <tr>
            <th
              scope="col"
              className="font-weight-bold"
              style={{ width: "12%" }}
            >
              Sr.
            </th>
            <th scope="col" className="font-weight-bold">
              Name
            </th>
            <th scope="col" className="font-weight-bold">
              Image
            </th>

            <th
              scope="col"
              className="font-weight-bold"
              style={{ width: "12%" }}
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => {
              return (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>
                      {item.name}
                  </td>
                  <td>
                      <img
                        style={{ maxWidth: "70px" }}
                        src={item.imageUrl}
                      />
                    </td>
                  <td>
                    {/* <Link to={`/admin-quote/edit/${item._id}`}><EditIcon/></Link> &emsp;  */}
                    <Link to={`/author/edit/${item._id}`}>
                          <Edit />
                        </Link>
                    <DeleteIcon
                      onClick={async () => {
                        await deleteAuthor(item._id);
                        getCategory();
                      }}
                    />{" "}
                    &emsp;
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Author</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            className="form container-fluid"
            onSubmit={(e) => imageUploader(e)}
          >
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label>Enter Author Name</label>
                  <input
                    type="text"
                    placeholder="Enter text"
                    required
                    onChange={(e) => {
                      setname(e.target.value);
                    }}
                  />
                
                </div>
                <div className="form-group">
                  <input
                    type="file"
                    id="myfileUpload"
                    name="myfile"
                    onChange={(e) => {
                      imageChange(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
              <div className="col-3">
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </div>
              <div className="col-3"></div>
              {/* <div className="col-3"></div> */}
              <div className="col-6">
                <input
                  type="submit"
                  className="btn btn-primary"
                  style={{ float: "right" }}
                  value="Create"
                />
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default QuoteCategory;
