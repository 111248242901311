import React, { useState, useEffect } from 'react';
import {
	suggestQuote,
	getQuoteCategory,
	getUpcomingCampaignForUser,
	postQuoteForUpcomingCampaign,
	postTopic
} from '../Api';
import { Helmet } from 'react-helmet-async';
import { Button, Alert, Image } from 'react-bootstrap';
import './SuggestQuote.css';
import './quotes.scss';
import { useHistory } from 'react-router-dom';

export default function SuggestQuote() {
	const [checked, setChecked] = useState(false);
	const [isError, setisError] = useState(null);
	const [isSuccess, setisSuccess] = useState(null);
	const [topicDescription, setTopicDescription] = useState(null);
	const [quoteAuthorName, setquoteAuthorName] = useState(null);
	const [userDetail, setuserDetail] = useState(null);
	const [topicTitle, setTopicTitle] = useState(null);
	const [selectedCategory, setselectedCategory] = useState('Choose Category');
	const [selectedCategoryId, setselectedCategoryId] = useState('');

	const [selectedCampaign, setselectedCampaign] = useState(
		'For Upcoming Campaign'
	);
	const [userData, setUserData] = useState(null);
	const [selectedCampaignId, setselectedCampaignId] = useState('');
	const [categoryAll, setcategoryAll] = useState(null);
	const [upcomingCampaign, setUpcomingCampaign] = useState([]);
	const [showCampaign, setShowCampaign] = useState(false);
const router=useHistory()
	const sendSuggestedQuote = async () => {
		let data = {
			topic: topicTitle,
			desc: topicDescription
		};

		let res = await postTopic(data);

		if (res.error) {
			setisError(true);
			setisSuccess(false);
		} else {
			setisSuccess(true);
			setisError(false);
		}
	};

	useEffect(() => {
		var data = JSON.parse(localStorage.getItem('InspirerWorld'));
		setUserData(data);
		console.log(router)
		if (data) {
			setuserDetail(data);
			console.log('Data is', data);
			getCategory();
		}
		else{
			router.push({
				pathname: '/login',
				state:'suggesttopic'
			  })
		}

		return () => {};
	}, [0]);

	const getCategory = async () => {
		let res = await getQuoteCategory();
		let res1 = await getUpcomingCampaignForUser();
		if (res.error) {
		} else {
			console.log('Res category is', res1);
			setcategoryAll(res.data.quoteCategories);
			setUpcomingCampaign(res1.res.data.upcomingCampaigns.reverse());
		}
	};

	return (
		<>
			<Helmet>
				<title>Suggesting Topic</title>
			</Helmet>
			{userData && (
			<div className="container custom-suggest-quote mt-5 suggestQuotes">
				<div className="row">
					<div
						className="col-lg-12 col-md-12"
						style={{ paddingLeft: 0, paddingRight: 0, marginTop: '-10px' }}
					>
						<div className="button-half-div" style={{ display: 'none' }}>
							<button
								className="btn2 rounded btn-danger"
								style={{ backgroundColor: '#f53d55' }}
							>
								<div className="dropdown">
									<button
										style={{
											border: 'none',
											padding: 0
										}}
										className="btn btn-secondary long-text-wrap dropdown-toggle navbar-dropdown"
										type="button"
										id="dropdownMenuButton"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										<strong>{selectedCampaign} </strong>
									</button>
									<div
										className="dropdown-menu text-center res-left"
										style={{
											padding: '10px',
											cursor: 'pointer',
											overflow: 'scroll',
											maxHeight: '400px'
										}}
										aria-labelledby="dropdownMenuButton"
									>
										{upcomingCampaign &&
											upcomingCampaign.map((item, index) => {
												if (index < 10) {
													return (
														<p
															onClick={() => {
																setselectedCampaign(item.title);
																setselectedCampaignId(item._id);
																setShowCampaign(true);
															}}
														>
															<li>{item.title}</li>
														</p>
													);
												}
											})}
									</div>
								</div>
							</button>
						</div>

						<div className="suggest-quote-user-profile-container">
							{userDetail && (
								<Image
									className="suggestquote-user-profile-picture"
									src={userDetail.profileImg}
									rounded
									roundedCircle
									thumbnail
									fluid
								/>
							)}
							<strong
								className="suggestquote-user-name"
								style={{ fontWeight: 'bold' }}
							>
								&nbsp;{userDetail && userDetail.name} |&nbsp;
								{!showCampaign
									? 'Suggesting Topic'
									: 'Suggest for Upcoming Campaign'}
							</strong>
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								padding: '10px',
								alignItems: 'stretch'
							}}
							className="form-group"
						>
							<div className="quoteSuggestLabel">Write a Topic</div>

							<div style={{ width: '100%' }}>
								<input
									onChange={e => setTopicTitle(e.target.value)}
									className="form-control addhashtag"
									style={{
										background: '#e5e5e5',
										borderRadius: '0px',
										padding: '5px 20px'
									}}
								/>
							</div>
						</div>
						<p className="quoteSuggestLabel" style={{ paddingLeft: '10px' }}>
							Description
						</p>
						<div>
							<div
								style={{
									padding: '10px'
								}}
								className="form-group"
							>
								<textarea
									onChange={e => setTopicDescription(e.target.value)}
									className="form-control suggest-quote-quote-textarea"
									placeholder="Add description..."
									style={{ background: '#e5e5e5', borderRadius: '0px  ' }}
								/>
							</div>

							{isError && (
								<Alert variant="danger">Oops Some Error Occurred !</Alert>
							)}

							{isSuccess && (
								<Alert variant="success">Topic Submitted Successfully !</Alert>
							)}

							<div className="suggest-quote-submit-button-div">
								<Button
									variant="info"
									className="btn3_submit"
									onClick={() => sendSuggestedQuote()}
								>
									Submit Topic
								</Button>
							</div>
						</div>
					</div>

					{/* <SidebarTrending /> */}
					{/* <div className="col-lg-3 quotesGuidLine">
						<div>
							<h4>Quote Guidelines</h4>
							<ul>
								<li>
									Only enter quotes from notable people. Generally, a person is
									notable if they have been the subject of published secondary
									source material which is reliable, intellectually independent,
									and independent of the subject.
								</li>
								<li>
									Quotes can be from any source (books, spoken words, news
									articles, etc) as long as they pass the above criteria.
								</li>
								<li>
									Only enter the author's name in the author field (not their
									birthdate or which book the quote is from).
								</li>
								<li>
									When entering author names with initials (H.G. Wells, J.K.
									Rowling), don't put spaces between the initials
								</li>
							</ul>
						</div>
					</div> */}
				</div>
			</div>
			)}
		</>
	);
}
