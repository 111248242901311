import React from "react";
import { Image } from "react-bootstrap";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

export default function CampaignContantUser({ index, item, userData }) {
  //	console.log('item', item);
  return (
    <div className="col-sm-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
      {index === 0 ? (
        <div className="campaign-card card">
          <div className="campaign-header-div mycampainCard">
            <div>
              <h5 style={{ display: "flex", alignItems: "center" }}>
                <Image
                  className="winner-dp-image trending-quotes-dp img-thumbnail"
                  style={{
                    height: "35px",
                    width: "35px",
                    marginRight: "10px",
                  }}
                  src={userData && userData.profileImg}
                  rounded
                  roundedCircle
                />
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {userData && userData?.name?.toUpperCase()}
                </span>
              </h5>
            </div>
          </div>
          <div
            className="trendingcard-image-div"
            style={{ padding: "15px 10px" }}
          >
            {item.imageUrl && (
              <Link
                to={
                  "/all-campaigns/" +
                  item.title?.replaceAll(" ", "-")?.replaceAll('?',"")  +
                  "/" +
                  userData?.name?.replaceAll(" ", "-")?.replaceAll('?',"")  +
                  "/" +
                  item._id +
              
                  "/" +
                  userData._id
                }
              >
                <img
                  src={item.imageUrl}
                  className="card-img-top"
                  alt="Campaign poster"
                />
              </Link>
            )}
            {item.contentId && (
              <div className="mt-2">{parse(item?.contentId?.body)}</div>
            )}
          </div>
        </div>
      ) : (
        <div className="campaign-card card mt-4">
          <div className="campaign-header-div mycampainCard">
            <div>
              <h5 style={{ display: "flex", alignItems: "center" }}>
                <Image
                  className="winner-dp-image trending-quotes-dp img-thumbnail"
                  style={{
                    height: "35px",
                    width: "35px",
                    marginRight: "10px",
                  }}
                  src={userData && userData.profileImg}
                  rounded
                  roundedCircle
                />
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {userData && userData?.name?.toUpperCase()}
                </span>
              </h5>
            </div>
          </div>
          <div
            className="trendingcard-image-div"
            style={{ padding: "15px 10px" }}
          >
            {item.imageUrl && (
              <Link
                to={
                  "/all-campaigns/" +
                  item.title?.replaceAll(" ", "-")?.replaceAll('?'," ")  +
                  "/" +
                  userData.name?.replaceAll(" ", "-")?.replaceAll('?'," ")  +
                  "/" +
                  item._id +
                  +"/" +
                  userData._id
                }
              >
                <img
                  src={item.imageUrl}
                  className="card-img-top"
                  alt="Campaign poster"
                />
              </Link>
            )}

            {item.contentId && (
              <div className="mt-2">{parse(item?.contentId?.body)}</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
