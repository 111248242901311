import React, { useState, useEffect } from 'react';
import {
	suggestQuote,
	getQuoteCategory,
	getUpcomingCampaignForUser,
	postQuoteForUpcomingCampaign,
	getMySuggestedQuotes,
	editMySuggestedQuotes
} from '../Api';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
	Modal,
	Button,
	Alert,
	Tab,
	Nav,
	Col,
	Row,
	Image
} from 'react-bootstrap';
import './SuggestQuote.css';
import SidebarTrending from '../SidebarComponent/SidebarTrending';
import ProfileStatsBar from '../Profile/ProfileStatsBar';
import { useParams } from 'react-router';

export default function SuggestQuote({ match }) {
	const [checked, setChecked] = useState(false);
	const [isError, setisError] = useState(null);
	const [isSuccess, setisSuccess] = useState(null);
	const [userSuggestedQuote, setuserSuggestedQuote] = useState(null);
	const [quoteAuthorName, setquoteAuthorName] = useState(null);
	const [userDetail, setuserDetail] = useState(null);
	const [hashtagString, sethashtagString] = useState(null);
	const [selectedCategory, setselectedCategory] = useState('Choose Category');
	const [selectedCampaign, setselectedCampaign] = useState(
		'For Upcoming Campaign'
	);
	const [selectedCampaignId, setselectedCampaignId] = useState('');
	const [categoryAll, setcategoryAll] = useState(null);
	const [upcomingCampaign, setUpcomingCampaign] = useState(null);
	const [showCampaign, setShowCampaign] = useState(false);

	const [quote, setQuote] = useState({});
	const handleChange = () => {
		setquoteAuthorName(userDetail.name);
		setChecked(false);
	};

	useEffect(() => {
		getSuggestedQuotes();
	}, []);
	const getSuggestedQuotes = async () => {
		let res = await getMySuggestedQuotes();
		if (res.error) {
			alert('Some Error Occurred !');
		} else {
			console.log('My Quotes are =>', res.data.quotes);
			res.data.quotes.map(item => {
				if (item._id === match.params.id) {
					setQuote(item);
					setquoteAuthorName(item.quoteAuthor);
					if (item.hashTags !== []) {
						let tags = item.hashTags.join(',');
						console.log(tags);
						sethashtagString(tags);
					}
				}
			});
		}
	};
	const sendSuggestedQuote = async () => {
		let data = {
			quoteMsg: userSuggestedQuote,
			quoteAuthor: quoteAuthorName,
			hashTags: hashtagString
		};

		let res = await editMySuggestedQuotes(match.params.id, data);

		if (res.error) {
			setisError(true);
			setisSuccess(false);
		} else {
			setisSuccess(true);
			setisError(false);
		}
	};
	const quoteForUpcomingCamp = async () => {
		let data = {
			quoteMsg: userSuggestedQuote,
			quoteAuthor: quoteAuthorName
		};

		let res = await postQuoteForUpcomingCampaign(selectedCampaignId, data);
		console.log(selectedCampaign, data);
		if (res.error) {
			setisError(true);
			setisSuccess(false);
		} else {
			setisSuccess(true);
			setisError(false);
		}
	};

	useEffect(() => {
		var data = JSON.parse(localStorage.getItem('InspirerWorld'));
		if (data) {
			setuserDetail(data);
			console.log('Data is', data);
		}

		getCategory();
		return () => {};
	}, [1]);

	const getCategory = async () => {
		let res = await getQuoteCategory();
		let res1 = await getUpcomingCampaignForUser();
		if (res.error) {
		} else {
			console.log('Res category is', res1);
			setcategoryAll(res.data.quoteCategories);
			setUpcomingCampaign(res1.res.data.upcomingCampaigns);
		}
	};

	return (
		<div className="container custom-suggest-quote mt-5 ">
			<div className="row">
				<div
					className="col-lg-9 col-md-12"
					style={{ paddingLeft: 0, marginTop: '-10px' }}
				>
					<div className="button-half-div">
						{/* dropdown button category  */}

						{/* <button className="btn2 rounded">  
          <div className="dropdown">
                      <button
                        style={{
                          background: "none",
                         
                          border: "none",
                          padding: 0,
                        }}
                        className="btn btn-secondary dropdown-toggle navbar-dropdown"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                    <strong>{selectedCampaign} | Upcoming Campaign</strong>
                      </button>
                      <div
                        className="dropdown-menu"
                        style={{ padding: "10px" ,cursor : 'pointer' }}
                        aria-labelledby="dropdownMenuButton"
                      >
                        {upcomingCampaign && upcomingCampaign.map((item,index)=>{
                          return    <p onClick={()=>{setselectedCampaign(item.title)
                            setselectedCampaignId(item._id)
                          setShowCampaign(true)}}><li>{item.title}</li></p>
                        
                        }) }

                          </div>

                      </div>
              </button>
        */}
					</div>

					<div
						className="profile-short-div profile-short-div-center"
						style={{ margin: 10 }}
					>
						{userDetail && (
							<Image
								style={{
									height: '50px',
									width: '50px',
									objectFit: 'cover'
								}}
								src={userDetail.profileImg}
								rounded
								roundedCircle
								thumbnail
								fluid
							/>
						)}
						<strong style={{ color: '#166D9C', fontWeight: 'bold' }}>
							&nbsp;{userDetail && userDetail.name} |&nbsp;
							{!showCampaign
								? 'Edit My Suggested Quote'
								: 'Suggest for Upcoming Campaign'}
						</strong>
					</div>
					{!showCampaign ? (
						<div>
							<div
								style={{
									padding: '10px'
								}}
								className="form-group"
							>
								<textarea
									onChange={e => setuserSuggestedQuote(e.target.value)}
									className="form-control"
									placeholder="your quote here..."
									defaultValue={quote.quoteMsg}
									style={{ background: '#e5e5e5', borderRadius: '0px  ' }}
								/>
							</div>

							<div
								style={{
									display: 'flex',
									padding: '10px',
									alignItems: 'stretch'
								}}
								className="form-group"
							>
								<div
									className="quote-suggest-label"
									style={{
										backgroundColor: '#e5e5e5',
										padding: '21px 1px',
										textAlign: 'center',
										width: '25%',
										marginRight: '1%'
									}}
								>
									Add Hashtags
								</div>
								<div style={{ width: '74%' }}>
									<input
										onChange={e => sethashtagString(e.target.value)}
										className="form-control"
										defaultValue={hashtagString}
										placeholder="example : #motivation"
										style={{
											background: '#e5e5e5',
											borderRadius: '0px',
											padding: '33px 20px'
										}}
									/>
								</div>
							</div>

							{checked && (
								<div
									style={{
										padding: '10px'
									}}
									className="form-group author-field"
								>
									<input
										onChange={e => setquoteAuthorName(e.target.value)}
										className="form-control"
										placeholder="Author Name"
										defaultValue={quoteAuthorName}
									/>
								</div>
							)}

							{isError && (
								<Alert variant="danger">Oops Some Error Occurred !</Alert>
							)}

							{isSuccess && (
								<Alert variant="success">Quote Submitted Successfully !</Alert>
							)}

							<div className="button-half-div">
								<button
									variant="info"
									className="btn1 btn3 rounded"
									onClick={() => setChecked(!checked)}
									style={{ fontWeight: 'bold' }}
								>
									{' '}
									Quote Author Name
								</button>

								<button
									variant="info"
									className="btn2 btn3 rounded"
									onClick={() => handleChange()}
									style={{ fontWeight: 'bold' }}
								>
									I'm the author*
								</button>
							</div>

							<Button
								variant="info"
								className="btn3_submit"
								onClick={() => sendSuggestedQuote()}
							>
								Edit Quote
							</Button>
						</div>
					) : (
						<div>
							<div
								style={{
									padding: '10px'
								}}
								className="form-group"
							>
								<textarea
									onChange={e => setuserSuggestedQuote(e.target.value)}
									className="form-control"
									placeholder="your quote here..."
								/>
							</div>
							{checked && (
								<div
									style={{
										padding: '10px'
									}}
									className="form-group author-field"
								>
									<input
										onChange={e => setquoteAuthorName(e.target.value)}
										className="form-control"
										placeholder="Author Name"
									/>
								</div>
							)}

							{isError && (
								<Alert variant="danger">Oops Some Error Occurred !</Alert>
							)}

							{isSuccess && (
								<Alert variant="success">Quote Submitted Successfully !</Alert>
							)}

							<div className="button-half-div">
								<button
									variant="info"
									className="btn1 btn3 rounded"
									onClick={() => setChecked(!checked)}
								>
									{' '}
									Quote Author Name
								</button>

								<button
									variant="info"
									className="btn2 btn3 rounded"
									onClick={() => handleChange()}
								>
									I am the Author*
								</button>
							</div>

							<Button
								variant="info"
								className="btn3 rounded"
								style={{
									width: '97.4%',
									borderRadius: '0px',
									margin: '0px 10px'
								}}
								onClick={() => quoteForUpcomingCamp()}
							>
								Submit Quote
							</Button>
						</div>
					)}
				</div>

				<SidebarTrending />
			</div>
		</div>
	);
}
