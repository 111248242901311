import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Cropper from 'react-easy-crop';
import {
	getMyCampaigns,
	getMyQuotes,
	viewQuote,
	getUserCampaigns,
	getUserQuotes
} from '../Api';
import './index.scss';
import { useHistory } from 'react-router-dom';
import { FaBookmark } from 'react-icons/fa';

const DownloadButton = ({ item }) => {
	const [DisabledClaim, setDisabledClaim] = useState(false);

	const mergeImageDownload = async item => {
		setDisabledClaim(true);
		const res2 = await viewQuote(item._id);
		var a = document.createElement('a'); //Create <a>
		a.href = res2.data; //Image Base64 Goes here
		a.download = `${item.title}.jpeg`; //File name Here
		a.click(); //Downloaded file
		setDisabledClaim(false);
	};

	return (
		<button
			style={{ color: 'white' }}
			className="reclaimBtn btn btn-primary res-btn-size-12"
			onClick={() => {
				mergeImageDownload(item);
			}}
		>
			{DisabledClaim ? 'Downloading...' : 'Reclaim'}
		</button>
	);
};
export default function ProfileDisplay({ profile, showEdit }) {
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState('');
	const [croppedAreaPixelsArray, setCroppedAreaPixelsArray] = useState([]);

	const [showModal, setshowModal] = useState(false);
	const [modalTitle, setmodalTitle] = useState('');
	const [modalMsg, setmodalMsg] = useState('');
	const [Error, setError] = useState(false);
	const [modalHeading, setmodalHeading] = useState('');
	const [modalText, setmodalText] = useState('');
	const [modalButton, setmodalButton] = useState('');
	const [loading, setloading] = useState(false);
	const [show, setShow] = useState(false);
	const handleClose = () => {
		setShow(false);
		setshowModal(false);
	};
	const handleShow = () => setShow(true);
	const [userImage, setuserImage] = useState(null);
	const [userImageUrl, setuserImageUrl] = useState(profile.profileImg);
	const [imgSrcArray, setImgSrcArray] = useState([]);
	const [email, setemail] = useState(profile.email);
	const [name, setName] = useState(profile.name);
	const [whatsapp, setwhatsapp] = useState(profile.phoneNumber);
	const [linkedinUrl, setlinkedinUrl] = useState(profile.linkedinUrl);
	let newDate = new Date(profile.dob);
	const [date, setDate] = useState(newDate);
	const [defaultDate, setDefaultDate] = useState(newDate);
	const [contentCreator, setContentCreator] = useState(
		profile.isContentCreator
	);
	console.log(newDate, date);
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [showCropper, setShowCropper] = useState(false);
	const onCropComplete = (croppedArea, croppedAreaPixels) => {
		console.log(croppedArea, croppedAreaPixels);
		setCroppedAreaPixels(croppedAreaPixels);
	};

	Date.prototype.yyyymmdd = function () {
		var mm = this.getMonth() + 1; // getMonth() is zero-based
		var dd = this.getDate();

		return [
			this.getFullYear(),
			(mm > 9 ? '' : '0') + mm,
			(dd > 9 ? '' : '0') + dd
		].join('-');
	};

	const [campaignData, setcampaignData] = useState([]);
	const [quoteData, setquoteData] = useState([]);
	const history = useHistory();

	console.log('campaignData', campaignData, quoteData);

	useEffect(() => {
		getCampainPosts();
		getQuotes();
		return () => {};
	}, []);

	const getCampainPosts = async () => {
		if (showEdit) {
			const res = await getMyCampaigns();
			setcampaignData(res.res.data.campaigns);
		} else {
			const res = await getUserCampaigns(profile._id);
			if (res.error) {
			} else {
				setcampaignData(res.res.data.campaigns);
			}
		}
	};

	const getQuotes = async () => {
		if (showEdit) {
			const res = await getMyQuotes();
			if (res.res) {
				console.log('res is ', res.res.data.quotes);
				setquoteData(res.res.data.quotes);
			}
		} else {
			const res = await getUserQuotes(profile._id);
			if (res.error) {
			} else {
				setquoteData(res.res.data.quotes);
			}
		}
	};

	return (
		<div className="profile-display profileDisplay">
			<Modal show={showCropper} onHide={handleClose}>
				<Modal.Header closeButton={() => setShowCropper(false)}>
					<Modal.Title>{modalTitle}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div
						id="crop-section"
						style={{
							width: '100vw',
							top: '0',
							bottom: '0',
							left: '0',
							right: '0',
							height: '50vh'
						}}
					>
						<Cropper
							style={{ zIndex: 100, backgroundColor: 'black' }}
							image={userImageUrl}
							crop={crop}
							zoom={zoom}
							aspect={780 / 780}
							onCropChange={crop => {
								setCrop(crop);
							}}
							onCropComplete={onCropComplete}
						/>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<input
						type="range"
						value={zoom}
						min="1"
						max="3"
						step="0.1"
						onChange={e => {
							setZoom(e.target.value);
						}}
						className="custom-range"
						id="customRange1"
					/>
					<br />
					<Button
						onClick={() => {
							// setImgSrc(imgSrcArrayVar)
							setShowCropper(false);
							handleClose(false);
							setCroppedAreaPixelsArray(croppedAreaPixels);
							console.log(croppedAreaPixelsArray);
						}}
					>
						Crop
					</Button>
				</Modal.Footer>
			</Modal>
			<div display="d-flex justify-content-center">
				<div className={`detailWrapper ${showEdit ? '' : 'hideEdit'}`}>
					<div className="user-img userImageDiv">
						<div className="dp ">
							<img src={userImageUrl} alt="user-img" className="userImage" />
						</div>
					</div>
					<div className="userDetail">
						<div className="editDP">
							<div className="user-img userImageDiv">
								<div className="dp ">
									<img
										src={userImageUrl}
										alt="user-img"
										className="userImage"
									/>
								</div>
							</div>
							{showEdit && (
								<a href={'/editprofile'} className="editButton">
									Edit Profile
								</a>
							)}
						</div>

						<div className="socialDetail mb-3">
							<span className="socialItem">{profile.likes} Likes</span>
							<span className="socialItem">{profile.comments} Comments</span>
							<span className="socialItem">{profile.views} Views</span>
						</div>
						<div className="userName mb-1">{name}</div>
						{contentCreator && (
							<div className="usertype mb-1">Regular Content Creator</div>
						)}
						{/* <div>About</div> */}
						<a
							className="linkdin linkdinURL mt-4"
							target="_blank"
							rel="noreferrer"
							href={linkedinUrl}
						>
							{linkedinUrl}
						</a>
					</div>
					<span className="line" />
				</div>
			</div>
			<div className="otherbtn">
				<button
					className="editButton mr-1"
					onClick={() => {
						setSelectedIndex(0);
					}}
				>
					Posts
				</button>
				<button
					className="editButton mr-1"
					onClick={() => {
						setSelectedIndex(1);
					}}
				>
					Quotes
				</button>
				<button
					className="editButton mr-3"
					onClick={() => {
						setSelectedIndex(2);
					}}
				>
					Achievements
				</button>
				<button
					className="saveQBtn"
					onClick={() => {
						history.push('/mysavedquotes');
					}}
				>
					<span>Saved Quotes</span>
					<FaBookmark className="profile-topbar-winner-icon" />
				</button>
			</div>
			<div className="userPoste">
				{selectedIndex === 0 &&
					campaignData &&
					campaignData.map(
						(item, index) =>
							item.artWork && (
								<a
									href={
										item?.participants &&
										item?.participants.length > 0 &&
										item?.participants[0].postUrl
									}
									target="_blank"
									rel="noreferrer"
									key={index}
									className="myPosts"
								>
									<img
										src={
											showEdit
												? item?.participants &&
												  item?.participants.length > 0 &&
												  item?.participants[0].imageUrl
												: item?.artWork.imgUrl
										}
										className="card-img-top"
										alt="..."
									/>
								</a>
							)
					)}
				{selectedIndex === 1 &&
					quoteData &&
					quoteData.map(
						(item, index) =>
							item.artWork && (
								<a
									href={
										item?.participants &&
										item?.participants.length > 0 &&
										item?.participants[0].postUrl
									}
									target="_blank"
									rel="noreferrer"
									key={index}
									className="myPosts"
								>
									<img
										src={item.artWork.imgUrl}
										className="card-img-top"
										alt="..."
									/>
									<DownloadButton item={item} />
								</a>
							)
					)}
				{selectedIndex === 2 && (
					<div className="commingSoon">Coming soon...</div>
				)}
			</div>
		</div>
	);
}
