import React from 'react'
import Loader from "react-loader-spinner";

export default function CustomLoader({loading,color,size}) {
    return (
        <div>
            <center>
            {loading && <Loader
        type="Puff"
        color={color || '#3b6387'}
        height={size}
        width={size}
      />}</center>
        </div>
    )
}
