import React, { useState, useEffect } from 'react';
import mergeImages from 'merge-images';
import Pic1 from '../../images/body.png';
// import Pic2 from '../../images/eyes.png'
// import Pic3 from '../../images/pic2.jpg'
import Cropper from 'react-easy-crop';
import Resizer from 'react-image-file-resizer';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import {
	getMyCampaigns,
	participateInCampaign,
	updateCampaignStats,
	viewArtwork
} from '../Api';
import resizeProfilePic from '../Campaign/HelperFunctions/resizeProfilePic';
import fileDownload from 'js-file-download';
import { uploadToCloudinary } from '../ImageCropper/canvasUtils';
import {
	checkImageExistence,
	setContentCreator,
	updateUserProfileImage
} from '../Api';
export default function PreviousCampaignClaimDiv({ item, getCampaigns }) {
	const handleCloseProfile = () => setShowModal(false);
	const handleShowProfile = () => setShowModal(true);
	const [profileImgExist, setProfileImgExist] = useState(false);
	const [regularCreator, setRegularCreator] = useState(false);
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [showModal, setShowModal] = useState(false);
	const [userImageUrl, setuserImageUrl] = useState(null);
	const [userImage, setuserImage] = useState(null);
	const [showCropper, setShowCropper] = useState(false);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState('');
	const [loading, setloading] = useState(false);
	const imageChange = async image => {
		setuserImage(image);
		console.log('File is', image);
		//  const url = URL.createObjectURL(image);
		const url = URL.createObjectURL(image);
		setuserImageUrl(url);
		setShowCropper(true);
		//setShowCropper(true);
	};
	useEffect(() => {
		checkImage();
	});
	const onCropComplete = (croppedArea, croppedAreaPixels) => {
		console.log(croppedArea, croppedAreaPixels);
		setCroppedAreaPixels(croppedAreaPixels);
	};
	const imageUploader = async () => {
		const res = await uploadToCloudinary(
			userImageUrl,
			croppedAreaPixels,
			userImageUrl
		);

		let data = { profileImg: res.url };
		console.log(res);
		let resBackend = await updateUserProfileImage(data);
		let resContent = await setContentCreator({
			isContentCreator: regularCreator
		});
		if (!resBackend.error) {
			const done = JSON.parse(localStorage.getItem('InspirerWorld'));
			console.log(res);
			let newdata = {
				token: done.token,
				isLeader: done.isLeader,
				userId: done.userId,
				teamId: done.teamId,
				type: done.type,
				profileImg: res.url,
				name: done.name,
				expiry: done?.expiry
			};
			console.log(newdata.profileImg, 'succes', resContent);

			//   localStorage.removeItem('InspirerWorld')
			await localStorage.setItem('InspirerWorld', JSON.stringify(newdata));
			// console.log("success")
			setProfileImgExist(true);
			handleCloseProfile();
			window.location.reload();
		} else {
			console.log(resBackend);
		}
	};
	const checkImage = async () => {
		let res = await checkImageExistence();
		//console.log('exist',res)
		setProfileImgExist(res.data.isSet);
	};

	const [NewArtwork, setNewArtwork] = useState(null);
	const [disabledClaim, setDisabledClaim] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [Pic2, setPic2] = useState('');
	const [imgUrl, setimgUrl] = useState(null);
	const [ModifieProfilePic, setModifieProfilePic] = useState(null);
	const [show, setShow] = useState(false);
	const [likes, setlikes] = useState(0);
	const [views, setviews] = useState(0);
	const [linkedinUrl, setlinkedinUrl] = useState('');
	const [comments, setcomments] = useState(0);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	useEffect(() => {
		var Pic2 = JSON.parse(localStorage.getItem('InspirerWorld')).profileImg;
		setPic2(Pic2);
	}, []);

	const updateStats = async () => {
		let data = {
			likes,
			comments,
			views,
			linkedinUrl
		};

		const res = await updateCampaignStats(item._id, data);
		if (res.error) {
			alert('Some Error occurred');
		} else {
			getCampaigns();
			return handleClose();
		}
	};

	const finalFuncToResizeProfilePic = async id => {
		setDisabled(true);
		const res1 = await viewArtwork(id);
		console.log('viewArtwork =>', res1);
		let filename = `${item.title}.jpeg`;
		console.log('viewArtwork =>', res1);
		axios
			.get(res1.data, {
				responseType: 'blob'
			})
			.then(res => {
				fileDownload(res.data, filename);
			});
		setNewArtwork(res1.data);
		setDisabled(false);
	};

	const finalFuncToDownloadProfilePic = async id => {
		if (profileImgExist) {
			setDisabledClaim(true);
			const res2 = await participateInCampaign(id);

			setDisabledClaim(false);
		} else {
			handleShowProfile();
		}
	};

	return (
		<p className="sidebar-list-item">
			<Modal show={showCropper}>
				<Modal.Header closeButton={() => setShowCropper(false)}>
					<Modal.Title>Upload Image</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div
						id="crop-section"
						style={{
							width: '100vw',
							top: '0',
							bottom: '0',
							left: '0',
							right: '0',
							height: '50vh'
						}}
					>
						<Cropper
							style={{ zIndex: 100, backgroundColor: 'black' }}
							image={userImageUrl}
							crop={crop}
							zoom={zoom}
							aspect={780 / 780}
							onCropChange={crop => {
								setCrop(crop);
							}}
							onCropComplete={onCropComplete}
						/>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<input
						type="range"
						value={zoom}
						min="1"
						max="3"
						step="0.1"
						onChange={e => {
							setZoom(e.target.value);
						}}
						className="custom-range"
						id="customRange1"
					/>
					<br />
					<Button onClick={() => setShowCropper(false)}>Crop</Button>
				</Modal.Footer>
			</Modal>

			<Modal show={showModal} onHide={handleCloseProfile}>
				<Modal.Body>
					<div className="container row m-auto">
						<div className="row">
							<div className="col-12 p-2 text-center mb-5 m-auto">
								<h4>Thank for showing interest in Inspire World </h4>
								<span>Please let just know about you </span>
							</div>

							<div className="col-8 text-center ml-auto mr-auto mt-10 ">
								<h5 className="mt-4">Update your Profile Picture</h5>
								<input
									type="file"
									onChange={e => {
										imageChange(e.target.files[0]);
									}}
									id="chooseImage"
									className="d-none"
								/>
								<button
									className="upload-half-buttons mt-3  m-auto"
									onClick={() => document.getElementById('chooseImage').click()}
								>
									Click here to upload your picture
								</button>
							</div>
							<div className="col-8  ml-auto mr-auto mt-10 text-center ">
								<h5 className="mt-4">Are You a regular content Creator?</h5>

								<button
									id="CreatorTrue"
									onClick={() => {
										document.getElementById('CreatorTrue').style.background =
											'green';
										document.getElementById('CreatorFalse').style.background =
											'#146d9c';
										setRegularCreator(true);
									}}
									className="upload-half-buttons rounded   m-auto"
								>
									yes
								</button>
								{'                     '}

								<button
									id="CreatorFalse"
									onClick={e => {
										document.getElementById('CreatorTrue').style.background =
											'#146d9c';
										document.getElementById('CreatorFalse').style.background =
											'green';

										setRegularCreator(false);
									}}
									className="upload-half-buttons rounded   m-auto"
								>
									No
								</button>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseProfile}>
						Close
					</Button>
					<Button
						variant="primary"
						id="uploadbtn"
						onClick={() => {
							document.getElementById('uploadbtn').style.background = 'green';
							imageUploader();
						}}
					></Button>
				</Modal.Footer>
			</Modal>

			<div className="grey-box radius-5" style={{ flexBasis: '90%' }}>
				<span>
					<strong>{item.title}</strong>
				</span>
			</div>
			<div
				className="grey-box radius-5"
				style={{ flexBasis: '22%', marginLeft: '7px' }}
			>
				<a
					classname="claim-hover"
					style={{
						width: '100%',
						fontWeight: '400',
						color: '#898989',
						textAlign: 'center'
					}}
					onClick={() => finalFuncToDownloadProfilePic(item._id)}
				>
					Claim
				</a>
			</div>
		</p>
	);
}
