import React, { useState, useEffect } from 'react';

import QuotesCard from './QuotesCard';
import { Helmet } from 'react-helmet-async';
import {
	getAllQuotes,
	getQuoteCategory,
	getUpcomingAndSuggestedQuotes
} from '../Api';
import user_pic from '../../images/user.png';
import Pagination from '../Pagination/index.js';

import { Link, useHistory, useLocation } from 'react-router-dom';
import { Tab, Nav } from 'react-bootstrap';
import TagList from '../TagList';

import '../Quotes/quotes.css';

export default function CampaignScreen() {
	const [quotesData, setquotesData] = useState(null);
	const history = useHistory();

	const [loading, setLoading] = useState(false);
	const [originalData, setoriginalData] = useState([]);
	const [selectedCategory, setselectedCategory] = useState('All Category');
	const [categoryAll, setcategoryAll] = useState(null);
	const [categoryWise, showCategoryWise] = useState(true);
	const [show, setShow] = useState(false);
	const [userSuggestedQuote, setuserSuggestedQuote] = useState(null);
	const [quoteAuthorName, setquoteAuthorName] = useState(null);
	const [userDetail, setuserDetail] = useState(null);
	const [search, setsearch] = useState('');
	// code for suggest quote

	const [tagData, setTagData] = useState([]);
	const [quotesFilterData, setquotesFilterData] = useState(null);
	const [entry, setEntry] = useState(10);
	const [entryToShow, setentryToShow] = useState(null);
	const [page, setPage] = useState(0);
	const [totalpage, setTotalPage] = useState(0);

	const [checked, setChecked] = useState(false);
	const [isError, setisError] = useState(null);
	const [isSuccess, setisSuccess] = useState(null);
	const [suggestedData, setsuggestedData] = useState([]);
	const [upcomingsuggestedData, setupcomingsuggestedData] = useState([]);
	const [showMyQuotes, setshowMyQuotes] = useState(false);
	const [quoteDownloads, setquoteDownloads] = useState('');
	const params = useLocation();

	const setFilterByUrl = (name, Qdata) => {
		let search = name.replace('?', '');
		// console.log(search, 'shy');
		let data = Qdata.filter(item => {
			if (item.author.name.toLowerCase() == search.toLowerCase()) {
				return item;
			} else if (item.madeBy.name.toLowerCase() == search.toLowerCase()) {
				return item;
			}
		});

		setquotesData(data);
	};
	const handleClose = () => {
		setShow(false);
		setisSuccess(null);
		setisError(null);
		setChecked(false);
	};
	const handleShow = () => setShow(true);

	useEffect(() => {
		getQuotes();
		getCategory();

		var data = JSON.parse(localStorage.getItem('InspirerWorld'));
		if (data) {
			setuserDetail(data);
		}
		return () => {};
	}, [1]);

	const getQuotes = async () => {
		setLoading(true);
		//const res = await getAllCampaigns();
		const res = await getUpcomingAndSuggestedQuotes();
		const response = await getAllQuotes();
		// console.log(response);
		setsuggestedData(res.res.data.quoteSuggestions);
		setupcomingsuggestedData(res.res.data.upcomingCampaigns);
		setquotesData(response.data.quotes);
		let tagArray = [];
		for (const item of response.data.quotes) {
			if (item.tags.length > 0) {
				tagArray = tagArray.concat(item.tags);
			}
		}
		setTagData(tagArray);

		setoriginalData(response.data.quotes);
		if (params.search) {
			// console.log(params, 'Params');
			setFilterByUrl(params.search, response.data.quotes);
		}
		return setLoading(false);
	};

	const getCategory = async () => {
		let res = await getQuoteCategory();
		if (res.error) {
		} else {
			// console.log('Res category is', res);
			setcategoryAll(res.data.quoteCategories);
		}
	};

	const [currentActiveTab, setcurrentActiveTab] = useState('trendingQuotes');

	//this is for category filter
	useEffect(() => {
		// console.log(selectedCategory)

		if (quotesData && selectedCategory !== 'All Category') {
			let data = originalData.filter(item => {
				if (item.category.toLowerCase() == selectedCategory.toLowerCase())
					return item;
			});

			setquotesData(data);
			// console.log(`Quotes for ${selectedCategory} are `, data);
		} else {
			setquotesData(originalData);
		}

		return () => {};
	}, [selectedCategory]);

	const setCategory = category => {
		// console.log(category);
		setselectedCategory(category);
	};

	const setFilterByName = name => {
		let data = originalData.filter(item => {
			if (item.author.name.toLowerCase() == name.toLowerCase()) {
				return item;
			} else if (item.madeBy.name.toLowerCase() == name.toLowerCase()) {
				return item;
			}
		});

		setquotesData(data);
	};

	const setTags = tag => {
		// console.log(tag);
		let res = [];
		let data = originalData.filter(item => {
			item.tags.filter(tagitem => {
				if (tag === tagitem) {
					// console.log(item)
					res.push(item);
					return item;
				}
			});
			//   console.log(res)
			return res;
		});
		// console.log(data);

		setquotesData(res);
	};

	const handleChange = event => {
		if (event.target.checked) {
			setquoteAuthorName(userDetail.name);
		}
		setChecked(event.target.checked);
	};

	const changeActiveTab = screenName => {
		setcurrentActiveTab(screenName);
	};

	const filterData = () => {
		if (quotesData && quotesData.length > 0) {
			const cur_Data = quotesData.filter((item, index) => {
				if (item.desc.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
					return item;
			});
			if (cur_Data && cur_Data.length > 0) {
				setTotalPage(Math.ceil(cur_Data.length / entry));
				let array = cur_Data.slice(page * entry, (page + 1) * entry);
				setquotesFilterData(array);
			}
		}
	};

	useEffect(() => {
		filterData();
	}, [entry, page, quotesData, search]);

	useEffect(() => {
		if (params && params.state) {
			if (params.state.tag && originalData.length > 0) {
				setTags(params.state.tag);
			}
		}
	}, [params, originalData]);

	return (
		<>
			<Helmet>
				<title>Quotes Library</title>
			</Helmet>
			<div className="quotesScreen">
				<br />

				<div className="container ">
					<div className="row  ">
						<div className="col-lg-9 ">
							<h2 className="quotesTitle">Popular Quotes</h2>
							<Tab.Container
								defaultActiveKey="third"
								unmountOnExit={true}
								activeKey={currentActiveTab}
							>
								<div className="form-group">
									<input
										onChange={e => setsearch(e.target.value)}
										type="text"
										className="form-control "
										id="firstName"
										name="firstName"
										placeholder="Search Quote by quote description"
									/>
								</div>
								<Tab.Content style={{ width: '100%' }}>
									<Tab.Pane lg={3} eventKey="campaignQuotes">
										<div>
											<div className="row">
												<div
													className="button-half-div col-12"
													style={{ marginTop: '-25px' }}
												>
													<button
														variant="info"
														id="cwise"
														className="btn1 btn3 rounded"
														onClick={() => {
															document.getElementById(
																'authorbtn'
															).style.background = '#146d9c';
															document.getElementById(
																'cwise'
															).style.background = 'green';
															showCategoryWise(true);
														}}
													>
														{' '}
														Campaign Wise
													</button>

													<button
														variant="info"
														id="authorbtn"
														className="btn2 btn3 rounded"
														onClick={() => {
															showCategoryWise(false);
															document.getElementById(
																'authorbtn'
															).style.background = 'green';
															document.getElementById(
																'cwise'
															).style.background = '#146d9c';
														}}
														style={{ fontWeight: 'bold' }}
													>
														Category Wise
													</button>
												</div>
											</div>
											{!categoryWise
												? suggestedData.map(item => {
														return (
															<div className="new-quote-cards-container">
																<div className="new-quote-cards-left">
																	<div className="trendingcard-image-div">
																		{/* <img  src={imgUrl} /> */}

																		<img
																			src={item.artWork.imgUrl}
																			className="card-img-top"
																			alt="..."
																		/>
																	</div>
																	<div className="quote-container-div">
																		{/* <h5>{item.title}</h5> */}
																		<p className="card-text quote-card-quote-text">
																			{item.quoteMsg}
																		</p>
																	</div>

																	<br />

																	<div className="save-div">
																		<div className="additional-info">
																			<div className="individual-info">
																				<div className="info-heading">
																					<span>Tags</span>
																				</div>
																				<div className="info-value">
																					{item.hashTags.map(i => {
																						return (
																							<span
																								style={{ cursor: 'pointer' }}
																							>
																								#{i}
																							</span>
																						);
																					})}
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="trending-quotes-card-button-container">
																		<button
																			className="trending-quotes-claim-button"
																			// onClick={() => finalFuncToDownloadProfilePic(item._id)}
																		>
																			{' '}
																			<Link to="/login">
																				<p className="block-claim-text">
																					<img
																						src={user_pic}
																						className="user-pic-icon"
																					/>
																					Sign-in to Claim
																				</p>
																			</Link>
																		</button>
																	</div>
																</div>
															</div>
														);
												  })
												: upcomingsuggestedData.map((item, index) => {
														return item.quoteSuggestions.map(element => {
															return (
																<div
																	className={'new-quote-cards-container mt-2 '}
																>
																	<div className="new-quote-cards-left">
																		<div className="trendingcard-image-div">
																			{/* <img  src={imgUrl} /> */}

																			<img
																				src={element.artWork.imgUrl}
																				className="card-img-top"
																				alt="..."
																			/>
																		</div>
																		<div className="quote-container-div">
																			{/* <h5>{item.title}</h5> */}
																			<p className="card-text quote-card-quote-text">
																				{element.quoteMsg}
																			</p>
																		</div>

																		<br />
																		<div className="save-div">
																			<div className="additional-info">
																				<div className="individual-info">
																					<div className="info-heading">
																						<span>Campaign</span>
																					</div>
																					<div
																						className="info-value"
																						style={{ cursor: 'pointer' }}
																						onClick={() =>
																							setCategory(item.category)
																						}
																					>
																						<span>{item.title}</span>
																					</div>
																				</div>
																			</div>
																			<div className="individual-info">
																				<div className="info-heading">
																					<span>Author</span>
																				</div>
																				<div className="info-value">
																					{element.quoteAuthor}
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="trending-quotes-card-button-container">
																		<button
																			className="trending-quotes-claim-button"
																			// onClick={() => finalFuncToDownloadProfilePic(item._id)}
																		>
																			{' '}
																			<Link to="/login">
																				<p className="block-claim-text">
																					<img
																						src={user_pic}
																						className="user-pic-icon"
																					/>
																					Sign-in to Claim
																				</p>
																			</Link>
																		</button>
																	</div>
																</div>
															);
														});
												  })}
										</div>
									</Tab.Pane>

									<Tab.Pane lg={3} eventKey="trendingQuotes">
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											{quotesFilterData &&
												quotesFilterData.map((item, index) => {
													return (
														<QuotesCard
															setCategory={setCategory}
															setTags={setTags}
															setFilterByName={setFilterByName}
															changeActiveTab={changeActiveTab}
															key={index}
															item={item}
														/>
													);
												})}

											{quotesData && quotesData.length < 1 && (
												<div>
													<center>
														<img
															style={{ width: '50%' }}
															src="https://cdn.dribbble.com/users/2071065/screenshots/6559618/dribble_4-19_4x.png?compress=1&resize=800x600"
														/>
														<h4>Oops ! No Quotes in this Category...</h4>
													</center>
												</div>
											)}
											{quotesData && quotesData.length > 0 && (
												<Pagination
													page={page}
													entryToShow={10}
													setPage={setPage}
													totalpage={totalpage}
													totalData={quotesData.length}
												/>
											)}
										</div>
									</Tab.Pane>
								</Tab.Content>
							</Tab.Container>
						</div>

						{/* <SidebarTrending /> */}
						<TagList tagData={tagData} setTags={setTags} islogout={true} />
					</div>
				</div>
			</div>
		</>
	);
}
