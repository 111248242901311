import React, { useState, useEffect } from 'react';
import SignupImage from '../../images/signup.jpg';
import {
	editQuoteDetail,
	editSuggestedTopic,
	getAllAuthor,
	getQuoteCategoryAdmin,
	getQuoteDetail,
	getSuggestedQuotes,
	getSuggestedTopicDetails,
	SignupUser,
	UploadCampaign
} from '../Api';
import { Modal, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ImageCropper from '../ImageCropper/ImageCropperEdit';
//import ImageCropper from "../ImageCropper/ImageCropper";
import { uploadToCloudinary } from '../ImageCropper/canvasUtils';
import uploadImageCampaign from '../AdminCampaign/uploadImageCampaign';

export default function EditSuggestedQuote({ match }) {
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [dataLoaded, setDataLoaded] = useState(false);
	const [loading, setLoading] = useState(false);

	const [topicDescription, setTopicDescription] = useState(null);
	const [topicTitle, setTopicTitle] = useState(null);

	const [campDesc, setcampDesc] = useState('');
	const [Category, setCategory] = useState(null);
	const [categoryData, setcategoryData] = useState([]);
	const [name, setname] = useState('');

	const [tags, setTags] = useState('');
	// const [campDesc, setcampDesc] = useState("");
	let location = useHistory();
	const [CategoryId, setCategoryId] = useState(false);
	const [userImageUrl, setuserImageUrl] = useState('');
	const [croppedAreaPixels, setCroppedAreaPixels] = useState('');

	const [authors, setauthors] = useState([]);
	const [selectedAuthor, setSelectedAuthor] = useState('');
	const [quoteData, setquoteData] = useState({});
	const [defaultAuthor, setDefaultAuthor] = useState('');
	const [selectedImage, setSelectedImage] = useState('');
	const [show, setShow] = useState(false);
	const [Error, setError] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [dimensions, setDimensions] = useState(null);
	const [scaleX, setscaleX] = useState(null);
	const [scaleY, setscaleY] = useState(null);
	const [showCropper, setShowCropper] = useState(false);
	const onCropComplete = (croppedArea, croppedAreaPixels) => {
		console.log(croppedArea, croppedAreaPixels);
		setCroppedAreaPixels(croppedAreaPixels);
	};

	useEffect(() => {
		fetchSuggestedQuoteDetail(match.params.id);
	}, []);

	const fetchSuggestedQuoteDetail = async quotesId => {
		const res = await getSuggestedTopicDetails(quotesId);
		setTopicTitle(res.res.data.suggestedTopic.topic);
		setTopicDescription(res.res.data.suggestedTopic.desc);
	};

	const handleSubmit = async e => {
		e.preventDefault();

		setLoading(true);
		const data = {
			topic: topicTitle,
			desc: topicDescription
		};
		const res = await editSuggestedTopic(match.params.id, data);
		if (!res.error) {
			setLoading(false);
			location.push({
				pathname: '/suggested-topic',
				state: { detail: 'Success' }
			});
		} else {
			setLoading(false);
			alert(' Something went Wrong');
		}
	};

	return (
		<div className="container">
			<div className="row">
				<div className="col-lg-1"></div>
				<br />
				<br />
				<br />
				<div className="col-lg-10 mb-2 contact-form__wrapper p-1 order-lg-1">
					<center>
						<h2>Edit Topic</h2>
					</center>

					<br />

					<form
						action="#"
						className="contact-form form-validate"
						onSubmit={handleSubmit}
					>
						<div className="row">
							<div className="col-sm-12 mb-3">
								<div className="form-group">
									<label className="required-field" htmlFor="firstName">
										Topic
									</label>
									<br />
									<input
										onChange={e => setTopicTitle(e.target.value)}
										type="text"
										className="form-control"
										id="firstName"
										name="firstName"
										defaultValue={topicTitle}
										placeholder="Topic Title"
									/>
								</div>
							</div>
							<div className="col-sm-12 mb-3">
								<div className="form-group">
									<label className="required-field" htmlFor="firstName">
										Description
									</label>
									<input
										onChange={e => setTopicDescription(e.target.value)}
										type="text"
										className="form-control"
										id="firstName"
										name="firstName"
										defaultValue={topicDescription}
										placeholder="Description"
									/>
								</div>
							</div>
							<div className="col-sm-12 mb-3">
								{!loading ? (
									<input
										type="submit"
										value="Edit Topic"
										name="submit"
										className="btn btn-primary"
									/>
								) : (
									<input
										type="submit"
										value="Loading..."
										name="submit"
										className="btn btn-primary"
									/>
								)}
							</div>
						</div>
					</form>
				</div>{' '}
				{/* End Contact Form Wrapper */}
			</div>
		</div>
	);
}
