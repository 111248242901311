import React, { useState, useEffect } from 'react';
import { Tab, Nav, Image, Row } from 'react-bootstrap';
import Moment from 'react-moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ProfileStatsBar from '../Profile/ProfileStatsBar';

import { Helmet } from 'react-helmet-async';

import { getAllCampaigns } from '../Api';
import CustomLoader from '../CustomLoader';
import SidebarTrending from '../SidebarComponent/SidebarTrending';
import tropy_img from '../../images/trophy.png';
import tropy_img2 from '../../images/Winner-Fill.png';
import tropy_img3 from '../../images/trophy-3.png';
import { Link } from 'react-router-dom';
import StatsComponent from '../TeamLeader/StatsComponent';
import Comments_img from '../../images/Comments.png';
import View_img from '../../images/VIew.png';
import Like_img from '../../images/Like.png';
import './winner.scss';

export default function Winners() {
	const [campaignData, setcampaignData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [selectedCampaign, setselectedCampaign] = useState({
		likes: 0,
		winner: 'Not Found',
		comments: 0,
		views: 0,
		artWork: { imgUrl: '' }
	});
	const [error, setError] = useState('');
	const [selectedCampaignName, setselectedCampaignName] = useState([
		{ title: 'Last Campaign' }
	]);

	const [currentActiveTab, setcurrentActiveTab] = useState(
		'bestContentCreatorTab'
	);

	const [viewership, setviewership] = useState(null);
	const [engager, setengager] = useState(null);
	const [compainList, setCompainList] = useState([]);
	const [contentCreator, setcontentCreator] = useState(null);

	useEffect(() => {
		getCampaigns();
		return () => {};
	}, [1]);

	const getCampaigns = async () => {
		setLoading(true);
		const res = await getAllCampaigns();

		let filetered = res.data.campaigns.filter(
			item => item['winner'] !== undefined
		);
		if (filetered.length > 0) {
			setActiveCampaign(filetered[0]);
			setcampaignData(filetered);
			setselectedCampaignName(filetered[0].title);
			setLoading(false);
			return;
		} else {
			setLoading(false);
			setError('Winners Not Found');
		}
	};

	function setActiveCampaign(campaign) {
		if (campaign) {
			setselectedCampaign(campaign);

			setselectedCampaignName(campaign.title);

			if (campaign.winnersGeneratedArtworkImages['viewership'] == undefined && campaign.winner['viewership'] ) {
				setviewership(null);
			} else if(campaign.winnersGeneratedArtworkImages.viewership.length > 0) {
				setviewership(campaign.winnersGeneratedArtworkImages.viewership);
			}
			else{
				setviewership(campaign.winner.viewership);
			}
			if (campaign.winner['engager'] == undefined && campaign.winnersGeneratedArtworkImages['engager'] == undefined) {
				setengager(null);
			}
			else if(campaign.winnersGeneratedArtworkImages.engager.length > 0) {
				setengager(campaign.winnersGeneratedArtworkImages.engager);
			}
			else{
				setengager(campaign.winner.engager);
			}
			if (campaign.winner['contentCreator'] == undefined && campaign.winnersGeneratedArtworkImages['contentCreator'] == undefined) {
				setcontentCreator(null);
			}
			else if(campaign.winnersGeneratedArtworkImages.contentCreator.length > 0) {
				setcontentCreator(campaign.winnersGeneratedArtworkImages.contentCreator);
				console.log(campaign.winnersGeneratedArtworkImages.contentCreator,"Hello Creator");
			}
			else{
			//	console.log(campaign.winner.contentCreator,"Hello Creator");
				setcontentCreator(campaign.winner.contentCreator);
			}
		}
	}

	const changeActiveTab = screenName => {
		setcurrentActiveTab(screenName);
	};

	return (
		<>
			<Helmet>
				<title>Campaigns Winners</title>
			</Helmet>
			<ProfileStatsBar />
			<div className="container winnerCampainWrapper">
				{!campaignData && <CustomLoader loading={true} />}
				{error && (
					<div className="mt-4 text-center">
						<h2>{error}</h2>
					</div>
				)}

				<br />
				{campaignData && (
					<div>
						<div className="row">
							<div className="col-lg-9">
								<Tab.Container
									defaultActiveKey="bestContentCreatorTab"
									className="tab-container"
									style={{ flexWrap: 'nowrap' }}
									unmountOnExit={true}
								>
									<Nav variant="pills" className="tab-navbar mb-2">
										<div className="button-container">
											<div className="winner-button">
												<Autocomplete
													style={{ width: '100%' }}
													multiple={false}
													options={campaignData}
													defaultValue={{
														title: selectedCampaignName
													}}
													onChange={(e, item) => {
														setActiveCampaign(item);
													}}
													getOptionLabel={option => option.title}
													renderInput={params => (
														<TextField
															{...params}
															className=" choosing-option-autocomplete"
															variant="standard"
															label=""
															placeholder={'Campaign...'}
														/>
													)}
												/>
											</div>

											<div className="winner-button ">
												<Nav.Item
													className="radius-5"
													style={{
														backgroundColor:
															currentActiveTab !== 'bestContentCreatorTab'
																? '#146d9c'
																: '#00AC07'
													}}
												>
													<Nav.Link
														style={{ fontSize: '12px', padding: '12px' }}
														className="winner-nav-link"
														onClick={() =>
															changeActiveTab('bestContentCreatorTab')
														}
														eventKey="bestContentCreatorTab"
													>
														Best Content Creator
													</Nav.Link>
												</Nav.Item>
											</div>
											<div className=" winner-button">
												<Nav.Item
													className="radius-5"
													style={{
														backgroundColor:
															currentActiveTab !== 'bestEngagerTab'
																? '#146d9c'
																: '#00AC07'
													}}
												>
													<Nav.Link
														style={{ fontSize: '12px', padding: '12px' }}
														className="winner-nav-link"
														onClick={() => changeActiveTab('bestEngagerTab')}
														eventKey="bestEngagerTab"
													>
														Best Engager
													</Nav.Link>
												</Nav.Item>
											</div>

											<div className="winner-button">
												<Nav.Item
													className="radius-5"
													style={{
														backgroundColor:
															currentActiveTab !== 'bestViewershipTab'
																? '#146d9c'
																: '#00AC07'
													}}
												>
													<Nav.Link
														style={{ fontSize: '12px', padding: '12px' }}
														className="winner-nav-link"
														onClick={() => changeActiveTab('bestViewershipTab')}
														eventKey="bestViewershipTab"
													>
														Best Viewership
													</Nav.Link>
												</Nav.Item>
											</div>
										</div>
									</Nav>

									<Tab.Content>
										<Tab.Pane eventKey="bestContentCreatorTab">
											{contentCreator &&
												contentCreator.map((item, index) => {
													return (
														<>
															<div
																className={
																	index === 0
																		? 'profile-short-div p-0 pl-0 pr-0 campaign-card card '
																		: 'pl-0 pr-0 campaign-card card mt-4'
																}
															>
																<div className="profile-topbar-container campaign-header-div  winnerCampainCard">
																	<div
																		className=""
																		style={{
																			flexBasis: '50%',
																			display: 'flex',
																			alignItems: 'center'
																		}}
																	>
																		<Image
																			className="winner-dp-image img-thumbnail"
																				src={item?.userId?.profileImg || item?.profileImg}
																			rounded
																			roundedCircle
																		/>
																		<strong
																			className="profile-topbar-name profileName"
																			style={{
																				color: '#166d9c',
																				fontWeight: 'bold',
																				display: 'flex',
																				flexDirection: 'column'
																			}}
																		>
																			{item?.userId?.name || item?.name}
																			<span
																				style={{
																					color: '#898989',
																					fontSize: '10px',
																					fontWeight: 'bold',
																					display: 'flex',
																					flexDirection: 'column'
																				}}
																			>
																				<Moment format="DD/MM/YYYY">
																					{item.startsOn}
																				</Moment>
																			</span>
																		</strong>
																	</div>
																	{/* <div className="col-lg-1 col-md-1"></div> */}
																	<div
																		className="text-right d-flex"
																		style={{
																			display: 'none',
																			flexBasis: '50%',
																			justifyContent: 'flex-end'
																		}}
																	>
																		<strong
																			className="profile-topbar-winner-title"
																			style={{
																				color: '#146d9c',
																				fontWeight: 'bold',
																				fontSize: '18px'
																			}}
																		>
																			Congratulations
																		</strong>
																	</div>
																</div>
																<div
																	style={{
																		padding: '10px',
																		paddingBottom: '0',
																		display: 'flex',
																		justifyContent: 'space-between',
																		alignItems: 'center',
																		background: 'white'
																	}}
																>
																	<h4
																		style={{
																			fontSize: '14px',
																			color: '#146d9c',
																			display: 'flex',
																			flexDirection: 'column',
																			fontWeight: 'bold'
																		}}
																	>
																		<span
																			style={{
																				color: '#898989',
																				fontSize: '10px'
																			}}
																		>
																			Compain {index + 1}
																		</span>
																		{selectedCampaignName}
																	</h4>

																	<div
																		className="text-right d-flex"
																		style={{
																			justifyContent: 'flex-end'
																		}}
																	>
																		<div
																			className="d-flex profile-topbar-winner-button"
																			style={{}}
																		>
																			<img
																				className="profile-topbar-winner-icon"
																				style={{
																					marginRight: '10px',
																					width: '32',
																					height: '46px'
																				}}
																				src={tropy_img3}
																				alt="Trophy Icon"
																			/>
																			<strong
																				className="profile-topbar-winner-title bestContentCreaterBtn"
																				style={{
																					color: 'white',
																					background: '#146d9c',
																					borderRadius: '5px',
																					padding: '10px',
																					textAlign: 'center',
																					fontSize: '12px',
																					fontWeight: 'bold',
																					minWidth: '120px'
																				}}
																			>
																				&nbsp; Best Content Creator
																			</strong>
																		</div>
																	</div>
																</div>
																<div className="trendingcard-image-div m-auto container winner-card-profile-custom">
																	{/* <img  src={imgUrl} /> */}
																	<div
																		style={{
																			padding: '10px',
																			background: 'white'
																		}}
																	>
																		<img
																			src={
																				selectedCampaign &&
																				item.poster || selectedCampaign?.artWork?.imgUrl

																			}
																			className="card-img-top "
																			alt="..."
																		/>
																	</div>
																	<div
																		className="card-body"
																		style={{ padding: 0 }}
																	>
																		<div
																			className="trendingcard-header trendingcard-footer row"
																			style={{
																				justifyContent: 'space-between'
																			}}
																		>
																			<div className="col-3 text-center p-0 m-0">
																				<StatsComponent
																					link={Like_img}
																					value={selectedCampaign.likes}
																					w={33}
																				/>
																			</div>
																			<div className="col-3 text-center p-0 m-0">
																				<StatsComponent
																					link={Comments_img}
																					value={selectedCampaign.comments}
																					w={33}
																				/>
																			</div>
																			<div className="col-3 text-center p-0 m-0">
																				<StatsComponent
																					link={View_img}
																					value={selectedCampaign.views}
																					w={33}
																				/>
																			</div>
																			<div className="col-3 text-center p-0 m-0 bg-primary trending-card-trophy">
																				<div className="d-flex margin-auto">
																					<Link
																						className="margin-auto w-100"
																						style={{ color: 'white' }}
																						to="/winners"
																					>
																						<StatsComponent
																							link={tropy_img2}
																							value={
																								Object.keys(
																									selectedCampaign.winner
																								).length
																							}
																							w={20}
																							lastItem={true}
																						/>
																					</Link>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</>
													);
												})}

											{contentCreator.length === 0 ? (
												<div>
													<strong>
														&nbsp; No Winner Announced Yet for Best content
														Creator
													</strong>
												</div>
											) : null}
										</Tab.Pane>

										<Tab.Pane eventKey="bestEngagerTab">
											{engager && (
												<>
													{engager &&
														engager.map((item, index) => {
															return (
																<div
																	className={
																		index === 0
																			? 'profile-short-div  campaign-card card p-0'
																			: 'campaign-card card mt-4 p-0'
																	}
																>
																	<div className="profile-topbar-container campaign-header-div  winnerCampainCard">
																		<div
																			className=""
																			style={{
																				flexBasis: '50%',
																				display: 'flex',
																				alignItems: 'center'
																			}}
																		>
																			<Image
																				className="winner-dp-image img-thumbnail"
																				src={item?.userId?.profileImg || item?.profileImg}
																				rounded
																				roundedCircle
																			/>
																			<strong
																				className="profile-topbar-name profileName"
																				style={{
																					color: '#166d9c',
																					fontWeight: 'bold',
																					display: 'flex',
																					flexDirection: 'column'
																				}}
																			>
																			{item?.userId?.name || item.name}
																				<span
																					style={{
																						color: '#898989',
																						fontSize: '10px',
																						fontWeight: 'bold',
																						display: 'flex',
																						flexDirection: 'column'
																					}}
																				>
																					<Moment format="DD/MM/YYYY">
																						{item.startsOn}
																					</Moment>
																				</span>
																			</strong>
																		</div>
																		<div
																			className="text-right d-flex"
																			style={{
																				display: 'none',
																				flexBasis: '50%',
																				justifyContent: 'flex-end'
																			}}
																		>
																			<strong
																				className="profile-topbar-winner-title"
																				style={{
																					color: '#146d9c',
																					fontWeight: 'bold',
																					fontSize: '18px'
																				}}
																			>
																				Congratulations
																			</strong>
																		</div>
																		{/* <div className="col-lg-1 col-md-1"></div> */}
																	</div>
																	<div
																		style={{
																			padding: '10px',
																			paddingBottom: '0',
																			display: 'flex',
																			justifyContent: 'space-between',
																			alignItems: 'center',
																			background: 'white'
																		}}
																	>
																		<h4
																			style={{
																				fontSize: '14px',
																				color: '#146d9c',
																				display: 'flex',
																				flexDirection: 'column',
																				fontWeight: 'bold'
																			}}
																		>
																			<span
																				style={{
																					color: '#898989',
																					fontSize: '10px'
																				}}
																			>
																				Compain {index + 1}
																			</span>
																			{selectedCampaignName}
																		</h4>

																		<div
																			className="text-right d-flex"
																			style={{
																				justifyContent: 'flex-end'
																			}}
																		>
																			<div
																				className="d-flex profile-topbar-winner-button"
																				style={{}}
																			>
																				<img
																					className="profile-topbar-winner-icon"
																					style={{
																						marginRight: '10px',
																						width: '32',
																						height: '46px'
																					}}
																					src={tropy_img3}
																					alt="Trophy Icon"
																				/>
																				<strong
																					className="profile-topbar-winner-title bestContentCreaterBtn"
																					style={{
																						color: 'white',
																						background: '#146d9c',
																						borderRadius: '5px',
																						padding: '10px',
																						textAlign: 'center',
																						fontSize: '12px',
																						fontWeight: 'bold'
																					}}
																				>
																					&nbsp; BEST ENGAGER
																				</strong>
																			</div>
																		</div>
																	</div>
																	<div className="trendingcard-image-div m-auto container winner-card-profile-custom">
																		{/* <img  src={imgUrl} /> */}
																		<div
																			style={{
																				padding: '10px',
																				background: 'white'
																			}}
																		>
																			<img
																				src={
																					selectedCampaign &&
																					item.poster || selectedCampaign?.artWork?.imgUrl
																				}
																				className="card-img-top "
																				alt="..."
																			/>
																		</div>
																		<div
																			className="card-body"
																			style={{ padding: 0 }}
																		>
																			<div
																				className="trendingcard-header trendingcard-footer row"
																				style={{
																					justifyContent: 'space-between'
																				}}
																			>
																				<div className="col-3 text-center p-0 m-0">
																					<StatsComponent
																						link={Like_img}
																						value={selectedCampaign.likes}
																						w={33}
																					/>
																				</div>
																				<div className="col-3 text-center p-0 m-0">
																					<StatsComponent
																						link={Comments_img}
																						value={selectedCampaign.comments}
																						w={33}
																					/>
																				</div>
																				<div className="col-3 text-center p-0 m-0">
																					<StatsComponent
																						link={View_img}
																						value={selectedCampaign.views}
																						w={33}
																					/>
																				</div>
																				<div className="col-3 text-center p-0 m-0 bg-primary trending-card-trophy">
																					<div className="d-flex margin-auto">
																						<Link
																							className="margin-auto w-100"
																							style={{ color: 'white' }}
																							to="/winners"
																						>
																							<StatsComponent
																								link={tropy_img2}
																								value={
																									Object.keys(
																										selectedCampaign.winner
																									).length
																								}
																								w={20}
																								lastItem={true}
																							/>
																						</Link>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															);
														})}
												</>
											)}

											{engager.length === 0 ? (
												<div>
													<strong>
														&nbsp; No Winner Announced Yet for Best Engager
													</strong>
												</div>
											) : null}
										</Tab.Pane>

										<Tab.Pane eventKey="bestViewershipTab">
											{viewership && (
												<>
													{viewership &&
														viewership.map((item, index) => {
															return (
																<div
																	className={
																		index === 0
																			? 'profile-short-div campaign-card card p-0'
																			: 'campaign-card card mt-4 p-0'
																	}
																>
																	<div className="profile-topbar-container winnerCampainCard campaign-header-div  ">
																		<div
																			className=""
																			style={{
																				flexBasis: '50%',
																				display: 'flex',
																				alignItems: 'center'
																			}}
																		>
																			<Image
																				className="winner-dp-image img-thumbnail"
																					src={item?.userId?.profileImg || item?.profileImg}
																				rounded
																				roundedCircle
																			/>

																			<strong
																				className="profile-topbar-name profileName"
																				style={{
																					color: '#166d9c',
																					fontWeight: 'bold',
																					display: 'flex',
																					flexDirection: 'column'
																				}}
																			>
																																							{item?.userId?.name || item.name}

																				<span
																					style={{
																						color: '#898989',
																						fontSize: '10px',
																						fontWeight: 'bold',
																						display: 'flex',
																						flexDirection: 'column'
																					}}
																				>
																					<Moment format="DD/MM/YYYY">
																						{item.startsOn}
																					</Moment>
																				</span>
																			</strong>
																		</div>
																		{/* <div className="col-lg-1 col-md-1"></div> */}
																		<div
																			className="text-right d-flex"
																			style={{
																				display: 'none',
																				flexBasis: '50%',
																				justifyContent: 'flex-end'
																			}}
																		>
																			<strong
																				className="profile-topbar-winner-title"
																				style={{
																					color: '#146d9c',
																					fontWeight: 'bold',
																					fontSize: '18px'
																				}}
																			>
																				Congratulations
																			</strong>
																		</div>
																	</div>
																	<div
																		style={{
																			padding: '10px',
																			paddingBottom: '0',
																			display: 'flex',
																			justifyContent: 'space-between',
																			alignItems: 'center',
																			background: 'white'
																		}}
																	>
																		<h4
																			style={{
																				fontSize: '14px',
																				color: '#146d9c',
																				display: 'flex',
																				flexDirection: 'column',
																				fontWeight: 'bold'
																			}}
																		>
																			<span
																				style={{
																					color: '#898989',
																					fontSize: '10px'
																				}}
																			>
																				Compain {index + 1}
																			</span>
																			{selectedCampaignName}
																		</h4>

																		<div
																			className="text-right d-flex"
																			style={{
																				justifyContent: 'flex-end'
																			}}
																		>
																			<div
																				className="d-flex profile-topbar-winner-button"
																				style={{}}
																			>
																				<img
																					className="profile-topbar-winner-icon"
																					style={{
																						marginRight: '10px',
																						width: '32',
																						height: '46px'
																					}}
																					src={tropy_img3}
																					alt="Trophy Icon"
																				/>
																				<strong
																					className="profile-topbar-winner-title bestContentCreaterBtn"
																					style={{
																						color: 'white',
																						background: '#146d9c',
																						borderRadius: '5px',
																						padding: '10px',
																						textAlign: 'center',
																						fontSize: '12px',
																						fontWeight: 'bold'
																					}}
																				>
																					&nbsp; BEST VIEWERSHIP
																				</strong>
																			</div>
																		</div>
																	</div>

																	<div className="trendingcard-image-div m-auto container winner-card-profile-custom">
																		{/* <img  src={imgUrl} /> */}
																		<div
																			style={{
																				padding: '10px',
																				background: 'white'
																			}}
																		>
																			<img
																				src={
																					selectedCampaign &&
																					item.poster || selectedCampaign?.artWork?.imgUrl

																				}
																				className="card-img-top "
																				alt="..."
																			/>
																		</div>
																		<div
																			className="card-body"
																			style={{ padding: 0 }}
																		>
																			<div
																				className="trendingcard-header trendingcard-footer row"
																				style={{
																					justifyContent: 'space-between'
																				}}
																			>
																				<div className="col-3 text-center p-0 m-0">
																					<StatsComponent
																						link={Like_img}
																						value={selectedCampaign.likes}
																						w={33}
																					/>
																				</div>
																				<div className="col-3 text-center p-0 m-0">
																					<StatsComponent
																						link={Comments_img}
																						value={selectedCampaign.comments}
																						w={33}
																					/>
																				</div>
																				<div className="col-3 text-center p-0 m-0">
																					<StatsComponent
																						link={View_img}
																						value={selectedCampaign.views}
																						w={33}
																					/>
																				</div>
																				<div className="col-3 text-center p-0 m-0 bg-primary trending-card-trophy">
																					<div className="d-flex margin-auto">
																						<Link
																							className="margin-auto w-100"
																							style={{ color: 'white' }}
																							to="/winners"
																						>
																							<StatsComponent
																								link={tropy_img2}
																								value={
																									Object.keys(
																										selectedCampaign.winner
																									).length
																								}
																								w={20}
																								lastItem={true}
																							/>
																						</Link>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															);
														})}
												</>
											)}

											{viewership.length === 0 ? (
												<div>
													<strong>
														&nbsp; No Winner Announced Yet for Best Viewership
													</strong>
												</div>
											) : null}
										</Tab.Pane>
									</Tab.Content>
								</Tab.Container>
							</div>

							<SidebarTrending />
						</div>
					</div>
				)}

				<br />
				<br />
				<br />
				<br />
			</div>
		</>
	);
}
