import React, { useState, useEffect } from "react";
import { getAllCampaigns, getNomination, setNomination } from "../Api";
import { Modal, Button, Dropdown, DropdownButton } from "react-bootstrap";
import { TrendingUpRounded } from "@material-ui/icons";

export default function CampaignNomination({ teamId, teamMembers }) {
  const [campaignData, setcampaignData] = useState(null);
  const [nominationExists, setnominationExists] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [currentCampaign, setcurrentCampaign] = useState("");
  const [currentCampaignId, setcurrentCampaignId] = useState("");
  const [contentCreator, setcontentCreator] = useState("Select Winner");
  const [engager, setengager] = useState("Select Winner");
  const [viewership, setviewership] = useState("Select Winner");

  const [contentCreatorId, setcontentCreatorId] = useState("");
  const [engagerId, setengagerId] = useState("");
  const [viewershipId, setviewershipId] = useState("");

  useEffect(() => {
    getCampaigns();
    return () => {};
  }, [1]);

  const getCampaigns = async () => {
    const res = await getAllCampaigns();
    setcampaignData(res.data.campaigns);
    return;
  };

  const checkNomination = async (campaignId, campaignName) => {
    let res = await getNomination(teamId, campaignId);
    console.log(`${campaignId} and team id is ${teamId}`);
    if (res.error) {
      setnominationExists(false);
    } else {
      console.log("Nomination is", res.res.data.nomination);
      let nominees = res.res.data.nomination.nominees;
      setnominationExists(true);

      if (nominees.contentCreator) {
        setcontentCreator(nominees.contentCreator.name);
        setcontentCreatorId(nominees.contentCreator._id);
      }

      if (nominees.engager) {
        setengager(nominees.engager.name);
        setengagerId(nominees.engager._id);
      }

      if (nominees.viewership) {
        setviewership(nominees.viewership.name);
        setviewershipId(nominees.viewership._id);
      }
    }
    setcurrentCampaign(campaignName);
    setcurrentCampaignId(campaignId);
    handleShow();
  };

  const sendNomination = async () => {
    let data = {
      nominees: {
        contentCreator: contentCreatorId,
        engager: engagerId,
        viewership: viewershipId,
      },
    };

    let res = await setNomination(teamId, currentCampaignId, data);

    if (res.error) {
      console.log(res.error.data);
    } else {
      alert("Winners Nominated");

      handleClose();
    }
  };

  return (
    <div>
      <h2>Nominate Winners</h2>
      <br />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {nominationExists
              ? `Your Nomination for : ${currentCampaign}`
              : `Add Nomination for : ${currentCampaign}`}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {nominationExists ? (
            <div>
              <div className="form-group">
                <label>Best Content Creator Winner :</label>
                <DropdownButton
                  id="dropdown-basic-button"
                  title={contentCreator}
                  className="nomination-button"
                >
                  {teamMembers.map((item, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        onClick={() => {
                          setcontentCreator(item.name);
                          setcontentCreatorId(item._id);
                        }}
                      >
                        {item.name}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
              </div>

              {/* //best engager award */}
              <br />
              <div className="form-group">
                <label>Best Engagement Winner :</label>
                <DropdownButton
                  id="dropdown-basic-button"
                  title={engager}
                  className="nomination-button"
                >
                  {teamMembers.map((item, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        onClick={() => {
                          setengager(item.name);
                          setengagerId(item._id);
                        }}
                      >
                        {item.name}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
              </div>

              {/* //best viewership award */}
              <br />
              <div className="form-group">
                <label>Best Viewership Winner :</label>
                <DropdownButton
                  id="dropdown-basic-button"
                  title={viewership}
                  className="nomination-button"
                >
                  {teamMembers.map((item, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        onClick={() => {
                          setviewership(item.name);
                          setviewershipId(item._id);
                        }}
                      >
                        {item.name}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
              </div>
            </div>
          ) : (
            <div>
              <div className="form-group">
                <label>Best Content Creator Winner :</label>
                <DropdownButton
                  id="dropdown-basic-button"
                  title={contentCreator}
                  className="nomination-button"
                >
                  {teamMembers.map((item, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        onClick={() => {
                          setcontentCreator(item.name);
                          setcontentCreatorId(item._id);
                        }}
                      >
                        {item.name}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
              </div>

              {/* //best engager award */}
              <br />
              <div className="form-group">
                <label>Best Engagement Winner :</label>
                <DropdownButton
                  id="dropdown-basic-button"
                  title={engager}
                  className="nomination-button"
                >
                  {teamMembers.map((item, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        onClick={() => {
                          setengager(item.name);
                          setengagerId(item._id);
                        }}
                      >
                        {item.name}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
              </div>

              {/* //best viewership award */}
              <br />
              <div className="form-group">
                <label>Best Viewership Winner :</label>
                <DropdownButton
                  id="dropdown-basic-button"
                  title={viewership}
                  className="nomination-button"
                >
                  {teamMembers.map((item, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        onClick={() => {
                          setviewership(item.name);
                          setviewershipId(item._id);
                        }}
                      >
                        {item.name}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

          {!nominationExists && (
            <a>
              <Button variant="primary" onClick={() => sendNomination()}>
                Send Nomination
              </Button>
            </a>
          )}

          {nominationExists && (
            <a>
              <Button variant="primary" onClick={handleClose}>
                okay
              </Button>
            </a>
          )}
        </Modal.Footer>
      </Modal>

      <table className="table table-bordered">
        <thead>
          <tr>
            <th
              scope="col"
              className="font-weight-bold"
              style={{ width: "10%" }}
            >
              <div className="up-down-container">
                <strong> Sr.</strong>
                <div className="up-down-arrows"></div>
              </div>
            </th>
            <th
              scope="col"
              className="font-weight-bold"
              style={{ width: "60%" }}
            >
              <div className="up-down-container">
                <strong> Campaign Name </strong>
                <div className="up-down-arrows"></div>
              </div>
            </th>

            <th
              scope="col"
              className="font-weight-bold"
              style={{ width: "80%" }}
            >
              <div className="up-down-container">
                <strong> Nominate Winners</strong>
                <div className="up-down-arrows"></div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {campaignData &&
            campaignData.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.title}</td>
                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={() => checkNomination(item._id, item.title)}
                    >
                      Add Nomination
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
