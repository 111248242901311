import React, { useState, useEffect } from 'react';
import mergeImages from 'merge-images';
import Resizer from 'react-image-file-resizer';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import {
	participateInQuotes,
	checkImageExistence,
	setContentCreator,
	updateUserProfileImage
} from '../Api';
import fileDownload from 'js-file-download';
import { FaPlusSquare } from 'react-icons/fa';
import { uploadToCloudinary } from '../ImageCropper/canvasUtils';
import Cropper from 'react-easy-crop';

export default function TrendingCampaignClaimDiv({ item }) {
	const handleCloseProfile = () => setShowModal(false);
	const handleShowProfile = () => setShowModal(true);
	const [profileImgExist, setProfileImgExist] = useState(false);
	const [regularCreator, setRegularCreator] = useState(false);
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [showModal, setShowModal] = useState(false);
	const [userImageUrl, setuserImageUrl] = useState(null);
	const [userImage, setuserImage] = useState(null);
	const [showCropper, setShowCropper] = useState(false);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState('');
	const [disabledClaim, setDisabledClaim] = useState(false);
	const [loading, setloading] = useState(false);
	const imageChange = async image => {
		setuserImage(image);
		console.log('File is', image);
		//  const url = URL.createObjectURL(image);
		const url = URL.createObjectURL(image);
		setuserImageUrl(url);
		setShowCropper(true);
		//setShowCropper(true);
	};
	useEffect(() => {
		checkImage();
	});
	const onCropComplete = (croppedArea, croppedAreaPixels) => {
		console.log(croppedArea, croppedAreaPixels);
		setCroppedAreaPixels(croppedAreaPixels);
	};
	const imageUploader = async () => {
		const res = await uploadToCloudinary(
			userImageUrl,
			croppedAreaPixels,
			userImageUrl
		);

		let data = { profileImg: res.url };
		console.log(res);
		let resBackend = await updateUserProfileImage(data);
		let resContent = await setContentCreator({
			isContentCreator: regularCreator
		});
		if (!resBackend.error) {
			const done = JSON.parse(localStorage.getItem('InspirerWorld'));
			console.log(res);
			let newdata = {
				token: done.token,
				isLeader: done.isLeader,
				userId: done.userId,
				teamId: done.teamId,
				type: done.type,
				profileImg: res.url,
				name: done.name,
				expiry: done?.expiry
			};
			console.log(newdata.profileImg, 'succes', resContent);

			//   localStorage.removeItem('InspirerWorld')
			await localStorage.setItem('InspirerWorld', JSON.stringify(newdata));
			// console.log("success")
			setProfileImgExist(true);
			handleCloseProfile();
			window.location.reload();
		} else {
			console.log(resBackend);
		}
	};
	const checkImage = async () => {
		let res = await checkImageExistence();
		// console.log('exist',res)
		setProfileImgExist(res.data.isSet);
	};

	const finalFuncToDownloadProfilePic = async id => {
		if (profileImgExist) {
			setloading(true);
			setDisabledClaim(true);
			const res2 = await participateInQuotes(id);
			console.log('rs2', res2);
			let url = res2.data.userArtWork;
			let filename = `${item.title}.jpeg`;
			axios
				.get(url, {
					responseType: 'blob'
				})
				.then(res => {
					fileDownload(res.data, filename);
					setloading(false);
				});
			setDisabledClaim(false);
		} else {
			handleShowProfile();
		}
	};

	return (
		<div className="sidebar-list-item" style={{ alignItems: 'unset' }}>
			<Modal show={showCropper}>
				<Modal.Header closeButton={() => setShowCropper(false)}>
					<Modal.Title>Upload Image</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div
						id="crop-section"
						style={{
							width: '100vw',
							top: '0',
							bottom: '0',
							left: '0',
							right: '0',
							height: '50vh'
						}}
					>
						<Cropper
							style={{ zIndex: 100, backgroundColor: 'black' }}
							image={userImageUrl}
							crop={crop}
							zoom={zoom}
							aspect={780 / 780}
							onCropChange={crop => {
								setCrop(crop);
							}}
							onCropComplete={onCropComplete}
						/>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<input
						type="range"
						value={zoom}
						min="1"
						max="3"
						step="0.1"
						onChange={e => {
							setZoom(e.target.value);
						}}
						className="custom-range"
						id="customRange1"
					/>
					<br />
					<Button onClick={() => setShowCropper(false)}>Crop</Button>
				</Modal.Footer>
			</Modal>

			<Modal show={showModal} onHide={handleCloseProfile}>
				<Modal.Body>
					<div className="container row m-auto">
						<div className="row">
							<div className="col-12 p-2 text-center mb-5 m-auto">
								<h4>Thank for showing interest in Inspire World </h4>
								<span>Please let just know about you </span>
							</div>

							<div className="col-8 text-center ml-auto mr-auto mt-10 ">
								<h5 className="mt-4">Update your Profile Picture</h5>
								<input
									type="file"
									onChange={e => {
										imageChange(e.target.files[0]);
									}}
									id="chooseImage"
									className="d-none"
								/>
								<button
									className="upload-half-buttons mt-3  m-auto"
									onClick={() => document.getElementById('chooseImage').click()}
								>
									Click here to upload your picture
								</button>
							</div>
							<div className="col-8  ml-auto mr-auto mt-10 text-center ">
								<h5 className="mt-4">Are You a regular content Creator?</h5>

								<button
									id="CreatorTrue"
									onClick={() => {
										document.getElementById('CreatorTrue').style.background =
											'green';
										document.getElementById('CreatorFalse').style.background =
											'#146d9c';
										setRegularCreator(true);
									}}
									className="upload-half-buttons rounded   m-auto"
								>
									yes
								</button>
								{'                     '}

								<button
									id="CreatorFalse"
									onClick={e => {
										document.getElementById('CreatorTrue').style.background =
											'#146d9c';
										document.getElementById('CreatorFalse').style.background =
											'green';

										setRegularCreator(false);
									}}
									className="upload-half-buttons rounded   m-auto"
								>
									No
								</button>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseProfile}>
						Close
					</Button>
					<Button
						variant="primary"
						id="uploadbtn"
						onClick={() => {
							document.getElementById('uploadbtn').style.background = 'green';
							imageUploader();
						}}
					></Button>
				</Modal.Footer>
			</Modal>

			<div
				style={{ border: '0.5px solid #E5E5E5', padding: '10px' }}
				className="radius-5"
			>
				<span style={{ color: '#898989', fontSize: '12px' }}>
					<strong>{item.desc}</strong>
				</span>
			</div>

			<div
				style={{
					border: '0.5px solid #E5E5E5',
					padding: '10px',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					marginLeft: '7px'
				}}
				className="radius-5"
			>
				{loading && (
					<a
						classname="claim-hover"
						style={{ fontWeight: 'bold', color: '#898989' }}
					>
						Claiming
					</a>
				)}

				{!loading && (
					<a
						classname="claim-hover"
						style={{ fontWeight: '400', color: '#898989' }}
						onClick={() => finalFuncToDownloadProfilePic(item._id)}
					>
						Claim
					</a>
				)}
			</div>
		</div>
	);
}
