import React, { useState, useEffect } from 'react';
import Trendup from '../../images/trendup.svg';
import CampaignCard from './CampaignCard';
import { Helmet } from 'react-helmet-async';

import { getAllCampaigns } from '../Api';
import CustomLoader from '../CustomLoader';
import ProfileStatsBar from '../Profile/ProfileStatsBar';
import SidebarTrending from '../SidebarComponent/SidebarTrending';
import './campaigncard.css';
import { useHistory } from 'react-router-dom';

export default function CampaignScreen() {
	const [campaignData, setcampaignData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [userData, setUserData] = useState(null);
const router = useHistory();
	useEffect(() => {
		var data = JSON.parse(localStorage.getItem('InspirerWorld'));
		setUserData(data);
if(data){
		getCampaigns();}
	else{
		router.push({
			pathname: '/login',
			state:'campaigns'
		  })
	}
	}, [1]);

	const getCampaigns = async () => {
		setLoading(true);
		const res = await getAllCampaigns();
		// console.log('res is ',res.data)
		setcampaignData(res.data.campaigns);
		setLoading(false);
		return;
	};

	return (
		<>
		<Helmet>
				<title>Campaigns</title>
			</Helmet>
			<div>
				{campaignData && (
					<ProfileStatsBar topCampaign={campaignData[0]} type="campaign" />
				)}

				<br />

				<div className="container mt-2">
					<div className="row ">
						<div className="col-lg-9 col-md-12">
							{!campaignData && (
								<center>
									<h5>Loading.......</h5>
								</center>
							)}

							{campaignData && campaignData.length < 1 && (
								<div>
									<center>
										<img
											style={{ width: '50%' }}
											src="https://cdn.dribbble.com/users/2071065/screenshots/6559618/dribble_4-19_4x.png?compress=1&resize=800x600"
										/>
										<h4>Oops ! Nothing Found here...</h4>
									</center>
								</div>
							)}

							{campaignData &&
								campaignData.map((item, index) => {
									return (
										<CampaignCard
											c_number={campaignData.length - index}
											key={index}
											item={item}
											index={index}
											getCampaigns={getCampaigns}
										/>
									);
								})}
						</div>

						<SidebarTrending />
					</div>
				</div>
			</div></>
	
	);
}
