import React, { useState, useEffect } from 'react';
import StatsCard from '../Dashboard/StatsCard';
import { Link } from 'react-router-dom';
import {
	getTeamDetails,
	getMyTeamMembers,
	getMyTeamStats,
	getAllCampaigns,
	getMyTeamsMembers,
	getTeamCampaignStats,
	getUserParticipatedByCampaign,
	editUserParticipatedByStats,
	createNomination,
	deleteMember
} from '../Api';
import ProfileStatsBar from '../Profile/ProfileStatsBar';
import { FaLinkedin, FaWhatsapp } from 'react-icons/fa';
import { Button, Modal, Image } from 'react-bootstrap';
import CampaignNomination from './CampaignNomination';
import { BsThreeDots } from 'react-icons/bs';
import SidebarTrending from '../SidebarComponent/SidebarTrending';
import { MenuItem, Select } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import StatsComponent from './StatsComponent';

import tropy_img2 from '../../images/Winner-Fill.png';
import Comments_img from '../../images/Comments.png';
import View_img from '../../images/VIew.png';
import Like_img from '../../images/Like.png';

export default function MyTeamList(props) {
	let id = props.match.params.teamId;
	let teamName = props.match.params.teamname;

	const [userDetail, setuserDetail] = useState(null);
	const [updateStat, setUpdateStat] = useState({});
	const [searchTerm, setsearchTerm] = useState('');
	const [entryToShow, setentryToShow] = useState(25);
	const [teamData, setteamData] = useState(true);
	const [allMembers, setAllMembers] = useState([]);
	const [teamStats, setteamStats] = useState({});
	const [campaignStats, setCampaignStats] = useState({});
	const [show, setShow] = useState(false);
	const [showNominate, setShowNominate] = useState(false);
	const handleNominateClose = () => setShowNominate(false);
	const handleNominateShow = () => setShowNominate(true);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [likes, setlikes] = useState(0);
	const [views, setviews] = useState(0);
	const [linkedinUrl, setlinkedinUrl] = useState('');
	const [comments, setcomments] = useState(0);
	const [showWinnerMobile, setshowWinnerMobile] = useState(false);
	const [campaignData, setcampaignData] = useState([]);
	const [selectedCampaign, setSelectedCampaign] = useState('');
	const [memberList, setMemberList] = useState([]);
	const [myTeam, setMyTeam] = useState([]);
	const [updateStatsUser, setUpdateStatsUser] = useState('');
	const [nomination, setNomination] = useState([]);
	const [nominees, setNominees] = useState({
		contentCreator: '',
		viewership: '',
		engager: ''
	});
	const [del, showDelete] = useState(true);
	const deleteMem = async userId => {
		const res = await deleteMember(id, userId);
		if (res.error) {
			console.log(res.error);
		} else {
			console.log(res);
			window.location.reload();
		}
	};
	const updateStats = async () => {
		let body = {
			likes: likes,
			comments: comments,
			views: views,
			postUrl: linkedinUrl
		};
		console.log(selectedCampaign, updateStatsUser, body);
		let res = await editUserParticipatedByStats(
			selectedCampaign,
			updateStatsUser,
			body
		);
		if (res.error) {
			alert('User has not participated in this campaign');
			console.log(res.error);
		} else {
			//  let Teams=res.res.data
			handleClose();
			console.log(res.res.data);
		}
	};

	const submitNomination = async () => {
		let data = {
			nominees: {
				...nominees
			}
		};
		console.log(data);
		const res = await createNomination(
			userDetail.teamId,
			selectedCampaign,
			data
		);
		if (res.error) {
			alert('Some Error Occurred !');
			// console.log(res.error.data)
			console.log(res);
		} else {
			handleNominateClose();
			console.log('Res of Team is', res.res.data);

			//setNomination(res.res.data.team.members)
		}
	};
	const getCampaigns = async () => {
		// setLoading(true)
		const res = await getAllCampaigns();
		console.log('res is ', res.data);
		setcampaignData(res.data.campaigns);
		setSelectedCampaign(res.data.campaigns[0]._id);

		// setLoading(false)
		return;
	};
	var data = JSON.parse(localStorage.getItem('InspirerWorld'));

	useEffect(() => {
		getTeamStats();
		getCampaigns();
		getCampaignStats();
		if (data) {
			setuserDetail(data);
		}
		getUsers();
		return () => {};
	}, [1]);
	const getCampaignStats = async () => {
		const res = await getTeamCampaignStats();
		if (res.error) {
			alert('Some Error Occurred !');
			// console.log(res.error.data)
			console.log(res);
		} else {
			console.log('Res of Team is', res.res.data);
			setCampaignStats(res.res.data.team);
			setMyTeam(res.res.data.team.members);
			setNominees({
				contentCreator: res.res.data.team.members[0]._id,
				engager: res.res.data.team.members[1]._id,
				viewership: res.res.data.team.members[2]._id
			});
			//setNomination(res.res.data.team.members)
		}
	};
	const getTeamStats = async () => {
		const res = await getMyTeamsMembers();
		if (res.error) {
			alert('Some Error Occurred !');
			// console.log(res.error.data)
			console.log(res);
		} else {
			console.log('Res of Team is', res.res);
			setteamStats(res.res.data.stats);
		}
	};

	const selectUser = async (e, v) => {
		console.log(v.props);
		setUpdateStatsUser(v.props.value);
		let res = await getUserParticipatedByCampaign(
			selectedCampaign,
			v.props.value
		);
		if (res.error) {
			console.log(res.error);
		} else {
			//  let Teams=res.res.data

			console.log(res.res.data);
			setUpdateStat({
				likes: res.res.data.participation.likes,
				comments: res.res.data.participation.comments,
				views: res.res.data.participation.views
			});
		}
	};
	const getUsers = async () => {
		let res = await getMyTeamMembers(id);
		if (res.error) {
			console.log(res.error);
		} else {
			//  let Teams=res.res.data
			setAllMembers(res.res.data.team.members);
			console.log(res.res.data.team.members, 'members');
		}
	};
	const handleChange = async (e, v) => {
		console.log(v.props);
		setSelectedCampaign(v.props.value);
		let memberList = [];
		console.log(campaignStats);
		campaignStats.members.map(item => {
			item.campaigns.map(element => {
				console.log(
					element,
					v.props.value,
					element.campaignId == v.props.value
				);
				if (element.campaignId == v.props.value) {
					memberList.push({ user: item, stats: element.participation });
				}
			});
		});
		console.log(memberList, v.props.value);
		setMemberList(memberList);
	};
	return (
		<div>
			<ProfileStatsBar />
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Update Stats</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						<div className="form-group">
							<div className="myteam-buttonheader row">
								<div className="col-12 col-md-6">
									<Select
										labelId="demo-simple-select-label"
										className="btn btn-primary"
										id="demo-simple-select"
										style={{ color: 'white', width: '100%' }}
										value={selectedCampaign}
										onChange={handleChange}
									>
										{campaignData.map(item => {
											return <MenuItem value={item._id}>{item.title}</MenuItem>;
										})}
									</Select>
								</div>
								<div className="col-12 col-md-6">
									<Select
										labelId="demo-simple-select-label"
										className="btn btn-primary"
										id="demo-simple-select"
										style={{ color: 'white', width: '100%' }}
										onChange={selectUser}
									>
										{myTeam &&
											myTeam.map(item => {
												return (
													<MenuItem value={item._id}>{item.name}</MenuItem>
												);
											})}
									</Select>
								</div>
							</div>
							<label htmlFor="password">
								Likes{' '}
								<img
									style={{ marginTop: '-4px' }}
									src="https://img.icons8.com/material-sharp/20/101f41/facebook-like.png"
								/>{' '}
							</label>
							<input
								type="number"
								onChange={e => setlikes(e.target.value)}
								className="form-control"
								id="password"
								defaultValue={updateStat.likes}
								placeholder="Number of likes"
							/>
						</div>

						<div className="form-group">
							<label htmlFor="password">
								{' '}
								Comments{' '}
								<img
									style={{ marginTop: '-4px' }}
									src="https://img.icons8.com/material-rounded/20/101f41/topic.png"
								/>{' '}
							</label>
							<input
								defaultValue={updateStat.comments}
								type="number"
								onChange={e => setcomments(e.target.value)}
								className="form-control"
								id="password"
								placeholder="Number of Comments"
							/>
						</div>

						<div className="form-group">
							<label htmlFor="password">
								Views{' '}
								<img
									style={{ marginTop: '-4px' }}
									src="https://img.icons8.com/material-sharp/20/101f41/visible.png"
								/>
							</label>
							<input
								type="number"
								defaultValue={updateStat.views}
								onChange={e => setviews(e.target.value)}
								className="form-control"
								id="password"
								placeholder="Number of Views"
							/>
						</div>
						<div className="form-group">
							<label htmlFor="password">Linkedin Url :</label>
							<input
								type="text"
								onChange={e => setlinkedinUrl(e.target.value)}
								className="form-control"
								id="password"
								placeholder="Link of the Post"
							/>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
					<Button variant="primary" onClick={updateStats}>
						Update
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={showNominate} onHide={handleNominateClose}>
				<Modal.Header closeButton>
					<Modal.Title>Nominate</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						<div className="form-group">
							<div className="myteam-buttonheader row">
								<div className="col-12 mt-2" style={{ width: '60%' }}>
									<Select
										labelId="demo-simple-select-label"
										className="btn btn-primary"
										id="demo-simple-select"
										style={{ color: 'white', width: '100%' }}
										value={selectedCampaign}
										placeholder="Hello"
										onChange={handleChange}
									>
										{campaignData.map(item => {
											return <MenuItem value={item._id}>{item.title}</MenuItem>;
										})}
									</Select>
								</div>
								<div className="col-12 mt-2" style={{ width: '60%' }}>
									<div className="row mt-2">
										<div className="col-4 mt-2">
											<h5>best Content Creator</h5>{' '}
										</div>
										<div className="col-8 mt-2">
											<select
												aria-controls="datatable-buttons"
												className="custom-select  teams-custom-select"
												onChange={e => {
													setNominees({
														...nominees,
														contentCreator: e.target.value
													});
													console.log(nominees);
												}}
											>
												<option value="">None</option>
												{myTeam.map((item, index) => {
													return (
														<option key={index} value={item._id}>
															{item.name}
														</option>
													);
												})}
											</select>
										</div>
										<div className="col-4 mt-2">
											<h5>best viewership</h5>{' '}
										</div>
										<div className="col-8 mt-2">
											<select
												aria-controls="datatable-buttons"
												className="custom-select  teams-custom-select"
												onChange={e => {
													setNominees({
														...nominees,
														viewership: e.target.value
													});
													console.log(nominees);
												}}
											>
												{' '}
												<option value="">None</option>
												{myTeam.map((item, index) => {
													return (
														<option key={index} value={item._id}>
															{item.name}
														</option>
													);
												})}
											</select>
										</div>
										<div className="col-4 mt-2">
											<h5>best Engager</h5>{' '}
										</div>
										<div className="col-8 mt-2">
											<select
												aria-controls="datatable-buttons"
												className="custom-select  teams-custom-select"
												onChange={e => {
													setNominees({ ...nominees, engager: e.target.value });
													console.log(nominees);
												}}
											>
												<option value="">None</option>
												{myTeam.map((item, index) => {
													return (
														<option key={index} value={item._id}>
															{item.name}
														</option>
													);
												})}
											</select>
										</div>
									</div>
								</div>
							</div>
						</div>{' '}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleNominateClose}>
						Close
					</Button>
					<Button variant="primary" onClick={submitNomination}>
						Update
					</Button>
				</Modal.Footer>
			</Modal>

			{teamData && (
				<div className="container myteam-desktop-version">
					<br />
					<h3>---Team Details---</h3>
					<br />
					<h4>Team Name : {teamName}</h4>
					<br />
					<h4>Team Leader : {data && data.name}</h4>

					<br />
					<br />
					<h4>Total Stats</h4>
					<br />

					{teamStats && (
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<StatsCard
								img="../../images/white-copy.svg"
								title="Team Members"
								value={teamStats.members}
							/>

							<StatsCard
								img="../../images/white-copy.svg"
								title="Total Views"
								value={teamStats.views}
							/>

							<StatsCard
								img="../../images/white-copy.svg"
								title="Total Likes"
								value={teamStats.likes}
							/>

							<StatsCard
								img="../../images/white-copy.svg"
								title="Total Comments"
								value={teamStats.comments}
							/>
						</div>
					)}
				</div>
			)}

			{/* Table list of team members  */}
			{/* Table list of team members  */}
			{/* Table list of team members  */}
			<br />
			{/* <br /> */}

			<div className="dashboard-main-container myteam-desktop-version">
				<div className="teams-title">
					<h3
						className="last-campaign admin-last-campaign text-left"
						style={{ textTransform: 'capitalize' }}
					>
						Team Member List
					</h3>
				</div>

				<div className="dashboard-container admin-campaign">
					<div className="teams-search">
						<label className="teams-select">
							Show
							<select
								name="datatable-buttons_length"
								aria-controls="datatable-buttons"
								className="custom-select teams-custom-select"
								onChange={e => setentryToShow(e.target.value)}
							>
								<option value="10">10</option>
								<option value="25">25</option>
								<option value="50">50</option>
								<option value="100">100</option>
							</select>
							entries
						</label>

						<label className="teams-searchbar">
							Search:
							<input
								type="search"
								className="form-control form-control-sm"
								placeholder=""
								aria-controls="datatable-buttons"
								onChange={e => setsearchTerm(e.target.value)}
							/>
						</label>
					</div>

					<br />

					<br />
					<div className="container">
						<div className="row text-align-center">
							<div className="col-lg-6"></div>

							<div className="col-lg-6">
								<h3 className="last-campaign admin-last-campaign text-right"></h3>
							</div>
						</div>
					</div>

					<br />

					<table className="table table-bordered">
						<thead>
							<tr>
								<th
									scope="col"
									className="font-weight-bold"
									style={{ width: '10%' }}
								>
									<div className="up-down-container">
										<strong> Sr.</strong>
										<div className="up-down-arrows"></div>
									</div>
								</th>
								<th
									scope="col"
									className="font-weight-bold"
									style={{ width: '80%' }}
								>
									<div className="up-down-container">
										<strong> Member </strong>
										<div className="up-down-arrows"></div>
									</div>
								</th>

								<th
									scope="col"
									className="font-weight-bold"
									style={{ width: '80%' }}
								>
									<div className="up-down-container">
										<strong> Email</strong>
										<div className="up-down-arrows"></div>
									</div>
								</th>

								<th
									scope="col"
									className="font-weight-bold"
									style={{ width: '80%' }}
								>
									<div className="up-down-container">
										<strong> Whatsapp</strong>
										<div className="up-down-arrows"></div>
									</div>
								</th>

								<th scope="col" className="font-weight-bold">
									<div className="up-down-container">
										<strong>Action</strong>
										<div className="up-down-arrows"></div>
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							{allMembers &&
								allMembers
									.filter((item, index) => {
										if (
											(item.name + '')
												.toLowerCase()
												.includes(searchTerm.toLocaleLowerCase()) &&
											index < entryToShow
										)
											return item;
									})
									.map((item, index) => {
										return (
											<tr key={index + 1}>
												<th scope="row">{index + 1}</th>
												<td>
													<Link
														to={'/user/' + item.name + '/profile/' + item._id}
													>
														{item.name}
													</Link>
												</td>
												<td>{item.email}</td>
												<td>{item.phoneNumber}</td>
												<td style={{ display: 'flex' }}>
													<Link to={item.linkedinUrl}>
														<FaLinkedin
															color="#0d80e6"
															style={{
																width: '100%',
																height: '20px',
																width: '20px'
															}}
														/>
													</Link>
													&nbsp;
													<Link to={`https://wa.me/${item.phoneNumber}`}>
														<FaWhatsapp
															color="green"
															style={{
																width: '100%',
																height: '20px',
																width: '20px'
															}}
														/>
													</Link>
												</td>
											</tr>
										);
									})}
						</tbody>
					</table>

					<div className="prev-next-row">
						<div className="entries-text">
							Showing{' '}
							{entryToShow > allMembers.length
								? allMembers.length
								: entryToShow}{' '}
							of {allMembers.length} entries
						</div>
						{/* <div className='prev-next'>
            <div>Previous</div> <div className='prev-count'>1</div>{' '}
            <div>Next</div>
          </div> */}
					</div>
				</div>
			</div>

			<div className="container myteam-desktop-version">
				{allMembers && (
					<CampaignNomination teamId={id} teamMembers={allMembers} />
				)}
			</div>

			<div className="container" style={{ marginTop: '-10px' }}>
				<div className="row justify-content-center">
					<div className="col-lg-9  myteam-mobile-version">
						<div className="myteam-header radius-5">
							<span>
								<Image
									style={{ height: '40px', width: '40px', objectFit: 'cover' }}
									src={userDetail && userDetail.profileImg}
									rounded
									roundedCircle
									thumbnail
									fluid
								/>
								<strong className="font-bold">&nbsp; {'  '}My Team</strong>
							</span>
							{del ? (
								<Link
									className=""
									style={{ color: '#fff' }}
									onClick={() => showDelete(false)}
								>
									<strong>Edit</strong>
								</Link>
							) : (
								<Link
									className=""
									style={{ color: '#fff' }}
									onClick={() => showDelete(true)}
								>
									<strong>Hide</strong>
								</Link>
							)}
						</div>

						<div className="myteam-buttonheader">
							<button
								style={{ marginRight: '1%', fontWeight: 'bold' }}
								onClick={handleNominateShow}
								className="radius-5"
							>
								Nominate Winners
							</button>
							<button
								style={{ fontWeight: 'bold' }}
								onClick={handleShow}
								className="radius-5"
							>
								Add Stats
							</button>
						</div>

						<br />

						{!showWinnerMobile &&
							allMembers.map((item, index) => {
								return (
									<div key={index + 1} style={{ marginBottom: '23px' }}>
										<div
											className="myteam-header member-header radius-5"
											style={{ marginBottom: '7px' }}
										>
											<span>
												<Image
													style={{
														height: '40px',
														width: '40px',
														objectFit: 'cover'
													}}
													src={
														item && item?.profileImg
															? item?.profileImg
															: userDetail && userDetail.profileImg
													}
													rounded
													roundedCircle
													thumbnail
													fluid
												/>
												<span>
													&nbsp;
													<Link
														to={'/user/' + item.name + '/profile/' + item._id}
													>
														<strong className="font-bold">{item.name}</strong>
													</Link>
												</span>
											</span>
											{del ? (
												<span>
													{' '}
													<a target="_blank" href={item.linkedinUrl}>
														<FaLinkedin
															color="#0d80e6"
															style={{
																width: '100%',
																height: '20px',
																width: '20px'
															}}
														/>
													</a>
													&nbsp;
													<a
														target="_blank"
														href={`https://wa.me/${item.phoneNumber}`}
													>
														<FaWhatsapp
															color="green"
															style={{
																width: '100%',
																height: '20px',
																width: '20px'
															}}
														/>
													</a>
												</span>
											) : (
												<span>
													{' '}
													<Link onClick={() => deleteMem(item._id)}>
														<Delete
															color="#0d80e6"
															style={{
																width: '100%',
																height: '20px',
																width: '20px'
															}}
														/>
													</Link>
													&nbsp;
												</span>
											)}{' '}
										</div>

										<div
											className="trendingcard-footer"
											style={{
												display: 'flex',
												backgroundColor: '#fff !important'
											}}
										>
											<StatsComponent
												link={Like_img}
												value={item.likes}
												w={16}
											/>
											<StatsComponent
												link={Comments_img}
												value={item.comments}
												w={16}
											/>
											<StatsComponent
												link={View_img}
												value={item.views}
												w={16}
											/>
											<StatsComponent
												link={tropy_img2}
												value={0}
												w={16}
												lastItem={true}
											/>

											{/* <h6 className="footer-content-title view-winner-title">
                        <Link style={{ color: "#146d9c" }} to="/winners">
                          Winners
                        </Link>
                      </h6> */}
										</div>
									</div>
								);
							})}

						{showWinnerMobile &&
							allMembers &&
							allMembers.map((item, index) => {
								return (
									<div className="mt-4" key={index + 1}>
										<div className="myteam-header member-header">
											<span>
												<Image
													style={{
														height: '50px',
														width: '50px',
														objectFit: 'cover'
													}}
													src={userDetail && userDetail.profileImg}
													rounded
													roundedCircle
													thumbnail
													fluid
												/>
												<span>
													&nbsp;
													<Link
														to={'/user/' + item.name + '/profile/' + item._id}
													>
														<strong>{item.name}</strong>
													</Link>
												</span>
											</span>

											<span>
												<BsThreeDots style={{ width: '100%' }} />
											</span>
										</div>
									</div>
								);
							})}
					</div>

					<SidebarTrending />
				</div>
			</div>

			{/* end of mobile version here */}

			<br />
		</div>
	);
}
