import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getQuotesByCategory, getSuggestedQuotes } from "../Api";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaCopy,FaCheckCircle } from "react-icons/fa";

function SuggestedQuotes() {
  const [data, setdata] = useState(null);

  const [copyId, setcopyId] = useState(null);
  const params = useParams();
console.log('hello')
  useEffect(async () => {
    const res = await getQuotesByCategory(params.id);
    setdata(res.data.quotes);
    console.log(res.data)
  }, []);
  return (
    <div className="dashboard-container admin-campaign">
      <div className="container">
        <div className="row text-align-center">
          <div className="col-lg-6">
            <h3 className="last-campaign admin-last-campaign text-left">
              ~~ Quotes by Category ~~
            </h3>
          </div>

          <div className="col-lg-6">
            <h3 className="last-campaign admin-last-campaign text-right">
              <Link to="/admin-quote">
                <button className="btn btn-outline-info">
                  Go back to Quotes
                </button>
              </Link>
            </h3>
          </div>
        </div>
      </div>

      <br />

      <table className="table table-bordered">
        <thead>
          <tr>
            <th scope="col" className="font-weight-bold">
              Sr.
            </th>
            <th scope="col" className="font-weight-bold">
              Quote
            </th>
            <th scope="col" className="font-weight-bold">
              Author
            </th>
            <th scope="col" className="font-weight-bold">
              Artwork
            </th>
          </tr>
        </thead>
        <tbody>


          {data &&
            data.map((item, index) => {
              return (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>
                    <CopyToClipboard
                      text={item.desc}
                      onCopy={() => setcopyId(index)} >
                      

                        { copyId==index ? ( <button className="btn btn-primary" style={{ padding: "10px",
                      background : '#0ba50b' , border : 'none' }} >
                           <FaCheckCircle size={20} style={{ width: "20px", height: "20px" }} />
                      </button>
                           ) : (

                            <button className="btn btn-primary" style={{ padding: "10px" }} >
                           <FaCopy size={20} style={{ width: "20px", height: "20px" }} />
                      </button>

                             )}
                      
                
                    </CopyToClipboard>
                    &nbsp; &nbsp;
                    <span>
                      <strong>{item.desc}</strong>
                    </span>
                  </td>
                  <td>
                  <Link  to={'/quote-by-author/'+item.madeBy.name}>
                    {item.madeBy.name}
                    </Link></td>

                  <td> <img
                      src={item.artWork.imgUrl}
                      style={{ width: "50px" }}
                    /></td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default SuggestedQuotes;
